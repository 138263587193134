import React, { useContext } from "react";

// reactstrap components11
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  Form,
  FormGroup,
  TabContent,
  TabPane,
  Input,
} from "reactstrap";
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import classnames from "classnames";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import axios from "axios";
import moment from "moment";
import DataContext from "../../context/DataContext";
import NotificationAlert from "react-notification-alert";
import baseApi1 from "../../api/BaseApi1";

const { SearchBar } = Search;
const BrandApproval = () => {
  const { jwt_token } = JSON.parse(localStorage.getItem("user"));
  const userInfo = JSON.parse(localStorage.getItem("user"));
  const [showPerPage, setShowPerPage] = React.useState(null);
  const [tabs, setTabs] = React.useState(1);
  const [changeRequests, setChangeRequests] = React.useState([]);
  const componentRef = React.useRef(null);
  //Notification Alert
  const notificationAlertRef = React.useRef(null);
  const notify = (type, message) => {
    let options = {
      place: "bc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            Approval Notification
          </span>
          <span data-notify="message">{message}</span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 15,
    };
    notificationAlertRef.current.notificationAlert(options);
  };
  const getChangeRequests = async () => {
    try {
      const response = await baseApi1.get(
        "/api/change-requests",
        {
          headers: {
            Authorization: `Bearer ${jwt_token}`,
          },
        }
      );
      if (!response.statusText === "OK") {
        console.log("Not Getting Response From Server");
      } else {
        console.log(response.data);
        setChangeRequests(response.data);
      }
    } catch (err) {
      console.log(err.response);
    }
  };
  React.useEffect(() => {
    getChangeRequests();
  }, [tabs]);

  const pagination1 = paginationFactory({
    page: 1,
    alwaysShowAllBtns: true,
    showTotal: true,
    withFirstAndLast: false,
    sizePerPageRenderer: ({ onSizePerPageChange }) => (
      <div className="dataTables_length" id="datatable-basic_length">
        <label>
          Show{" "}
          {
            <select
              name="datatable-basic_length"
              aria-controls="datatable-basic"
              className="form-control form-control-sm"
              onChange={(e) => onSizePerPageChange(e.target.value)}
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
              <option value="">All</option>
            </select>
          }{" "}
          entries.
        </label>
      </div>
    ),
  });
  const pagination2 = paginationFactory({
    page: 1,
    alwaysShowAllBtns: true,
    showTotal: true,
    withFirstAndLast: false,
    sizePerPageRenderer: ({ onSizePerPageChange }) => (
      <div className="dataTables_length" id="datatable-basic_length">
        <label>
          Show{" "}
          {
            <select
              name="datatable-basic_length"
              aria-controls="datatable-basic"
              className="form-control form-control-sm"
              onChange={(e) => onSizePerPageChange(e.target.value)}
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
              <option value="">All</option>
            </select>
          }{" "}
          entries.
        </label>
      </div>
    ),
  });
  const pagination3 = paginationFactory({
    page: 1,
    alwaysShowAllBtns: true,
    showTotal: true,
    withFirstAndLast: false,
    sizePerPageRenderer: ({ onSizePerPageChange }) => (
      <div className="dataTables_length" id="datatable-basic_length">
        <label>
          Show{" "}
          {
            <select
              name="datatable-basic_length"
              aria-controls="datatable-basic"
              className="form-control form-control-sm"
              onChange={(e) => onSizePerPageChange(e.target.value)}
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
              <option value="">All</option>
            </select>
          }{" "}
          entries.
        </label>
      </div>
    ),
  });
  const declineRequests = async (id) => {
    try {
      const response = await baseApi1.delete(
        `/api/change-request/${id}`,
        {
          headers: {
            Authorization: `Bearer ${jwt_token}`,
          },
        }
      );
      if (!response.statusText === "OK") {
        console.log("Not Getting Response From Server");
      } else {
        console.log(response.data.message);
        getChangeRequests();
        notify("success", response.data.message);
      }
    } catch (err) {
      console.log(err.response);
    }
  };
  const approveRequests = async (id) => {
    try {
      const response = await baseApi1.put(
        `/api/change-request/${id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${jwt_token}`,
          },
        }
      );
      if (!response.statusText === "OK") {
        console.log("Not Getting Response From Server");
      } else {
        console.log(response.data.message);
        getChangeRequests();
        notify("success", response.data.message);
      }
    } catch (err) {
      console.log(err.response);
    }
  };
  const handleViewData = () => {};
  const handleApprove = (id) => {
    approveRequests(id);
  };
  const handleDecline = (id) => {
    declineRequests(id);
  };
  return (
    <div>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <SimpleHeader name="Request Status" parentName="Requests" />
      <Container className="mt--6" fluid>
        <Card>
          <Container>
            <div className="nav-wrapper">
              <Nav
                className="nav-fill flex-column flex-md-row"
                id="tabs-icons-text"
                pills
                role="tablist"
              >
                <NavItem>
                  <NavLink
                    aria-selected={tabs === 1}
                    className={classnames("mb-sm-3 mb-md-0", {
                      active: tabs === 1,
                    })}
                    onClick={() => setTabs(1)}
                    role="tab"
                    style={{ cursor: "pointer" }}
                  >
                    <i className="ni ni-button-pause mr-2" />
                    Pending Requests
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    aria-selected={tabs === 2}
                    className={classnames("mb-sm-3 mb-md-0", {
                      active: tabs === 2,
                    })}
                    onClick={() => setTabs(2)}
                    role="tab"
                    style={{ cursor: "pointer" }}
                  >
                    <i className="ni ni-check-bold mr-2" />
                    Approved Requests
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    aria-selected={tabs === 3}
                    className={classnames("mb-sm-3 mb-md-0", {
                      active: tabs === 3,
                    })}
                    onClick={() => setTabs(3)}
                    role="tab"
                    style={{ cursor: "pointer" }}
                  >
                    <i className="ni ni-fat-remove mr-2" />
                    Declined Requests
                  </NavLink>
                </NavItem>
              </Nav>
            </div>
          </Container>
          <Container>
            <Card className="shadow">
              <CardBody>
                <TabContent activeTab={"tabs" + tabs}>
                  <TabPane tabId="tabs1">
                    <Row>
                      <div className="col">
                        <ToolkitProvider
                          data={changeRequests.filter(
                            (x) => x.brand.action === 0
                          )}
                          keyField="outletCode"
                          columns={[
                            {
                              dataField: "#",
                              text: "SL",
                              formatter: (cell, row, rowIndex) => {
                                return rowIndex + 1;
                              },
                            },
                            {
                              dataField: "date",
                              text: "Request Time",
                              sort: true,
                              formatter: (cell) => {
                                return moment(new Date(cell)).format(
                                  "MMMM Do YYYY, h:mm:ss a"
                                );
                              },
                            },
                            {
                              dataField: "outletCode",
                              text: "Outlet Code",
                              sort: true,
                            },
                            {
                              dataField: "outlet.name",
                              text: "Outlet Name",
                              sort: true,
                            },
                            {
                              dataField: "region",
                              text: "Region Name",
                              sort: true,
                              formatter: (cell) => {
                                return cell.name;
                              },
                            },
                            {
                              dataField: "area",
                              text: "Area Name",
                              sort: true,
                              formatter: (cell) => {
                                return cell.name;
                              },
                            },
                            {
                              dataField: "territory",
                              text: "Territory Name",
                              sort: true,
                              formatter: (cell) => {
                                return cell.name;
                              },
                            },
                            {
                              dataField: "oldSalesPoint.name",
                              text: "SalesPoint Name",
                              sort: true,
                            },
                            {
                              dataField: "oldRoute.name",
                              text: "Route Name",
                              sort: true,
                            },
                            {
                              dataField: "oldBrand",
                              text: "Focus Brand",
                              sort: true,
                            },
                            {
                              dataField: "brand.name",
                              text: "Focus Brand",
                              sort: true,
                              style: {
                                fontWeight: "bold",
                                backgroundColor: "orange",
                                color: "white",
                              },
                            },
                            {
                              dataField: "_id",
                              text: "Action",
                              formatter: (cell) => {
                                return (
                                  <div>
                                    <Button
                                      color="success"
                                      onClick={() => handleApprove(cell)}
                                    >
                                      Approve
                                    </Button>
                                    <Button
                                      onClick={() => handleDecline(cell)}
                                      color="danger"
                                    >
                                      Decline
                                    </Button>
                                  </div>
                                );
                              },
                            },
                          ]}
                          search
                        >
                          {(props) => (
                            <div className="py-4 table-responsive">
                              <Container fluid>
                                <Row>
                                  <Col xs={6} sm={6}>
                                    <div>
                                      <select
                                        name="datatable-basic_length"
                                        aria-controls="datatable-basic"
                                        className="form-control form-control-sm w-25"
                                        onChange={(e) =>
                                          setShowPerPage(e.target.value)
                                        }
                                      >
                                        <option
                                          selected={showPerPage === 10 && true}
                                          value={10}
                                        >
                                          10
                                        </option>
                                        <option
                                          selected={showPerPage === 25 && true}
                                          value={25}
                                        >
                                          25
                                        </option>
                                        <option
                                          selected={showPerPage === 50 && true}
                                          value={50}
                                        >
                                          50
                                        </option>
                                        <option
                                          selected={showPerPage === 100 && true}
                                          value={100}
                                        >
                                          100
                                        </option>
                                        <option
                                          selected={
                                            showPerPage === "all" && true
                                          }
                                          value="all"
                                        >
                                          all
                                        </option>
                                      </select>
                                    </div>
                                  </Col>
                                  <Col xs={6} sm={6}>
                                    <div
                                      id="datatable-basic_filter"
                                      className="dataTables_filter px-4 pb-1 float-right"
                                    >
                                      <label>
                                        Search:
                                        <SearchBar
                                          className="form-control-sm"
                                          placeholder=""
                                          {...props.searchProps}
                                        />
                                      </label>
                                    </div>
                                  </Col>
                                </Row>
                              </Container>
                              <BootstrapTable
                                ref={componentRef}
                                {...props.baseProps}
                                bootstrap4={true}
                                pagination={pagination1}
                                noDataIndication="There is no data"
                                bordered={false}
                                id="react-bs-table"
                              />
                            </div>
                          )}
                        </ToolkitProvider>
                      </div>
                    </Row>
                  </TabPane>
                  <TabPane tabId="tabs2">
                    <Row>
                      <div className="col">
                        <ToolkitProvider
                          data={changeRequests.filter(
                            (x) => x.brand.action === 1
                          )}
                          keyField="outlet"
                          columns={[
                            {
                              dataField: "#",
                              text: "SL",
                              formatter: (cell, row, rowIndex) => {
                                return rowIndex + 1;
                              },
                            },
                            {
                              dataField: "date",
                              text: "Request Time",
                              sort: true,
                              formatter: (cell) => {
                                return moment(new Date(cell)).format(
                                  "MMMM Do YYYY, h:mm:ss a"
                                );
                              },
                            },
                            {
                              dataField: "outletCode",
                              text: "Outlet Code",
                              sort: true,
                            },
                            {
                              dataField: "outlet.name",
                              text: "Outlet Name",
                              sort: true,
                            },
                            {
                              dataField: "region",
                              text: "Region Name",
                              sort: true,
                              formatter: (cell) => {
                                return cell.name;
                              },
                            },
                            {
                              dataField: "area",
                              text: "Area Name",
                              sort: true,
                              formatter: (cell) => {
                                return cell.name;
                              },
                            },
                            {
                              dataField: "territory",
                              text: "Territory Name",
                              sort: true,
                              formatter: (cell) => {
                                return cell.name;
                              },
                            },
                            {
                              dataField: "oldSalesPoint.name",
                              text: "SalesPoint Name",
                              sort: true,
                            },
                            {
                              dataField: "oldSalesPoint.name",
                              text: "SalesPoint",
                              sort: true,
                            },
                            {
                              dataField: "oldRoute.name",
                              text: "Route Name",
                              sort: true,
                            },
                            {
                              dataField: "oldBrand",
                              text: "Focus Brand",
                              sort: true,
                            },
                            {
                              dataField: "brand.name",
                              text: "Focus Brand",
                              sort: true,
                              style: {
                                fontWeight: "bold",
                                backgroundColor: "orange",
                                color: "white",
                              },
                            },
                          ]}
                          search
                        >
                          {(props) => (
                            <div className="py-4 table-responsive">
                              <Container fluid>
                                <Row>
                                  <Col xs={6} sm={6}>
                                    <div>
                                      <select
                                        name="datatable-basic_length"
                                        aria-controls="datatable-basic"
                                        className="form-control form-control-sm w-25"
                                        onChange={(e) =>
                                          setShowPerPage(e.target.value)
                                        }
                                      >
                                        <option
                                          selected={showPerPage === 10 && true}
                                          value={10}
                                        >
                                          10
                                        </option>
                                        <option
                                          selected={showPerPage === 25 && true}
                                          value={25}
                                        >
                                          25
                                        </option>
                                        <option
                                          selected={showPerPage === 50 && true}
                                          value={50}
                                        >
                                          50
                                        </option>
                                        <option
                                          selected={showPerPage === 100 && true}
                                          value={100}
                                        >
                                          100
                                        </option>
                                        <option
                                          selected={
                                            showPerPage === "all" && true
                                          }
                                          value="all"
                                        >
                                          all
                                        </option>
                                      </select>
                                    </div>
                                  </Col>
                                  <Col xs={6} sm={6}>
                                    <div
                                      id="datatable-basic_filter"
                                      className="dataTables_filter px-4 pb-1 float-right"
                                    >
                                      <label>
                                        Search:
                                        <SearchBar
                                          className="form-control-sm"
                                          placeholder=""
                                          {...props.searchProps}
                                        />
                                      </label>
                                    </div>
                                  </Col>
                                </Row>
                              </Container>
                              <BootstrapTable
                                ref={componentRef}
                                {...props.baseProps}
                                bootstrap4={true}
                                pagination={pagination2}
                                noDataIndication="There is no data"
                                bordered={false}
                                id="react-bs-table"
                              />
                            </div>
                          )}
                        </ToolkitProvider>
                      </div>
                    </Row>
                  </TabPane>
                  <TabPane tabId="tabs3">
                    <Row>
                      <div className="col">
                        <ToolkitProvider
                          data={changeRequests.filter(
                            (x) => x.brand.action === -1
                          )}
                          keyField="outlet"
                          columns={[
                            {
                              dataField: "#",
                              text: "SL",
                              formatter: (cell, row, rowIndex) => {
                                return rowIndex + 1;
                              },
                            },
                            {
                              dataField: "date",
                              text: "Request Time",
                              sort: true,
                              formatter: (cell) => {
                                return moment(new Date(cell)).format(
                                  "MMMM Do YYYY, h:mm:ss a"
                                );
                              },
                            },
                            {
                              dataField: "outletCode",
                              text: "Outlet Code",
                              sort: true,
                            },
                            {
                              dataField: "outlet.name",
                              text: "Outlet Name",
                              sort: true,
                            },
                            {
                              dataField: "region",
                              text: "Region Name",
                              sort: true,
                              formatter: (cell) => {
                                return cell.name;
                              },
                            },
                            {
                              dataField: "area",
                              text: "Area Name",
                              sort: true,
                              formatter: (cell) => {
                                return cell.name;
                              },
                            },
                            {
                              dataField: "territory",
                              text: "Territory Name",
                              sort: true,
                              formatter: (cell) => {
                                return cell.name;
                              },
                            },
                            {
                              dataField: "oldSalesPoint.name",
                              text: "SalesPoint Name",
                              sort: true,
                            },
                            {
                              dataField: "oldSalesPoint.name",
                              text: "SalesPoint",
                              sort: true,
                            },
                            {
                              dataField: "oldRoute.name",
                              text: "Route Name",
                              sort: true,
                            },
                            {
                              dataField: "oldBrand",
                              text: "Focus Brand",
                              sort: true,
                            },
                            {
                              dataField: "brand.name",
                              text: "Focus Brand",
                              sort: true,
                              style: {
                                fontWeight: "bold",
                                backgroundColor: "orange",
                                color: "white",
                              },
                            },
                          ]}
                          search
                        >
                          {(props) => (
                            <div className="py-4 table-responsive">
                              <Container fluid>
                                <Row>
                                  <Col xs={6} sm={6}>
                                    <div>
                                      <select
                                        name="datatable-basic_length"
                                        aria-controls="datatable-basic"
                                        className="form-control form-control-sm w-25"
                                        onChange={(e) =>
                                          setShowPerPage(e.target.value)
                                        }
                                      >
                                        <option
                                          selected={showPerPage === 10 && true}
                                          value={10}
                                        >
                                          10
                                        </option>
                                        <option
                                          selected={showPerPage === 25 && true}
                                          value={25}
                                        >
                                          25
                                        </option>
                                        <option
                                          selected={showPerPage === 50 && true}
                                          value={50}
                                        >
                                          50
                                        </option>
                                        <option
                                          selected={showPerPage === 100 && true}
                                          value={100}
                                        >
                                          100
                                        </option>
                                        <option
                                          selected={
                                            showPerPage === "all" && true
                                          }
                                          value="all"
                                        >
                                          all
                                        </option>
                                      </select>
                                    </div>
                                  </Col>
                                  <Col xs={6} sm={6}>
                                    <div
                                      id="datatable-basic_filter"
                                      className="dataTables_filter px-4 pb-1 float-right"
                                    >
                                      <label>
                                        Search:
                                        <SearchBar
                                          className="form-control-sm"
                                          placeholder=""
                                          {...props.searchProps}
                                        />
                                      </label>
                                    </div>
                                  </Col>
                                </Row>
                              </Container>
                              <BootstrapTable
                                ref={componentRef}
                                {...props.baseProps}
                                bootstrap4={true}
                                pagination={pagination3}
                                noDataIndication="There is no data"
                                bordered={false}
                                id="react-bs-table"
                              />
                            </div>
                          )}
                        </ToolkitProvider>
                      </div>
                    </Row>
                  </TabPane>
                </TabContent>
              </CardBody>
            </Card>
          </Container>
        </Card>
      </Container>
    </div>
  );
};

export default BrandApproval;
