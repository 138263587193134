/*eslint-disable*/

// reactstrap components11
import {NavItem, NavLink, Nav, Container, Row, Col} from "reactstrap";

function AuthFooter() {
    return (
        <>
            <footer className="py-5" id="footer-main">
                <Container>
                    <Row className="align-items-center justify-content-xl-between">
                        <Col xl="6">
                            <div className="copyright text-center text-xl-left text-white">
                                © {new Date().getFullYear()}{" "}
                                <a
                                    className="font-weight-bold ml-1"
                                    href="https://fifo-tech.com"
                                    target="_blank"
                                >
                                    FIFOTech
                                </a>
                            </div>
                        </Col>
                        <Col xl="6"></Col>
                    </Row>
                </Container>
            </footer>
        </>
    );
}

export default AuthFooter;
