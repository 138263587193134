import React, { useContext, useEffect } from "react";
// react plugin that prints a given react component
import ReactToPrint from "react-to-print";
import classnames from "classnames";
// react component for creating dynamic tables
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import ReactLoading from "react-loading";

// reactstrap components11
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  Container,
  CardBody,
  FormGroup,
  Form,
  Modal,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import baseApi1 from "../../api/BaseApi1";
import {baseURL1} from "../../api/baseURL.json";

import axios from "axios";
import DataContext from "context/DataContext";
import NotificationAlert from "react-notification-alert";
import CheckboxTree from "react-checkbox-tree";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import moment from "moment";

const { SearchBar } = Search;

const Campaign = () => {
  const { region, area, territory, outlet } = useContext(DataContext);
  const [showPerPage, setShowPerPage] = React.useState(null);
  //Edit Campaign
  const [campaignName, setCampaignName] = React.useState("");
  const [startDate, setStartDate] = React.useState("");
  const [endDate, setEndDate] = React.useState("");
  const [campaignAction, setCampaignAction] = React.useState("");
  const [description, setDescription] = React.useState("");
  //Add Campaign
  const [newCampaignName, setNewCampaignName] = React.useState("");
  const [newStartDate, setNewStartDate] = React.useState(null);
  const [newEndDate, setNewEndDate] = React.useState(null);
  const [newCampaignAction, setNewCampaignAction] = React.useState("");
  const [newDescription, setNewDescription] = React.useState("");
  //Others
  const [formModal, setformModal] = React.useState(false);
  const [addCampaignModal, setCampaignModal] = React.useState(false);
  const [assignModal, setAssaignModal] = React.useState(false);
  const [allCampaign, setAllCampaign] = React.useState([]);
  const [singleCampaign, setSingleCampaign] = React.useState({});
  const [editedStartDate, setEditedStartDate] = React.useState("");
  const [editedEndDate, setEditedEndDate] = React.useState("");
  //Assign Tree
  const [treeData, setTreeData] = React.useState([]);
  const [nodeTree, setNodeTree] = React.useState([]);
  const [expanded, setExpended] = React.useState([]);
  const [checkedNode, setCheckedNode] = React.useState([]);
  const [singleCheckedNode, setSingleCheckedNode] = React.useState([]);

  const getTreeData = async () => {
    try {
      const response = await baseApi1.get("/api/regions/tree");
      if (!response.statusText === "OK") {
        notify("danger", "Not Getting Response");
      } else {
        setTreeData(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const treeoutput = treeData?.map((region) => {
      const regionMapping = {
        value: region._id,
        label: region.name,
      };

      if (region.area.length) {
        regionMapping.children = region.area.map((a) => {
          const areaMapping = {
            value: a._id,
            label: a.name,
          };
          if (a.territory.length) {
            areaMapping.children = a.territory.map((t) => {
              const territoryMapping = {
                value: t._id,
                label: t.name,
              };

              if (t.outlets.length) {
                territoryMapping.children = t.outlets.map((outlet) => ({
                  value: outlet._id,
                  label: outlet.name,
                }));
              }
              return territoryMapping;
            });
          }
          return areaMapping;
        });
      }
      return regionMapping;
    });
    setNodeTree(treeoutput);
  }, [treeData]);

  //Notification Alert
  const notificationAlertRef = React.useRef(null);
  const notify = (type, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            TMS Notification
          </span>
          <span data-notify="message">{message}</span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 7,
    };
    notificationAlertRef.current.notificationAlert(options);
  };

  const componentRef = React.useRef(null);
  // this function will copy to clipboard an entire table,
  // so you can paste it inside an excel or csv file
  const getCampaignData = async () => {
    try {
      const response = await baseApi1.get("/api/campaign/all");
      if (!response.statusText === "OK") {
        console.log("Not Getting Response");
      } else {
        setAllCampaign(response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getOneCampaignData = async (id) => {
    try {
      const response = await baseApi1.get(
        `/api/campaign/one/${id}`
      );
      if (!response.statusText === "OK") {
        console.log("Not Getting Response");
      } else {
        setEditedStartDate(
          moment(new Date(response.data.startDate)).format("yyyy-MM-DD")
        );
        setEditedEndDate(
          moment(new Date(response.data.endDate)).format("yyyy-MM-DD")
        );
        setSingleCheckedNode([
          ...response.data.region,
          ...response.data.area,
          ...response.data.territory,
          ...response.data.outlet,
        ]);
        setSingleCampaign(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const deleteOneCampaign = async (id) => {
    try {
      const response = await baseApi1.delete(
        `/api/campaign/${id}`
      );
      if (!response.statusText === "OK") {
        console.log("Not Getting Response");
        notify("danger", response.data.message);
      } else {
        getCampaignData();
        notify("success", response.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const updateOneUserData = async (id) => {
    const editedTms = {
      name: campaignName === "" ? singleCampaign?.name : campaignName,
      description:
        description === "" ? singleCampaign?.description : description,
      startDate: startDate === null ? editedStartDate : startDate,
      endDate: endDate === null ? editedEndDate : endDate,
      status: campaignAction === "" ? singleCampaign.action : campaignAction,
    };
    try {
      const response = await baseApi1.put(
        `/api/campaign/${id}`,
        editedTms,
        { headers: { "Content-Type": "application/json" } }
      );
      if (!response.statusText === "OK") {
        console.log("Not Getting Response");
        notify("danger", response.data.message);
      } else {
        getCampaignData();
        setformModal(false);
        notify("success", response.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getCampaignData();
  }, []);

  const pagination = paginationFactory({
    page: 1,
    alwaysShowAllBtns: true,
    showTotal: true,
    withFirstAndLast: false,
    sizePerPageRenderer: ({
      options,
      currSizePerPage,
      onSizePerPageChange,
    }) => (
      <div className="dataTables_length" id="datatable-basic_length">
        <label>
          Show{" "}
          {
            <select
              name="datatable-basic_length"
              aria-controls="datatable-basic"
              className="form-control form-control-sm"
              onChange={(e) => onSizePerPageChange(e.target.value)}
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
              <option value="all">All</option>
            </select>
          }{" "}
          entries.
        </label>
      </div>
    ),
  });
  const addNewCampaign = async () => {
    const newCampaign = {
      name: newCampaignName,
      description: newDescription,
      startDate: newStartDate,
      endDate: newEndDate,
      status: newCampaignAction,
    };
    try {
      const response = await baseApi1.post(
        "/api/campaign",
        newCampaign,
        { headers: { "Content-Type": "application/json" } }
      );
      if (!response.statusText === "OK") {
        console.log("Not Getting Response");
        notify("danger", "Some Thing Went Wrong");
      } else {
        getCampaignData();
        response.status === 200 || response.status === 201
          ? setCampaignModal(false)
          : setCampaignModal(true);
        notify("success", response.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const submitNewTms = () => {
    addNewCampaign();
  };
  const handleSingleTmsDelete = (id) => {
    deleteOneCampaign(id);
  };
  const handleAssign = async (id) => {
    setAssaignModal(true);
    getTreeData();
    try {
      const response = await baseApi1.get(
        `/api/campaign/one/${id}`
      );
      if (!response.statusText === "OK") {
        console.log("Not Getting Response");
      } else {
        setSingleCheckedNode([
          ...response.data.region,
          ...response.data.area,
          ...response.data.territory,
          ...response.data.outlet,
        ]);
        setSingleCampaign(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const submitAssign = async (id) => {
    console.log(id);
    try {
      const response = await baseApi1.put(
        `/api/campaign/assign/${id}`,
        checkedNode,
        { headers: { "Content-Type": "application/json" } }
      );
      if (!response.statusText === "OK") {
        notify("danger", "Not Getting Response");
      } else {
        console.log(response);
        setAssaignModal(false);
        notify("success", response.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <SimpleHeader name="Campaign" parentName="Campaign List" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="d-flex justify-content-between">
                <h3 style={{ color: "#DB5A3B" }}>Campaign</h3>
                <Button
                  onClick={() => setCampaignModal(true)}
                  style={{ backgroundColor: "#DB5A3B", color: "white" }}
                >
                  Add Campaign
                </Button>
              </CardHeader>
              <ToolkitProvider
                data={allCampaign}
                keyField="name"
                columns={[
                  {
                    dataField: "#",
                    text: "SL",
                    formatter: (cell, row, rowIndex) => {
                      return rowIndex + 1;
                    },
                  },
                  {
                    dataField: "name",
                    text: "Campaign Name",
                    sort: true,
                  },
                  {
                    dataField: "description",
                    text: "Campaign Description",
                    sort: true,
                  },
                  {
                    dataField: "startDate",
                    text: "Start Date",
                    formatter: (cell) => {
                      const dateFormat = new Date(cell).toDateString();
                      return dateFormat;
                    },
                  },
                  {
                    dataField: "endDate",
                    text: "End Date",
                    formatter: (cell) => {
                      const dateFormat = new Date(cell).toDateString();
                      return dateFormat;
                    },
                  },
                  {
                    dataField: "status",
                    text: "Status",
                    sort: true,
                  },
                  {
                    dataField: "_id",
                    text: "Action",
                    formatter: (cell) => {
                      const handleEdit = () => {
                        getOneCampaignData(cell);
                        setformModal(true);
                      };
                      const action = (
                        <div>
                          <Button color="info" onClick={handleEdit}>
                            Edit
                          </Button>
                          <Button
                            onClick={() => handleSingleTmsDelete(cell)}
                            color="danger"
                          >
                            Delete
                          </Button>
                          <Button
                            onClick={() => handleAssign(cell)}
                            color="warning"
                          >
                            Assign
                          </Button>
                        </div>
                      );
                      return action;
                    },
                  },
                ]}
                search
              >
                {(props) => (
                  <div className="py-4 table-responsive">
                    <Container fluid>
                      <Row>
                        <Col xs={4} sm={4}>
                          <div>
                            <select
                              name="datatable-basic_length"
                              aria-controls="datatable-basic"
                              className="form-control form-control-sm w-25"
                              onChange={(e) => setShowPerPage(e.target.value)}
                            >
                              <option
                                selected={showPerPage === 10 && true}
                                value={10}
                              >
                                10
                              </option>
                              <option
                                selected={showPerPage === 25 && true}
                                value={25}
                              >
                                25
                              </option>
                              <option
                                selected={showPerPage === 50 && true}
                                value={50}
                              >
                                50
                              </option>
                              <option
                                selected={showPerPage === 100 && true}
                                value={100}
                              >
                                100
                              </option>
                              <option
                                selected={showPerPage === "all" && true}
                                value="all"
                              >
                                all
                              </option>
                            </select>
                          </div>
                        </Col>
                        <Col xs={4} sm={4}>
                          <ButtonGroup>
                            <Button
                              className="buttons-copy buttons-html5"
                              color="warning"
                              size="sm"
                              id="copy-tooltip"
                              onClick={() =>
                                window.open(
                                  `${baseURL1}/api/campaign/csv`
                                )
                              }
                            >
                              <span>CSV</span>
                            </Button>
                            <ReactToPrint
                              trigger={() => (
                                <Button
                                  color="warning"
                                  size="sm"
                                  className="buttons-copy buttons-html5"
                                  id="print-tooltip"
                                >
                                  Print
                                </Button>
                              )}
                              content={() => componentRef.current}
                            />
                          </ButtonGroup>
                          <UncontrolledTooltip
                            placement="top"
                            target="print-tooltip"
                          >
                            This will open a print page with the visible rows of
                            the table.
                          </UncontrolledTooltip>
                          <UncontrolledTooltip
                            placement="top"
                            target="copy-tooltip"
                          >
                            This will Download Campaign As CSV.
                          </UncontrolledTooltip>
                        </Col>
                        <Col xs={4} sm={4}>
                          <div
                            id="datatable-basic_filter"
                            className="dataTables_filter px-4 pb-1 float-right"
                          >
                            <label>
                              Search:
                              <SearchBar
                                className="form-control-sm"
                                placeholder=""
                                {...props.searchProps}
                              />
                            </label>
                          </div>
                        </Col>
                      </Row>
                    </Container>
                    {allCampaign.length > 0 ? (
                      <BootstrapTable
                        ref={componentRef}
                        {...props.baseProps}
                        bootstrap4={true}
                        pagination={pagination}
                        bordered={false}
                        id="react-bs-table"
                      />
                    ) : (
                      <div className="d-flex justify-content-center">
                        <ReactLoading
                          className=""
                          type="bubbles"
                          color="#DB5A3B"
                          height={200}
                          width={100}
                        />
                      </div>
                    )}
                  </div>
                )}
              </ToolkitProvider>
            </Card>
          </div>
        </Row>
      </Container>
      {/* Edit Modal */}
      <Modal
        className="modal-dialog-centered"
        size="md"
        isOpen={formModal}
        toggle={() => setformModal(false)}
      >
        <div className="modal-body p-0">
          <Card className="bg-secondary border-0 mb-0">
            <CardHeader style={{ color: "#DB5A3B" }} className="bg-transparent">
              Update Campaign
            </CardHeader>
            <CardBody className="px-lg-5 py-lg-5">
              <Form role="form">
                <FormGroup
                  className={classnames("mb-3", {
                    focused: campaignName,
                  })}
                >
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlSelect1"
                  >
                    Campaign Name
                  </label>
                  <InputGroup className="input-group-merge input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Campaign Name"
                      defaultValue={singleCampaign?.name}
                      type="name"
                      onChange={(e) => setCampaignName(e.target.value)}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example-date-input"
                    md="2"
                  >
                    Start Date
                  </label>
                  <Input
                    id="example-date-input"
                    value={startDate === "" ? editedStartDate : startDate}
                    type="date"
                    onChange={(e) =>
                      setStartDate(
                        moment(new Date(e.target.value)).format("yyyy-MM-DD")
                      )
                    }
                  />
                </FormGroup>
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example-date-input"
                    md="2"
                  >
                    End Date
                  </label>

                  <Input
                    id="example-date-input"
                    value={endDate === "" ? editedEndDate : endDate}
                    type="date"
                    onChange={(e) =>
                      setEndDate(
                        moment(new Date(e.target.value)).format("yyyy-MM-DD")
                      )
                    }
                  />
                </FormGroup>

                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlSelect1"
                  >
                    Action
                  </label>
                  <Input
                    id="exampleFormControlSelect1"
                    onChange={(e) => setCampaignAction(e.target.value)}
                    type="select"
                  >
                    <option>Select</option>
                    <option
                      selected={singleCampaign?.status === "active" && true}
                      value="active"
                    >
                      Active
                    </option>
                    <option
                      selected={singleCampaign?.status === "inactive" && true}
                      value="inactive"
                    >
                      Inactive
                    </option>
                  </Input>
                </FormGroup>
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlTextarea1"
                  >
                    Campaign Description
                  </label>
                  <Input
                    id="exampleFormControlTextarea1"
                    placeholder="Campaign Description"
                    defaultValue={singleCampaign?.description}
                    rows="3"
                    type="textarea"
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </FormGroup>
                <div className="text-center">
                  <Button
                    onClick={() => setformModal(false)}
                    className="my-4"
                    color="danger"
                    type="button"
                  >
                    Close
                  </Button>
                  <Button
                    onClick={() => updateOneUserData(singleCampaign?._id)}
                    className="my-4"
                    color="success"
                    type="button"
                  >
                    Update
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </div>
      </Modal>
      {/* Add Campaign */}
      <Modal
        className="modal-dialog-centered"
        size="md"
        isOpen={addCampaignModal}
        toggle={() => setCampaignModal(false)}
      >
        <div className="modal-body p-0">
          <Card className="bg-secondary border-0 mb-0">
            <CardHeader style={{ color: "#DB5A3B" }} className="bg-transparent">
              Add Campaign
            </CardHeader>
            <CardBody className="px-lg-5 py-lg-5">
              <Form role="form">
                <FormGroup
                  className={classnames("mb-3", {
                    focused: newCampaignName,
                  })}
                >
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlSelect1"
                  >
                    Campaign Name
                  </label>
                  <InputGroup className="input-group-merge input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Campaign Name"
                      type="name"
                      onChange={(e) => setNewCampaignName(e.target.value)}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example-date-input"
                    md="2"
                  >
                    Start Date
                  </label>

                  <Input
                    id="example-date-input"
                    type="date"
                    onChange={(e) =>
                      setNewStartDate(
                        moment(e.target.value).format("yyyy-MM-DD")
                      )
                    }
                  />
                </FormGroup>
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example-date-input"
                    md="2"
                  >
                    End Date
                  </label>

                  <Input
                    id="example-date-input"
                    type="date"
                    onChange={(e) =>
                      setNewEndDate(moment(e.target.value).format("yyyy-MM-DD"))
                    }
                  />
                </FormGroup>
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlSelect1"
                  >
                    Action
                  </label>
                  <Input
                    id="exampleFormControlSelect1"
                    onChange={(e) => setNewCampaignAction(e.target.value)}
                    type="select"
                  >
                    <option>Select</option>
                    <option value="active">Active</option>
                    <option value="inactive">Inactive</option>
                  </Input>
                </FormGroup>
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlTextarea1"
                  >
                    Campaign Description
                  </label>
                  <Input
                    id="exampleFormControlTextarea1"
                    placeholder="Campaign Description"
                    rows="3"
                    type="textarea"
                    onChange={(e) => setNewDescription(e.target.value)}
                  />
                </FormGroup>
                <div className="text-center">
                  <Button
                    onClick={() => setCampaignModal(false)}
                    className="my-4"
                    color="danger"
                    type="button"
                  >
                    Close
                  </Button>
                  <Button
                    onClick={submitNewTms}
                    className="my-4"
                    color="success"
                    type="button"
                  >
                    Add
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </div>
      </Modal>
      {/* Assign Campaign */}
      <Modal
        className="modal-dialog-centered"
        size="md"
        isOpen={assignModal}
        toggle={() => setAssaignModal(false)}
      >
        <div className="modal-body p-0">
          <Card className="bg-secondary border-0 mb-0">
            <CardHeader style={{ color: "#DB5A3B" }} className="bg-transparent">
              Assign Campaign
            </CardHeader>
            <CardBody className="px-lg-5 py-lg-5">
              <Form role="form">
                <CheckboxTree
                  nativeCheckboxes
                  checked={
                    checkedNode.length === 0 ? singleCheckedNode : checkedNode
                  }
                  checkModel={"all"}
                  iconsClass={"fa5"}
                  expanded={expanded}
                  nodes={nodeTree}
                  onCheck={(checked) => {
                    console.log("Checked", checked);
                    setCheckedNode(checked);
                  }}
                  onExpand={(expanded) => {
                    console.log("expanded", expanded);
                    setExpended(expanded);
                  }}
                />
                <div className="text-center">
                  <Button
                    onClick={() => setAssaignModal(false)}
                    className="my-4"
                    color="danger"
                    type="button"
                  >
                    Close
                  </Button>
                  <Button
                    onClick={() => submitAssign(singleCampaign?._id)}
                    className="my-4"
                    color="success"
                    type="button"
                  >
                    Assign
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </div>
      </Modal>
    </div>
  );
};

export default Campaign;
