import React, {useContext} from "react";
import NotificationAlert from "react-notification-alert";
import SimpleHeader from "../../components/Headers/SimpleHeader";
import {
    Button,
    ButtonGroup,
    Card,
    CardHeader,
    Col,
    Container,
    FormGroup,
    Input,
    Form,
    Row,
    UncontrolledTooltip,
    CardBody,
    InputGroup,
} from "reactstrap";
import axios from "axios";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {Search} from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import moment from "moment";
import DataContext from "../../context/DataContext";
import {baseURL2} from "../../api/baseURL.json";

const {SearchBar} = Search;

const PhotoFrame = () => {
    const {region, area, territory, salesPoint, tms, outlet} =
        useContext(DataContext);
    const userInfo = JSON.parse(localStorage.getItem("user"));
    //Selected data for Details Report
    const [newRegion, setNewRegion] = React.useState("");
    const [newRegionId, setNewRegionId] = React.useState("");
    const [newArea, setNewArea] = React.useState("");
    const [newAreaId, setNewAreaId] = React.useState("");
    const [newTerrtory, setNewTerritory] = React.useState("");
    const [newTerrtoryId, setNewTerritoryId] = React.useState("");
    const [newSalesPoint, setNewSalesPoint] = React.useState("");
    const [newSalesPointId, setNewSalesPointId] = React.useState("");

    const [showPerPage, setShowPerPage] = React.useState(10);
    const [page, setPage] = React.useState(1);
    const [searchTerm, setSearchTerm] = React.useState("");
    const [totalSize, setTotalSize] = React.useState(10);
    const [totalRequests, setTotalRequests] = React.useState([]);

    //Filter Dropdown Datas
    const filteredArea = area.filter((item) => item.regionId === newRegionId);
    const filteredTerritory = territory.filter((item) => item.areaId === newAreaId);
    const filteredSalesPoint = salesPoint.filter(
        (item) =>
            item.territory._id ===
            (newTerrtory === "" ? userInfo?.territory?._id : newTerrtoryId)
    );

    //Notification Alert
    const notificationAlertRef = React.useRef(null);
    const notify = (type, message) => {
        let options = {
            place: "bc",
            message: (
                <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
              ! Photo Frame
          </span>
                    <span data-notify="message">{message}</span>
                </div>
            ),
            type: type,
            icon: "ni ni-bell-55",
            autoDismiss: 7,
        };
        notificationAlertRef.current.notificationAlert(options);
    };
    const componentRef = React.useRef(null);
    const pagination = paginationFactory({
        page,
        totalSize,
        showTotal: true,
        sizePerPage: showPerPage,
        alwaysShowAllBtns: true,
        withFirstAndLast: true,
        onPageChange: (page) => {
            setPage(page);
        },
        onSizePerPageChange: (_, size) => {
            setShowPerPage(size);
        },
        sizePerPageRenderer: ({
                                  options,
                                  currSizePerPage,
                                  onSizePerPageChange,
                              }) => (
            <div className="dataTables_length" id="datatable-basic_length">
                <label>
                    Show{" "}
                    {
                        <select
                            value={currSizePerPage}
                            name="datatable-basic_length"
                            aria-controls="datatable-basic"
                            className="form-control form-control-sm"
                            onChange={(e) => onSizePerPageChange(e.target.value)}
                        >
                            <option value={10}>10</option>
                            <option value={25}>25</option>
                            <option value={50}>50</option>
                            <option value={100}>100</option>
                            <option value="all">all</option>
                        </select>
                    }{" "}
                    entries.
                </label>
            </div>
        ),
    });

    const getPhotoFrames = async () => {
        try {
            const response = await axios.get(
                `${baseURL2}:5000/api/getPhotoFrames?page=${page}&limit=${showPerPage}&search=${searchTerm}&region=${newRegion}&area=${newArea}&territory=${newTerrtory}&salesPoint=${newSalesPoint}`
            );
            if (response.statusText !== "OK") {
                notify("danger", "Something Went Wrong");
            } else {
                setTotalRequests(response?.data?.data);
                setTotalSize(parseInt(response?.data?.total));
            }
        } catch (e) {
            console.log(e.response);
        }
    };

    React.useEffect(() => {
        getPhotoFrames();
    }, [page, showPerPage, searchTerm]);

    function imageFormatter(cell, row) {
        return (
            <img
                style={{width: 50, height: 50}}
                className="rounded"
                src={`${baseURL2}:5000/photoFrame/${row?.region}/${row?.area}/${row?.territory}/${row?.salesPoint}/${row?.tmsName}_${row?.tmsEnroll}/${row?.framePhoto}`}
                alt="icon"
            />
        );
    }

    return (
        <div>
            <div className="rna-wrapper">
                <NotificationAlert ref={notificationAlertRef}/>
            </div>
            <SimpleHeader name="List Photo Frame" parentName="Photo Frame"/>
            <Container className="mt--6" fluid>
                <Row>
                    <div className="col">
                        <Card>
                            <CardHeader className="d-flex justify-content-between">
                                <h3 style={{color: "#DB5A3B"}}>Filters</h3>
                            </CardHeader>
                            <CardBody className="px-lg-5 py-lg-5">
                                <Form role="form">
                                    <Row>
                                        <Col xs={4} sm={4}>
                                            <FormGroup>
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="exampleFormControlSelect1"
                                                >
                                                    Region
                                                </label>
                                                {userInfo.region === undefined ? (
                                                    <Input
                                                        id="exampleFormControlSelect1"
                                                        onChange={(e) => {
                                                            let value =JSON.parse(e.target.value)
                                                            setNewRegion(value.name);
                                                            setNewRegionId(value.id);
                                                        }}
                                                        type="select"
                                                    >
                                                        <option>Select</option>
                                                        {region.map((region) => (
                                                            <option value={JSON.stringify({
                                                                name: region.name,
                                                                id: region._id,
                                                            })}>{region.name}</option>
                                                        ))}
                                                    </Input>
                                                ) : (
                                                    <InputGroup className="input-group-merge input-group-alternative">
                                                        <Input
                                                            type="name"
                                                            value={userInfo.region.name}
                                                            disabled
                                                        />
                                                    </InputGroup>
                                                )}
                                            </FormGroup>
                                        </Col>
                                        <Col xs={4} sm={4}>
                                            <FormGroup>
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="exampleFormControlSelect1"
                                                >
                                                    Area
                                                </label>
                                                {userInfo.area === undefined ? (
                                                    <Input
                                                        id="exampleFormControlSelect1"
                                                        onChange={(e) => {
                                                            const value = JSON.parse(e.target.value);
                                                            setNewAreaId(value.id)
                                                            setNewArea(value.name);
                                                        }}
                                                        type="select"
                                                    >
                                                        <option>Select</option>
                                                        {filteredArea.map((area) => (
                                                            <option value={JSON.stringify({
                                                                name: area.name,
                                                                id: area._id,
                                                            })}>{area.name}</option>
                                                        ))}
                                                    </Input>
                                                ) : (
                                                    <InputGroup className="input-group-merge input-group-alternative">
                                                        <Input
                                                            type="name"
                                                            value={userInfo.area.name}
                                                            disabled
                                                        />
                                                    </InputGroup>
                                                )}
                                            </FormGroup>
                                        </Col>
                                        <Col xs={4} sm={4}>
                                            <FormGroup>
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="exampleFormControlSelect1"
                                                >
                                                    Territory
                                                </label>
                                                {userInfo.territory === undefined ? (
                                                    <Input
                                                        id="exampleFormControlSelect1"
                                                        onChange={(e) => {
                                                            const value = JSON.parse(e.target.value);
                                                            setNewTerritoryId(value.id)
                                                            setNewTerritory(value.name);
                                                        }}
                                                        type="select"
                                                    >
                                                        <option>Select</option>
                                                        {filteredTerritory.map((territory) => (
                                                            <option value={JSON.stringify({
                                                                name: territory.name,
                                                                id: territory._id,
                                                            })}>
                                                                {territory.name}
                                                            </option>
                                                        ))}
                                                    </Input>
                                                ) : (
                                                    <InputGroup className="input-group-merge input-group-alternative">
                                                        <Input
                                                            type="name"
                                                            value={userInfo.territory.name}
                                                            disabled
                                                        />
                                                    </InputGroup>
                                                )}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={6} sm={6}>
                                            <FormGroup>
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="exampleFormControlSelect1"
                                                >
                                                    Sales Point
                                                </label>
                                                <Input
                                                    id="exampleFormControlSelect1"
                                                    onChange={(e) => {
                                                        const value = JSON.parse(e.target.value);
                                                        setNewSalesPointId(value.id);
                                                        setNewSalesPoint(value.name);
                                                    }}
                                                    type="select"
                                                >
                                                    <option>Select</option>
                                                    {filteredSalesPoint.map((sp) => (
                                                        <option value={JSON.stringify({
                                                            name: sp.name,
                                                            id: sp._id,
                                                        })}>{sp.name}</option>
                                                    ))}
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Form>
                                <div className="text-right">
                                    <Button color="primary">Submit</Button>
                                </div>
                            </CardBody>
                        </Card>
                    </div>
                </Row>
            </Container>
            <Container className="mt-3" fluid>
                <Row>
                    <div className="col">
                        <Card>
                            <CardHeader className="d-flex justify-content-between">
                                <h3 style={{color: "#DB5A3B"}}>Photo Frames</h3>
                            </CardHeader>
                            <ToolkitProvider
                                data={totalRequests}
                                keyField="outletCode"
                                columns={[
                                    {
                                        dataField: "framePhoto",
                                        text: "Photo",
                                        formatter: imageFormatter,
                                    },
                                    {
                                        dataField: "#",
                                        text: "SL",
                                        formatter: (cell, row, rowIndex) => {
                                            return rowIndex + 1;
                                        },
                                    },
                                    {
                                        dataField: "region",
                                        text: "Region",
                                        sort: true,
                                    },
                                    {
                                        dataField: "area",
                                        text: "Area",
                                        sort: true,
                                    },
                                    {
                                        dataField: "territory",
                                        text: "Territory",
                                        sort: true,
                                    },
                                    {
                                        dataField: "salesPoint",
                                        text: "Sales Point",
                                        sort: true,
                                    },
                                    {
                                        dataField: "outletCode",
                                        text: "Outlet Code",
                                        sort: true,
                                    },
                                    {
                                        dataField: "createdAt",
                                        text: "Request Time",
                                        sort: true,
                                        formatter: (cell) => {
                                            return moment(new Date(cell)).format(
                                                "MMMM Do YYYY, h:mm:ss a"
                                            );
                                        },
                                    },
                                    {
                                        dataField: "framePhoto",
                                        text: "Download",
                                        formatter: (cell, row) => {
                                            return (
                                                <div>
                                                    <Button
                                                        color="info"
                                                        onClick={() =>
                                                            window.open(
                                                                `${baseURL2}:5000/photoFrame/${row?.region}/${row?.area}/${row?.territory}/${row?.salesPoint}/${row?.tmsName}_${row?.tmsEnroll}/${row?.framePhoto}`
                                                            )
                                                        }
                                                    >
                                                        Download
                                                    </Button>
                                                </div>
                                            );
                                        },
                                    },
                                ]}
                                search
                            >
                                {(props) => (
                                    <div className="py-4 table-responsive">
                                        <Container fluid>
                                            <Row>
                                                <Col xs={4} sm={4}>
                                                    <div>
                                                        <select
                                                            name="datatable-basic_length"
                                                            aria-controls="datatable-basic"
                                                            className="form-control form-control-sm w-25"
                                                            onChange={(e) => setShowPerPage(e.target.value)}
                                                        >
                                                            <option
                                                                selected={showPerPage === 10 && true}
                                                                value={10}
                                                            >
                                                                10
                                                            </option>
                                                            <option
                                                                selected={showPerPage === 25 && true}
                                                                value={25}
                                                            >
                                                                25
                                                            </option>
                                                            <option
                                                                selected={showPerPage === 50 && true}
                                                                value={50}
                                                            >
                                                                50
                                                            </option>
                                                            <option
                                                                selected={showPerPage === 100 && true}
                                                                value={100}
                                                            >
                                                                100
                                                            </option>
                                                            <option
                                                                selected={showPerPage === "all" && true}
                                                                value="all"
                                                            >
                                                                all
                                                            </option>
                                                        </select>
                                                    </div>
                                                </Col>
                                                <Col xs={4} sm={4}>
                                                    <ButtonGroup>
                                                        <Button
                                                            className="buttons-copy buttons-html5"
                                                            color="warning"
                                                            size="sm"
                                                            id="copy-tooltip"
                                                            onClick={() =>
                                                                window.open(
                                                                    `${baseURL2}:5000/api/getPhotoFrameCsv`
                                                                )
                                                            }
                                                        >
                                                            <span>CSV</span>
                                                        </Button>
                                                        <Button
                                                            className="buttons-copy buttons-html5"
                                                            color="warning"
                                                            size="sm"
                                                            id="print-tooltip"
                                                            onClick={() =>
                                                                window.open(
                                                                    `${baseURL2}:5000/api/getFrameZip?region=${ userInfo?.region === undefined ? newRegion :userInfo?.region.name}&area=${ userInfo.area ===undefined ? newArea : userInfo.area.name}&territory=${ userInfo.territory === undefined ? newTerrtory : userInfo.territory.name}&salesPoint=${newSalesPoint}`
                                                                )
                                                            }
                                                        >
                                                            <span>ZIP</span>
                                                        </Button>
                                                    </ButtonGroup>
                                                    <UncontrolledTooltip
                                                        placement="top"
                                                        target="print-tooltip"
                                                    >
                                                        This will Download Photo Frame List As ZIP as Per
                                                        Date Range.
                                                    </UncontrolledTooltip>
                                                    <UncontrolledTooltip
                                                        placement="top"
                                                        target="copy-tooltip"
                                                    >
                                                        This will Download Photo Frame List As CSV.
                                                    </UncontrolledTooltip>
                                                </Col>
                                                <Col xs={4} sm={4}>
                                                    <div
                                                        id="datatable-basic_filter"
                                                        className="dataTables_filter px-4 pb-1 float-right"
                                                    >
                                                        <label>
                                                            Search:
                                                            <SearchBar
                                                                className="form-control-sm"
                                                                placeholder=""
                                                                {...props.searchProps}
                                                            />
                                                        </label>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Container>
                                        <BootstrapTable
                                            remote
                                            ref={componentRef}
                                            {...props.baseProps}
                                            bootstrap4={true}
                                            pagination={pagination}
                                            bordered={false}
                                            hover={true}
                                            id="react-bs-table"
                                            onTableChange={(type, {searchText}) => {
                                                setSearchTerm(searchText);
                                            }}
                                        />
                                    </div>
                                )}
                            </ToolkitProvider>
                        </Card>
                    </div>
                </Row>
            </Container>
            {/*            <Button onClick={downloadFile}> Download </Button>*/}
        </div>
    );
};

export default PhotoFrame;
