import React, {createContext, useEffect, useState} from "react";
import axios from "axios";
import {baseURL1} from "../api/baseURL.json";

const DataContext = createContext({});

export const DataProvider = ({children}) => {
    const [region, setRegion] = useState([]);
    const [area, setArea] = useState([]);
    const [territory, setTerritory] = useState([]);
    const [salesPoint, setSalesPoint] = useState([]);
    const [route, setRoute] = useState([]);
    const [tms, setTms] = useState([]);
    const [outlet, setOutlet] = useState([]);
    const [brands, setBrands] = useState([]);

    const getRegion = async () => {
        try {
            const response = await axios.get(`${baseURL1}:3000/api/regions`);
            if (!response.statusText === "OK") {
                console.log("Not Getting Response");
            } else {
                setRegion(response.data.data);
            }
        } catch (error) {
            console.log(error.response);
        }
    };
    const getArea = async () => {
        try {
            const response = await axios.get(`${baseURL1}:3000/api/areas/all`);
            if (!response.statusText === "OK") {
                console.log("Not Getting Response");
            } else {
                setArea(response.data.data);
            }
        } catch (error) {
            console.log(error.response);
        }
    };
    const getTMS = async () => {
        try {
            const response = await axios.get(
                `${baseURL1}:3000/api/usercontrol/all`
            );
            if (!response.statusText === "OK") {
                console.log("Not Getting Response");
            } else {
                setTms(response.data.data);
            }
        } catch (error) {
            console.log(error.response);
        }
    };
    const getTerritory = async () => {
        try {
            const response = await axios.get(
                `${baseURL1}:3000/api/territories/all`
            );
            if (!response.statusText === "OK") {
                console.log("Not Getting Response");
            } else {
                setTerritory(response.data.data);
            }
        } catch (error) {
            console.log(error.response);
        }
    };
    const getSalesPoint = async () => {
        try {
            const response = await axios.get(
                `${baseURL1}:3000/api/sales-point/all`
            );
            if (!response.statusText === "OK") {
                console.log("Not Getting Response");
            } else {
                setSalesPoint(response.data.data);
            }
        } catch (error) {
            console.log(error.response);
        }
    };
    const getRoutes = async () => {
        try {
            const response = await axios.get(`${baseURL1}:3000/api/routes/all`);
            if (!response.statusText === "OK") {
                console.log("Not Getting Response");
            } else {
                setRoute(response.data.data);
            }
        } catch (error) {
            console.log(error.response);
        }
    };
    const getOutlet = async () => {
        try {
            const response = await axios.get(`${baseURL1}:3000/api/outlets/all`);
            if (!response.statusText === "OK") {
                console.log("Not Getting Response");
            } else {
                setOutlet(response.data.data);
            }
        } catch (error) {
            console.log(error.response);
        }
    };
    const getBrands = async () => {
        try {
            const response = await axios.get(`${baseURL1}:3000/api/brand/all`);
            if (!response.statusText === "OK") {
                console.log("Not Getting Response");
            } else {
                setBrands(response.data.data);
            }
        } catch (error) {
            console.log(error.response);
        }
    };

    useEffect(() => {
        getRegion();
        getArea();
        getTerritory();
        getSalesPoint();
        getRoutes();
        getTMS();
        getOutlet();
        getBrands();
    }, []);

    return (
        <DataContext.Provider
            value={{
                region,
                area,
                territory,
                salesPoint,
                route,
                tms,
                outlet,
                brands,
            }}
        >
            {children}
        </DataContext.Provider>
    );
};

export default DataContext;
