import React, {useContext, useEffect, useLayoutEffect} from "react";
// react plugin that prints a given react component
import ReactToPrint from "react-to-print";
import classnames from "classnames";
// react component for creating dynamic tables
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {Search} from "react-bootstrap-table2-toolkit";
import {MultiSelect} from "react-multi-select-component";
import ReactLoading from "react-loading";

// reactstrap components11
import {
    Button,
    ButtonGroup,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Form,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Modal,
    Row,
    UncontrolledTooltip,
} from "reactstrap";
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";

import axios from "axios";
import DataContext from "context/DataContext";
import NotificationAlert from "react-notification-alert";
import baseApi1 from "../../api/BaseApi1";
import {baseURL1} from "../../api/baseURL.json";

const {SearchBar} = Search;
const TmsControl = () => {
    const {region, area, territory, salesPoint, outlet} =
        useContext(DataContext);
    const [showPerPage, setShowPerPage] = React.useState(null);
    //Edit TMS
    const [tmsName, setTmsName] = React.useState("");
    const [tmsEmail, setTmsEmail] = React.useState("");
    const [tmsPhone, setTmsPhone] = React.useState("");
    const [tmsRegion, setTmsRegion] = React.useState("");
    const [tmsArea, setTmsArea] = React.useState("");
    const [tmsTerritory, setTmsTerritory] = React.useState("");
    const [tmsSalesPoint, setTmsSalesPoint] = React.useState([]);
    const [tmsRoute, setTmsRoute] = React.useState([]);
    const [tmsSalesPointAE, setTmsSalesPointAE] = React.useState([]);
    const [tmsRouteAE, setTmsRouteAE] = React.useState([]);
    const [tmsAction, setTmsAction] = React.useState("");
    const [editedOutlets, setEditedOutlets] = React.useState([]);
    const [editedTmsOutletText, setEditedTmsOutletText] = React.useState(null);
    //Add TMS
    const [newTmsName, setNewTmsName] = React.useState("");
    const [newTmsEnrollID, setNewTmsEnrollID] = React.useState("");
    const [newTmsEmail, setNewTmsEmail] = React.useState("");
    const [newTmsPhone, setNewTmsPhone] = React.useState("");
    const [newTmsRegion, setNewTmsRegion] = React.useState(null);
    const [newTmsArea, setNewTmsArea] = React.useState(null);
    const [newTmsTerritory, setNewTmsTerritory] = React.useState(null);
    const [newTmsSalesPoint, setNewTmsSalesPoint] = React.useState([]);
    const [newTmsRoute, setNewTmsRoute] = React.useState([]);
    const [newTmsOutlets, setNewTmsOutlets] = React.useState([]);
    const [newTmsOutletText, setNewTmsOutletText] = React.useState(null);
    const [newTmsAction, setNewTmsAction] = React.useState("");
    //Others
    const [formModal, setformModal] = React.useState(false);
    const [addTMSModal, setTMSModal] = React.useState(false);
    const [tms, setTms] = React.useState([]);
    const [singleTMS, setSingleTMS] = React.useState({});
    const [singleOutlets, setSingleOutlets] = React.useState([]);
    const [showText, setShowText] = React.useState("");
    const [editShowText, setEditShowText] = React.useState("");
    //Filters From API
    const [newfilteredSalesPoint, setNewfilteredSalesPoint] = React.useState([]);
    const [newfilteredRoute, setNewfilteredRoute] = React.useState([]);
    const [newfilteredOutlet, setNewFilterOutlet] = React.useState([]);

    const [editfilteredSalesPoint, setEditfilteredSalesPoint] = React.useState(
        []
    );
    const [editfilteredRoute, setEditfilteredRoute] = React.useState([]);
    const [editfilteredOutlet, setEditFilterOutlet] = React.useState([]);
    //Notification Alert
    const notificationAlertRef = React.useRef(null);
    const notify = (type, message) => {
        let options = {
            place: "bc",
            message: (
                <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
              TMS Notification
          </span>
                    <span data-notify="message">{message}</span>
                </div>
            ),
            type: type,
            icon: "ni ni-bell-55",
            autoDismiss: 7,
        };
        notificationAlertRef.current.notificationAlert(options);
    };
    const getUserData = async () => {
        try {
            const response = await baseApi1.get(
                "/api/usercontrol/all"
            );
            if (!response.statusText === "OK") {
                console.log("Not Getting Response");
            } else {
                setTms(response.data.data);
            }
        } catch (error) {
            console.log(error);
        }
    };
    useLayoutEffect(() => {
        getUserData();
    }, []);
    //Filter Edited TMS
    const filteredArea = area.filter((item) => item.regionId === tmsRegion);
    const filteredTerritory = territory.filter((item) => item.areaId === tmsArea);
    //Filter Add TMS
    const newfilteredArea = area.filter((item) => item.regionId === newTmsRegion);
    const newfilteredTerritory = territory.filter(
        (item) => item.areaId === newTmsArea
    );

    const getEditTEWiseSp = async () => {
        try {
            const response = await baseApi1.get(
                `/api/sales-point/${tmsTerritory}`
            );
            if (!response.statusText === "OK") {
                console.log("Not Getting Response");
            } else {
                setEditfilteredSalesPoint(
                    response?.data?.data.map((x) => {
                        return {value: x?._id, label: x?.name};
                    })
                );
            }
        } catch (error) {
            console.log(error);
        }
    };
    const getTEWiseSp = async () => {
        try {
            const response = await baseApi1.get(
                `/api/sales-point/${newTmsTerritory}`
            );
            if (!response.statusText === "OK") {
                console.log("Not Getting Response");
            } else {
                setNewfilteredSalesPoint(response?.data?.data);
            }
        } catch (error) {
            console.log(error);
        }
    };
    const getSpWiseRoute = async () => {
        const sp = newTmsSalesPoint.map((x) => x.value);
        try {
            const response = await baseApi1.put(
                `/api/routes-by-salespoints`,
                sp,
                {headers: {"Content-Type": "application/json"}}
            );
            if (!response.statusText === "OK") {
                console.log("Not Getting Response");
            } else {
                setNewfilteredRoute(
                    response?.data?.map((x) => {
                        return {value: x._id, label: x.id};
                    })
                );
            }
        } catch (error) {
            console.log(error.response);
        }
    };
    const getEditSpWiseRoute = async () => {
        const spOld = tmsSalesPoint.map((x) => x.value);
        const spNew = tmsSalesPointAE.map((x) => x.value);
        try {
            const response = await baseApi1.put(
                `/api/routes-by-salespoints`,
                tmsSalesPointAE.length === 0 ? spOld : spNew,
                {headers: {"Content-Type": "application/json"}}
            );
            if (response.statusText !== "OK") {
                console.log("Not Getting Response");
            } else {
                setEditfilteredRoute(
                    response?.data?.map((x) => {
                        return {value: x._id, label: x.id};
                    })
                );
            }
        } catch (error) {
            console.log(error.response);
        }
    };
    const getRouteWiseOutlets = async () => {
        const routes = newTmsRoute.map((x) => x.value);
        try {
            const response = await baseApi1.put(
                `/api/outlets-by-routes`,
                routes,
                {headers: {"Content-Type": "application/json"}}
            );
            if (response.statusText !== "OK") {
                console.log("Not Getting Response");
            } else {
                setNewFilterOutlet(
                    response?.data?.map((x) => {
                        return {value: x.code, label: x.code};
                    })
                );
            }
        } catch (error) {
            console.log(error.response);
        }
    };
    const getEditRouteWiseOutlets = async () => {
        const oldRoutes = tmsRoute.map((x) => x.value);
        const newRoutes = tmsRouteAE.map((x) => x.value);
        try {
            const response = await baseApi1.put(
                `/api/outlets-by-routes`,
                tmsRouteAE.length === 0 ? oldRoutes : newRoutes,
                {headers: {"Content-Type": "application/json"}}
            );
            if (!response.statusText === "OK") {
                console.log("Not Getting Response");
            } else {
                setEditFilterOutlet(
                    response?.data?.map((x) => {
                        return {value: x.code, label: x.code};
                    })
                );
                console.log("Route Wise");
            }
        } catch (error) {
            console.log(error.response);
        }
    };
    useEffect(() => {
        getTEWiseSp();
    }, [newTmsTerritory]);
    useEffect(() => {
        getSpWiseRoute();
    }, [newTmsSalesPoint]);
    useEffect(() => {
        getRouteWiseOutlets();
    }, [newTmsRoute]);
    useEffect(() => {
        getEditTEWiseSp();
    }, [tmsTerritory]);
    useEffect(() => {
        getEditSpWiseRoute();
    }, [tmsSalesPoint, tmsSalesPointAE]);
    useEffect(() => {
        getEditRouteWiseOutlets();
    }, [tmsRoute, tmsRouteAE]);

    const componentRef = React.useRef(null);
    const getOneUserData = async (id) => {
        try {
            const response = await baseApi1.get(
                `/api/usercontrol/one/${id}`
            );
            if (response.statusText !== "OK") {
                console.log("Not Getting Response");
            } else {
                setSingleTMS(response.data);
                setTmsRegion(response.data.region);
                setTmsArea(response.data.area);
                setTmsTerritory(response.data.territory);
                setTmsSalesPoint(response.data.salesPoint);
                setTmsRoute(response.data.route);
                setSingleOutlets(
                    response.data.outletCode.map((x) => {
                        return {value: x, label: x};
                    })
                );
            }
        } catch (error) {
            console.log(error);
        }
    };
    const deleteOneUserData = async (id) => {
        try {
            const response = await baseApi1.delete(
                `/api/usercontrol/${id}`
            );
            if (!response.statusText === "OK") {
                console.log("Not Getting Response");
                notify("danger", response.data.message);
            } else {
                getUserData();
                notify("success", response.data.message);
            }
        } catch (error) {
            console.log(error.response);
        }
    };
    const updateOneUserData = async (id) => {
        const editedTms = {
            name: tmsName === "" ? singleTMS?.name : tmsName,
            outletCode: editShowText === "yes" ? editedTmsOutletText?.split(","):
                (editedOutlets.length === 0
                    ? singleOutlets?.map((x) => x.value)
                    : editedOutlets?.map((x) => x.value)),
            email: tmsEmail === "" ? singleTMS?.email : tmsEmail,
            phone: tmsPhone === "" ? singleTMS?.phone : tmsPhone,
            region: tmsRegion === "" ? singleTMS.region : tmsRegion,
            area: tmsArea === "" ? singleTMS.area : tmsArea,
            territory: tmsTerritory === "" ? singleTMS.territory : tmsTerritory,
            salesPoint:
                tmsSalesPointAE.length === 0
                    ? singleTMS.salesPoint.map((x) => x.value)
                    : tmsSalesPointAE.map((x) => x.value),
            route:
                tmsRouteAE.length === 0
                    ? singleTMS.route.map((x) => x.value)
                    : tmsRouteAE.map((x) => x.value),
            action: tmsAction === "" ? singleTMS.action : tmsAction,
        };
        try {
            const response = await baseApi1.put(
                `/api/usercontrol/${id}`,
                editedTms,
                {headers: {"Content-Type": "application/json"}}
            );
            if (!response.statusText === "OK") {
                console.log("Not Getting Response");
                notify("danger", response.data.message);
            } else {
                getUserData();
                setformModal(false);
                notify("success", response.data.message);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const pagination = paginationFactory({
        page: 1,
        alwaysShowAllBtns: true,
        showTotal: true,
        withFirstAndLast: false,
        sizePerPageRenderer: ({
                                  // options,
                                  // currSizePerPage,
                                  onSizePerPageChange,
                              }) => (
            <div className="dataTables_length" id="datatable-basic_length">
                <label>
                    Show{" "}
                    {
                        <select
                            name="datatable-basic_length"
                            aria-controls="datatable-basic"
                            className="form-control form-control-sm"
                            onChange={(e) => onSizePerPageChange(e.target.value)}
                        >
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="all">All</option>
                        </select>
                    }{" "}
                    entries.
                </label>
            </div>
        ),
    });
    const addNewTms = async () => {
        const newTms = {
            name: newTmsName,
            enrollId: newTmsEnrollID,
            outletCode: showText === "yes" ? newTmsOutletText?.split(",") : newTmsOutlets?.map((x) => x.value),
            email: newTmsEmail,
            phone: newTmsPhone,
            region: newTmsRegion,
            area: newTmsArea,
            territory: newTmsTerritory,
            salesPoint: newTmsSalesPoint?.map((x) => x.value),
            route: newTmsRoute?.map((x) => x.value),
            action: newTmsAction,
        };
        try {
            const response = await baseApi1.post(
                "/api/usercontrol",
                newTms,
                {headers: {"Content-Type": "application/json"}}
            );
            if (response.statusText !== "OK") {
                console.log("Not Getting Response");
                notify("danger", "Some Thing Went Wrong");
            } else {
                getUserData();
                response.status === 200 || response.status === 201
                    ? setTMSModal(false)
                    : setTMSModal(true);
                notify("success", response.data.message);
            }
        } catch (error) {
            console.log(error.response.data);
        }
    };
    const submitNewTms = () => {
        addNewTms();
    };
    const handleSingleTmsDelete = (id) => {
        deleteOneUserData(id);
    };
    return (
        <div>
            <div className="rna-wrapper">
                <NotificationAlert ref={notificationAlertRef}/>
            </div>
            <SimpleHeader name="TMS Control" parentName="TMS List"/>
            <Container className="mt--6" fluid>
                <Row>
                    <div className="col">
                        <Card>
                            <CardHeader className="d-flex justify-content-between">
                                <h3 style={{color: "#DB5A3B"}}>User Control</h3>
                                <Button
                                    onClick={() => setTMSModal(true)}
                                    style={{backgroundColor: "#DB5A3B", color: "white"}}
                                >
                                    Add TMS
                                </Button>
                            </CardHeader>
                            <ToolkitProvider
                                data={tms}
                                keyField="phone"
                                columns={[
                                    {
                                        dataField: "#",
                                        text: "SL",
                                        formatter: (cell, row, rowIndex) => {
                                            return rowIndex + 1;
                                        },
                                    },
                                    {
                                        dataField: "name",
                                        text: "Name",
                                        sort: true,
                                    },
                                    {
                                        dataField: "outletCode",
                                        text: "Outlet Code",
                                        sort: true,
                                        formatter: (cell) => {
                                            return cell.join(", ").substring(0, 25) + "  .....";
                                        },
                                    },
                                    {
                                        dataField: "email",
                                        text: "Email",
                                        sort: true,
                                    },
                                    {
                                        dataField: "phone",
                                        text: "Phone",
                                        sort: true,
                                    },
                                    {
                                        dataField: "password",
                                        text: "Password",
                                        sort: true,
                                    },
                                    {
                                        dataField: "region",
                                        text: "Region",
                                        sort: true,
                                    },
                                    {
                                        dataField: "area",
                                        text: "Area",
                                        sort: true,
                                    },
                                    {
                                        dataField: "territory",
                                        text: "Territory",
                                        sort: true,
                                    },
                                    {
                                        dataField: "salesPoint",
                                        text: "Sales Point",
                                        sort: true,
                                        formatter: (cell) => {
                                            return (
                                                cell
                                                    .map((x) => x.label)
                                                    .join(", ")
                                                    .substring(0, 25) + "  ....."
                                            );
                                        },
                                    },
                                    {
                                        dataField: "route",
                                        text: "Route Name",
                                        sort: true,
                                        formatter: (cell) => {
                                            return (
                                                cell
                                                    .map((x) => x.label)
                                                    .join(", ")
                                                    .substring(0, 25) + "  ....."
                                            );
                                        },
                                    },
                                    {
                                        dataField: "version",
                                        text: "Version",
                                        sort: true,
                                    },
                                    {
                                        dataField: "action",
                                        text: "Status",
                                        sort: true,
                                    },
                                    {
                                        dataField: "_id",
                                        text: "Action",
                                        formatter: (cell) => {
                                            const handleEdit = () => {
                                                getOneUserData(cell);
                                                setformModal(true);
                                            };
                                            return (
                                                <div>
                                                    <Button color="info" onClick={handleEdit}>
                                                        Edit
                                                    </Button>
                                                    <Button
                                                        onClick={() => handleSingleTmsDelete(cell)}
                                                        color="danger"
                                                    >
                                                        Delete
                                                    </Button>
                                                </div>
                                            );
                                        },
                                    },
                                ]}
                                search
                            >
                                {(props) => (
                                    <div className="py-4 table-responsive">
                                        <Container fluid>
                                            <Row>
                                                <Col xs={4} sm={4}>
                                                    <div>
                                                        <select
                                                            name="datatable-basic_length"
                                                            aria-controls="datatable-basic"
                                                            className="form-control form-control-sm w-25"
                                                            onChange={(e) => setShowPerPage(e.target.value)}
                                                        >
                                                            <option
                                                                selected={showPerPage === 10 && true}
                                                                value={10}
                                                            >
                                                                10
                                                            </option>
                                                            <option
                                                                selected={showPerPage === 25 && true}
                                                                value={25}
                                                            >
                                                                25
                                                            </option>
                                                            <option
                                                                selected={showPerPage === 50 && true}
                                                                value={50}
                                                            >
                                                                50
                                                            </option>
                                                            <option
                                                                selected={showPerPage === 100 && true}
                                                                value={100}
                                                            >
                                                                100
                                                            </option>
                                                            <option
                                                                selected={showPerPage === "all" && true}
                                                                value="all"
                                                            >
                                                                all
                                                            </option>
                                                        </select>
                                                    </div>
                                                </Col>
                                                <Col xs={4} sm={4}>
                                                    <ButtonGroup>
                                                        <Button
                                                            className="buttons-copy buttons-html5"
                                                            color="warning"
                                                            size="sm"
                                                            id="copy-tooltip"
                                                            onClick={() =>
                                                                window.open(
                                                                    `${baseURL1}/api/usercontrol/csv`
                                                                )
                                                            }
                                                        >
                                                            <span>CSV</span>
                                                        </Button>
                                                        <ReactToPrint
                                                            trigger={() => (
                                                                <Button
                                                                    color="warning"
                                                                    size="sm"
                                                                    className="buttons-copy buttons-html5"
                                                                    id="print-tooltip"
                                                                >
                                                                    Print
                                                                </Button>
                                                            )}
                                                            content={() => componentRef.current}
                                                        />
                                                    </ButtonGroup>
                                                    <UncontrolledTooltip
                                                        placement="top"
                                                        target="print-tooltip"
                                                    >
                                                        This will open a print page with the visible rows of
                                                        the table.
                                                    </UncontrolledTooltip>
                                                    <UncontrolledTooltip
                                                        placement="top"
                                                        target="copy-tooltip"
                                                    >
                                                        This will Download TMS As CSV.
                                                    </UncontrolledTooltip>
                                                </Col>
                                                <Col xs={4} sm={4}>
                                                    <div
                                                        id="datatable-basic_filter"
                                                        className="dataTables_filter px-4 pb-1 float-right"
                                                    >
                                                        <label>
                                                            Search:
                                                            <SearchBar
                                                                className="form-control-sm"
                                                                placeholder=""
                                                                {...props.searchProps}
                                                            />
                                                        </label>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Container>
                                        <BootstrapTable
                                            ref={componentRef}
                                            {...props.baseProps}
                                            bootstrap4={true}
                                            pagination={pagination}
                                            bordered={false}
                                            id="react-bs-table"
                                        />
                                    </div>
                                )}
                            </ToolkitProvider>
                        </Card>
                    </div>
                </Row>
            </Container>
            {/* Edit Modal */}
            <Modal
                className="modal-dialog-centered"
                size="md"
                isOpen={formModal}
                toggle={() => setformModal(false)}
            >
                <div className="modal-body p-0">
                    <Card className="bg-secondary border-0 mb-0">
                        <CardHeader style={{color: "#DB5A3B"}} className="bg-transparent">
                            Update TMS
                        </CardHeader>
                        <CardBody className="px-lg-5 py-lg-5">
                            <Form role="form">
                                <FormGroup
                                    className={classnames("mb-3", {
                                        focused: tmsName,
                                    })}
                                >
                                    <label
                                        className="form-control-label"
                                        htmlFor="exampleFormControlSelect1"
                                    >
                                        TMS Name
                                    </label>
                                    <InputGroup className="input-group-merge input-group-alternative">
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="ni ni-email-83"/>
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            placeholder="TMS Name"
                                            defaultValue={singleTMS?.name}
                                            type="name"
                                            onChange={(e) => setTmsName(e.target.value)}
                                        />
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup
                                    className={classnames({
                                        focused: tmsEmail,
                                    })}
                                >
                                    <label
                                        className="form-control-label"
                                        htmlFor="exampleFormControlSelect1"
                                    >
                                        Email
                                    </label>
                                    <InputGroup className="input-group-merge input-group-alternative">
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="ni ni-lock-circle-open"/>
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            placeholder="Email"
                                            defaultValue={singleTMS?.email}
                                            type="email"
                                            onChange={(e) => setTmsEmail(e.target.value)}
                                        />
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup
                                    className={classnames({
                                        focused: tmsPhone,
                                    })}
                                >
                                    <label
                                        className="form-control-label"
                                        htmlFor="exampleFormControlSelect1"
                                    >
                                        Mobile Number
                                    </label>
                                    <InputGroup className="input-group-merge input-group-alternative">
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="ni ni-lock-circle-open"/>
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            placeholder="Phone"
                                            defaultValue={singleTMS?.phone}
                                            type="number"
                                            onChange={(e) => setTmsPhone(e.target.value)}
                                        />
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="exampleFormControlSelect1"
                                    >
                                        Region
                                    </label>
                                    <Input
                                        id="exampleFormControlSelect1"
                                        onChange={(e) => setTmsRegion(e.target.value)}
                                        type="select"
                                    >
                                        <option>Select</option>
                                        {region.map((region) => (
                                            <option
                                                selected={region._id === singleTMS.region}
                                                value={region._id}
                                            >
                                                {region.name}
                                            </option>
                                        ))}
                                    </Input>
                                </FormGroup>
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="exampleFormControlSelect1"
                                    >
                                        Area
                                    </label>
                                    <Input
                                        id="exampleFormControlSelect1"
                                        onChange={(e) => setTmsArea(e.target.value)}
                                        type="select"
                                    >
                                        <option>Select</option>
                                        {filteredArea.map((area) => (
                                            <option
                                                selected={area._id === singleTMS.area}
                                                value={area._id}
                                            >
                                                {area.name}
                                            </option>
                                        ))}
                                    </Input>
                                </FormGroup>
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="exampleFormControlSelect1"
                                    >
                                        Territory
                                    </label>
                                    <Input
                                        id="exampleFormControlSelect1"
                                        onChange={(e) => setTmsTerritory(e.target.value)}
                                        type="select"
                                    >
                                        <option>Select</option>
                                        {filteredTerritory.map((territory) => (
                                            <option
                                                selected={territory._id === singleTMS.territory}
                                                value={territory._id}
                                            >
                                                {territory.name}
                                            </option>
                                        ))}
                                    </Input>
                                </FormGroup>
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="exampleFormControlSelect1"
                                    >
                                        Sales Point
                                    </label>
                                    <MultiSelect
                                        options={editfilteredSalesPoint}
                                        value={
                                            tmsSalesPointAE.length === 0
                                                ? tmsSalesPoint
                                                : tmsSalesPointAE
                                        }
                                        onChange={setTmsSalesPointAE}
                                        labelledBy="Select"
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="exampleFormControlSelect1"
                                    >
                                        Route
                                    </label>
                                    <MultiSelect
                                        options={editfilteredRoute}
                                        value={
                                            tmsRouteAE.length === 0
                                                ? tmsRoute.map((x) => {
                                                    return {
                                                        value: x.value,
                                                        label: `${x.label} (${x.name})`,
                                                    };
                                                })
                                                : tmsRouteAE
                                        }
                                        onChange={setTmsRouteAE}
                                        labelledBy="Select"
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="exampleFormControlSelect1"
                                    >
                                        Outlet as Text
                                    </label>
                                    <Input
                                        id="exampleFormControlSelect1"
                                        onChange={(e) => setEditShowText(e.target.value)}
                                        type="select"
                                    >
                                        <option>Select</option>
                                        <option value="yes">Yes</option>
                                        <option value="no">No</option>
                                    </Input>
                                </FormGroup>
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="exampleFormControlSelect1"
                                    >
                                        Outlets
                                    </label>
                                    <MultiSelect
                                        options={editfilteredOutlet}
                                        value={
                                            editedOutlets.length === 0 ? singleOutlets : editedOutlets
                                        }
                                        onChange={setEditedOutlets}
                                        labelledBy="Select"
                                    />
                                </FormGroup>
                                <FormGroup
                                    className={classnames("mb-3", {
                                        focused: editedTmsOutletText,
                                    })}
                                >
                                    <label
                                        className="form-control-label"
                                        htmlFor="exampleFormControlSelect1"
                                    >
                                        Outlets As Text
                                    </label>
                                    <InputGroup className="input-group-merge input-group-alternative">
                                        <Input
                                            placeholder="TMS Outlets"
                                            defaultValue={singleTMS?.outletCode}
                                            type="textarea"
                                            onChange={(e) => setEditedTmsOutletText(e.target.value)}
                                        />
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="exampleFormControlSelect1"
                                    >
                                        Action
                                    </label>
                                    <Input
                                        id="exampleFormControlSelect1"
                                        onChange={(e) => setTmsAction(e.target.value)}
                                        type="select"
                                    >
                                        <option>Select</option>
                                        <option
                                            selected={singleTMS.action === "active" && true}
                                            value="active"
                                        >
                                            Active
                                        </option>
                                        <option
                                            selected={singleTMS.action === "inactive" && true}
                                            value="inactive"
                                        >
                                            Inactive
                                        </option>
                                    </Input>
                                </FormGroup>
                                <div className="text-center">
                                    <Button
                                        onClick={() => setformModal(false)}
                                        className="my-4"
                                        color="danger"
                                        type="button"
                                    >
                                        Close
                                    </Button>
                                    <Button
                                        onClick={() => updateOneUserData(singleTMS?._id)}
                                        className="my-4"
                                        color="success"
                                        type="button"
                                    >
                                        Update
                                    </Button>
                                </div>
                            </Form>
                        </CardBody>
                    </Card>
                </div>
            </Modal>
            {/* Add TMS */}
            <Modal
                className="modal-dialog-centered"
                size="md"
                isOpen={addTMSModal}
                toggle={() => setTMSModal(false)}
            >
                <div className="modal-body p-0">
                    <Card className="bg-secondary border-0 mb-0">
                        <CardHeader style={{color: "#DB5A3B"}} className="bg-transparent">
                            Add TMS
                        </CardHeader>
                        <CardBody className="px-lg-5 py-lg-5">
                            <Form role="form">
                                <FormGroup
                                    className={classnames("mb-3", {
                                        focused: newTmsName,
                                    })}
                                >
                                    <label
                                        className="form-control-label"
                                        htmlFor="exampleFormControlSelect1"
                                    >
                                        TMS Name
                                    </label>
                                    <InputGroup className="input-group-merge input-group-alternative">
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="ni ni-email-83"/>
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            placeholder="TMS Name"
                                            type="name"
                                            onChange={(e) => setNewTmsName(e.target.value)}
                                        />
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup
                                    className={classnames("mb-3", {
                                        focused: newTmsEnrollID,
                                    })}
                                >
                                    <label
                                        className="form-control-label"
                                        htmlFor="exampleFormControlSelect1"
                                    >
                                        TMS Enroll ID
                                    </label>
                                    <InputGroup className="input-group-merge input-group-alternative">
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="ni ni-email-83"/>
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            placeholder="TMS Enroll ID"
                                            type="text"
                                            onChange={(e) => setNewTmsEnrollID(e.target.value)}
                                        />
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup
                                    className={classnames({
                                        focused: newTmsEmail,
                                    })}
                                >
                                    <label
                                        className="form-control-label"
                                        htmlFor="exampleFormControlSelect1"
                                    >
                                        Email
                                    </label>
                                    <InputGroup className="input-group-merge input-group-alternative">
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="ni ni-lock-circle-open"/>
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            placeholder="Email"
                                            type="email"
                                            onChange={(e) => setNewTmsEmail(e.target.value)}
                                        />
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup
                                    className={classnames({
                                        focused: newTmsPhone,
                                    })}
                                >
                                    <label
                                        className="form-control-label"
                                        htmlFor="exampleFormControlSelect1"
                                    >
                                        Mobile Number
                                    </label>
                                    <InputGroup className="input-group-merge input-group-alternative">
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="ni ni-lock-circle-open"/>
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            placeholder="Phone"
                                            type="number"
                                            onChange={(e) => setNewTmsPhone(e.target.value)}
                                        />
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="exampleFormControlSelect1"
                                    >
                                        Region
                                    </label>
                                    <Input
                                        id="exampleFormControlSelect1"
                                        onChange={(e) => setNewTmsRegion(e.target.value)}
                                        type="select"
                                    >
                                        <option>Select</option>
                                        {region.map((region) => (
                                            <option value={region._id}>{region.name}</option>
                                        ))}
                                    </Input>
                                </FormGroup>
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="exampleFormControlSelect1"
                                    >
                                        Area
                                    </label>
                                    <Input
                                        id="exampleFormControlSelect1"
                                        onChange={(e) => setNewTmsArea(e.target.value)}
                                        type="select"
                                    >
                                        <option>Select</option>
                                        {newfilteredArea.map((area) => (
                                            <option value={area._id}>{area.name}</option>
                                        ))}
                                    </Input>
                                </FormGroup>
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="exampleFormControlSelect1"
                                    >
                                        Territory
                                    </label>
                                    <Input
                                        id="exampleFormControlSelect1"
                                        onChange={(e) => setNewTmsTerritory(e.target.value)}
                                        type="select"
                                    >
                                        <option>Select</option>
                                        {newfilteredTerritory.map((territory) => (
                                            <option value={territory._id}>{territory.name}</option>
                                        ))}
                                    </Input>
                                </FormGroup>
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="exampleFormControlSelect1"
                                    >
                                        Sales Point
                                    </label>
                                    <MultiSelect
                                        options={newfilteredSalesPoint?.map((x) => {
                                            return {value: x._id, label: x.name};
                                        })}
                                        value={newTmsSalesPoint}
                                        onChange={setNewTmsSalesPoint}
                                        labelledBy="Select"
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="exampleFormControlSelect1"
                                    >
                                        Route
                                    </label>
                                    <MultiSelect
                                        options={newfilteredRoute}
                                        value={newTmsRoute}
                                        onChange={setNewTmsRoute}
                                        labelledBy="Select"
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="exampleFormControlSelect1"
                                    >
                                        Outlet As Text
                                    </label>
                                    <Input
                                        id="exampleFormControlSelect1"
                                        onChange={(e) => setShowText(e.target.value)}
                                        type="select"
                                    >
                                        <option>Select</option>
                                        <option value="yes">Yes</option>
                                        <option value="no">No</option>
                                    </Input>
                                </FormGroup>
                                <FormGroup style={{display: showText === "no" ? "block" : "none"}}>
                                    <label
                                        className="form-control-label"
                                        htmlFor="exampleFormControlSelect1"
                                    >
                                        Outlets
                                    </label>
                                    <MultiSelect
                                        options={newfilteredOutlet}
                                        value={newTmsOutlets}
                                        onChange={setNewTmsOutlets}
                                        labelledBy="Select"
                                    />
                                </FormGroup>
                                <FormGroup
                                    className={classnames("mb-3", {
                                        focused: newTmsOutletText,
                                    })}
                                    style={{display: showText === "yes" ? "block" : "none"}}
                                >
                                    <label
                                        className="form-control-label"
                                        htmlFor="exampleFormControlSelect1"
                                    >
                                        Outlet As Text
                                    </label>
                                    <InputGroup className="input-group-merge input-group-alternative">
                                        <Input
                                            placeholder="Outlet Code"
                                            value={newTmsOutletText}
                                            type="textarea"
                                            onChange={(e) => setNewTmsOutletText(e.target.value)}
                                        />
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="exampleFormControlSelect1"
                                    >
                                        Action
                                    </label>
                                    <Input
                                        id="exampleFormControlSelect1"
                                        onChange={(e) => setNewTmsAction(e.target.value)}
                                        type="select"
                                    >
                                        <option>Select</option>
                                        <option value="active">Active</option>
                                        <option value="inactive">Inactive</option>
                                    </Input>
                                </FormGroup>
                                <div className="text-center">
                                    <Button
                                        onClick={() => setTMSModal(false)}
                                        className="my-4"
                                        color="danger"
                                        type="button"
                                    >
                                        Close
                                    </Button>
                                    <Button
                                        onClick={submitNewTms}
                                        className="my-4"
                                        color="success"
                                        type="button"
                                    >
                                        Add
                                    </Button>
                                </div>
                            </Form>
                        </CardBody>
                    </Card>
                </div>
            </Modal>
        </div>
    );
};

export default TmsControl;
