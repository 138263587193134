import React from 'react';
import {Button, Card, CardBody, CardTitle, Col, Container, Form, FormGroup, Input, Label, Row} from "reactstrap";
import {useFileUpload} from "use-file-upload";
import SimpleHeader from "../../../components/Headers/SimpleHeader";
import baseApi1 from "../../../api/BaseApi1";
import baseApi2 from "../../../api/BaseApi2";
import Select2 from "react-select2-wrapper";
import moment from "moment";
import NotificationAlert from "react-notification-alert";

const TmsDistribution = () => {
    const notificationAlertRef = React.useRef(null);
    const notify = (type, message) => {
        let options = {
            place: "bc", message: (<div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
              Distribution Notification
          </span>
                <span data-notify="message">{message}</span>
            </div>), type: type, icon: "ni ni-bell-55", autoDismiss: 20,
        };
        notificationAlertRef?.current?.notificationAlert(options);
    };
    const [outletCode, setOutletCode] = React.useState("");
    const [filteredOutlets, setFilteredOutlets] = React.useState([]);

    const [distributionInfo, setDistributionInfo] = React.useState({});
    const [photoOneLogo, setPhotoOneLogo] = useFileUpload();
    const [finalPhotoOneLogo, setFinalPhotoOneLogo] = React.useState({});
    React.useEffect(() => {
        if (photoOneLogo) {
            setFinalPhotoOneLogo(photoOneLogo);
        }
    }, [photoOneLogo]);
    const [photoTwoLogo, setPhotoTwoLogo] = useFileUpload();
    const [finalPhotoTwoLogo, setFinalPhotoTwoLogo] = React.useState({});
    React.useEffect(() => {
        if (photoTwoLogo) {
            setFinalPhotoTwoLogo(photoTwoLogo);
        }
    }, [photoTwoLogo]);
    const [retailerSign, setRetailerSign] = useFileUpload();
    const [finalRetailerSign, setFinalRetailerSign] = React.useState({});
    React.useEffect(() => {
        if (retailerSign) {
            setFinalRetailerSign(retailerSign);
        }
    }, [retailerSign]);
    const [tmsSign, setTmsSign] = useFileUpload();
    const [finalTmsSign, setFinalTmsSign] = React.useState({});
    React.useEffect(() => {
        if (tmsSign) {
            setFinalTmsSign(tmsSign);
        }
    }, [tmsSign]);

    const [proofOneBase64, setProofOneBase64] = React.useState("");
    const [proofTwoBase64, setProofTwoBase64] = React.useState("");
    const [retailerSignBase64, setRetailerSignBase64] = React.useState("");
    const [tmsSignBase64, setTmsSignBase64] = React.useState("");
    const defaultSrc = "https://i.ibb.co/2nwCHfR/user.png";
    const defaultSrc2 = "https://i.ibb.co/h1x87SM/sign.png";
    const user = JSON.parse(localStorage.getItem("user"));
    const getBase64 = file => {
        return new Promise(resolve => {
            let baseURL = "";
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                baseURL = reader.result;
                resolve(baseURL);
            };
        });
    };

    const handleProofOne = e => {
        getBase64(e)
            .then(result => {
                e["base64"] = result;
                setProofOneBase64(e.base64);
            })
            .catch(err => {
                console.log(err);
            });
    };

    const handleProofTwo = e => {
        getBase64(e)
            .then(result => {
                e["base64"] = result;
                setProofTwoBase64(e.base64);
            })
            .catch(err => {
                console.log(err);
            });
    };

    const handleRetailerSign = e => {
        getBase64(e)
            .then(result => {
                e["base64"] = result;
                setRetailerSignBase64(e.base64);
            })
            .catch(err => {
                console.log(err);
            });
    };

    const handleTmsSign = e => {
        getBase64(e)
            .then(result => {
                e["base64"] = result;
                setTmsSignBase64(e.base64);
            })
            .catch(err => {
                console.log(err);
            });
    };

    const getTmsWiseOutlet = async () => {
        try {
            const response = await baseApi1.get(`/api/tms_outlets/${user?._id}`);
            if (response.statusText !== "OK") {
                console.log("Not Getting Response");
            } else {
                setFilteredOutlets(response?.data?.map(x => {
                    return {
                        id: x?.code, text: `${x?.code} (${x?.name})`
                    };
                }));
            }
        } catch (error) {
            console.log(error.response);
        }
    };

    React.useEffect(() => {
        if (user?._id) {
            getTmsWiseOutlet();
        }
    }, [user?._id]);

    React.useEffect(() => {
        const getDistributionData = async () => {
            if (outletCode) {
                const response = await baseApi2.get(`/api/outlet-target-retailer/${outletCode}`);
                if (response.status === 200) {
                    console.log(response?.data);
                    setDistributionInfo(response?.data);
                } else {
                    console.log("Something Went Wrong");
                }
            }
        };
        getDistributionData();
    }, [outletCode]);

    const handleDistributionSubmit = async () => {
        const payload = {
            region: user?.region?.name,
            regionId: user?.region?._id,
            area: user?.area?.name,
            areaId: user?.area?._id,
            retailerName: distributionInfo?.name,
            retailerPhone: distributionInfo?.phone,
            retailerAddress: distributionInfo?.address,
            outletCode: outletCode,
            storeName: distributionInfo?.storeName,
            sales: distributionInfo?.totalSales,
            incentivesOne: distributionInfo?.incentivesOne,
            incentivesTwo: distributionInfo?.incentivesTwo,
            location: "no location found",
            territory: user?.territory?.name,
            territoryId: user?.territory?._id,
            salesPoint: user?.salesPoint[0]?.name,
            proofPhotoOne: proofOneBase64,
            proofPhotoTwo: proofTwoBase64,
            retailerSign: retailerSignBase64,
            tmsSign: tmsSignBase64,
            tms: user?.name,
            typedOtp: "1234",
            createdAt: moment(new Date()).format('YYYY-MM-DD'),
        };
        const response = await baseApi2.post('/api/addDistributionWeb', payload, {
            headers: {'Content-type': 'application/json'},
        },);
        if (response.data?.message === "Already Distributed Incentive") {
            await notify("danger", response.data?.message);
        }
        if (response.statusText !== "OK") {
            await notify("danger", 'Something went wrong.');
        } else {
            await notify("success", response.data?.message);
            setOutletCode("");
            setDistributionInfo({});
            setFinalPhotoOneLogo({});
            setFinalPhotoTwoLogo({});
            setFinalRetailerSign({});
            setFinalTmsSign({});
            setProofOneBase64("");
            setProofTwoBase64("");
            setRetailerSignBase64("");
            setTmsSignBase64("");
        }
    };


    return (<div>
        <div className="rna-wrapper">
            <NotificationAlert ref={notificationAlertRef}/>
        </div>
        <SimpleHeader name="Distribution" parentName="Incentive"/>
        <Container className="mt--6" fluid>
            <section>
                <Card>
                    <CardTitle>
                        <h3 className="mb-0 mt-3 ml-4">
                            <i className="ni ni-active-40 text-orange"/>
                            <span className="ml-2" style={{
                                fontSize: "large", color: "orange"
                            }}>Select Ground</span>
                            <hr/>
                        </h3>
                    </CardTitle>
                    <CardBody>
                        <Row>
                            <Col md="12" sm="12" lg="12">
                                <Form>
                                    <Select2
                                        className="form-control"
                                        value={outletCode}
                                        onChange={(e) => setOutletCode(e.target.value)}
                                        options={{
                                            placeholder: "Select"
                                        }}
                                        data={filteredOutlets}
                                    />
                                </Form>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </section>
        </Container>
        <Container className="mt-4" fluid>
            <section>
                <Card>
                    <CardTitle>
                        <h3 className="mb-0 mt-3 ml-4">
                            <i className="ni ni-active-40 text-orange"/>
                            <span className="ml-2" style={{
                                fontSize: "large", color: "orange"
                            }}>Outlet Details</span>
                            <hr/>
                        </h3>
                    </CardTitle>
                    <CardBody>
                        <Row>
                            <Col md="4" sm="4" lg="4">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="example4cols1Input"
                                    >
                                        Retailer Name
                                    </label>
                                    <Input disabled={true} defaultValue={distributionInfo?.name}/>
                                </FormGroup>
                            </Col>
                            <Col md="4" sm="4" lg="4">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="example4cols1Input"
                                    >
                                        Retailer Phone
                                    </label>
                                    <Input disabled={true} defaultValue={distributionInfo?.phone}/>
                                </FormGroup>
                            </Col>
                            <Col md="4" sm="4" lg="4">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="example4cols1Input"
                                    >
                                        Retailer Address
                                    </label>
                                    <Input disabled={true} defaultValue={distributionInfo?.address}/>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="6" sm="6" lg="6">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="example4cols1Input"
                                    >
                                        Store Name
                                    </label>
                                    <Input disabled={true} defaultValue={distributionInfo?.storeName}/>
                                </FormGroup>
                            </Col>
                            <Col md="6" sm="6" lg="6">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="example4cols1Input"
                                    >
                                        Sales Count
                                    </label>
                                    <Input disabled={true} defaultValue={distributionInfo?.totalSales}/>
                                </FormGroup>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </section>
        </Container>
        <Container className="mt-4" fluid>
            <Card className="p-4">
                <Row>
                    <Col md="6" sm="6" lg="6">
                        <Form>
                            <FormGroup className="row">
                                <Label
                                    className="form-control-label"
                                    htmlFor="example-tel-input"
                                    md="2"
                                >
                                    Proof Photo 1
                                </Label>
                                <Col md="10">
                                    <img
                                        style={{cursor: "pointer"}}
                                        src={finalPhotoOneLogo?.source || defaultSrc}
                                        alt="preview"
                                        className="fluid w-25 rounded mb-3"
                                        onClick={() => setPhotoOneLogo({accept: "image/*"}, ({
                                                                                                 name,
                                                                                                 size,
                                                                                                 source,
                                                                                                 file
                                                                                             }) => {
                                            handleProofOne(file);
                                        })}
                                    />
                                </Col>
                            </FormGroup>
                        </Form>
                    </Col>
                    <Col md="6" sm="6" lg="6">
                        <Form>
                            <FormGroup className="row">
                                <Label
                                    className="form-control-label"
                                    htmlFor="example-tel-input"
                                    md="2"
                                >
                                    Proof Photo 2
                                </Label>
                                <Col md="10">
                                    <img
                                        style={{cursor: "pointer"}}
                                        src={finalPhotoTwoLogo?.source || defaultSrc}
                                        alt="preview"
                                        className="fluid w-25 rounded mb-3"
                                        onClick={() => setPhotoTwoLogo({accept: "image/*"}, ({
                                                                                                 name,
                                                                                                 size,
                                                                                                 source,
                                                                                                 file
                                                                                             }) => {
                                            handleProofTwo(file);
                                        })}
                                    />
                                </Col>
                            </FormGroup>
                        </Form>
                    </Col>
                </Row>
                <Row className="mt-5">
                    <Col md="6" sm="6" lg="6">
                        <Form>
                            <FormGroup className="row">
                                <Label
                                    className="form-control-label"
                                    htmlFor="example-tel-input"
                                    md="2"
                                >
                                    Retailer Sign
                                </Label>
                                <Col md="10">
                                    <img
                                        style={{cursor: "pointer"}}
                                        src={finalRetailerSign?.source || defaultSrc2}
                                        alt="preview"
                                        className="fluid w-25 rounded mb-3"
                                        onClick={() => setRetailerSign({accept: "image/*"}, ({
                                                                                                 name,
                                                                                                 size,
                                                                                                 source,
                                                                                                 file
                                                                                             }) => {
                                            handleRetailerSign(file);
                                        })}
                                    />
                                </Col>
                            </FormGroup>
                        </Form>
                    </Col>
                    <Col md="6" sm="6" lg="6">
                        <Form>
                            <FormGroup className="row">
                                <Label
                                    className="form-control-label"
                                    htmlFor="example-tel-input"
                                    md="2"
                                >
                                    TMS Sign
                                </Label>
                                <Col md="10">
                                    <img
                                        style={{cursor: "pointer"}}
                                        src={finalTmsSign?.source || defaultSrc2}
                                        alt="preview"
                                        className="fluid w-25 rounded mb-3"
                                        onClick={() => setTmsSign({accept: "image/*"}, ({
                                                                                            name, size, source, file
                                                                                        }) => {
                                            handleTmsSign(file);
                                        })}
                                    />
                                </Col>
                            </FormGroup>
                        </Form>
                    </Col>
                </Row>
                <div className="text-right">
                    <Button color="primary" onClick={() => handleDistributionSubmit()}>Submit</Button>
                </div>
            </Card>
        </Container>
    </div>);
};

export default TmsDistribution;
