import React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";
// reactstrap components11
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Container,
    Row,
    Col,
} from "reactstrap";
// core components
import axios from "axios";
import NotificationAlert from "react-notification-alert";
import Logo from "../../../assets/img/brand/Logo.png";
import IndexNavbar from "../../../components/Navbars/IndexNavbar";
import baseUrl1 from "../../../api/BaseApi1";

function Login(props) {
    const [focusedEmail, setfocusedEmail] = React.useState(false);
    const [focusedPassword, setfocusedPassword] = React.useState(false);
    const [userEmail, setUserEmail] = React.useState("");
    const [userPassword, setUserPassword] = React.useState("");
    const notificationAlertRef = React.useRef(null);
    const notify = (type, message) => {
        let options = {
            place: "bc",
            message: (
                <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
              Auth Notification
          </span>
                    <span data-notify="message">{message}</span>
                </div>
            ),
            type: type,
            icon: "ni ni-bell-55",
            autoDismiss: 7,
        };
        notificationAlertRef.current.notificationAlert(options);
    };
    const getUserInfo = async () => {
        const userData = {
            email: userEmail,
            password: userPassword,
        };
        try {
            const response = await baseUrl1.post(
                `/api/login`,
                userData,
                {headers: {"Content-Type": "application/json"}}
            );
            if (!response.statusText === "OK") {
                console.log("Not Getting Response");
            } else {
                localStorage.setItem("token", response.data.payload.jwt_token);
                localStorage.setItem("user", JSON.stringify(response.data.payload));
                localStorage.setItem("version", "v1");
                props.history.push("/admin/dashboard");
            }
        } catch (error) {
            notify("danger", error.response.data.message);
        }
    };
    const getAppUserInfo = async () => {
        const userData = {
            phone: userEmail,
            password: userPassword,
        };
        try {
            const response = await baseUrl1.post(
                `/app/login`,
                userData,
                {headers: {"Content-Type": "application/json"}}
            );
            if (!response.statusText === "OK") {
                console.log("Not Getting Response");
            } else {
                localStorage.setItem("token", response.data.payload.jwt_token);
                localStorage.setItem("user", JSON.stringify(response.data.payload));
                localStorage.setItem("version", "v1");
                props.history.push("/admin/tmswebdistribution");
            }
        } catch (error) {
            notify("danger", error.response.data.message);
        }
    };
    const handleSingIn = () => {
        if (Number(userEmail)) {
            getAppUserInfo();
        } else {
            getUserInfo();
        }
    };
    const hanleButtonLogin = (e) => {
        if (e.charCode === 13) {
            if (Number(userEmail)) {
                getAppUserInfo();
            } else {
                getUserInfo();
            }
        }
    };

    return (
        <>
            <div className="rna-wrapper">
                <NotificationAlert ref={notificationAlertRef}/>
            </div>
            <IndexNavbar/>
            <Container className="mt-8 pb-5">
                <Row className="justify-content-center">
                    <Col lg="5" md="7">
                        <Card className="bg-secondary border-0 mb-0">
                            <CardHeader className="bg-transparent pb-5">
                                <div className="text-muted text-center mt-2 mb-3">
                                    <img className="img-fluid" src={Logo} alt=""/>
                                </div>
                            </CardHeader>
                            <CardBody className="px-lg-5 py-lg-5">
                                <Form role="form">
                                    <FormGroup
                                        className={classnames("mb-3", {
                                            focused: focusedEmail,
                                        })}
                                    >
                                        <InputGroup className="input-group-merge input-group-alternative">
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    <i className="ni ni-email-83"/>
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input
                                                placeholder="Email or Phone Number"
                                                type="email"
                                                onFocus={() => setfocusedEmail(true)}
                                                onBlur={() => setfocusedEmail(true)}
                                                onChange={(e) => setUserEmail(e.target.value)}
                                                onKeyPress={hanleButtonLogin}
                                            />
                                        </InputGroup>
                                    </FormGroup>
                                    <FormGroup
                                        className={classnames({
                                            focused: focusedPassword,
                                        })}
                                    >
                                        <InputGroup className="input-group-merge input-group-alternative">
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    <i className="ni ni-lock-circle-open"/>
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input
                                                placeholder="Password"
                                                type="password"
                                                onFocus={() => setfocusedPassword(true)}
                                                onBlur={() => setfocusedPassword(true)}
                                                onChange={(e) => setUserPassword(e.target.value)}
                                                onKeyPress={hanleButtonLogin}
                                            />
                                        </InputGroup>
                                    </FormGroup>
                                    <div className="text-center">
                                        <Button
                                            onClick={handleSingIn}
                                            className="my-4"
                                            color="info"
                                            type="button"
                                        >
                                            Sign in
                                        </Button>
                                    </div>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default Login;
