import React from 'react';
import {GoogleMap, LoadScript, Marker, InfoWindow, useLoadScript} from '@react-google-maps/api';
import Map from "./Map";

const ViewTmsMap = () => {
    const {isLoaded} = useLoadScript({
        googleMapsApiKey: "AIzaSyDOluDqFc8O5M-I6a5h3M3pAtnvWAZuoqo" // Add your API key
    });

    return isLoaded ? <Map /> : null;
};

export default ViewTmsMap;
