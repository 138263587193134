import React, { useContext, useEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import cellEditFactory, { Type } from "react-bootstrap-table2-editor";
import SweetAlert from "react-bootstrap-sweetalert";
import classnames from "classnames";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  Modal,
  Row,
} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import axios from "axios";
import NotificationAlert from "react-notification-alert";
import DataContext from "context/DataContext";
import baseApi1 from "../../api/BaseApi1";
import {baseURL1} from "../../api/baseURL.json";

const { SearchBar } = Search;
const ChangeData = () => {
  const { salesPoint, route, tms, brands } = useContext(DataContext);
  const [alert, setalert] = React.useState(false);
  const [showPerPage, setShowPerPage] = React.useState(10);
  const [page, setPage] = React.useState(1);
  const [searchTerm, setSearchTerm] = React.useState("");
  const [totalSize, setTotalSize] = React.useState(10);
  const { jwt_token } = JSON.parse(localStorage.getItem("user"));
  const userInfo = JSON.parse(localStorage.getItem("user"));
  //All Changeable List
  const [changeableList, setChangeableList] = React.useState([]);
  //TMS
  const [allTms, setAllTms] = React.useState([]);
  const [oldTmsID, setOldTmsID] = React.useState("");
  const [oldTmsName, setOldTmsName] = React.useState("");
  const [retailerID, setRetailerID] = React.useState("");
  const [newTMS, setNewTMS] = React.useState("");
  //Modals
  const [changeTMSModal, setChangeTMSModal] = React.useState(false);
  const [assignOutletModal, setAssignOutletModal] = React.useState(false);
  const [assignSalesPoint, setAssignSalesPoint] = React.useState({});
  const [assignRoute, setAssignRoute] = React.useState({});
  const [editingRow, setEditingRow] = React.useState({});

  useEffect(() => {
    localStorage.setItem(
      "edit",
      JSON.stringify({ ...editingRow, ...assignSalesPoint, ...assignRoute })
    );
  }, [editingRow, assignSalesPoint, assignRoute]);

  const filterSalesPoint = salesPoint.filter(
    (x) => x.territory._id === userInfo?.territory?._id
  );

  //Notification Alert
  const notificationAlertRef = React.useRef(null);
  const notify = (type, message) => {
    let options = {
      place: "bc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            Change Database Notification
          </span>
          <span data-notify="message">{message}</span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 15,
    };
    notificationAlertRef.current.notificationAlert(options);
  };
  const componentRef = React.useRef(null);
  //All Data
  const getAllChangeableData = async () => {
    try {
      const response = await baseApi1.get(
        `/api/my-retailers?page=${page}&limit=${showPerPage}&search=${searchTerm}`,
        {
          headers: {
            Authorization: `Bearer ${jwt_token}`,
          },
        }
      );
      if (!response.statusText === "OK") {
        notify("danger", response.data.message);
      } else {
        setTotalSize(response.data.total);
        setChangeableList(response?.data?.data);
      }
    } catch (error) {
      notify("danger", error.response.data.message);
    }
  };
  useEffect(() => {
    getAllChangeableData();
  }, [page, showPerPage, searchTerm]);

  const pagination = paginationFactory({
    page,
    totalSize,
    showTotal: true,
    sizePerPage: showPerPage,
    alwaysShowAllBtns: true,
    withFirstAndLast: true,
    onPageChange: (page) => {
      setPage(page);
    },
    onSizePerPageChange: (_, size) => {
      setShowPerPage(size);
    },
    sizePerPageRenderer: ({
      options,
      currSizePerPage,
      onSizePerPageChange,
    }) => (
      <div className="dataTables_length" id="datatable-basic_length">
        <label>
          Show{" "}
          {
            <select
              value={currSizePerPage}
              name="datatable-basic_length"
              aria-controls="datatable-basic"
              className="form-control form-control-sm"
              onChange={(e) => onSizePerPageChange(e.target.value)}
            >
              <option value={10}>10</option>
              <option value={25}>25</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
              <option value="">all</option>
            </select>
          }{" "}
          entries.
        </label>
      </div>
    ),
  });
  const warningAlert = (oldValue, newValue, row, column) => {
    let newFinal = column?.editor?.options.find((x) => x.value === newValue);
    setalert(
      <SweetAlert
        custom
        showCloseButton
        confirmBtnText="Dismiss"
        confirmBtnBsStyle="warning"
        cancelBtnBsStyle="light"
        customIcon="https://i.ibb.co/5nNgVLD/alert.gif"
        title="Your Change Details"
        onConfirm={() => setalert(false)}
      >
        Your Current Data is <b style={{ color: "green" }}> {oldValue}</b> , Now
        You Are Changing Value to{" "}
        <b style={{ color: "red" }}>
          {" "}
          {column?.dataField === "brand" ? newFinal?.label : newValue}
        </b>
      </SweetAlert>
    );
  };
  const assignOutlets = (oldValue, newValue, row, column) => {
    setAssignSalesPoint({ salesPoint: newValue });
    const finalRoute = route.filter((x) => x.salesPoint._id === newValue);

    setAssignOutletModal(
      <Modal
        className="modal-dialog-centered"
        size="md"
        isOpen={newValue !== "" ? true : assignOutletModal}
        toggle={() => setAssignOutletModal(false)}
      >
        <div className="modal-body p-0">
          <Card className="bg-secondary border-0 mb-0">
            <CardHeader style={{ color: "#DB5A3B" }} className="bg-transparent">
              Assign Outlet
            </CardHeader>
            <CardBody className="px-lg-5 py-lg-5">
              <Form role="form">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlSelect1"
                  >
                    Sales Point
                  </label>
                  <Input
                    id="exampleFormControlSelect1"
                    type="select"
                    onChange={(e) =>
                      setAssignSalesPoint({ salesPoint: e.target.value })
                    }
                  >
                    <option>Select</option>
                    {filterSalesPoint.map((sp) => (
                      <option selected={sp._id === newValue} value={sp._id}>
                        {sp.name}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlSelect1"
                  >
                    Route
                  </label>
                  <Input
                    id="exampleFormControlSelect1"
                    type="select"
                    onChange={(e) => setAssignRoute({ route: e.target.value })}
                  >
                    <option>Select</option>
                    {finalRoute.map((route) => (
                      <option
                        /*                                                selected={route.id === row?.route?.id}*/
                        value={route._id}
                      >
                        {route.name}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
                <div className="text-center">
                  <Button
                    onClick={() => setAssignOutletModal(false)}
                    className="my-4"
                    color="success"
                    type="button"
                  >
                    Dismiss
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </div>
      </Modal>
    );
  };

  //Single TMS
  const singleTMS = (id) => {
    const single = changeableList.find((x) => x._id === id);
    setOldTmsID(single?.tms?._id);
    setOldTmsName(single?.tms?.name);
    setRetailerID(single?._id);
  };

  const getTEWiseTms = async (id) => {
    try {
      const response = await baseApi1.get(
        `/api/usercontrol/${userInfo.territory._id}`
      );
      if (!response.statusText === "OK") {
        notify("danger", response.data.message);
      } else {
        setAllTms(response?.data?.data);
      }
    } catch (error) {
      notify("danger", error.response.data.message);
    }
  };

  //Change TMS
  const changeTMS = async () => {
    const changedData = JSON.stringify({
      retailer: retailerID,
      tms: newTMS,
    });
    try {
      const response = await baseApi1.post(
        "/api/retailer/change-tms",
        changedData,
        {
          headers: {
            Authorization: `Bearer ${jwt_token}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (!response.statusText === "OK") {
        notify("danger", response.data.message);
      } else {
        getAllChangeableData();
        notify("success", response.data.message);
        setChangeTMSModal(false);
      }
    } catch (error) {
      notify("danger", error.response.data.message);
    }
  };

  const submitChangeTMS = () => {
    changeTMS();
  };

  function beforeSaveCell(oldValue, newValue, row, column, done) {
    if (oldValue !== newValue && column.dataField !== "salesPoint.name") {
      warningAlert(oldValue, newValue, row, column);
      setEditingRow((pre) => ({
        ...pre,
        outletCode: row?.outletCode,
        ...(column.dataField === "outlet.name" && { outletName: newValue }),
        ...(column.dataField === "name" && { retailerName: newValue }),
        ...(column.dataField === "email" && { retailerEmail: newValue }),
        ...(column.dataField === "phone" && { retailerPhone: newValue }),
        ...(column.dataField === "address" && { retailerAddress: newValue }),
        ...(column.dataField === "emergencyContact" && {
          retailerEmergencyContact: newValue,
        }),
        ...(column.dataField === "relationship" && {
          retailerRelationship: newValue,
        }),
        ...(column.dataField === "tms.name" && { tmsName: newValue }),
        ...(column.dataField === "tms.phone" && { tmsPhone: newValue }),
        ...(column.dataField === "tms.enrollId" && { tmsEnrollId: newValue }),
        ...(column.dataField === "tms.email" && { tmsEmail: newValue }),
        ...(column.dataField === "brand" && { brand: newValue }),
      }));
    } else {
      assignOutlets(oldValue, newValue, row, column);
    }
    return { async: true };
  }

  const sendRequest = async () => {
    const editFinal = JSON.parse(localStorage.getItem("edit"));
    const changedData = JSON.stringify({
      ...(editFinal.outletCode !== undefined && {
        outletCode: editFinal.outletCode,
      }),
      ...(editFinal.outletName !== undefined && {
        outletName: editFinal.outletName,
      }),
      ...(editFinal.retailerName !== undefined && {
        retailerName: editFinal.retailerName,
      }),
      ...(editFinal.retailerEmail !== undefined && {
        retailerEmail: editFinal.retailerEmail,
      }),
      ...(editFinal.retailerPhone !== undefined && {
        retailerPhone: editFinal.retailerPhone,
      }),
      ...(editFinal.retailerAddress !== undefined && {
        retailerAddress: editFinal.retailerAddress,
      }),
      ...(editFinal.retailerEmergencyContact !== undefined && {
        retailerEmergencyContact: editFinal.retailerEmergencyContact,
      }),
      ...(editFinal.retailerRelationship !== undefined && {
        retailerRelationship: editFinal.retailerRelationship,
      }),
      ...(editFinal.tmsName !== undefined && { tmsName: editFinal.tmsName }),
      ...(editFinal.tmsPhone !== undefined && { tmsPhone: editFinal.tmsPhone }),
      ...(editFinal.tmsEnrollId !== undefined && {
        tmsEnrollId: editFinal.tmsEnrollId,
      }),
      ...(editFinal.tmsEmail !== undefined && { tmsEmail: editFinal.tmsEmail }),
      ...(editFinal.brand !== undefined && { brand: { id: editFinal.brand } }),
      ...(editFinal.salesPoint !== undefined && {
        salesPoint: editFinal.salesPoint,
      }),
      ...(editFinal.route !== undefined && { route: editFinal.route }),
    });
    try {
      const response = await baseApi1.post(
        "/api/change-request",
        changedData,
        {
          headers: {
            Authorization: `Bearer ${jwt_token}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (!response.statusText === "OK") {
        notify("danger", response.data.message);
      } else {
        console.log(response.data.message);
        if (response.data.message === "Request Added Successfully!") {
          setalert(false);
          setEditingRow({});
          setAssignSalesPoint({});
          setAssignRoute({});
          localStorage.removeItem("edit");
          getAllChangeableData();
        }
        notify("success", response.data.message);
      }
    } catch (error) {
      setEditingRow({});
      setAssignSalesPoint({});
      setAssignRoute({});
      localStorage.removeItem("edit");
      notify("danger", error.response.data.message);
    }
  };
  const handleRequest = (outletCode) => {
    if (JSON.parse(localStorage.getItem("edit")).outletCode === undefined) {
      notify("danger", "Not Edited Data Found");
    } else if (
      outletCode !== JSON.parse(localStorage.getItem("edit")).outletCode
    ) {
      console.log("Not Matched");
      notify("danger", "You Are Trying to Edit Multiple Outlets Data");
    } else {
      sendRequest();
    }
  };
  return (
    <div>
      {alert}
      {assignOutletModal}
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <SimpleHeader name="Change Database" parentName="Database List" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="d-flex justify-content-between">
                <h3 style={{ color: "#DB5A3B" }}>Database List</h3>
              </CardHeader>
              <ToolkitProvider
                data={changeableList}
                keyField="outlet.code"
                columns={[
                  {
                    dataField: "#",
                    text: "SL",
                    formatter: (cell, row, rowIndex) => {
                      return rowIndex + 1;
                    },
                    editable: false,
                  },
                  {
                    dataField: "outlet",
                    text: "Outlet Code",
                    sort: true,
                    formatter: (cell) => {
                      return cell.code;
                    },
                    editable: false,
                  },
                  {
                    dataField: "outlet.name",
                    text: "Outlet Name",
                    sort: true,
                    editorStyle: {
                      width: "50vh",
                    },
                  },
                  {
                    dataField: "region",
                    text: "Region Name",
                    sort: true,
                    formatter: (cell) => {
                      return cell.name;
                    },
                    editable: false,
                  },
                  {
                    dataField: "area",
                    text: "Area Name",
                    sort: true,
                    formatter: (cell) => {
                      return cell.name;
                    },
                    editable: false,
                  },
                  {
                    dataField: "territory",
                    text: "Territory Name",
                    sort: true,
                    formatter: (cell) => {
                      return cell.name;
                    },
                    editable: false,
                  },
                  {
                    dataField: "salesPoint.name",
                    text: "SalesPoint Name",
                    sort: true,
                    editor: {
                      type: Type.SELECT,
                      options: filterSalesPoint.map((x) => {
                        return { value: x._id, label: x.name };
                      }),
                    },
                  },
                  {
                    dataField: "route.id",
                    text: "Route ID",
                    sort: true,
                    editable: false,
                  },
                  {
                    dataField: "route.name",
                    text: "Route Name",
                    sort: true,
                    editable: false,
                  },
                  {
                    dataField: "brand",
                    text: "Focus Brand",
                    sort: true,
                    editor: {
                      type: Type.SELECT,
                      options: brands.map((x) => {
                        return { value: x._id, label: x.name };
                      }),
                    },
                    editorStyle: {
                      width: "50vh",
                    },
                  },
                  {
                    dataField: "name",
                    text: "Retailer Name",
                    sort: true,
                    editorStyle: {
                      width: "50vh",
                    },
                  },
                  {
                    dataField: "phone",
                    text: "Retailer Number",
                    sort: true,
                    editorStyle: {
                      width: "50vh",
                    },
                  },
                  {
                    dataField: "email",
                    text: "Retailer Email",
                    sort: true,
                    editorStyle: {
                      width: "50vh",
                    },
                  },
                  {
                    dataField: "address",
                    text: "Retailer Address",
                    sort: true,
                    editorStyle: {
                      width: "50vh",
                    },
                  },
                  {
                    dataField: "emergencyContact",
                    text: "Emergency Contact",
                    sort: true,
                    editorStyle: {
                      width: "50vh",
                    },
                  },
                  {
                    dataField: "relationship",
                    text: "Relationship",
                    sort: true,
                    editorStyle: {
                      width: "50vh",
                    },
                  },
                  {
                    dataField: "tms.name",
                    text: "TMS Name",
                    sort: true,
                    editorStyle: {
                      width: "50vh",
                    },
                  },
                  {
                    dataField: "tms.phone",
                    text: "TMS Phone",
                    sort: true,
                    editorStyle: {
                      width: "50vh",
                    },
                  },
                  {
                    dataField: "tms.enrollId",
                    text: "TMS enrollId",
                    sort: true,
                    editorStyle: {
                      width: "50vh",
                    },
                  },
                  {
                    dataField: "tms.email",
                    text: "TMS Email",
                    sort: true,
                    editorStyle: {
                      width: "50vh",
                    },
                  },
                  {
                    dataField: "_id",
                    text: "Action",
                    formatter: (cell, row) => {
                      const handleChangeTms = () => {
                        getAllChangeableData();
                        singleTMS(cell);
                        getTEWiseTms(cell);
                        setChangeTMSModal(true);
                      };
                      return (
                        <div>
                          <Button onClick={handleChangeTms} color="danger">
                            Change TMS
                          </Button>
                          <Button
                            disabled={row.pending > 0 && true}
                            onClick={() => handleRequest(row.outletCode)}
                            color="warning"
                          >
                            {row.pending > 0 ? "Pending" : "Request"}
                          </Button>
                        </div>
                      );
                    },
                  },
                ]}
                search
              >
                {(props) => (
                  <div className="py-4 table-responsive">
                    <Container fluid>
                      <Row>
                        <Col xs={6} sm={6}>
                          <div>
                            <select
                              name="datatable-basic_length"
                              aria-controls="datatable-basic"
                              className="form-control form-control-sm w-25"
                              onChange={(e) => setShowPerPage(e.target.value)}
                            >
                              <option
                                selected={showPerPage === 10 && true}
                                value={10}
                              >
                                10
                              </option>
                              <option
                                selected={showPerPage === 25 && true}
                                value={25}
                              >
                                25
                              </option>
                              <option
                                selected={showPerPage === 50 && true}
                                value={50}
                              >
                                50
                              </option>
                              <option
                                selected={showPerPage === 100 && true}
                                value={100}
                              >
                                100
                              </option>
                              <option
                                selected={showPerPage === "all" && true}
                                value="all"
                              >
                                all
                              </option>
                            </select>
                          </div>
                        </Col>
                        <Col xs={6} sm={6}>
                          <div
                            id="datatable-basic_filter"
                            className="dataTables_filter px-4 pb-1 float-right"
                          >
                            <label>
                              Search:
                              <SearchBar
                                className="form-control-sm"
                                placeholder=""
                                {...props.searchProps}
                              />
                            </label>
                          </div>
                        </Col>
                      </Row>
                    </Container>
                    <BootstrapTable
                      remote
                      ref={componentRef}
                      {...props.baseProps}
                      bootstrap4={true}
                      pagination={pagination}
                      bordered={false}
                      hover={true}
                      id="react-bs-table"
                      onTableChange={(type, { searchText }) => {
                        setSearchTerm(searchText);
                      }}
                      cellEdit={cellEditFactory({
                        mode: "dbclick",
                        autoSelectText: true,
                        beforeSaveCell,
                      })}
                    />
                  </div>
                )}
              </ToolkitProvider>
            </Card>
          </div>
        </Row>
      </Container>
      <Modal
        className="modal-dialog-centered"
        size="md"
        isOpen={changeTMSModal}
        toggle={() => setChangeTMSModal(false)}
      >
        <div className="modal-body p-0">
          <Card className="bg-secondary border-0 mb-0">
            <CardHeader style={{ color: "#DB5A3B" }} className="bg-transparent">
              Change TMS
            </CardHeader>
            <CardBody className="px-lg-5 py-lg-5">
              <Form role="form">
                <FormGroup className={classnames("mb-3")}>
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlSelect1"
                  >
                    Old TMS
                  </label>
                  <InputGroup className="input-group-merge input-group-alternative">
                    <Input type="name" value={oldTmsName} disabled />
                  </InputGroup>
                </FormGroup>
                <FormGroup className={classnames("mb-3")}>
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlSelect1"
                  >
                    New TMS
                  </label>
                  <Input
                    id="exampleFormControlSelect1"
                    onChange={(e) => setNewTMS(e.target.value)}
                    type="select"
                  >
                    <option>Select</option>
                    {allTms.map((tms) => (
                      <option value={tms._id}>{tms.name}</option>
                    ))}
                  </Input>
                </FormGroup>
                <div className="text-center">
                  <Button
                    onClick={() => setChangeTMSModal(false)}
                    className="my-4"
                    color="danger"
                    type="button"
                  >
                    Close
                  </Button>
                  <Button
                    onClick={submitChangeTMS}
                    className="my-4"
                    color="success"
                    type="button"
                  >
                    Request
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </div>
      </Modal>
    </div>
  );
};

export default ChangeData;
