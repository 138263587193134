import React, {useContext, useEffect} from "react";
// react plugin that prints a given react component
import ReactToPrint from "react-to-print";
import classnames from "classnames";
// react component for creating dynamic tables
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {Search} from "react-bootstrap-table2-toolkit";
import ReactLoading from "react-loading";
import CurrencyFormat from "react-currency-format";
// reactstrap components11
import {
    Button,
    ButtonGroup,
    Card,
    CardHeader,
    Container,
    CardBody,
    FormGroup,
    Form,
    Modal,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Row,
    Col,
    UncontrolledTooltip,
    CardTitle,
} from "reactstrap";
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";

import axios from "axios";
import NotificationAlert from "react-notification-alert";
import DataContext from "context/DataContext";
import baseApi1 from "../../api/BaseApi1";
import {baseURL1} from "../../api/baseURL.json";

const {SearchBar} = Search;

const OutletSetting = () => {
    const {region, area, territory, salesPoint, route} =
        useContext(DataContext);
    const [showPerPage, setShowPerPage] = React.useState(10);
    const [page, setPage] = React.useState(1);
    const [searchTerm, setSearchTerm] = React.useState("");
    const [totalSize, setTotalSize] = React.useState(10);
    //Edit Area
    const [editRegion, setEditRegion] = React.useState("");
    const [editArea, setEditArea] = React.useState("");
    const [editTerritory, setEditTerritory] = React.useState("");
    const [editSalesPoint, setEditSalesPoint] = React.useState("");
    const [editRoute, setEditRoute] = React.useState("");
    const [outletName, setOutletName] = React.useState("");
    const [outletCode, setOutletCode] = React.useState("");
    //Add Area
    const [newRegion, setNewRegion] = React.useState("");
    const [newArea, setNewArea] = React.useState("");
    const [newTerritory, setNewTerritory] = React.useState("");
    const [newSalesPoint, setNewSalesPoint] = React.useState("");
    const [newRoute, setNewRoute] = React.useState("");
    const [newOutletName, setNewOutletName] = React.useState("");
    const [newOutletCode, setNewOutletCode] = React.useState("");
    //Others
    const [formModal, setformModal] = React.useState(false);
    const [addOutletModal, setOutletModal] = React.useState(false);
    const [allOutlet, setAllOutlet] = React.useState([]);
    const [singleOutlet, setSingleOutlet] = React.useState({});
    //EditFilters
    const filteredArea = area.filter((item) => item.regionId === editRegion);
    const filteredTerritory = territory.filter(
        (item) => item.areaId === editArea
    );
    const filteredSalesPoint = salesPoint.filter(
        (item) => item.territory._id === editTerritory
    );
    const filteredRoute = route?.filter(
        (item) => item?.salesPoint?._id === editSalesPoint
    );
    //AddFilters
    const newfilteredArea = area.filter((item) => item.regionId === newRegion);
    const newfilteredTerritory = territory.filter(
        (item) => item.areaId === newArea
    );
    const newfilteredSalesPoint = salesPoint.filter(
        (item) => item.territory._id === newTerritory
    );
    const newfilteredRoute = route?.filter(
        (item) => item?.salesPoint?._id === newSalesPoint
    );
    //Notification Alert
    const notificationAlertRef = React.useRef(null);
    const notify = (type, message) => {
        let options = {
            place: "bc",
            message: (
                <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
              Outlet Notification
          </span>
                    <span data-notify="message">{message}</span>
                </div>
            ),
            type: type,
            icon: "ni ni-bell-55",
            autoDismiss: 7,
        };
        notificationAlertRef.current.notificationAlert(options);
    };

    const componentRef = React.useRef(null);
    const getOutletData = async () => {
        try {
            const response = await baseApi1.get(
                `/api/outlets/all?page=${page}&limit=${showPerPage}&search=${searchTerm}`
            );
            if (!response.statusText === "OK") {
                console.log("Not Getting Response");
            } else {
                setTotalSize(response.data.total);
                setAllOutlet(response.data.data);
            }
        } catch (error) {
            console.log(error);
        }
    };
    const getOneOutletData = async (id) => {
        try {
            const response = await baseApi1.get(
                `/api/outlet/one/${id}`
            );
            if (!response.statusText === "OK") {
                console.log("Not Getting Response");
            } else {
                setEditRegion(response.data.region);
                setEditArea(response.data.area);
                setEditTerritory(response.data.territory);
                setEditSalesPoint(response.data.salesPoint);
                setSingleOutlet(response.data);
            }
        } catch (error) {
            console.log(error);
        }
    };
    const deleteOneOutlet = async (id) => {
        try {
            const response = await baseApi1.delete(
                `/api/outlets/${id}`
            );
            if (!response.statusText === "OK") {
                console.log("Not Getting Response");
                notify("danger", response.data.message);
            } else {
                getOutletData();
                notify("success", response.data.message);
            }
        } catch (error) {
            console.log(error.response);
        }
    };
    const updateOneOutletData = async (id) => {
        const editedOutlet = {
            region: editRegion === "" ? singleOutlet?.region : editRegion,
            area: editArea === "" ? singleOutlet?.area : editArea,
            territory: editTerritory === "" ? singleOutlet?.area : editTerritory,
            salesPoint:
                editSalesPoint === "" ? singleOutlet?.salesPoint : editSalesPoint,
            route: editRoute === "" ? singleOutlet?.route : editRoute,
            code: outletCode === "" ? singleOutlet?.code : outletCode,
            name: outletName === "" ? singleOutlet?.name : outletName,
        };
        try {
            const response = await baseApi1.put(
                `/api/outlets/${id}`,
                editedOutlet,
                {headers: {"Content-Type": "application/json"}}
            );
            if (!response.statusText === "OK") {
                notify("danger", response.data.message);
            } else {
                getOutletData();
                setformModal(false);
                notify("success", response.data.message);
            }
        } catch (error) {
            console.log(error);
        }
    };
    useEffect(() => {
        getOutletData();
    }, [page, showPerPage, searchTerm]);

    const pagination = paginationFactory({
        page,
        totalSize,
        showTotal: true,
        sizePerPage: showPerPage,
        alwaysShowAllBtns: true,
        withFirstAndLast: true,
        onPageChange: (page) => {
            setPage(page);
        },
        onSizePerPageChange: (_, size) => {
            setShowPerPage(size);
        },
        sizePerPageRenderer: ({
                                  options,
                                  currSizePerPage,
                                  onSizePerPageChange,
                              }) => (
            <div className="dataTables_length" id="datatable-basic_length">
                <label>
                    Show{" "}
                    {
                        <select
                            value={currSizePerPage}
                            name="datatable-basic_length"
                            aria-controls="datatable-basic"
                            className="form-control form-control-sm"
                            onChange={(e) => onSizePerPageChange(e.target.value)}
                        >
                            <option value={10}>10</option>
                            <option value={25}>25</option>
                            <option value={50}>50</option>
                            <option value={100}>100</option>
                            <option value="">all</option>
                        </select>
                    }{" "}
                    entries.
                </label>
            </div>
        ),
    });
    const addNewOutlet = async () => {
        const newOutletData = {
            regionId: newRegion,
            areaId: newArea,
            territoryId: newTerritory,
            salesPoint: newSalesPoint,
            route: newRoute,
            code: newOutletCode,
            name: newOutletName,
        };
        try {
            const response = await baseApi1.post(
                "/api/outlets",
                newOutletData,
                {headers: {"Content-Type": "application/json"}}
            );
            if (!response.statusText === "OK") {
                console.log("Not Getting Response");
                notify("danger", "Some Thing Went Wrong");
            } else {
                getOutletData();
                response.status === 200 || response.status === 201
                    ? setOutletModal(false)
                    : setOutletModal(true);
                notify("success", response.data.message);
            }
        } catch (error) {
            console.log(error);
        }
    };
    const submitNewTms = () => {
        addNewOutlet();
    };
    const handleSingleTmsDelete = (id) => {
        deleteOneOutlet(id);
    };
    return (
        <div>
            <div className="rna-wrapper">
                <NotificationAlert ref={notificationAlertRef}/>
            </div>
            <SimpleHeader name="Outlet List" parentName="Outlet"/>
            <Container className="mt--6" fluid>
                <Row>
                    <div className="col">
                        <Card>
                            <CardHeader className="d-flex justify-content-between">
                                <h3 style={{color: "#DB5A3B"}}>Outlet Setting</h3>
                                <Button
                                    onClick={() => setOutletModal(true)}
                                    style={{backgroundColor: "#DB5A3B", color: "white"}}
                                >
                                    Add New
                                </Button>
                            </CardHeader>
                            <div className="m-3" style={{width: "18rem"}}>
                                <Card className="card-stats bg-gradient-warning mb-4 mb-lg-0">
                                    <CardBody>
                                        <Row>
                                            <div className="col">
                                                <CardTitle className="text-uppercase text-white text-muted mb-0">
                                                    Total Outlet
                                                </CardTitle>
                                                <span className="h2 font-weight-bold mb-0 text-white">
                          <CurrencyFormat
                              value={totalSize === 10 ? 0 : totalSize}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={""}
                          />
                        </span>
                                            </div>
                                            <Col className="col-auto">
                                                <div
                                                    className="icon icon-shape bg-danger text-white rounded-circle shadow">
                                                    <i className="fas fa-chart-bar"/>
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </div>
                            <ToolkitProvider
                                data={allOutlet}
                                keyField="code"
                                columns={[
                                    {
                                        dataField: "#",
                                        text: "SL",
                                        formatter: (cell, row, rowIndex) => {
                                            return rowIndex + 1;
                                        },
                                    },
                                    {
                                        dataField: "region",
                                        text: "Region",
                                        sort: true,
                                    },
                                    {
                                        dataField: "area",
                                        text: "Area",
                                        sort: true,
                                    },
                                    {
                                        dataField: "territory",
                                        text: "Territory",
                                        sort: true,
                                    },
                                    {
                                        dataField: "salesPoint",
                                        text: "Sales Point",
                                        sort: true,
                                    },
                                    {
                                        dataField: "routeName",
                                        text: "Route Name",
                                        sort: true,
                                    },
                                    {
                                        dataField: "name",
                                        text: "Outlet Name",
                                        sort: true,
                                    },
                                    {
                                        dataField: "code",
                                        text: "Outlet Code",
                                        sort: true,
                                    },
                                    {
                                        dataField: "_id",
                                        text: "Action",
                                        formatter: (cell) => {
                                            const handleEdit = () => {
                                                getOneOutletData(cell);
                                                setformModal(true);
                                            };
                                            const action = (
                                                <div>
                                                    <Button color="info" onClick={handleEdit}>
                                                        Edit
                                                    </Button>
                                                    <Button
                                                        onClick={() => handleSingleTmsDelete(cell)}
                                                        color="danger"
                                                    >
                                                        Delete
                                                    </Button>
                                                </div>
                                            );
                                            return action;
                                        },
                                    },
                                ]}
                                search
                            >
                                {(props) => (
                                    <div className="py-4 table-responsive">
                                        <Container fluid>
                                            <Row>
                                                <Col xs={4} sm={4}>
                                                    <div>
                                                        <select
                                                            name="datatable-basic_length"
                                                            aria-controls="datatable-basic"
                                                            className="form-control form-control-sm w-25"
                                                            onChange={(e) => setShowPerPage(e.target.value)}
                                                        >
                                                            <option
                                                                selected={showPerPage === 10 && true}
                                                                value={10}
                                                            >
                                                                10
                                                            </option>
                                                            <option
                                                                selected={showPerPage === 25 && true}
                                                                value={25}
                                                            >
                                                                25
                                                            </option>
                                                            <option
                                                                selected={showPerPage === 50 && true}
                                                                value={50}
                                                            >
                                                                50
                                                            </option>
                                                            <option
                                                                selected={showPerPage === 100 && true}
                                                                value={100}
                                                            >
                                                                100
                                                            </option>
                                                            <option
                                                                selected={showPerPage === "all" && true}
                                                                value="all"
                                                            >
                                                                all
                                                            </option>
                                                        </select>
                                                    </div>
                                                </Col>
                                                <Col xs={4} sm={4}>
                                                    <ButtonGroup>
                                                        <Button
                                                            className="buttons-copy buttons-html5"
                                                            color="warning"
                                                            size="sm"
                                                            id="copy-tooltip"
                                                            onClick={() =>
                                                                window.open(
                                                                    `${baseURL1}/api/outlets/all/csv`
                                                                )
                                                            }
                                                        >
                                                            <span>CSV</span>
                                                        </Button>
                                                        <ReactToPrint
                                                            trigger={() => (
                                                                <Button
                                                                    color="warning"
                                                                    size="sm"
                                                                    className="buttons-copy buttons-html5"
                                                                    id="print-tooltip"
                                                                >
                                                                    Print
                                                                </Button>
                                                            )}
                                                            content={() => componentRef.current}
                                                        />
                                                    </ButtonGroup>
                                                    <UncontrolledTooltip
                                                        placement="top"
                                                        target="print-tooltip"
                                                    >
                                                        This will open a print page with the visible rows of
                                                        the table.
                                                    </UncontrolledTooltip>
                                                    <UncontrolledTooltip
                                                        placement="top"
                                                        target="copy-tooltip"
                                                    >
                                                        This will Download Outlet Setting As CSV.
                                                    </UncontrolledTooltip>
                                                </Col>
                                                <Col xs={4} sm={4}>
                                                    <div
                                                        id="datatable-basic_filter"
                                                        className="dataTables_filter px-4 pb-1 float-right"
                                                    >
                                                        <label>
                                                            Search:
                                                            <SearchBar
                                                                className="form-control-sm"
                                                                placeholder=""
                                                                {...props.searchProps}
                                                            />
                                                        </label>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Container>
                                        {allOutlet.length > 0 ? (
                                            <BootstrapTable
                                                remote
                                                ref={componentRef}
                                                {...props.baseProps}
                                                bootstrap4={true}
                                                hover={true}
                                                pagination={pagination}
                                                bordered={false}
                                                id="react-bs-table"
                                                onTableChange={(type, {searchText}) => {
                                                    setSearchTerm(searchText);
                                                }}
                                            />
                                        ) : (
                                            <div className="d-flex justify-content-center">
                                                <ReactLoading
                                                    className=""
                                                    type="bubbles"
                                                    color="#DB5A3B"
                                                    height={200}
                                                    width={100}
                                                />
                                            </div>
                                        )}
                                    </div>
                                )}
                            </ToolkitProvider>
                        </Card>
                    </div>
                </Row>
            </Container>
            {/* Edit Modal */}
            <Modal
                className="modal-dialog-centered"
                size="md"
                isOpen={formModal}
                toggle={() => setformModal(false)}
            >
                <div className="modal-body p-0">
                    <Card className="bg-secondary border-0 mb-0">
                        <CardHeader style={{color: "#DB5A3B"}} className="bg-transparent">
                            Update Outlet
                        </CardHeader>
                        <CardBody className="px-lg-5 py-lg-5">
                            <Form role="form">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="exampleFormControlSelect1"
                                    >
                                        Region
                                    </label>
                                    <Input
                                        id="exampleFormControlSelect1"
                                        onChange={(e) => setEditRegion(e.target.value)}
                                        type="select"
                                    >
                                        <option>Select</option>
                                        {region.map((region) => (
                                            <option
                                                selected={
                                                    region._id === singleOutlet?.region ? true : false
                                                }
                                                value={region._id}
                                            >
                                                {region.name}
                                            </option>
                                        ))}
                                    </Input>
                                </FormGroup>
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="exampleFormControlSelect1"
                                    >
                                        Area
                                    </label>
                                    <Input
                                        id="exampleFormControlSelect1"
                                        onChange={(e) => setEditArea(e.target.value)}
                                        type="select"
                                    >
                                        <option>Select</option>
                                        {filteredArea.map((area) => (
                                            <option
                                                selected={
                                                    area._id === singleOutlet?.area ? true : false
                                                }
                                                value={area._id}
                                            >
                                                {area.name}
                                            </option>
                                        ))}
                                    </Input>
                                </FormGroup>
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="exampleFormControlSelect1"
                                    >
                                        Territory
                                    </label>
                                    <Input
                                        id="exampleFormControlSelect1"
                                        onChange={(e) => setEditTerritory(e.target.value)}
                                        type="select"
                                    >
                                        <option>Select</option>
                                        {filteredTerritory.map((territory) => (
                                            <option
                                                selected={
                                                    territory._id === singleOutlet?.territory
                                                        ? true
                                                        : false
                                                }
                                                value={territory._id}
                                            >
                                                {territory.name}
                                            </option>
                                        ))}
                                    </Input>
                                </FormGroup>
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="exampleFormControlSelect1"
                                    >
                                        Sales Point
                                    </label>
                                    <Input
                                        id="exampleFormControlSelect1"
                                        onChange={(e) => setEditSalesPoint(e.target.value)}
                                        type="select"
                                    >
                                        <option>Select</option>
                                        {filteredSalesPoint.map((sp) => (
                                            <option
                                                selected={
                                                    sp._id === singleOutlet?.salesPoint ? true : false
                                                }
                                                value={sp._id}
                                            >
                                                {sp.name}
                                            </option>
                                        ))}
                                    </Input>
                                </FormGroup>
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="exampleFormControlSelect1"
                                    >
                                        Route
                                    </label>
                                    <Input
                                        id="exampleFormControlSelect1"
                                        onChange={(e) => setEditRoute(e.target.value)}
                                        type="select"
                                    >
                                        <option>Select</option>
                                        {filteredRoute.map((route) => (
                                            <option
                                                selected={
                                                    route._id === singleOutlet?.route ? true : false
                                                }
                                                value={route._id}
                                            >
                                                {route.id}
                                            </option>
                                        ))}
                                    </Input>
                                </FormGroup>
                                <FormGroup
                                    className={classnames("mb-3", {
                                        focused: outletName,
                                    })}
                                >
                                    <label
                                        className="form-control-label"
                                        htmlFor="exampleFormControlSelect1"
                                    >
                                        Outlet Name
                                    </label>
                                    <InputGroup className="input-group-merge input-group-alternative">
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="ni ni-email-83"/>
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            placeholder="Outlet Name"
                                            defaultValue={singleOutlet?.name}
                                            type="text"
                                            onChange={(e) => setOutletName(e.target.value)}
                                        />
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup
                                    className={classnames("mb-3", {
                                        focused: outletName,
                                    })}
                                >
                                    <label
                                        className="form-control-label"
                                        htmlFor="exampleFormControlSelect1"
                                    >
                                        Outlet Code
                                    </label>
                                    <InputGroup className="input-group-merge input-group-alternative">
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="ni ni-email-83"/>
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            placeholder="Outlet Code"
                                            defaultValue={singleOutlet?.code}
                                            type="text"
                                            onChange={(e) => setOutletCode(e.target.value)}
                                        />
                                    </InputGroup>
                                </FormGroup>
                                <div className="text-center">
                                    <Button
                                        onClick={() => setformModal(false)}
                                        className="my-4"
                                        color="danger"
                                        type="button"
                                    >
                                        Close
                                    </Button>
                                    <Button
                                        onClick={() => updateOneOutletData(singleOutlet?._id)}
                                        className="my-4"
                                        color="success"
                                        type="button"
                                    >
                                        Update
                                    </Button>
                                </div>
                            </Form>
                        </CardBody>
                    </Card>
                </div>
            </Modal>
            {/* Add Outlet */}
            <Modal
                className="modal-dialog-centered"
                size="md"
                isOpen={addOutletModal}
                toggle={() => setOutletModal(false)}
            >
                <div className="modal-body p-0">
                    <Card className="bg-secondary border-0 mb-0">
                        <CardHeader style={{color: "#DB5A3B"}} className="bg-transparent">
                            Add Outlet
                        </CardHeader>
                        <CardBody className="px-lg-5 py-lg-5">
                            <Form role="form">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="exampleFormControlSelect1"
                                    >
                                        Region
                                    </label>
                                    <Input
                                        id="exampleFormControlSelect1"
                                        onChange={(e) => setNewRegion(e.target.value)}
                                        type="select"
                                    >
                                        <option>Select</option>
                                        {region.map((region) => (
                                            <option value={region._id}>{region.name}</option>
                                        ))}
                                    </Input>
                                </FormGroup>
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="exampleFormControlSelect1"
                                    >
                                        Area
                                    </label>
                                    <Input
                                        id="exampleFormControlSelect1"
                                        onChange={(e) => setNewArea(e.target.value)}
                                        type="select"
                                    >
                                        <option>Select</option>
                                        {newfilteredArea.map((area) => (
                                            <option value={area._id}>{area.name}</option>
                                        ))}
                                    </Input>
                                </FormGroup>
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="exampleFormControlSelect1"
                                    >
                                        Territory
                                    </label>
                                    <Input
                                        id="exampleFormControlSelect1"
                                        onChange={(e) => setNewTerritory(e.target.value)}
                                        type="select"
                                    >
                                        <option>Select</option>
                                        {newfilteredTerritory.map((territory) => (
                                            <option value={territory._id}>{territory.name}</option>
                                        ))}
                                    </Input>
                                </FormGroup>
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="exampleFormControlSelect1"
                                    >
                                        Sales Point
                                    </label>
                                    <Input
                                        id="exampleFormControlSelect1"
                                        onChange={(e) => setNewSalesPoint(e.target.value)}
                                        type="select"
                                    >
                                        <option>Select</option>
                                        {newfilteredSalesPoint.map((sp) => (
                                            <option value={sp._id}>{sp.name}</option>
                                        ))}
                                    </Input>
                                </FormGroup>
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="exampleFormControlSelect1"
                                    >
                                        Route
                                    </label>
                                    <Input
                                        id="exampleFormControlSelect1"
                                        onChange={(e) => setNewRoute(e.target.value)}
                                        type="select"
                                    >
                                        <option>Select</option>
                                        {newfilteredRoute.map((route) => (
                                            <option value={route._id}>{route.id}</option>
                                        ))}
                                    </Input>
                                </FormGroup>
                                <FormGroup
                                    className={classnames("mb-3", {
                                        focused: newOutletName,
                                    })}
                                >
                                    <label
                                        className="form-control-label"
                                        htmlFor="exampleFormControlSelect1"
                                    >
                                        Outlet Name
                                    </label>
                                    <InputGroup className="input-group-merge input-group-alternative">
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="ni ni-email-83"/>
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            placeholder="Outlet Name"
                                            type="text"
                                            onChange={(e) => setNewOutletName(e.target.value)}
                                        />
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup
                                    className={classnames("mb-3", {
                                        focused: newOutletCode,
                                    })}
                                >
                                    <label
                                        className="form-control-label"
                                        htmlFor="exampleFormControlSelect1"
                                    >
                                        Outlet Code
                                    </label>
                                    <InputGroup className="input-group-merge input-group-alternative">
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="ni ni-email-83"/>
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            placeholder="Outlet Code"
                                            type="text"
                                            onChange={(e) => setNewOutletCode(e.target.value)}
                                        />
                                    </InputGroup>
                                </FormGroup>
                                <div className="text-center">
                                    <Button
                                        onClick={() => setOutletModal(false)}
                                        className="my-4"
                                        color="danger"
                                        type="button"
                                    >
                                        Close
                                    </Button>
                                    <Button
                                        onClick={submitNewTms}
                                        className="my-4"
                                        color="success"
                                        type="button"
                                    >
                                        Add
                                    </Button>
                                </div>
                            </Form>
                        </CardBody>
                    </Card>
                </div>
            </Modal>
        </div>
    );
};

export default OutletSetting;
