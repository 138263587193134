import React from 'react';
import SimpleHeader from "../../components/Headers/SimpleHeader";
import {Button, Card, CardBody, Container, Input} from "reactstrap";
import NotificationAlert from "react-notification-alert";
import {addCallingMonth, getCallingMonth} from "../../network/apiChangeCallingMonth";

const CallingMonth = () => {
    //Notification Alert
    const notificationAlertRef = React.useRef(null);
    const notify = (type, message) => {
        let options = {
            place: "bc",
            message: (
                <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
              Route Notification
          </span>
                    <span data-notify="message">{message}</span>
                </div>
            ),
            type: type,
            icon: "ni ni-bell-55",
            autoDismiss: 7,
        };
        notificationAlertRef.current.notificationAlert(options);
    };
    const [month, setMonth] = React.useState(0);
    const [active, setActive] = React.useState(0);

    const getMonth = async () => {
        const response = await getCallingMonth();
        setActive(response?.month + 1);
    };

    React.useEffect(() => {
        getMonth();
    }, []);

    const handleChange = async () => {
        const response = await addCallingMonth(month - 1);
        setMonth(0);
        await notify("success", response?.message);
        await getMonth();
    };


    return (
        <div>
            <div className="rna-wrapper">
                <NotificationAlert ref={notificationAlertRef}/>
            </div>
            <SimpleHeader name="Active" parentName="Call"/>
            <Container fluid className="mt--6">
                <Card>
                    <CardBody>
                        <div className="d-flex">
                            <Input className="w-50" value={month} type="number" placeholder="Type Active Month"
                                   onChange={(e) => setMonth(e.target.value)}/>
                            <Button className="ml-3" onClick={handleChange} color="info">Change</Button>
                        </div>
                        <div className="mt-4">
                            <label className="font-weight-bold text-warning small">
                                Active Month:
                            </label>
                            <Input className="w-25" value={active} type="number" disabled={true}/>
                        </div>
                    </CardBody>
                </Card>
            </Container>
        </div>
    );
};

export default CallingMonth;
