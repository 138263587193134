import React, { useContext, useEffect } from "react";
// react plugin that prints a given react component
// react plugin that prints a given react component
import ReactToPrint from "react-to-print";
// react component for creating dynamic tables
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import ReactLoading from "react-loading";
// reactstrap components11
import {
  Button,
  Card,
  CardHeader,
  Container,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroup,
  Row,
  Col,
  ButtonGroup,
  UncontrolledTooltip,
  CardTitle,
} from "reactstrap";
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import moment from "moment";

import axios from "axios";
import NotificationAlert from "react-notification-alert";
import DataContext from "context/DataContext";
import baseApi1 from "../api/BaseApi1";
import {baseURL1} from"../api/baseURL.json";

const { SearchBar } = Search;
const SalesData = () => {
  const { region, area, territory, salesPoint, outlet } =
    useContext(DataContext);
  const [showPerPage, setShowPerPage] = React.useState(10);
  const [page, setPage] = React.useState(1);
  const [searchTerm, setSearchTerm] = React.useState("");
  const [totalSize, setTotalSize] = React.useState(10);
  const userInfo = JSON.parse(localStorage.getItem("user"));
  //Filters
  const [filterStartDate, setFilterStartDate] = React.useState("");
  const [filterEndDate, setFilterEndDate] = React.useState("");
  const [filterRegion, setFilterRegion] = React.useState("");
  const [filterArea, setFilterArea] = React.useState("");
  const [filterTerritoryName, setFilterTerritoryName] = React.useState("");
  const [filterTerritoryId, setFilterTerritoryId] = React.useState("");
  const [filterSalesPointName, setFilterSalesPointName] = React.useState("");
  const [filterSalesPointId, setFilterSalesPointId] = React.useState("");
  const [filterOutletCode, setFilterOutletCode] = React.useState("");
  const [totalSales, setTotalSales] = React.useState([]);
  //Notification Alert
  const notificationAlertRef = React.useRef(null);
  const notify = (type, message) => {
    let options = {
      place: "bc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            Sales Notification
          </span>
          <span data-notify="message">{message}</span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 7,
    };
    notificationAlertRef.current.notificationAlert(options);
  };

  const newfilteredArea = area.filter((item) => item.region === filterRegion);
  const newfilteredTerritory = territory.filter(
    (item) => item.area === filterArea
  );
  const newFilterSalesPoint = salesPoint.filter(
    (x) =>
      x.territory._id ===
      (filterTerritoryId === "" ? userInfo?.territory?._id : filterTerritoryId)
  );
  const newFilterOutlet = outlet?.filter(
    (x) => x?.salesPointId === filterSalesPointId
  );

  const componentRef = React.useRef(null);
  const getSalesData = async () => {
    try {
      const response = await baseApi1.get(
        `/api/sales/all?startDate=${filterStartDate}&endDate=${filterEndDate}&page=${page}&limit=${showPerPage}&region=${
          userInfo.region === undefined ? filterRegion : userInfo.region._id
        }&area=${
          userInfo.area === undefined ? filterArea : userInfo.area._id
        }&territory=${
          userInfo.territory === undefined
            ? filterTerritoryName
            : userInfo.territory._id
        }&salesPoint=${filterSalesPointName}&outletCode=${filterOutletCode}&search=${searchTerm}`
      );
      if (!response.statusText === "OK") {
        console.log("Not Getting Response");
      } else {
        setTotalSales(response.data.data);
        setTotalSize(response.data.total);
      }
    } catch (error) {
      console.log(error.response);
    }
  };

  const pagination = paginationFactory({
    page,
    totalSize,
    showTotal: true,
    sizePerPage: showPerPage,
    alwaysShowAllBtns: true,
    withFirstAndLast: true,
    onPageChange: (page) => {
      setPage(page);
    },
    onSizePerPageChange: (_, size) => {
      setShowPerPage(size);
    },
    sizePerPageRenderer: ({
      options,
      currSizePerPage,
      onSizePerPageChange,
    }) => (
      <div className="dataTables_length" id="datatable-basic_length">
        <label>
          Show{" "}
          {
            <select
              value={currSizePerPage}
              name="datatable-basic_length"
              aria-controls="datatable-basic"
              className="form-control form-control-sm"
              onChange={(e) => onSizePerPageChange(e.target.value)}
            >
              <option value={10}>10</option>
              <option value={25}>25</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
              <option value="all">all</option>
            </select>
          }{" "}
          entries.
        </label>
      </div>
    ),
  });
  useEffect(() => {
    if (filterStartDate !== "" && filterEndDate !== "") {
      getSalesData();
    }
  }, [page, showPerPage, searchTerm]);
  const handleViewData = () => {
    getSalesData();
  };
  return (
    <div>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <SimpleHeader name="Sales Database" parentName="Sales" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="d-flex justify-content-between">
                <h3 style={{ color: "#DB5A3B" }}>Filters</h3>
              </CardHeader>
              <CardBody className="px-lg-5 py-lg-5">
                <Form role="form">
                  <Row className="row-example">
                    <Col xs={4} sm={4}>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="example-date-input"
                          md="2"
                        >
                          Start Date
                        </label>
                        <Input
                          id="example-date-input"
                          defaultValue={filterStartDate}
                          type="date"
                          onChange={(e) =>
                            setFilterStartDate(
                              moment(new Date(e.target.value)).format(
                                "yyyy-MM-DD"
                              )
                            )
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={4} sm={4}>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="example-date-input"
                          md="2"
                        >
                          End Date
                        </label>

                        <Input
                          id="example-date-input"
                          type="date"
                          onChange={(e) =>
                            setFilterEndDate(
                              moment(new Date(e.target.value)).format(
                                "yyyy-MM-DD"
                              )
                            )
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={4} sm={4}>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="exampleFormControlSelect1"
                        >
                          Region
                        </label>
                        {userInfo.region === undefined ? (
                          <Input
                            id="exampleFormControlSelect1"
                            onChange={(e) => setFilterRegion(e.target.value)}
                            type="select"
                          >
                            <option>Select</option>
                            {region.map((region) => (
                              <option value={region.name}>{region.name}</option>
                            ))}
                          </Input>
                        ) : (
                          <InputGroup className="input-group-merge input-group-alternative">
                            <Input
                              type="name"
                              value={userInfo.region.name}
                              disabled
                            />
                          </InputGroup>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="row-example">
                    <Col xs={3} sm={3}>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="exampleFormControlSelect1"
                        >
                          Area
                        </label>
                        {userInfo.area === undefined ? (
                          <Input
                            id="exampleFormControlSelect1"
                            onChange={(e) => setFilterArea(e.target.value)}
                            type="select"
                          >
                            <option>Select</option>
                            {newfilteredArea.map((area) => (
                              <option value={area.name}>{area.name}</option>
                            ))}
                          </Input>
                        ) : (
                          <InputGroup className="input-group-merge input-group-alternative">
                            <Input
                              type="name"
                              value={userInfo.area.name}
                              disabled
                            />
                          </InputGroup>
                        )}
                      </FormGroup>
                    </Col>
                    <Col xs={3} sm={3}>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="exampleFormControlSelect1"
                        >
                          Territory
                        </label>
                        {userInfo.territory === undefined ? (
                          <Input
                            id="exampleFormControlSelect1"
                            onChange={(e) => {
                              const value = JSON.parse(e.target.value);
                              setFilterTerritoryName(value?.name);
                              setFilterTerritoryId(value?.id);
                            }}
                            type="select"
                          >
                            <option>Select</option>
                            {newfilteredTerritory.map((territory) => (
                              <option
                                value={JSON.stringify({
                                  name: territory.name,
                                  id: territory._id,
                                })}
                              >
                                {territory.name}
                              </option>
                            ))}
                          </Input>
                        ) : (
                          <InputGroup className="input-group-merge input-group-alternative">
                            <Input
                              type="name"
                              value={userInfo.territory.name}
                              disabled
                            />
                          </InputGroup>
                        )}
                      </FormGroup>
                    </Col>
                    <Col xs={3} sm={3}>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="exampleFormControlSelect1"
                        >
                          Sales Point
                        </label>
                        <Input
                          id="exampleFormControlSelect1"
                          onChange={(e) => {
                            const value = JSON.parse(e.target.value);
                            setFilterSalesPointName(value?.name);
                            setFilterSalesPointId(value?.id);
                          }}
                          type="select"
                        >
                          <option>Select</option>
                          {newFilterSalesPoint.map((salesPoint) => (
                            <option
                              value={JSON.stringify({
                                name: salesPoint.name,
                                id: salesPoint._id,
                              })}
                            >
                              {salesPoint.name}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col xs={3} sm={3}>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="exampleFormControlSelect1"
                        >
                          Outlet
                        </label>
                        <Input
                          id="exampleFormControlSelect1"
                          onChange={(e) => setFilterOutletCode(e.target.value)}
                          type="select"
                        >
                          <option>Select</option>
                          {newFilterOutlet.map((outlet) => (
                            <option value={outlet.code}>{outlet.code}</option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>
                  <div className="text-right">
                    <Button
                      onClick={handleViewData}
                      color="warning"
                      type="button"
                    >
                      View
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </div>
        </Row>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="d-flex justify-content-between">
                <h3 style={{ color: "#DB5A3B" }}>Sales Status</h3>
              </CardHeader>
              <ToolkitProvider
                data={totalSales}
                keyField="outletCode"
                columns={[
                  {
                    dataField: "#",
                    text: "SL",
                    formatter: (cell, row, rowIndex) => {
                      return rowIndex + 1;
                    },
                  },
                  {
                    dataField: "date",
                    text: "Date",
                    sort: true,
                    formatter: (cell) => {
                      return moment(new Date(cell)).format(
                        "MMMM Do YYYY, h:mm:ss a"
                      );
                    },
                  },
                  {
                    dataField: "region",
                    text: "Region",
                    sort: true,
                  },
                  {
                    dataField: "area",
                    text: "Area",
                    sort: true,
                  },
                  {
                    dataField: "territory",
                    text: "Territory",
                    sort: true,
                  },
                  {
                    dataField: "salesPoint",
                    text: "Sales Point",
                    sort: true,
                  },
                  {
                    dataField: "route",
                    text: "Route",
                    sort: true,
                  },
                  {
                    dataField: "routeID",
                    text: "Route ID",
                    sort: true,
                  },
                  {
                    dataField: "outletCode",
                    text: "Outlet Code",
                    sort: true,
                  },
                  {
                    dataField: "outletName",
                    text: "Outlet Name",
                    sort: true,
                  },
                  {
                    dataField: "channel",
                    text: "Channel",
                    sort: true,
                  },
                  {
                    dataField: "brand",
                    text: "Brand",
                    sort: true,
                  },
                  {
                    dataField: "quantity",
                    text: "Quantity",
                    sort: true,
                  },
                ]}
                search
              >
                {(props) => (
                  <div className="py-4 table-responsive">
                    <Container fluid>
                      <Row>
                        <Col xs={4} sm={4}>
                          <div>
                            <select
                              name="datatable-basic_length"
                              aria-controls="datatable-basic"
                              className="form-control form-control-sm w-25"
                              onChange={(e) => setShowPerPage(e.target.value)}
                            >
                              <option
                                selected={showPerPage === 10 && true}
                                value={10}
                              >
                                10
                              </option>
                              <option
                                selected={showPerPage === 25 && true}
                                value={25}
                              >
                                25
                              </option>
                              <option
                                selected={showPerPage === 50 && true}
                                value={50}
                              >
                                50
                              </option>
                              <option
                                selected={showPerPage === 100 && true}
                                value={100}
                              >
                                100
                              </option>
                              <option
                                selected={showPerPage === "all" && true}
                                value="all"
                              >
                                all
                              </option>
                            </select>
                          </div>
                        </Col>
                        <Col xs={4} sm={4}>
                          <ButtonGroup>
                            <ReactToPrint
                              trigger={() => (
                                <Button
                                  color="warning"
                                  size="sm"
                                  className="buttons-copy buttons-html5"
                                  id="print-tooltip"
                                >
                                  Print
                                </Button>
                              )}
                              content={() => componentRef.current}
                            />
                          </ButtonGroup>
                          <UncontrolledTooltip
                            placement="top"
                            target="print-tooltip"
                          >
                            This will open a print page with the visible rows of
                            the table.
                          </UncontrolledTooltip>
                        </Col>
                        <Col xs={4} sm={4}>
                          <div
                            id="datatable-basic_filter"
                            className="dataTables_filter px-4 pb-1 float-right"
                          >
                            <label>
                              Search:
                              <SearchBar
                                className="form-control-sm"
                                placeholder=""
                                {...props.searchProps}
                              />
                            </label>
                          </div>
                        </Col>
                      </Row>
                    </Container>
                    <BootstrapTable
                      remote
                      bootstrap4
                      bordered={false}
                      ref={componentRef}
                      id="react-bs-table"
                      {...props.baseProps}
                      pagination={pagination}
                      onTableChange={(type, { searchText }) => {
                        setSearchTerm(searchText);
                      }}
                    />
                  </div>
                )}
              </ToolkitProvider>
            </Card>
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default SalesData;
