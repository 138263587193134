import React from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Form
} from "reactstrap";
import classnames from "classnames";
import { useFileUpload } from "use-file-upload";
import axios from "axios";
import baseApi2 from "../../api/BaseApi2";
import {baseURL2} from "../../api/baseURL.json";

const AddPhotoFrame = () => {
  const [newFramePhoto, setNewFramePhoto] = useFileUpload();
  const defaultSrc = "https://i.ibb.co/sv6vLrj/paper.png";

  const submitNewFrame = async () => {
    const newFrame = new FormData();
    newFrame.append("region", "Dhaka");
    newFrame.append("regionId", "1");
    newFrame.append("area", "Dhanmondi");
    newFrame.append("areaId", "2");
    newFrame.append("territory", "Mohammadpur");
    newFrame.append("territoryId", "1");
    newFrame.append("salesPoint", "Asad Gate");
    newFrame.append("salesPointId", "4");
    newFrame.append("tmsName", "Rian");
    newFrame.append("tmsEnroll", "6736363");
    newFrame.append("tmsMobile", "01632321050");
    newFrame.append("outletCode", "1632321052");
    newFrame.append("outletName", "Rian Store4");
    newFrame.append("photo", newFramePhoto?.file);
    try {
      const response = await axios.post(
        `${baseURL2}:5000/api/addPhotoFrame`,
        newFrame,
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      if (response.statusText !== "OK") {
        console.log("Something Went Wrong");
      } else {
        console.log(response?.data);
      }
    } catch (e) {
      console.log(e.response);
    }
  };
  return (
    <div>
      <div className="modal-body p-0">
        <Card className="bg-secondary border-0 mb-0">
          <CardHeader style={{ color: "#DB5A3B" }} className="bg-transparent">
            Add Photo Frame
          </CardHeader>
          <CardBody className="px-lg-5 py-lg-5">
            <Form role="form">
              <label
                className="form-control-label"
                htmlFor="exampleFormControlSelect1"
              >
                Frame Photo
              </label>
              <div className="text-center">
                <img
                  src={newFramePhoto?.source || defaultSrc}
                  alt="preview"
                  className="fluid w-25 rounded mb-3"
                />
                <br />
                <Button
                  color="primary"
                  outline
                  type="button"
                  onClick={() => setNewFramePhoto({ accept: "image/*" })}
                >
                  Upload Frame Photo
                </Button>
              </div>
              <div className="text-right">
                <Button
                  onClick={() => submitNewFrame()}
                  className="my-4"
                  color="success"
                  type="button"
                >
                  Add
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default AddPhotoFrame;
