import React from "react";

const TopUp = () => {
  return (
    <div>
      <h4>Top Up</h4>
    </div>
  );
};

export default TopUp;
