import React, { useContext, useEffect } from "react";

// reactstrap components11
import {
  Button,
  Card,
  CardHeader,
  Container,
  CardBody,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  TabPane,
  TabContent,
  Nav,
  NavItem,
  NavLink,
  InputGroup,
} from "reactstrap";
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";

import axios from "axios";
import DataContext from "context/DataContext";
import classnames from "classnames";
import moment from "moment";
import baseApi1 from "../../api/BaseApi1";
import {baseURL1} from "../../api/baseURL.json";

const DetailsReport = () => {
  const { region, area, territory, salesPoint, tms, outlet } =
    useContext(DataContext);
  const userInfo = JSON.parse(localStorage.getItem("user"));
  //Selected data for Details Report
  const [startDate, setStartDate] = React.useState("");
  const [endDate, setEndDate] = React.useState("");
  const [newRegion, setNewRegion] = React.useState("");
  const [newArea, setNewArea] = React.useState("");
  const [newTerrtory, setNewTerritory] = React.useState("");
  const [newSalesPoint, setNewSalesPoint] = React.useState("");
  const [newTMS, setNewTMS] = React.useState("");
  const [newOutlet, setNewOutlet] = React.useState("");
  const [filteredOutlets, setFilteredOutlets] = React.useState([]);

  //Report Tabs
  const [tabs, setTabs] = React.useState(1);

  //Filter Dropdown Datas
  const filteredArea = area.filter((item) => item.regionId === newRegion);
  const filteredTerritory = territory.filter((item) => item.areaId === newArea);
  const filteredSalesPoint = salesPoint?.filter(
    (item) =>
      item?.territory?._id ===
      (newTerrtory === "" ? userInfo?.territory?._id : newTerrtory)
  );
  const filteredTms = tms.filter(
    (item) =>
      item.territoryId ===
      (newTerrtory === "" ? userInfo?.territory?._id : newTerrtory)
  );

  const getTmsWiseOutlet = async () => {
    try {
      const response = await baseApi1.get(
        `/api/tms_outlets/${newTMS}`
      );
      if (!response.statusText === "OK") {
        console.log("Not Getting Response");
      } else {
        newTMS !== "" && setFilteredOutlets(response?.data);
      }
    } catch (error) {
      console.log(error.response);
    }
  };

  useEffect(() => {
    getTmsWiseOutlet();
  }, [newTMS]);

  return (
    <div>
      <SimpleHeader name="Details Report" parentName="Report List" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="d-flex justify-content-between">
                <h3 style={{ color: "#DB5A3B" }}>Details Report</h3>
              </CardHeader>
              <CardBody className="px-lg-5 py-lg-5">
                <Form role="form">
                  <Row className="row-example">
                    <Col>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="example-date-input"
                          md="2"
                        >
                          Start Date
                        </label>
                        <Input
                          id="example-date-input"
                          type="date"
                          onChange={(e) =>
                            setStartDate(
                              moment(new Date(e.target.value)).format(
                                "yyyy-MM-DD"
                              )
                            )
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="example-date-input"
                          md="2"
                        >
                          End Date
                        </label>

                        <Input
                          id="example-date-input"
                          type="date"
                          onChange={(e) =>
                            setEndDate(
                              moment(new Date(e.target.value)).format(
                                "yyyy-MM-DD"
                              )
                            )
                          }
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={4} sm={4}>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="exampleFormControlSelect1"
                        >
                          Region
                        </label>
                        {userInfo.region === undefined ? (
                          <Input
                            id="exampleFormControlSelect1"
                            onChange={(e) => setNewRegion(e.target.value)}
                            type="select"
                          >
                            <option>Select</option>
                            {region.map((region) => (
                              <option value={region._id}>{region.name}</option>
                            ))}
                          </Input>
                        ) : (
                          <InputGroup className="input-group-merge input-group-alternative">
                            <Input
                              type="name"
                              value={userInfo.region.name}
                              disabled
                            />
                          </InputGroup>
                        )}
                      </FormGroup>
                    </Col>
                    <Col xs={4} sm={4}>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="exampleFormControlSelect1"
                        >
                          Area
                        </label>
                        {userInfo.area === undefined ? (
                          <Input
                            id="exampleFormControlSelect1"
                            onChange={(e) => setNewArea(e.target.value)}
                            type="select"
                          >
                            <option>Select</option>
                            {filteredArea.map((area) => (
                              <option value={area._id}>{area.name}</option>
                            ))}
                          </Input>
                        ) : (
                          <InputGroup className="input-group-merge input-group-alternative">
                            <Input
                              type="name"
                              value={userInfo.area.name}
                              disabled
                            />
                          </InputGroup>
                        )}
                      </FormGroup>
                    </Col>
                    <Col xs={4} sm={4}>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="exampleFormControlSelect1"
                        >
                          Territory
                        </label>
                        {userInfo.territory === undefined ? (
                          <Input
                            id="exampleFormControlSelect1"
                            onChange={(e) => setNewTerritory(e.target.value)}
                            type="select"
                          >
                            <option>Select</option>
                            {filteredTerritory.map((territory) => (
                              <option value={territory._id}>
                                {territory.name}
                              </option>
                            ))}
                          </Input>
                        ) : (
                          <InputGroup className="input-group-merge input-group-alternative">
                            <Input
                              type="name"
                              value={userInfo.territory.name}
                              disabled
                            />
                          </InputGroup>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={4} sm={4}>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="exampleFormControlSelect1"
                        >
                          Sales Point
                        </label>
                        <Input
                          id="exampleFormControlSelect1"
                          onChange={(e) => setNewSalesPoint(e.target.value)}
                          type="select"
                        >
                          <option>Select</option>
                          {filteredSalesPoint.map((sp) => (
                            <option value={sp._id}>{sp.name}</option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col xs={4} sm={4}>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="exampleFormControlSelect1"
                        >
                          TMS
                        </label>
                        <Input
                          id="exampleFormControlSelect1"
                          onChange={(e) => setNewTMS(e.target.value)}
                          type="select"
                        >
                          <option>Select</option>
                          {filteredTms.map((tms) => (
                            <option value={tms._id}>{tms.name}</option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col xs={4} sm={4}>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="exampleFormControlSelect1"
                        >
                          Outlets
                        </label>
                        <Input
                          id="exampleFormControlSelect1"
                          onChange={(e) => setNewOutlet(e.target.value)}
                          type="select"
                        >
                          <option>Select</option>
                          {filteredOutlets.length > 0 &&
                            filteredOutlets?.map((outlet) => (
                              <option
                                value={outlet?._id}
                              >{`${outlet?.name} (${outlet?.code})`}</option>
                            ))}
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
      {/* //Download Options */}
      <Container>
        <Card>
          <Container>
            <div className="nav-wrapper">
              <Nav
                className="nav-fill flex-column flex-md-row"
                id="tabs-icons-text"
                pills
                role="tablist"
              >
                <NavItem>
                  <NavLink
                    aria-selected={tabs === 1}
                    className={classnames("mb-sm-3 mb-md-0", {
                      active: tabs === 1,
                    })}
                    onClick={() => setTabs(1)}
                    role="tab"
                    style={{ cursor: "pointer" }}
                  >
                    <i className="ni ni-cloud-download-95 mr-2" />
                    Sales Report
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    aria-selected={tabs === 2}
                    className={classnames("mb-sm-3 mb-md-0", {
                      active: tabs === 2,
                    })}
                    onClick={() => setTabs(2)}
                    role="tab"
                    style={{ cursor: "pointer" }}
                  >
                    <i className="ni ni-cloud-download-95 mr-2" />
                    Portfolio Report
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    aria-selected={tabs === 3}
                    className={classnames("mb-sm-3 mb-md-0", {
                      active: tabs === 3,
                    })}
                    onClick={() => setTabs(3)}
                    role="tab"
                    style={{ cursor: "pointer" }}
                  >
                    <i className="ni ni-cloud-download-95 mr-2" />
                    EAS Report
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    aria-selected={tabs === 4}
                    className={classnames("mb-sm-3 mb-md-0", {
                      active: tabs === 4,
                    })}
                    onClick={() => setTabs(4)}
                    role="tab"
                    style={{ cursor: "pointer" }}
                  >
                    <i className="ni ni-cloud-download-95 mr-2" />
                    Focus Report
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    aria-selected={tabs === 5}
                    className={classnames("mb-sm-3 mb-md-0", {
                      active: tabs === 5,
                    })}
                    onClick={() => setTabs(5)}
                    role="tab"
                    style={{ cursor: "pointer" }}
                  >
                    <i className="ni ni-cloud-download-95 mr-2" />
                    Call Card Report
                  </NavLink>
                </NavItem>
              </Nav>
            </div>
          </Container>
          <Container>
            <Card className="shadow">
              <CardBody>
                <TabContent activeTab={"tabs" + tabs}>
                  <TabPane tabId="tabs1">
                    <Button
                      onClick={() =>
                        window.open(
                          `${baseURL1}/report/sales?startDate=${startDate}&endDate=${endDate}&region=${
                            userInfo.region === undefined
                              ? newRegion
                              : userInfo.region._id
                          }&area=${
                            userInfo.area === undefined
                              ? newArea
                              : userInfo.area._id
                          }&territory=${
                            userInfo.territory === undefined
                              ? newTerrtory
                              : userInfo.territory._id
                          }&salesPoint=${newSalesPoint}&tms=${newTMS}&outlet=${newOutlet}`
                        )
                      }
                      color="primary"
                      outline
                      type="button"
                    >
                      Download Sales Report
                    </Button>
                  </TabPane>
                  <TabPane tabId="tabs2">
                    <Button
                      onClick={() =>
                        window.open(
                          `${baseURL1}/report/portfolio?startDate=${startDate}&endDate=${endDate}&region=${
                            userInfo.region === undefined
                              ? newRegion
                              : userInfo.region._id
                          }&area=${
                            userInfo.area === undefined
                              ? newArea
                              : userInfo.area._id
                          }&territory=${
                            userInfo.territory === undefined
                              ? newTerrtory
                              : userInfo.territory._id
                          }&salesPoint=${newSalesPoint}&tms=${newTMS}&outlet=${newOutlet}`
                        )
                      }
                      color="primary"
                      outline
                      type="button"
                    >
                      Download Portfolio Report
                    </Button>
                  </TabPane>
                  <TabPane tabId="tabs3">
                    <Button
                      onClick={() =>
                        window.open(
                          `${baseURL1}/report/eas?startDate=${startDate}&endDate=${endDate}&region=${
                            userInfo.region === undefined
                              ? newRegion
                              : userInfo.region._id
                          }&area=${
                            userInfo.area === undefined
                              ? newArea
                              : userInfo.area._id
                          }&territory=${
                            userInfo.territory === undefined
                              ? newTerrtory
                              : userInfo.territory._id
                          }&salesPoint=${newSalesPoint}&tms=${newTMS}&outlet=${newOutlet}`
                        )
                      }
                      color="primary"
                      outline
                      type="button"
                    >
                      Download EAS Report
                    </Button>
                  </TabPane>
                  <TabPane tabId="tabs4">
                    <Button
                      onClick={() =>
                        window.open(
                          `${baseURL1}/report/focus?startDate=${startDate}&endDate=${endDate}&region=${
                            userInfo.region === undefined
                              ? newRegion
                              : userInfo.region._id
                          }&area=${
                            userInfo.area === undefined
                              ? newArea
                              : userInfo.area._id
                          }&territory=${
                            userInfo.territory === undefined
                              ? newTerrtory
                              : userInfo.territory._id
                          }&salesPoint=${newSalesPoint}&tms=${newTMS}&outlet=${newOutlet}`
                        )
                      }
                      color="primary"
                      outline
                      type="button"
                    >
                      Download Focus Report
                    </Button>
                  </TabPane>
                  <TabPane tabId="tabs5">
                    <Button
                      onClick={() =>
                        window.open(
                          `${baseURL1}/report/callCard?startDate=${startDate}&endDate=${endDate}&region=${
                            userInfo.region === undefined
                              ? newRegion
                              : userInfo.region._id
                          }&area=${
                            userInfo.area === undefined
                              ? newArea
                              : userInfo.area._id
                          }&territory=${
                            userInfo.territory === undefined
                              ? newTerrtory
                              : userInfo.territory._id
                          }&salesPoint=${newSalesPoint}&tms=${newTMS}&outlet=${newOutlet}`
                        )
                      }
                      color="primary"
                      outline
                      type="button"
                    >
                      Download Call Card
                    </Button>
                  </TabPane>
                </TabContent>
              </CardBody>
            </Card>
          </Container>
        </Card>
      </Container>
    </div>
  );
};

export default DetailsReport;
