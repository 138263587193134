import React from "react";
import "./ImportSales.css";

// reactstrap components11
import {
  Button,
  Row,
  Container,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Input,
} from "reactstrap";
import CSVReader from "react-csv-reader";
import baseApi1 from "../api/BaseApi1";
import {baseURL1} from "../api/baseURL.json";

import SimpleHeader from "components/Headers/SimpleHeader.js";
import axios from "axios";
import NotificationAlert from "react-notification-alert";
import moment from "moment";

const ImportSales = () => {
  const [salesDate, setSalesDate] = React.useState("");
  const [file, setFile] = React.useState(null);
  const { jwt_token } = JSON.parse(localStorage.getItem("user"));

  //Notification Alert
  const notificationAlertRef = React.useRef(null);
  const notify = (type, message) => {
    let options = {
      place: "bc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            Sales Import Notification
          </span>
          <span data-notify="message">{message}</span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 7,
    };
    notificationAlertRef.current.notificationAlert(options);
  };

  const addSalesData = async () => {
    const newSales = new FormData();
    newSales.append("date", salesDate);
    newSales.append("file", file);
    try {
      const response = await baseApi1.post(
        "/api/sales/import",
        newSales,
        {
          headers: {
            Authorization: `Bearer ${jwt_token}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (!response.statusText === "OK") {
        notify("danger", "Some Thing Went Wrong");
      } else {
        console.log(response?.data);
        setSalesDate("");
        setFile(null);
        notify("success", response?.data?.message);
      }
    } catch (error) {
      console.log(error.response);
    }
  };

  const handleSalesSubmit = () => {
    addSalesData();
  };

  return (
    <div>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <SimpleHeader name="Import Sales Data" parentName="Import Sales" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="d-flex justify-content-between">
                <h3 style={{ color: "#DB5A3B" }}>Import Sales</h3>
              </CardHeader>
              <CardBody className="m-4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example-date-input"
                    md="2"
                    style={{ color: "#DB5A3B" }}
                  >
                    Sales Date
                  </label>
                  <Input
                    id="example-date-input"
                    defaultValue={salesDate}
                    type="date"
                    onChange={(e) =>
                      setSalesDate(
                        moment(new Date(e.target.value)).format("yyyy-MM-DD")
                      )
                    }
                  />
                </FormGroup>

                <label
                  className="form-control-label"
                  htmlFor="example-date-input"
                  md="2"
                  style={{ color: "#DB5A3B" }}
                >
                  Select File
                </label>
                <div className="csv">
                  <Input
                    id="example-date-input"
                    type="file"
                    onChange={(e) => setFile(e.target.files[0])}
                  />
                </div>
                <div className="text-right">
                  <Button
                    onClick={handleSalesSubmit}
                    className="my-4"
                    color="success"
                    type="button"
                  >
                    Upload
                  </Button>
                </div>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default ImportSales;
