import React, {useContext, useEffect} from "react";
import NotificationAlert from "react-notification-alert";
import SimpleHeader from "../../components/Headers/SimpleHeader";
import {
    Button,
    ButtonGroup,
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Col,
    Container,
    Form,
    FormGroup,
    Input,
    InputGroup,
    Row,
    UncontrolledTooltip,
} from "reactstrap";
import Select from "react-select";
import axios from "axios";
import DataContext from "../../context/DataContext";
import baseApi1 from "../../api/BaseApi1";
import baseApi2 from "../../api/BaseApi2";
import {baseURL2} from "../../api/baseURL.json";

const SelectIncentive = () => {
    const {region, area, territory, salesPoint, tms} = useContext(DataContext);
    const userInfo = JSON.parse(localStorage.getItem("user"));

    const [newRegionId, setNewRegionId] = React.useState("");
    const [newAreaId, setNewAreaId] = React.useState("");
    const [newTerrtoryId, setNewTerritoryId] = React.useState("");
    const [newSalesPointId, setNewSalesPointId] = React.useState("");
    const [newTMSId, setNewTMSId] = React.useState("");
    const [newOutletId, setNewOutletId] = React.useState("");

    const [newRegion, setNewRegion] = React.useState("");
    const [newArea, setNewArea] = React.useState("");
    const [newTerrtory, setNewTerritory] = React.useState("");
    const [newSalesPoint, setNewSalesPoint] = React.useState("");
    const [newTMS, setNewTMS] = React.useState("");
    const [newOutlet, setNewOutlet] = React.useState("");

    const [filteredOutlets, setFilteredOutlets] = React.useState([]);

    const [selectTypeOne, setSelectTypeOne] = React.useState([]);
    const [selectTypeTwo, setSelectTypeTwo] = React.useState([]);
    const [type1, setType1] = React.useState([]);
    const [type2, setType2] = React.useState([]);

    //Filter Dropdown Data
    const filteredArea = area.filter((item) => item.regionId === newRegionId);
    const filteredTerritory = territory.filter(
        (item) => item.areaId === newAreaId
    );
    const filteredSalesPoint = salesPoint.filter(
        (item) =>
            item.territory._id ===
            (newTerrtoryId === "" ? userInfo?.territory?._id : newTerrtoryId)
    );
    const filteredTms = tms.filter(
        (item) =>
            item.territoryId ===
            (newTerrtoryId === "" ? userInfo?.territory?._id : newTerrtoryId)
    );

    const getTmsWiseOutlet = async () => {
        try {
            const response = await baseApi1.get(
                `/api/tms_outlets/${newTMSId}`
            );
            if (!response.statusText === "OK") {
                console.log("Not Getting Response");
            } else {
                newTMSId !== "" && setFilteredOutlets(response?.data);
            }
        } catch (error) {
            console.log(error.response);
        }
    };

    useEffect(() => {
        getTmsWiseOutlet();
    }, [newTMSId]);

    //Notification Alert
    const notificationAlertRef = React.useRef(null);
    const notify = (type, message) => {
        let options = {
            place: "bc",
            message: (
                <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
              ! Incentive
          </span>
                    <span data-notify="message">{message}</span>
                </div>
            ),
            type: type,
            icon: "ni ni-bell-55",
            autoDismiss: 7,
        };
        notificationAlertRef.current.notificationAlert(options);
    };

    const getType1 = async () => {
        try {
            const response = await baseApi2.get(":5000/api/getTypeOne");
            if (response.statusText !== "OK") {
                notify("danger", "Something Went Wrong");
            } else {
                setType1(
                    response?.data?.data.map((x) => {
                        return {
                            value: x.name,
                            label: (
                                <div className="text-center">
                                    <img
                                        src={`${baseURL2}:5000/incentiveMedia/${x.photo}`}
                                        alt="test"
                                        height="80px"
                                        width="80px"
                                    />{" "}
                                    <span className="font-weight-bold">{x.name}</span>
                                </div>
                            ),
                        };
                    })
                );
            }
        } catch (e) {
            console.log(e.response);
        }
    };

    const getType2 = async () => {
        try {
            const response = await baseApi2.get(":5000/api/getTypeTwo");
            if (response.statusText !== "OK") {
                notify("danger", "Something Went Wrong");
            } else {
                setType2(
                    response?.data?.data.map((x) => {
                        return {
                            value: x.name,
                            label: (
                                <div className="text-center">
                                    <img
                                        src={`${baseURL2}:5000/incentiveMedia/${x.photo}`}
                                        alt="test"
                                        height="80px"
                                        width="80px"
                                    />{" "}
                                    <span className="font-weight-bold">{x.name}</span>
                                </div>
                            ),
                        };
                    })
                );
            }
        } catch (e) {
            console.log(e.response);
        }
    };

    React.useLayoutEffect(() => {
        getType1();
        getType2();
    }, []);

    const submitRequest = async () => {
        const payload = {
            region: newRegion,
            regionId: newRegionId,
            area: newArea,
            areaId: newAreaId,
            territory: newTerrtory,
            territoryId: newTerrtoryId,
            salesPoint: newSalesPoint,
            salesPointId: newSalesPointId,
            tmsName: newTMS,
            tmsEnroll: newTMSId,
            tmsMobile: newTMSId,
            outletCode: newOutlet,
            outletName: newOutlet,
            incentiveOne: selectTypeOne?.value,
            incentiveTwo: selectTypeTwo?.value,
        };

        try {
            const response = await baseApi2.post(
                ":5000/api/selectNewIncentive",
                payload,
                {headers: {"Content-Type": "application/json"}}
            );
            if (response.statusText !== "OK") {
                notify("danger", "Something Went Wrong");
            } else {
                notify("success", response?.data?.message);
                setNewRegionId("");
                setNewAreaId("");
                setNewTerritoryId("");
                setNewSalesPointId("");
                setNewTMSId("");
                setNewOutletId("");
                setNewRegion("");
                setNewArea("");
                setNewTerritory("");
                setNewSalesPoint("");
                setNewTMS("");
                setNewOutlet("");
                setFilteredOutlets([]);
                setSelectTypeOne([]);
                setSelectTypeTwo([]);
            }
        } catch (e) {
            notify("danger", e.response.data.data.message);
            notify("danger", e.response.data.data.keyValue.outletCode);
        }
    };

    return (
        <div>
            <div className="rna-wrapper">
                <NotificationAlert ref={notificationAlertRef}/>
            </div>
            <SimpleHeader name="Select Incentive" parentName="Incentive"/>
            <Container className="mt--6" fluid>
                <Row>
                    <div className="col">
                        <Card>
                            <CardHeader className="d-flex justify-content-between">
                                <h3 style={{color: "#DB5A3B"}}>Filter</h3>
                            </CardHeader>
                            <CardBody className="px-lg-5 py-lg-5">
                                <Form role="form">
                                    <Row>
                                        <Col xs={4} sm={4}>
                                            <FormGroup>
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="exampleFormControlSelect1"
                                                >
                                                    Region
                                                </label>
                                                {userInfo.region === undefined ? (
                                                    <Input
                                                        id="exampleFormControlSelect1"
                                                        onChange={(e) => {
                                                            const value = JSON.parse(e.target.value);
                                                            setNewRegion(value?.name);
                                                            setNewRegionId(value?.id);
                                                        }}
                                                        type="select"
                                                    >
                                                        <option>Select</option>
                                                        {region.map((region) => (
                                                            <option
                                                                value={JSON.stringify({
                                                                    name: region.name,
                                                                    id: region._id,
                                                                })}
                                                            >
                                                                {region.name}
                                                            </option>
                                                        ))}
                                                    </Input>
                                                ) : (
                                                    <InputGroup className="input-group-merge input-group-alternative">
                                                        <Input
                                                            type="name"
                                                            value={userInfo.region.name}
                                                            disabled
                                                        />
                                                    </InputGroup>
                                                )}
                                            </FormGroup>
                                        </Col>
                                        <Col xs={4} sm={4}>
                                            <FormGroup>
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="exampleFormControlSelect1"
                                                >
                                                    Area
                                                </label>
                                                {userInfo.area === undefined ? (
                                                    <Input
                                                        id="exampleFormControlSelect1"
                                                        onChange={(e) => {
                                                            const value = JSON.parse(e.target.value);
                                                            setNewArea(value?.name);
                                                            setNewAreaId(value?.id);
                                                        }}
                                                        type="select"
                                                    >
                                                        <option>Select</option>
                                                        {filteredArea.map((area) => (
                                                            <option
                                                                value={JSON.stringify({
                                                                    name: area.name,
                                                                    id: area._id,
                                                                })}
                                                            >
                                                                {area.name}
                                                            </option>
                                                        ))}
                                                    </Input>
                                                ) : (
                                                    <InputGroup className="input-group-merge input-group-alternative">
                                                        <Input
                                                            type="name"
                                                            value={userInfo.area.name}
                                                            disabled
                                                        />
                                                    </InputGroup>
                                                )}
                                            </FormGroup>
                                        </Col>
                                        <Col xs={4} sm={4}>
                                            <FormGroup>
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="exampleFormControlSelect1"
                                                >
                                                    Territory
                                                </label>
                                                {userInfo.territory === undefined ? (
                                                    <Input
                                                        id="exampleFormControlSelect1"
                                                        onChange={(e) => {
                                                            const value = JSON.parse(e.target.value);
                                                            setNewTerritory(value?.name);
                                                            setNewTerritoryId(value?.id);
                                                        }}
                                                        type="select"
                                                    >
                                                        <option>Select</option>
                                                        {filteredTerritory.map((territory) => (
                                                            <option
                                                                value={JSON.stringify({
                                                                    name: territory.name,
                                                                    id: territory._id,
                                                                })}
                                                            >
                                                                {territory.name}
                                                            </option>
                                                        ))}
                                                    </Input>
                                                ) : (
                                                    <InputGroup className="input-group-merge input-group-alternative">
                                                        <Input
                                                            type="name"
                                                            value={userInfo.territory.name}
                                                            disabled
                                                        />
                                                    </InputGroup>
                                                )}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={4} sm={4}>
                                            <FormGroup>
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="exampleFormControlSelect1"
                                                >
                                                    Sales Point
                                                </label>
                                                <Input
                                                    id="exampleFormControlSelect1"
                                                    onChange={(e) => {
                                                        const value = JSON.parse(e.target.value);
                                                        setNewSalesPoint(value?.name);
                                                        setNewSalesPointId(value?.id);
                                                    }}
                                                    type="select"
                                                >
                                                    <option>Select</option>
                                                    {filteredSalesPoint.map((sp) => (
                                                        <option
                                                            value={JSON.stringify({
                                                                name: sp.name,
                                                                id: sp._id,
                                                            })}
                                                        >
                                                            {sp.name}
                                                        </option>
                                                    ))}
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                        <Col xs={4} sm={4}>
                                            <FormGroup>
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="exampleFormControlSelect1"
                                                >
                                                    TMS
                                                </label>
                                                <Input
                                                    id="exampleFormControlSelect1"
                                                    onChange={(e) => {
                                                        const value = JSON.parse(e.target.value);
                                                        setNewTMS(value?.name);
                                                        setNewTMSId(value?.id);
                                                    }}
                                                    type="select"
                                                >
                                                    <option>Select</option>
                                                    {filteredTms.map((tms) => (
                                                        <option
                                                            value={JSON.stringify({
                                                                name: tms.name,
                                                                id: tms._id,
                                                            })}
                                                        >
                                                            {tms.name}
                                                        </option>
                                                    ))}
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                        <Col xs={4} sm={4}>
                                            <FormGroup>
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="exampleFormControlSelect1"
                                                >
                                                    Outlets
                                                </label>
                                                <Input
                                                    id="exampleFormControlSelect1"
                                                    onChange={(e) => {
                                                        const value = JSON.parse(e.target.value);
                                                        setNewOutlet(value?.code);
                                                        setNewOutletId(value?.id);
                                                    }}
                                                    type="select"
                                                >
                                                    <option>Select</option>
                                                    {filteredOutlets.length > 0 &&
                                                        filteredOutlets?.map((outlet) => (
                                                            <option
                                                                value={JSON.stringify({
                                                                    code: outlet.code,
                                                                    id: outlet._id,
                                                                })}
                                                            >{`${outlet?.name} (${outlet?.code})`}</option>
                                                        ))}
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Form>
                            </CardBody>
                        </Card>
                    </div>
                </Row>
            </Container>
            <Container className="mt-2" fluid>
                <Card>
                    <CardHeader className="d-flex justify-content-between">
                        <h3 style={{color: "#DB5A3B"}}>Select Incentive</h3>
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col xs={6} sm={6}>
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="exampleFormControlSelect1"
                                    >
                                        Select Incentive 1
                                    </label>
                                    <Select
                                        value={selectTypeOne}
                                        onChange={setSelectTypeOne}
                                        isClearable
                                        isSearchable
                                        options={type1}
                                    />
                                </FormGroup>
                            </Col>
                            <Col xs={6} sm={6}>
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="exampleFormControlSelect1"
                                    >
                                        Select Incentive 2
                                    </label>
                                    <Select
                                        value={selectTypeTwo}
                                        isClearable
                                        isSearchable
                                        onChange={setSelectTypeTwo}
                                        options={type2}
                                    />
                                </FormGroup>
                                <div className="text-right">
                                    <Button onClick={() => submitRequest()} color="success">
                                        Submit
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Container>
        </div>
    );
};

export default SelectIncentive;
