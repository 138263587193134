import React, { useContext, useEffect } from "react";
// react plugin that prints a given react component
import ReactToPrint from "react-to-print";
import classnames from "classnames";
// react component for creating dynamic tables
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { MultiSelect } from "react-multi-select-component";

// reactstrap components11
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  Container,
  CardBody,
  FormGroup,
  Form,
  Modal,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  Col,
  UncontrolledTooltip,
  CardTitle,
} from "reactstrap";
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";

import axios from "axios";
import NotificationAlert from "react-notification-alert";
import DataContext from "context/DataContext";
import ReactLoading from "react-loading";
import CurrencyFormat from "react-currency-format";
import baseApi1 from "../../api/BaseApi1";
import {baseURL1} from "../../api/baseURL.json";

const { SearchBar } = Search;
const RoleSetting = () => {
  const { region, area, territory } = useContext(DataContext);
  const [showPerPage, setShowPerPage] = React.useState(null);
  //Edit User
  const [userName, setUserName] = React.useState("");
  const [userEmail, setUserEmail] = React.useState("");
  const [userPassword, setUserPassword] = React.useState("");
  const [userPhone, setUserPhone] = React.useState("");
  const [userAddress, setUserAddress] = React.useState("");
  const [userRole, setUserRole] = React.useState("");
  const [userRegion, setUserRegion] = React.useState("");
  const [userArea, setUserArea] = React.useState("");
  const [userTerritory, setUserTerritory] = React.useState("");
  const [userDOB, setUserDOB] = React.useState("");
  const [userStatus, setUserStatus] = React.useState("");

  //Add User
  const [newUserName, setNewUserName] = React.useState("");
  const [newUserEmail, setNewUserEmail] = React.useState("");
  const [newUserPassword, setNewUserPassword] = React.useState("");
  const [newUserPhone, setNewUserPhone] = React.useState("");
  const [newUserAddress, setNewUserAddress] = React.useState("");
  const [newUserRole, setNewUserRole] = React.useState("");
  const [newUserRegion, setNewUserRegion] = React.useState("");
  const [newUserArea, setNewUserArea] = React.useState("");
  const [newUserTerritory, setNewUserTerritory] = React.useState("");
  const [newUserDOB, setNewUserDOB] = React.useState("");
  const [newUserStatus, setNewUserStatus] = React.useState("");
  //Others
  const [formModal, setformModal] = React.useState(false);
  const [addUserModal, setUserModal] = React.useState(false);
  const [user, setUser] = React.useState([]);
  const [singleUser, setSingleUser] = React.useState({});
  //Graph
  const [nationalCount, setNationalCount] = React.useState(0);
  const [territoryCount, setTerritoryCount] = React.useState(0);
  const [regionCount, setRegionCount] = React.useState(0);
  const [areaCount, setAreaCount] = React.useState(0);
  const [superAdminCount, setSuperAdminCount] = React.useState(0);
  const [nationActive, setNationalActive] = React.useState(0);
  const [regionActive, setRegionActive] = React.useState(0);
  const [areaActive, setAreaActive] = React.useState(0);
  const [territoryActive, setTerritoryActive] = React.useState(0);
  const [adminActive, setAdminActive] = React.useState(0);
  const [allActive, setAllActive] = React.useState(0);
  useEffect(() => {
    const nationalManager = user.filter((x) => x.role === "National Manager");
    const nationalActive = user.filter(
      (x) => x.role === "National Manager" && x.status === "active"
    );
    setNationalActive(nationalActive.length);
    const territoryManager = user.filter((x) => x.role === "Territory Manager");
    const territoryActive = user.filter(
      (x) => x.role === "Territory Manager" && x.status === "active"
    );
    setTerritoryActive(territoryActive.length);
    const regionalManager = user.filter((x) => x.role === "Regional Manager");
    const regionalActive = user.filter(
      (x) => x.role === "Regional Manager" && x.status === "active"
    );
    setRegionActive(regionalActive.length);
    const areaManager = user.filter((x) => x.role === "Area Manager");
    const areaActive = user.filter(
      (x) => x.role === "Area Manager" && x.status === "active"
    );
    setAreaActive(areaActive.length);
    const superAdmin = user.filter((x) => x.role === "Superadmin");
    const superActive = user.filter(
      (x) => x.role === "Superadmin" && x.status === "active"
    );
    setAdminActive(superActive.length);
    const allActive = user.filter((x) => x.status === "active");
    setAllActive(allActive.length);
    setNationalCount(nationalManager.length);
    setTerritoryCount(territoryManager.length);
    setRegionCount(regionalManager.length);
    setAreaCount(areaManager.length);
    setSuperAdminCount(superAdmin.length);
  }, [user]);

  const filteredArea = area.filter((item) => item.regionId === userRegion);
  const filteredTerritory = territory.filter(
    (item) => item.areaId === userArea
  );

  const newfilteredArea = area.filter(
    (item) => item.regionId === newUserRegion
  );
  const newfilteredTerritory = territory.filter(
    (item) => item.areaId === newUserArea
  );

  //Notification Alert
  const notificationAlertRef = React.useRef(null);
  const notify = (type, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            User Notification
          </span>
          <span data-notify="message">{message}</span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 7,
    };
    notificationAlertRef.current.notificationAlert(options);
  };

  const componentRef = React.useRef(null);
  // this function will copy to clipboard an entire table,
  // so you can paste it inside an excel or csv file
  const getUserData = async () => {
    try {
      const response = await baseApi1.get("/api/user/all");
      if (!response.statusText === "OK") {
        console.log("Not Getting Response");
      } else {
        setUser(response.data.data);
      }
    } catch (error) {
      console.log(error.response);
    }
  };
  const getOneUserData = (id) => {
    const singleUserFinal = user.find((x) => x._id === id);
    setSingleUser(singleUserFinal);
    setUserRegion(singleUserFinal?.region?._id);
    setUserArea(singleUserFinal?.area?._id);
    setUserTerritory(singleUserFinal?.territory?._id);
  };
  const deleteOneUserData = async (id) => {
    try {
      const response = await baseApi1.delete(`/api/user/${id}`);
      if (!response.statusText === "OK") {
        console.log("Not Getting Response");
        notify("danger", response.data.message);
      } else {
        getUserData();
        notify("success", response.data.message);
      }
    } catch (error) {
      console.log(error.response);
    }
  };
  const updateOneUserData = async (id) => {
    const editedUser = {
      name: userName === "" ? singleUser?.name : userName,
      email: userEmail === "" ? singleUser?.email : userEmail,
      dob: userDOB === "" ? singleUser?.dob : userDOB,
      mobile: userPhone === "" ? singleUser.mobile : userPhone,
      address: userAddress === "" ? singleUser.address : userAddress,
      role: userRole === "" ? singleUser.designation : userRole,
      password: userPassword !== "" && userPassword,
      status: userStatus === "" ? singleUser.status : userStatus,
      region: userRegion === "" ? singleUser.region : userRegion,
      area: userArea === "" ? singleUser.area : userArea,
      territory: userTerritory === "" ? singleUser.territory : userTerritory,
    };
    try {
      const response = await baseApi1.put(
        `/api/user/${id}`,
        editedUser,
        { headers: { "Content-Type": "application/json" } }
      );
      if (!response.statusText === "OK") {
        console.log("Not Getting Response");
        notify("danger", response.data.message);
      } else {
        getUserData();
        setformModal(false);
        notify("success", response.data.message);
      }
    } catch (error) {
      console.log(error.response);
    }
  };
  useEffect(() => {
    getUserData();
  }, []);
  const manageDOB = (e) => {
    const d = new Date(e.target.value);
    let dt = d.getDate();
    let mn = d.getMonth() + 1;
    let yy = d.getFullYear();
    setNewUserDOB(
      yy + "-" + (mn <= 9 ? "0" + mn : mn) + "-" + (dt <= 9 ? "0" + dt : dt)
    );
    setUserDOB(
      yy + "-" + (mn <= 9 ? "0" + mn : mn) + "-" + (dt <= 9 ? "0" + dt : dt)
    );
  };

  const pagination = paginationFactory({
    page: 1,
    alwaysShowAllBtns: true,
    showTotal: true,
    withFirstAndLast: false,
    sizePerPageRenderer: ({
      options,
      currSizePerPage,
      onSizePerPageChange,
    }) => (
      <div className="dataTables_length" id="datatable-basic_length">
        <label>
          Show{" "}
          {
            <select
              name="datatable-basic_length"
              aria-controls="datatable-basic"
              className="form-control form-control-sm"
              onChange={(e) => onSizePerPageChange(e.target.value)}
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
              <option value="all">All</option>
            </select>
          }{" "}
          entries.
        </label>
      </div>
    ),
  });
  const addNewUser = async () => {
    const newUser = {
      name: newUserName,
      email: newUserEmail,
      dob: newUserDOB,
      mobile: newUserPhone,
      address: newUserAddress,
      role: newUserRole,
      password: newUserPassword,
      status: newUserStatus,
      region: newUserRegion,
      area: newUserArea,
      territory: newUserTerritory,
    };
    try {
      const response = await baseApi1.post(
        "/api/user/create",
        newUser,
        { headers: { "Content-Type": "application/json" } }
      );
      if (!response.statusText === "OK") {
        console.log("Not Getting Response");
        notify("danger", "Some Thing Went Wrong");
      } else {
        getUserData();
        response.status === 200 || response.status === 201
          ? setUserModal(false)
          : setUserModal(true);
        notify("success", response.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const submitNewUser = () => {
    addNewUser();
  };
  const handleSingleUserDelete = (id) => {
    deleteOneUserData(id);
  };
  return (
    <div>
      {alert}
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <SimpleHeader name="Users" parentName="Users List" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="d-flex justify-content-between">
                <h3 style={{ color: "#DB5A3B" }}>Users</h3>
                <Button
                  onClick={() => setUserModal(true)}
                  style={{ backgroundColor: "#DB5A3B", color: "white" }}
                >
                  Add User
                </Button>
              </CardHeader>
              <Row>
                <Col xs={4} sm={4}>
                  <div className="m-3" style={{ width: "15rem" }}>
                    <Card className="card-stats bg-gradient-primary mb-4 mb-lg-0">
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle className="text-uppercase text-white text-muted mb-0">
                              Total User
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0 text-white">
                              {`${allActive}/${user.length}`}
                            </span>
                          </div>
                          <Col className="col-auto">
                            <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                              <i className="fas fa-chart-bar" />
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </div>
                </Col>
                <Col xs={4} sm={4}>
                  <div className="m-3" style={{ width: "15rem" }}>
                    <Card className="card-stats bg-gradient-info mb-4 mb-lg-0">
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle className="text-uppercase text-white text-muted mb-0">
                              National User
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0 text-white">
                              {`${nationActive}/${nationalCount}`}
                            </span>
                          </div>
                          <Col className="col-auto">
                            <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                              <i className="fas fa-chart-bar" />
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </div>
                </Col>
                <Col xs={4} sm={4}>
                  <div className="m-3" style={{ width: "15rem" }}>
                    <Card className="card-stats bg-gradient-success mb-4 mb-lg-0">
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle className="text-uppercase text-white text-muted mb-0">
                              Regional User
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0 text-white">
                              {`${regionActive}/${regionCount}`}
                            </span>
                          </div>
                          <Col className="col-auto">
                            <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                              <i className="fas fa-chart-bar" />
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </div>
                </Col>
                <Col xs={4} sm={4}>
                  <div className="m-3" style={{ width: "15rem" }}>
                    <Card className="card-stats bg-gradient-warning mb-4 mb-lg-0">
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle className="text-uppercase text-white text-muted mb-0">
                              Area User
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0 text-white">
                              {`${areaActive}/${areaCount}`}
                            </span>
                          </div>
                          <Col className="col-auto">
                            <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                              <i className="fas fa-chart-bar" />
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </div>
                </Col>
                <Col xs={4} sm={4}>
                  <div className="m-3" style={{ width: "15rem" }}>
                    <Card className="card-stats bg-gradient-danger mb-4 mb-lg-0">
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle className="text-uppercase text-white text-muted mb-0">
                              Territory User
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0 text-white">
                              {`${territoryActive}/${territoryCount}`}
                            </span>
                          </div>
                          <Col className="col-auto">
                            <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                              <i className="fas fa-chart-bar" />
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </div>
                </Col>
                <Col xs={4} sm={4}>
                  <div className="m-3" style={{ width: "15rem" }}>
                    <Card className="card-stats bg-gradient-primary mb-4 mb-lg-0">
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle className="text-uppercase text-white text-muted mb-0">
                              Admin User
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0 text-white">
                              {`${adminActive}/${superAdminCount}`}
                            </span>
                          </div>
                          <Col className="col-auto">
                            <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                              <i className="fas fa-chart-bar" />
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </div>
                </Col>
              </Row>
              <ToolkitProvider
                data={user}
                keyField="phone"
                columns={[
                  {
                    dataField: "#",
                    text: "SL",
                    formatter: (cell, row, rowIndex) => {
                      return rowIndex + 1;
                    },
                  },
                  {
                    dataField: "name",
                    text: "Name",
                    sort: true,
                  },
                  {
                    dataField: "email",
                    text: "Email",
                    sort: true,
                  },
                  {
                    dataField: "mobile",
                    text: "Phone",
                    sort: true,
                  },
                  {
                    dataField: "dob",
                    text: "DOB",
                    sort: true,
                    formatter: (cell) => {
                      const dateFormat = new Date(cell).toDateString();
                      return dateFormat;
                    },
                  },
                  {
                    dataField: "address",
                    text: "Address",
                    sort: true,
                  },
                  {
                    dataField: "role",
                    text: "Role",
                    sort: true,
                  },
                  {
                    dataField: "status",
                    text: "Status",
                    sort: true,
                  },
                  {
                    dataField: "_id",
                    text: "Action",
                    formatter: (cell) => {
                      const handleEdit = () => {
                        getOneUserData(cell);
                        setformModal(true);
                      };
                      const action = (
                        <div>
                          <Button color="info" onClick={handleEdit}>
                            Edit
                          </Button>
                          <Button
                            onClick={() => handleSingleUserDelete(cell)}
                            color="danger"
                          >
                            Delete
                          </Button>
                        </div>
                      );
                      return action;
                    },
                  },
                ]}
                search
              >
                {(props) => (
                  <div className="py-4 table-responsive">
                    <Container fluid>
                      <Row>
                        <Col xs={4} sm={4}>
                          <div>
                            <select
                              name="datatable-basic_length"
                              aria-controls="datatable-basic"
                              className="form-control form-control-sm w-25"
                              onChange={(e) => setShowPerPage(e.target.value)}
                            >
                              <option
                                selected={showPerPage === 10 && true}
                                value={10}
                              >
                                10
                              </option>
                              <option
                                selected={showPerPage === 25 && true}
                                value={25}
                              >
                                25
                              </option>
                              <option
                                selected={showPerPage === 50 && true}
                                value={50}
                              >
                                50
                              </option>
                              <option
                                selected={showPerPage === 100 && true}
                                value={100}
                              >
                                100
                              </option>
                              <option
                                selected={showPerPage === "all" && true}
                                value="all"
                              >
                                all
                              </option>
                            </select>
                          </div>
                        </Col>
                        <Col xs={4} sm={4}>
                          <ButtonGroup>
                            <Button
                              className="buttons-copy buttons-html5"
                              color="warning"
                              size="sm"
                              id="copy-tooltip"
                              onClick={() =>
                                window.open(`${baseURL1}/api/users/csv`)
                              }
                            >
                              <span>CSV</span>
                            </Button>
                            <ReactToPrint
                              trigger={() => (
                                <Button
                                  color="warning"
                                  size="sm"
                                  className="buttons-copy buttons-html5"
                                  id="print-tooltip"
                                >
                                  Print
                                </Button>
                              )}
                              content={() => componentRef.current}
                            />
                          </ButtonGroup>
                          <UncontrolledTooltip
                            placement="top"
                            target="print-tooltip"
                          >
                            This will open a print page with the visible rows of
                            the table.
                          </UncontrolledTooltip>
                          <UncontrolledTooltip
                            placement="top"
                            target="copy-tooltip"
                          >
                            This will Download Users As CSV.
                          </UncontrolledTooltip>
                        </Col>
                        <Col xs={4} sm={4}>
                          <div
                            id="datatable-basic_filter"
                            className="dataTables_filter px-4 pb-1 float-right"
                          >
                            <label>
                              Search:
                              <SearchBar
                                className="form-control-sm"
                                placeholder=""
                                {...props.searchProps}
                              />
                            </label>
                          </div>
                        </Col>
                      </Row>
                    </Container>
                    {user.length > 0 ? (
                      <BootstrapTable
                        ref={componentRef}
                        {...props.baseProps}
                        bootstrap4={true}
                        pagination={pagination}
                        bordered={false}
                        id="react-bs-table"
                      />
                    ) : (
                      <div className="d-flex justify-content-center">
                        <ReactLoading
                          className=""
                          type="bubbles"
                          color="#DB5A3B"
                          height={200}
                          width={100}
                        />
                      </div>
                    )}
                  </div>
                )}
              </ToolkitProvider>
            </Card>
          </div>
        </Row>
      </Container>
      {/* Edit Modal */}
      <Modal
        className="modal-dialog-centered"
        size="md"
        isOpen={formModal}
        toggle={() => setformModal(false)}
      >
        <div className="modal-body p-0">
          <Card className="bg-secondary border-0 mb-0">
            <CardHeader style={{ color: "#DB5A3B" }} className="bg-transparent">
              Update User
            </CardHeader>
            <CardBody className="px-lg-5 py-lg-5">
              <Form role="form">
                <FormGroup
                  className={classnames("mb-3", {
                    focused: userName,
                  })}
                >
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlSelect1"
                  >
                    User Name
                  </label>
                  <InputGroup className="input-group-merge input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="User Name"
                      defaultValue={singleUser?.name}
                      type="text"
                      onChange={(e) => setUserName(e.target.value)}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup
                  className={classnames({
                    focused: userEmail,
                  })}
                >
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlSelect1"
                  >
                    Email
                  </label>
                  <InputGroup className="input-group-merge input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Email"
                      defaultValue={singleUser?.email}
                      type="email"
                      onChange={(e) => setUserEmail(e.target.value)}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup
                  className={classnames({
                    focused: userPassword,
                  })}
                >
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlSelect1"
                  >
                    Password
                  </label>
                  <InputGroup className="input-group-merge input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Password"
                      type="text"
                      onChange={(e) => setUserPassword(e.target.value)}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup
                  className={classnames({
                    focused: userPhone,
                  })}
                >
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlSelect1"
                  >
                    Mobile Number
                  </label>
                  <InputGroup className="input-group-merge input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Phone"
                      defaultValue={singleUser?.mobile}
                      type="number"
                      onChange={(e) => setUserPhone(e.target.value)}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup
                  className={classnames({
                    focused: userAddress,
                  })}
                >
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlSelect1"
                  >
                    Address
                  </label>
                  <InputGroup className="input-group-merge input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Address"
                      defaultValue={singleUser?.address}
                      type="text"
                      onChange={(e) => setUserAddress(e.target.value)}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlSelect1"
                  >
                    Role
                  </label>
                  <Input
                    id="exampleFormControlSelect1"
                    onChange={(e) => setUserRole(e.target.value)}
                    type="select"
                  >
                    <option>Select</option>
                    <option
                      selected={
                        singleUser?.role === "National Manager" ? true : false
                      }
                      value="National Manager"
                    >
                      National Manager
                    </option>
                    <option
                      selected={
                        singleUser?.role === "Regional Manager" ? true : false
                      }
                      value="Regional Manager"
                    >
                      Regional Manager
                    </option>
                    <option
                      selected={
                        singleUser?.role === "Area Manager" ? true : false
                      }
                      value="Area Manager"
                    >
                      Area Manager
                    </option>
                    <option
                      selected={
                        singleUser?.role === "Territory Manager" ? true : false
                      }
                      value="Territory Manager"
                    >
                      Territory Manager
                    </option>
                    <option
                      selected={
                        singleUser?.role === "Superadmin" ? true : false
                      }
                      value="Superadmin"
                    >
                      Superadmin
                    </option>
                  </Input>
                </FormGroup>
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlSelect1"
                  >
                    Region
                  </label>
                  <Input
                    id="exampleFormControlSelect1"
                    onChange={(e) => setUserRegion(e.target.value)}
                    type="select"
                  >
                    <option>Select</option>
                    {region.map((region) => (
                      <option
                        selected={
                          region._id === singleUser?.region?._id ? true : false
                        }
                        value={region._id}
                      >
                        {region.name}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlSelect1"
                  >
                    Area
                  </label>
                  <Input
                    id="exampleFormControlSelect1"
                    onChange={(e) => setUserArea(e.target.value)}
                    type="select"
                  >
                    <option>Select</option>
                    {filteredArea.map((area) => (
                      <option
                        selected={
                          area._id === singleUser?.area?._id ? true : false
                        }
                        value={area._id}
                      >
                        {area.name}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlSelect1"
                  >
                    Territory
                  </label>
                  <Input
                    id="exampleFormControlSelect1"
                    onChange={(e) => setUserTerritory(e.target.value)}
                    type="select"
                  >
                    <option>Select</option>
                    {filteredTerritory.map((territory) => (
                      <option
                        selected={
                          territory._id === singleUser?.territory?._id
                            ? true
                            : false
                        }
                        value={territory._id}
                      >
                        {territory.name}
                      </option>
                    ))}
                  </Input>
                </FormGroup>

                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlSelect1"
                  >
                    Action
                  </label>
                  <Input
                    id="exampleFormControlSelect1"
                    onChange={(e) => setUserStatus(e.target.value)}
                    type="select"
                  >
                    <option>Select</option>
                    <option
                      selected={singleUser.status === "active" && true}
                      value="active"
                    >
                      Active
                    </option>
                    <option
                      selected={singleUser.status === "inactive" && true}
                      value="inactive"
                    >
                      Inactive
                    </option>
                  </Input>
                </FormGroup>
                <div className="text-center">
                  <Button
                    onClick={() => setformModal(false)}
                    className="my-4"
                    color="danger"
                    type="button"
                  >
                    Close
                  </Button>
                  <Button
                    onClick={() => updateOneUserData(singleUser?._id)}
                    className="my-4"
                    color="success"
                    type="button"
                  >
                    Update
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </div>
      </Modal>
      {/* Add User */}
      <Modal
        className="modal-dialog-centered"
        size="md"
        isOpen={addUserModal}
        toggle={() => setUserModal(false)}
      >
        <div className="modal-body p-0">
          <Card className="bg-secondary border-0 mb-0">
            <CardHeader style={{ color: "#DB5A3B" }} className="bg-transparent">
              Add User
            </CardHeader>
            <CardBody className="px-lg-5 py-lg-5">
              <Form role="form">
                <FormGroup
                  className={classnames("mb-3", {
                    focused: newUserName,
                  })}
                >
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlSelect1"
                  >
                    User Name
                  </label>
                  <InputGroup className="input-group-merge input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="User Name"
                      type="text"
                      onChange={(e) => setNewUserName(e.target.value)}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup
                  className={classnames({
                    focused: newUserEmail,
                  })}
                >
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlSelect1"
                  >
                    Email
                  </label>
                  <InputGroup className="input-group-merge input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Email"
                      type="email"
                      onChange={(e) => setNewUserEmail(e.target.value)}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup
                  className={classnames({
                    focused: newUserPassword,
                  })}
                >
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlSelect1"
                  >
                    Password
                  </label>
                  <InputGroup className="input-group-merge input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Password"
                      type="password"
                      onChange={(e) => setNewUserPassword(e.target.value)}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup
                  className={classnames({
                    focused: newUserPhone,
                  })}
                >
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlSelect1"
                  >
                    Mobile Number
                  </label>
                  <InputGroup className="input-group-merge input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Phone"
                      type="number"
                      onChange={(e) => setNewUserPhone(e.target.value)}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup
                  className={classnames({
                    focused: newUserAddress,
                  })}
                >
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlSelect1"
                  >
                    Address
                  </label>
                  <InputGroup className="input-group-merge input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Address"
                      type="text"
                      onChange={(e) => setNewUserAddress(e.target.value)}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlSelect1"
                  >
                    Role
                  </label>
                  <Input
                    id="exampleFormControlSelect1"
                    onChange={(e) => setNewUserRole(e.target.value)}
                    type="select"
                  >
                    <option>Select</option>
                    <option value="National Manager">National Manager</option>
                    <option value="Regional Manager">Regional Manager</option>
                    <option value="Area Manager">Area Manager</option>
                    <option value="Territory Manager">Territory Manager</option>
                    <option value="Superadmin">Superadmin</option>
                    <option value="technicalSupport">Technical Support</option>
                    <option value="deliveryPartner">Delivery Partner</option>
                  </Input>
                </FormGroup>
                <FormGroup
                  style={{
                    display:
                      newUserRole === "Regional Manager" ||
                      newUserRole === "Area Manager" ||
                      newUserRole === "Territory Manager"
                        ? "block"
                        : "none",
                  }}
                >
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlSelect1"
                  >
                    Region
                  </label>
                  <Input
                    id="exampleFormControlSelect1"
                    onChange={(e) => setNewUserRegion(e.target.value)}
                    type="select"
                  >
                    <option>Select</option>
                    {region.map((region) => (
                      <option value={region._id}>{region.name}</option>
                    ))}
                  </Input>
                </FormGroup>
                <FormGroup
                  style={{
                    display:
                      newUserRole === "Area Manager" ||
                      newUserRole === "Territory Manager"
                        ? "block"
                        : "none",
                  }}
                >
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlSelect1"
                  >
                    Area
                  </label>
                  <Input
                    id="exampleFormControlSelect1"
                    onChange={(e) => setNewUserArea(e.target.value)}
                    type="select"
                  >
                    <option>Select</option>
                    {newfilteredArea.map((area) => (
                      <option value={area._id}>{area.name}</option>
                    ))}
                  </Input>
                </FormGroup>
                <FormGroup
                  style={{
                    display:
                      newUserRole === "Territory Manager" ? "block" : "none",
                  }}
                >
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlSelect1"
                  >
                    Territory
                  </label>
                  <Input
                    id="exampleFormControlSelect1"
                    onChange={(e) => setNewUserTerritory(e.target.value)}
                    type="select"
                  >
                    <option>Select</option>
                    {newfilteredTerritory.map((territory) => (
                      <option value={territory._id}>{territory.name}</option>
                    ))}
                  </Input>
                </FormGroup>
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example-date-input"
                    md="2"
                  >
                    DOB
                  </label>
                  <Input
                    id="example-date-input"
                    type="date"
                    onChange={manageDOB}
                  />
                </FormGroup>
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlSelect1"
                  >
                    Status
                  </label>
                  <Input
                    id="exampleFormControlSelect1"
                    onChange={(e) => setNewUserStatus(e.target.value)}
                    type="select"
                  >
                    <option>Select</option>
                    <option value="active">Active</option>
                    <option value="inactive">Inactive</option>
                  </Input>
                </FormGroup>
                <div className="text-center">
                  <Button
                    onClick={() => setUserModal(false)}
                    className="my-4"
                    color="danger"
                    type="button"
                  >
                    Close
                  </Button>
                  <Button
                    onClick={submitNewUser}
                    className="my-4"
                    color="success"
                    type="button"
                  >
                    Update
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </div>
      </Modal>
    </div>
  );
};

export default RoleSetting;
