import React from 'react';
import SimpleHeader from "../../components/Headers/SimpleHeader";
import {Button, Card, CardHeader, Col, Container, Row} from "reactstrap";
import NotificationAlert from "react-notification-alert";
import ToolkitProvider, {Search} from "react-bootstrap-table2-toolkit";
import moment from "moment/moment";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import baseApi2 from "../../api/BaseApi2";

const {SearchBar} = Search;
const PendingDistribution = () => {
    const [showPerPage, setShowPerPage] = React.useState(null);
    const [pendingList, setPendingList] = React.useState([]);
    const user = JSON.parse(localStorage.getItem("user"));
    //Notification Alert
    const notificationAlertRef = React.useRef(null);
    const notify = (type, message) => {
        let options = {
            place: "bc",
            message: (
                <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
              Distribution
          </span>
                    <span data-notify="message">{message}</span>
                </div>
            ),
            type: type,
            icon: "ni ni-bell-55",
            autoDismiss: 7,
        };
        notificationAlertRef?.current?.notificationAlert(options);
    };
    const componentRef = React.useRef(null);
    const pagination = paginationFactory({
        page: 1,
        alwaysShowAllBtns: true,
        showTotal: true,
        withFirstAndLast: false,
        sizePerPageRenderer: ({
                                  options,
                                  currSizePerPage,
                                  onSizePerPageChange,
                              }) => (
            <div className="dataTables_length" id="datatable-basic_length">
                <label>
                    Show{" "}
                    {
                        <select
                            name="datatable-basic_length"
                            aria-controls="datatable-basic"
                            className="form-control form-control-sm"
                            onChange={(e) => onSizePerPageChange(e.target.value)}
                        >
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="all">All</option>
                        </select>
                    }{" "}
                    entries.
                </label>
            </div>
        ),
    });
    const getPendingDistributionData = async () => {
        try {
            const response = await baseApi2.get(`/api/teWisePendingDistributions?regionId=${user?.region?._id}&areaId=${user?.area?._id}&territoryId=${user?.territory?._id}`
            );
            if (response.statusText !== "OK") {
                notify("danger", response.data.message);
            } else {
                console.log(response?.data);
                setPendingList(response?.data);
            }
        } catch (error) {
            notify("danger", error?.response?.data?.message);
        }
    };

    const approvePendingDistributionData = async (id) => {
        try {
            const response = await baseApi2.get(`/api/approvePendingDistribution/${id}`
            );
            if (response.statusText !== "OK") {
                notify("danger", response?.data?.message);
            } else {
                console.log(response);
                notify("success", response?.data?.message);
            }
        } catch (error) {
            console.log(error);
        }
    };

    React.useEffect(() => {
        getPendingDistributionData();
    }, [user?.territory?._id]);


    return (
        <div>
            <div className="rna-wrapper">
                <NotificationAlert ref={notificationAlertRef}/>
            </div>
            <SimpleHeader name="Pending" parentName="Distribution"/>
            <Container fluid className="mt--6">
                <Row>
                    <div className="col">
                        <Card>
                            <CardHeader className="d-flex justify-content-between">
                                <h3 style={{color: "#DB5A3B"}}>
                                    Pending Distributions
                                </h3>
                            </CardHeader>
                            <ToolkitProvider
                                data={pendingList}
                                keyField="outletCode"
                                columns={[
                                    {
                                        dataField: "#",
                                        text: "SL",
                                        formatter: (cell, row, rowIndex) => {
                                            return rowIndex + 1;
                                        },
                                    },
                                    {
                                        dataField: "outletCode",
                                        text: "outletCode",
                                        sort: true,
                                    },
                                    {
                                        dataField: "retailerPhone",
                                        text: "retailerPhone",
                                        sort: true,
                                    },
                                    {
                                        dataField: "sales",
                                        text: "sales",
                                        sort: true,
                                    },
                                    {
                                        dataField: "incentivesOne",
                                        text: "incentivesOne",
                                        sort: true,
                                    },
                                    {
                                        dataField: "incentivesTwo",
                                        text: "incentivesTwo",
                                        sort: true,
                                    },
                                    {
                                        dataField: "tms",
                                        text: "Tms",
                                        sort: true,
                                    },
                                    {
                                        dataField: "createdAt",
                                        text: "Request Time",
                                        sort: true,
                                        formatter: (cell) => {
                                            return moment(new Date(cell)).format(
                                                "MMMM Do YYYY, h:mm:ss a"
                                            );
                                        },
                                    },
                                    {
                                        dataField: "_id",
                                        text: "Action",
                                        formatter: (cell) => {
                                            const handleApprove = async () => {
                                                await approvePendingDistributionData(cell);
                                                await getPendingDistributionData();
                                            };
                                            return (
                                                <div>
                                                    <Button
                                                        color="success"
                                                        onClick={handleApprove}
                                                    >
                                                        Approve
                                                    </Button>
                                                </div>
                                            );
                                        },
                                    },
                                ]}
                                search
                            >
                                {(props) => (
                                    <div className="py-4 table-responsive">
                                        <Container fluid>
                                            <Row>
                                                <Col xs={6} sm={6}>
                                                    <div>
                                                        <select
                                                            name="datatable-basic_length"
                                                            aria-controls="datatable-basic"
                                                            className="form-control form-control-sm w-25"
                                                            onChange={(e) => setShowPerPage(e.target.value)}
                                                        >
                                                            <option
                                                                selected={showPerPage === 10 && true}
                                                                value={10}
                                                            >
                                                                10
                                                            </option>
                                                            <option
                                                                selected={showPerPage === 25 && true}
                                                                value={25}
                                                            >
                                                                25
                                                            </option>
                                                            <option
                                                                selected={showPerPage === 50 && true}
                                                                value={50}
                                                            >
                                                                50
                                                            </option>
                                                            <option
                                                                selected={showPerPage === 100 && true}
                                                                value={100}
                                                            >
                                                                100
                                                            </option>
                                                            <option
                                                                selected={showPerPage === "all" && true}
                                                                value="all"
                                                            >
                                                                all
                                                            </option>
                                                        </select>
                                                    </div>
                                                </Col>
                                                {/*                                                <Col xs={4} sm={4}>
                                                    <Button
                                                        color="success"
                                                        size="sm"
                                                        onClick={() => console.log("clicked")}
                                                    >
                                                        Approve All
                                                    </Button>
                                                </Col>*/}
                                                <Col xs={6} sm={6}>
                                                    <div
                                                        id="datatable-basic_filter"
                                                        className="dataTables_filter px-4 pb-1 float-right"
                                                    >
                                                        <label>
                                                            Search:
                                                            <SearchBar
                                                                className="form-control-sm"
                                                                placeholder=""
                                                                {...props.searchProps}
                                                            />
                                                        </label>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Container>
                                        <BootstrapTable
                                            ref={componentRef}
                                            {...props.baseProps}
                                            bootstrap4={true}
                                            pagination={pagination}
                                            bordered={false}
                                            id="react-bs-table"
                                        />
                                    </div>
                                )}
                            </ToolkitProvider>
                        </Card>
                    </div>
                </Row>
            </Container>
        </div>
    );
};

export default PendingDistribution;
