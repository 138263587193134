import React, {useContext, useEffect} from "react";
// react plugin that prints a given react component
import ReactToPrint from "react-to-print";
import classnames from "classnames";
// react component for creating dynamic tables
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {Search} from "react-bootstrap-table2-toolkit";
import DateFnsUtils from "@date-io/date-fns";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import moment from "moment";

// reactstrap components11
import {
    Button,
    ButtonGroup,
    Card,
    CardHeader,
    Container,
    CardBody,
    FormGroup,
    Form,
    Modal,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Row,
    Col,
    UncontrolledTooltip,
} from "reactstrap";
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import DataContext from "context/DataContext";
import NotificationAlert from "react-notification-alert";
import ReactLoading from "react-loading";
import baseApi1 from "../api/BaseApi1";
import {baseURL1} from "../api/baseURL.json";

const {SearchBar} = Search;
const AddTarget = () => {
    const {region, area, territory, outlet} = useContext(DataContext);
    const [showPerPage, setShowPerPage] = React.useState(25);
    const [page, setPage] = React.useState(1);
    const [searchTerm, setSearchTerm] = React.useState("");
    const [totalSize, setTotalSize] = React.useState(10);
    //Edit Target
    const [targetRegion, setTargetRegion] = React.useState("");
    const [targetArea, setTargetArea] = React.useState("");
    const [targetTerritory, setTargetTerritory] = React.useState("");
    const [targetOutlet, setTargetOutlet] = React.useState("");
    const [targetType, setTargetType] = React.useState("");
    const [targetBrand, setTargetBrand] = React.useState("");
    const [targetMonth, setTargetMonth] = React.useState(0);
    const [targetYear, setTargetYear] = React.useState(0);
    const [targetNumber, setTargetNumber] = React.useState(0);
    const [targetDeployedPcm, setTargetDeployedPcm] = React.useState("");
    //Add Target
    const [newTargetRegion, setNewTargetRegion] = React.useState("");
    const [newTargetArea, setNewTargetArea] = React.useState("");
    const [newTargetTerritory, setNewTargetTerritory] = React.useState("");
    const [newTargetOutlet, setNewTargetOutlet] = React.useState("");
    const [newTargetType, setNewTargetType] = React.useState("");
    const [newTargetBrand, setNewTargetBrand] = React.useState("");
    const [newTargetMonth, setNewTargetMonth] = React.useState(0);
    const [newTargetYear, setNewTargetYear] = React.useState(0);
    const [newTargetNumber, setNewTargetNumber] = React.useState(0);
    const [newTargetDeployedPcm, setNewTargetDeployedPcm] = React.useState("");
    //Others
    const [formModal, setformModal] = React.useState(false);
    const [addTargetModal, setTargetModal] = React.useState(false);
    const [allTarget, setAllTarget] = React.useState([]);
    const [singleTarget, setSingleTarget] = React.useState({});
    const [allBrand, setAllBrand] = React.useState([]);
    const [currentMonth, setCurrentMonth] = React.useState("");
    //Notification Alert
    const notificationAlertRef = React.useRef(null);
    const notify = (type, message) => {
        let options = {
            place: "bc",
            message: (
                <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
              Target Notification
          </span>
                    <span data-notify="message">{message}</span>
                </div>
            ),
            type: type,
            icon: "ni ni-bell-55",
            autoDismiss: 7,
        };
        notificationAlertRef.current.notificationAlert(options);
    };

    //Filter Edited Target
    // const filteredArea = area.filter((item) => item.regionId === targetRegion);
    const filteredArea = area.filter((item) => item.regionId === targetRegion);
    const filteredTerritory = territory.filter(
        (item) => item.areaId === targetArea
    );
    const filteredOutlet = outlet.filter(
        (item) => item.territoryId === targetTerritory
    );

    //Filter Add Target
    const newfilteredArea = area.filter(
        (item) => item.regionId === newTargetRegion
    );
    const newfilteredTerritory = territory.filter(
        (item) => item.areaId === newTargetArea
    );
    const newfilteredOutlet = outlet.filter(
        (item) => item.territoryId === newTargetTerritory
    );

    const componentRef = React.useRef(null);
    // this function will copy to clipboard an entire table,
    // so you can paste it inside an excel or csv file
    const getTargetData = async () => {
        try {
            const response = await baseApi1.get(
                `/api/target/all?page=${page}&limit=${showPerPage}&search=${searchTerm}`
            );
            if (!response.statusText === "OK") {
                console.log("Not Getting Response");
            } else {
                setAllTarget(response.data.data);
                setTotalSize(response.data.total);
            }
        } catch (error) {
            console.log(error);
        }
    };
    const getOneTargetData = (id) => {
        const allTargetFinal = allTarget.find((x) => x._id === id);
        setTargetRegion(allTargetFinal?.regionId);
        setTargetArea(allTargetFinal?.areaId);
        setTargetTerritory(allTargetFinal?.territoryId);
        setTargetOutlet(allTargetFinal?.outletId);
        setSingleTarget(allTargetFinal);
    };
    const deleteOneTargetData = async (id) => {
        try {
            const response = await baseApi1.delete(
                `/api/target/${id}`
            );
            if (!response.statusText === "OK") {
                console.log("Not Getting Response");
                notify("danger", response.data.message);
            } else {
                getTargetData();
                notify("success", response.data.message);
            }
        } catch (error) {
            console.log(error);
        }
    };
    const updateOneTargetData = async (id) => {
        const editedTarget = {
            region: targetRegion === "" ? singleTarget?.regionId : targetRegion,
            area: targetArea === "" ? singleTarget?.areaId : targetArea,
            territory:
                targetTerritory === "" ? singleTarget?.territoryId : targetTerritory,
            outlet: targetOutlet === "" ? singleTarget?.outletId : targetOutlet,
            brand: targetBrand === "" ? singleTarget?.brandId : targetBrand,
            targetType: targetType === "" ? singleTarget?.targetType : targetType,
            target: targetNumber === 0 ? singleTarget?.target : targetNumber,
            deployedPCM:
                targetDeployedPcm === ""
                    ? singleTarget?.deployedPCM
                    : targetDeployedPcm,
            month: targetMonth === 0 ? singleTarget?.month : targetMonth,
            year: targetYear === 0 ? singleTarget?.year : targetYear,
        };
        try {
            const response = await baseApi1.put(
                `/api/target/${id}`,
                editedTarget,
                {headers: {"Content-Type": "application/json"}}
            );
            if (!response.statusText === "OK") {
                console.log("Not Getting Response");
                notify("danger", response.data.message);
            } else {
                getTargetData();
                setformModal(false);
                notify("success", response.data.message);
            }
        } catch (error) {
            console.log(error);
        }
    };
    const getBrands = async () => {
        try {
            const response = await baseApi1.get("/api/brand/all");
            if (!response.statusText === "OK") {
                notify("danger", "Not Getting Response");
            } else {
                setAllBrand(response.data.data);
            }
        } catch (error) {
            console.log(error);
        }
    };
    useEffect(() => {
        setCurrentMonth(moment(new Date()).format("yyyy-MM"));
        getBrands();
        getTargetData();
    }, [page, showPerPage, searchTerm]);

    const manageNewTargetMonth = (e) => {
        const d = new Date(e);
        let mn = d.getMonth();
        let yy = d.getFullYear();
        setNewTargetMonth(mn);
        setNewTargetYear(yy);
    };
    const manageEditTargetMonth = (e) => {
        const d = new Date(e);
        let mn = d.getMonth();
        let yy = d.getFullYear();
        setTargetMonth(mn <= 9 ? "0" + mn : mn);
        setTargetYear(yy);
    };

    const pagination = paginationFactory({
        page,
        totalSize,
        showTotal: true,
        sizePerPage: showPerPage,
        alwaysShowAllBtns: true,
        withFirstAndLast: true,
        onPageChange: (page) => {
            setPage(page);
        },
        onSizePerPageChange: (_, size) => {
            setShowPerPage(size);
        },
        sizePerPageRenderer: ({
                                  options,
                                  currSizePerPage,
                                  onSizePerPageChange,
                              }) => (
            <div className="dataTables_length" id="datatable-basic_length">
                <label>
                    Show{" "}
                    {
                        <select
                            value={currSizePerPage}
                            name="datatable-basic_length"
                            aria-controls="datatable-basic"
                            className="form-control form-control-sm"
                            onChange={(e) => onSizePerPageChange(e.target.value)}
                        >
                            <option value={10}>10</option>
                            <option value={25}>25</option>
                            <option value={50}>50</option>
                            <option value={100}>100</option>
                            <option value="">all</option>
                        </select>
                    }{" "}
                    entries.
                </label>
            </div>
        ),
    });

    const addNewTarget = async () => {
        const newTarget = {
            region: newTargetRegion,
            area: newTargetArea,
            territory: newTargetTerritory,
            outlet: newTargetOutlet,
            brand: newTargetBrand,
            targetType: newTargetType,
            target: newTargetNumber,
            deployedPCM: newTargetDeployedPcm,
            month: newTargetMonth,
            year: newTargetYear,
        };
        try {
            const response = await baseApi1.post(
                "/api/target",
                newTarget,
                {headers: {"Content-Type": "application/json"}}
            );
            if (!response.statusText === "OK") {
                console.log("Not Getting Response");
                notify("danger", "Some Thing Went Wrong");
            } else {
                getTargetData();
                response.status === 200 || response.status === 201
                    ? setTargetModal(false)
                    : setTargetModal(true);
                setNewTargetRegion("");
                setNewTargetArea("");
                setNewTargetTerritory("");
                setNewTargetOutlet("");
                setNewTargetType("");
                setNewTargetBrand("");
                setNewTargetMonth(0);
                setNewTargetYear(0);
                setNewTargetNumber(0);
                setNewTargetDeployedPcm("");
                notify("success", response.data.message);
            }
        } catch (error) {
            console.log(error);
        }
    };
    const submitNewTarget = () => {
        addNewTarget();
    };
    const handleSingleTargetDelete = (id) => {
        deleteOneTargetData(id);
    };
    return (
        <div>
            <div className="rna-wrapper">
                <NotificationAlert ref={notificationAlertRef}/>
            </div>
            <SimpleHeader name="Target" parentName="Target List"/>
            <Container className="mt--6" fluid>
                <Row>
                    <div className="col">
                        <Card>
                            <CardHeader className="d-flex justify-content-between">
                                <h3 style={{color: "#DB5A3B"}}>Target</h3>
                                <Button
                                    onClick={() => setTargetModal(true)}
                                    style={{backgroundColor: "#DB5A3B", color: "white"}}
                                >
                                    Add Target
                                </Button>
                            </CardHeader>
                            <ToolkitProvider
                                data={allTarget}
                                keyField="_id"
                                columns={[
                                    {
                                        dataField: "#",
                                        text: "SL",
                                        formatter: (cell, row, rowIndex) => {
                                            return rowIndex + 1;
                                        },
                                    },
                                    {
                                        dataField: "region",
                                        text: "Region",
                                        sort: true,
                                    },
                                    {
                                        dataField: "area",
                                        text: "Area",
                                        sort: true,
                                    },
                                    {
                                        dataField: "territory",
                                        text: "Territory",
                                        sort: true,
                                    },
                                    {
                                        dataField: "outletCode",
                                        text: "Outlet Code",
                                        sort: true,
                                    },
                                    {
                                        dataField: "targetType",
                                        text: "Target Type",
                                        sort: true,
                                    },
                                    {
                                        dataField: "target",
                                        text: "Target",
                                        sort: true,
                                    },
                                    {
                                        dataField: "brand",
                                        text: "Brand",
                                        sort: true,
                                        formatter: (cell) => {
                                            return cell === undefined || cell === null ? "-" : cell;
                                        },
                                    },
                                    {
                                        dataField: "deployedPCM",
                                        text: "Deployed PCM",
                                        sort: true,
                                    },
                                    {
                                        dataField: "month",
                                        text: "Target Month",
                                        sort: true,
                                        formatter: (cell, row) => {
                                            return row.month + 1 + "/" + row.year;
                                        },
                                    },
                                    {
                                        dataField: "_id",
                                        text: "Action",
                                        formatter: (cell) => {
                                            const handleEdit = () => {
                                                getOneTargetData(cell);
                                                setformModal(true);
                                            };
                                            const action = (
                                                <div>
                                                    <Button color="info" onClick={handleEdit}>
                                                        Edit
                                                    </Button>
                                                    <Button
                                                        onClick={() => handleSingleTargetDelete(cell)}
                                                        color="danger"
                                                    >
                                                        Delete
                                                    </Button>
                                                </div>
                                            );
                                            return action;
                                        },
                                    },
                                ]}
                                search
                            >
                                {(props) => (
                                    <div className="py-4 table-responsive">
                                        <Container fluid>
                                            <Row>
                                                <Col xs={4} sm={4}>
                                                    <div>
                                                        <select
                                                            name="datatable-basic_length"
                                                            aria-controls="datatable-basic"
                                                            className="form-control form-control-sm w-25"
                                                            onChange={(e) => setShowPerPage(e.target.value)}
                                                        >
                                                            <option
                                                                selected={showPerPage === 10 && true}
                                                                value={10}
                                                            >
                                                                10
                                                            </option>
                                                            <option
                                                                selected={showPerPage === 25 && true}
                                                                value={25}
                                                            >
                                                                25
                                                            </option>
                                                            <option
                                                                selected={showPerPage === 50 && true}
                                                                value={50}
                                                            >
                                                                50
                                                            </option>
                                                            <option
                                                                selected={showPerPage === 100 && true}
                                                                value={100}
                                                            >
                                                                100
                                                            </option>
                                                            <option
                                                                selected={showPerPage === "" && true}
                                                                value=""
                                                            >
                                                                all
                                                            </option>
                                                        </select>
                                                    </div>
                                                </Col>
                                                <Col xs={4} sm={4}>
                                                    <ButtonGroup>
                                                        <Button
                                                            className="buttons-copy buttons-html5"
                                                            color="warning"
                                                            size="sm"
                                                            id="copy-tooltip"
                                                            onClick={() =>
                                                                window.open(
                                                                    `${baseURL1}/api/target/csv?page=${page}&limit=${showPerPage}&search=${searchTerm}`
                                                                )
                                                            }
                                                        >
                                                            <span>CSV</span>
                                                        </Button>
                                                        <ReactToPrint
                                                            trigger={() => (
                                                                <Button
                                                                    color="warning"
                                                                    size="sm"
                                                                    className="buttons-copy buttons-html5"
                                                                    id="print-tooltip"
                                                                >
                                                                    Print
                                                                </Button>
                                                            )}
                                                            content={() => componentRef.current}
                                                        />
                                                    </ButtonGroup>
                                                    <UncontrolledTooltip
                                                        placement="top"
                                                        target="print-tooltip"
                                                    >
                                                        This will open a print page with the visible rows of
                                                        the table.
                                                    </UncontrolledTooltip>
                                                    <UncontrolledTooltip
                                                        placement="top"
                                                        target="copy-tooltip"
                                                    >
                                                        This will Download Target As CSV.
                                                    </UncontrolledTooltip>
                                                </Col>
                                                <Col xs={4} sm={4}>
                                                    <div
                                                        id="datatable-basic_filter"
                                                        className="dataTables_filter px-4 pb-1 float-right"
                                                    >
                                                        <label>
                                                            Search:
                                                            <SearchBar
                                                                className="form-control-sm"
                                                                placeholder=""
                                                                {...props.searchProps}
                                                            />
                                                        </label>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Container>
                                        {allTarget.length > 0 ? (
                                            <BootstrapTable
                                                remote
                                                bootstrap4
                                                bordered={false}
                                                ref={componentRef}
                                                hover={true}
                                                id="react-bs-table"
                                                {...props.baseProps}
                                                pagination={pagination}
                                                onTableChange={(type, {searchText}) => {
                                                    setSearchTerm(searchText);
                                                }}
                                            />
                                        ) : (
                                            <div className="d-flex justify-content-center">
                                                <ReactLoading
                                                    className=""
                                                    type="bubbles"
                                                    color="#DB5A3B"
                                                    height={200}
                                                    width={100}
                                                />
                                            </div>
                                        )}
                                    </div>
                                )}
                            </ToolkitProvider>
                        </Card>
                    </div>
                </Row>
            </Container>
            {/* Edit Modal */}
            <Modal
                className="modal-dialog-centered"
                size="md"
                isOpen={formModal}
                toggle={() => setformModal(false)}
            >
                <div className="modal-body p-0">
                    <Card className="bg-secondary border-0 mb-0">
                        <CardHeader style={{color: "#DB5A3B"}} className="bg-transparent">
                            Update Target
                        </CardHeader>
                        <CardBody className="px-lg-5 py-lg-5">
                            <Form role="form">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="exampleFormControlSelect1"
                                    >
                                        Region
                                    </label>
                                    <Input
                                        id="exampleFormControlSelect1"
                                        onChange={(e) => setTargetRegion(e.target.value)}
                                        type="select"
                                    >
                                        <option>Select</option>
                                        {region.map((region) => (
                                            <option
                                                selected={
                                                    singleTarget?.regionId === region._id ? true : false
                                                }
                                                value={region._id}
                                            >
                                                {region.name}
                                            </option>
                                        ))}
                                    </Input>
                                </FormGroup>
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="exampleFormControlSelect1"
                                    >
                                        Area
                                    </label>
                                    <Input
                                        id="exampleFormControlSelect1"
                                        onChange={(e) => setTargetArea(e.target.value)}
                                        type="select"
                                    >
                                        <option>Select</option>
                                        {filteredArea.map((area) => (
                                            <option
                                                selected={
                                                    singleTarget?.areaId === area._id ? true : false
                                                }
                                                value={area._id}
                                            >
                                                {area.name}
                                            </option>
                                        ))}
                                    </Input>
                                </FormGroup>
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="exampleFormControlSelect1"
                                    >
                                        Territory
                                    </label>
                                    <Input
                                        id="exampleFormControlSelect1"
                                        onChange={(e) => setTargetTerritory(e.target.value)}
                                        type="select"
                                    >
                                        <option>Select</option>
                                        {filteredTerritory.map((territory) => (
                                            <option
                                                selected={
                                                    singleTarget?.territoryId === territory._id
                                                        ? true
                                                        : false
                                                }
                                                value={territory._id}
                                            >
                                                {territory.name}
                                            </option>
                                        ))}
                                    </Input>
                                </FormGroup>
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="exampleFormControlSelect1"
                                    >
                                        Outlet
                                    </label>
                                    <Input
                                        id="exampleFormControlSelect1"
                                        onChange={(e) => setTargetOutlet(e.target.value)}
                                        type="select"
                                    >
                                        <option>Select</option>
                                        {filteredOutlet.map((outlet) => (
                                            <option
                                                selected={
                                                    singleTarget?.outletId === outlet._id ? true : false
                                                }
                                                value={outlet._id}
                                            >
                                                {outlet.name}
                                            </option>
                                        ))}
                                    </Input>
                                </FormGroup>
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="exampleFormControlSelect1"
                                    >
                                        Target Type
                                    </label>
                                    <Input
                                        id="exampleFormControlSelect1"
                                        onChange={(e) => setTargetType(e.target.value)}
                                        type="select"
                                    >
                                        <option
                                            selected={singleTarget?.targetType === undefined && true}
                                            value=""
                                        >
                                            None
                                        </option>
                                        <option
                                            selected={singleTarget?.targetType === "EAS" && true}
                                            value="EAS"
                                        >
                                            EAS
                                        </option>
                                        <option
                                            selected={
                                                singleTarget?.targetType === "Volume RMC" && true
                                            }
                                            value="Volume RMC"
                                        >
                                            Volume RMC
                                        </option>
                                        <option
                                            selected={
                                                singleTarget?.targetType === "Focused Volume" && true
                                            }
                                            value="Focused Volume"
                                        >
                                            Focused Volume
                                        </option>
                                    </Input>
                                </FormGroup>
                                <FormGroup
                                    style={{
                                        display: targetType === "Focused Volume" ? "block" : "none",
                                    }}
                                >
                                    <label
                                        className="form-control-label"
                                        htmlFor="exampleFormControlSelect1"
                                    >
                                        Brand
                                    </label>
                                    <Input
                                        id="exampleFormControlSelect1"
                                        onChange={(e) => setTargetBrand(e.target.value)}
                                        type="select"
                                    >
                                        <option value="">None</option>
                                        {allBrand.map((brand) => (
                                            <option
                                                selected={
                                                    singleTarget?.brandId === brand._id ? true : false
                                                }
                                                value={brand._id}
                                            >
                                                {brand.name}
                                            </option>
                                        ))}
                                    </Input>
                                </FormGroup>
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="example-date-input"
                                        md="2"
                                    >
                                        Target Month
                                    </label>
                                    <br/>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <DatePicker
                                            clearable
                                            inputFormat="yyyy-MM"
                                            views={["year", "month"]}
                                            minDate={new Date("2022-01-01")}
                                            maxDate={new Date("2022-07-01")}
                                            value={
                                                targetMonth === 0
                                                    ? `${singleTarget?.year}-${
                                                        singleTarget?.month <= 9
                                                            ? "0" + singleTarget?.month
                                                            : singleTarget?.month
                                                    }`
                                                    : `${targetYear}-${
                                                        targetMonth <= 9 ? "0" + targetMonth : targetMonth
                                                    }`
                                            }
                                            onChange={(e) => manageEditTargetMonth(e)}
                                        />
                                    </MuiPickersUtilsProvider>
                                </FormGroup>
                                <FormGroup
                                    className={classnames({
                                        focused: targetNumber,
                                    })}
                                >
                                    <label
                                        className="form-control-label"
                                        htmlFor="exampleFormControlSelect1"
                                    >
                                        Target
                                    </label>
                                    <InputGroup className="input-group-merge input-group-alternative">
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="ni ni-caps-small"/>
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            placeholder="Target"
                                            defaultValue={singleTarget?.target}
                                            type="number"
                                            onChange={(e) => setTargetNumber(e.target.value)}
                                        />
                                    </InputGroup>
                                </FormGroup>

                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="exampleFormControlSelect1"
                                    >
                                        Deployed PCM
                                    </label>
                                    <Input
                                        id="exampleFormControlSelect1"
                                        onChange={(e) => setTargetDeployedPcm(e.target.value)}
                                        type="select"
                                    >
                                        <option
                                            selected={
                                                (singleTarget?.deployedPCM === undefined ||
                                                    singleTarget?.deployedPCM === "") &&
                                                true
                                            }
                                            value=""
                                        >
                                            None
                                        </option>
                                        <option
                                            selected={
                                                singleTarget?.deployedPCM === "Push-Cart" ? true : false
                                            }
                                            value="Push-Cart"
                                        >
                                            Push-Cart
                                        </option>
                                        <option
                                            selected={
                                                singleTarget?.deployedPCM === "Cash-Counter"
                                                    ? true
                                                    : false
                                            }
                                            value="Cash-Counter"
                                        >
                                            Cash-Counter
                                        </option>
                                        <option
                                            selected={
                                                singleTarget?.deployedPCM === "Grocery-Counter"
                                                    ? true
                                                    : false
                                            }
                                            value="Grocery-Counter"
                                        >
                                            Grocery-Counter
                                        </option>
                                        <option
                                            selected={
                                                singleTarget?.deployedPCM === "Street Kiosk"
                                                    ? true
                                                    : false
                                            }
                                            value="Street Kiosk"
                                        >
                                            Street Kiosk
                                        </option>
                                        <option
                                            selected={
                                                singleTarget?.deployedPCM === "New Cash Counter"
                                                    ? true
                                                    : false
                                            }
                                            value="New Cash Counter"
                                        >
                                            New Cash Counter
                                        </option>
                                        <option
                                            selected={
                                                singleTarget?.deployedPCM === "New Push-Cart"
                                                    ? true
                                                    : false
                                            }
                                            value="New Push-Cart"
                                        >
                                            New Push-Cart
                                        </option>
                                    </Input>
                                </FormGroup>
                                <div className="text-center">
                                    <Button
                                        onClick={() => setformModal(false)}
                                        className="my-4"
                                        color="danger"
                                        type="button"
                                    >
                                        Close
                                    </Button>
                                    <Button
                                        onClick={() => updateOneTargetData(singleTarget?._id)}
                                        className="my-4"
                                        color="success"
                                        type="button"
                                    >
                                        Update
                                    </Button>
                                </div>
                            </Form>
                        </CardBody>
                    </Card>
                </div>
            </Modal>
            {/* Add Target */}
            <Modal
                className="modal-dialog-centered"
                size="md"
                isOpen={addTargetModal}
                toggle={() => setTargetModal(false)}
            >
                <div className="modal-body p-0">
                    <Card className="bg-secondary border-0 mb-0">
                        <CardHeader style={{color: "#DB5A3B"}} className="bg-transparent">
                            Add Target
                        </CardHeader>
                        <CardBody className="px-lg-5 py-lg-5">
                            <Form role="form">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="exampleFormControlSelect1"
                                    >
                                        Region
                                    </label>
                                    <Input
                                        id="exampleFormControlSelect1"
                                        onChange={(e) => setNewTargetRegion(e.target.value)}
                                        type="select"
                                    >
                                        <option>Select</option>
                                        {region.map((region) => (
                                            <option value={region._id}>{region.name}</option>
                                        ))}
                                    </Input>
                                </FormGroup>
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="exampleFormControlSelect1"
                                    >
                                        Area
                                    </label>
                                    <Input
                                        id="exampleFormControlSelect1"
                                        onChange={(e) => setNewTargetArea(e.target.value)}
                                        type="select"
                                    >
                                        <option>Select</option>
                                        {newfilteredArea.map((area) => (
                                            <option value={area._id}>{area.name}</option>
                                        ))}
                                    </Input>
                                </FormGroup>
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="exampleFormControlSelect1"
                                    >
                                        Territory
                                    </label>
                                    <Input
                                        id="exampleFormControlSelect1"
                                        onChange={(e) => setNewTargetTerritory(e.target.value)}
                                        type="select"
                                    >
                                        <option>Select</option>
                                        {newfilteredTerritory.map((territory) => (
                                            <option value={territory._id}>{territory.name}</option>
                                        ))}
                                    </Input>
                                </FormGroup>
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="exampleFormControlSelect1"
                                    >
                                        Outlet
                                    </label>
                                    <Input
                                        id="exampleFormControlSelect1"
                                        onChange={(e) => setNewTargetOutlet(e.target.value)}
                                        type="select"
                                    >
                                        <option>Select</option>
                                        {newfilteredOutlet.map((outlet) => (
                                            <option
                                                value={outlet._id}
                                            >{`${outlet.name} (${outlet.code})`}</option>
                                        ))}
                                    </Input>
                                </FormGroup>
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="exampleFormControlSelect1"
                                    >
                                        Target Type
                                    </label>
                                    <Input
                                        id="exampleFormControlSelect1"
                                        onChange={(e) => setNewTargetType(e.target.value)}
                                        type="select"
                                    >
                                        <option value="">None</option>
                                        <option value="EAS">EAS</option>
                                        <option value="Volume RMC">Volume RMC</option>
                                        <option value="Focused Volume">Focused Volume</option>
                                    </Input>
                                </FormGroup>
                                <FormGroup
                                    style={{
                                        display:
                                            newTargetType === "Focused Volume" ? "block" : "none",
                                    }}
                                >
                                    <label
                                        className="form-control-label"
                                        htmlFor="exampleFormControlSelect1"
                                    >
                                        Brand
                                    </label>
                                    <Input
                                        id="exampleFormControlSelect1"
                                        onChange={(e) => setNewTargetBrand(e.target.value)}
                                        type="select"
                                    >
                                        <option value="">None</option>
                                        {allBrand.map((brand) => (
                                            <option value={brand._id}>{brand.name}</option>
                                        ))}
                                    </Input>
                                </FormGroup>
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="example-date-input"
                                        md="2"
                                    >
                                        Target Month
                                    </label>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <DatePicker
                                            clearable
                                            inputFormat="yyyy-MM"
                                            views={["year", "month"]}
                                            minDate={new Date("2022-01-01")}
                                            maxDate={new Date("2022-07-01")}
                                            value={
                                                newTargetMonth === 0
                                                    ? currentMonth
                                                    : `${newTargetYear}-${
                                                        newTargetMonth <= 9
                                                            ? "0" + newTargetMonth
                                                            : newTargetMonth
                                                    }`
                                            }
                                            onChange={(e) => manageNewTargetMonth(e)}
                                        />
                                    </MuiPickersUtilsProvider>
                                </FormGroup>
                                <FormGroup
                                    className={classnames({
                                        focused: newTargetNumber,
                                    })}
                                >
                                    <label
                                        className="form-control-label"
                                        htmlFor="exampleFormControlSelect1"
                                    >
                                        Target
                                    </label>
                                    <InputGroup className="input-group-merge input-group-alternative">
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="ni ni-caps-small"/>
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            placeholder="Target"
                                            type="number"
                                            onChange={(e) => setNewTargetNumber(e.target.value)}
                                        />
                                    </InputGroup>
                                </FormGroup>

                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="exampleFormControlSelect1"
                                    >
                                        Deployed PCM
                                    </label>
                                    <Input
                                        id="exampleFormControlSelect1"
                                        onChange={(e) => setNewTargetDeployedPcm(e.target.value)}
                                        type="select"
                                    >
                                        <option value="">None</option>
                                        <option value="Push-Cart">Push-Cart</option>
                                        <option value="Cash-Counter">Cash-Counter</option>
                                        <option value="Grocery-Counter">Grocery-Counter</option>
                                        <option value="Street Kiosk">Street Kiosk</option>
                                        <option value="New Cash Counter">New Cash Counter</option>
                                        <option value="New Push-Cart">New Push-Cart</option>
                                    </Input>
                                </FormGroup>
                                <div className="text-center">
                                    <Button
                                        onClick={() => setTargetModal(false)}
                                        className="my-4"
                                        color="danger"
                                        type="button"
                                    >
                                        Close
                                    </Button>
                                    <Button
                                        onClick={submitNewTarget}
                                        className="my-4"
                                        color="success"
                                        type="button"
                                    >
                                        Add
                                    </Button>
                                </div>
                            </Form>
                        </CardBody>
                    </Card>
                </div>
            </Modal>
        </div>
    );
};

export default AddTarget;
