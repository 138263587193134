import React, {useContext, useEffect} from "react";
import ReactToPrint from "react-to-print";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {Search} from "react-bootstrap-table2-toolkit";
import {
    Button,
    Card,
    CardHeader,
    Container,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroup,
    Row,
    Col,
    CardTitle,
    ButtonGroup,
    UncontrolledTooltip,
} from "reactstrap";
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import moment from "moment";

import axios from "axios";
import NotificationAlert from "react-notification-alert";
import DataContext from "context/DataContext";
import baseApi1 from "../api/BaseApi1";
import {baseURL1} from "../api/baseURL.json";

const {SearchBar} = Search;
const TmsActivity = () => {
    const {region, area, territory, salesPoint, tms} = useContext(DataContext);
    const userInfo = JSON.parse(localStorage.getItem("user"));
    const [showPerPage, setShowPerPage] = React.useState(null);
    //Filters
    const [filterStartDate, setFilterStartDate] = React.useState("");
    const [filterEndDate, setFilterEndDate] = React.useState("");
    const [filterRegion, setFilterRegion] = React.useState("");
    const [filterArea, setFilterArea] = React.useState("");
    const [filterTerritory, setFilterTerritory] = React.useState("");
    const [filterSalesPoint, setFilterSalesPoint] = React.useState("");
    const [selectedTms, setSelectedTms] = React.useState("");
    //All Activity
    const [heading, setHeading] = React.useState([]);
    const [allActivity, setAllActivity] = React.useState([]);

    //Notification Alert
    const notificationAlertRef = React.useRef(null);
    const notify = (type, message) => {
        let options = {
            place: "tc",
            message: (
                <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
              Dashboard Notification
          </span>
                    <span data-notify="message">{message}</span>
                </div>
            ),
            type: type,
            icon: "ni ni-bell-55",
            autoDismiss: 7,
        };
        notificationAlertRef.current.notificationAlert(options);
    };

    //DropdownFilter
    const newfilteredArea = area.filter(
        (item) =>
            item.regionId ===
            (userInfo.region === undefined ? filterRegion : userInfo.region._id)
    );
    const newfilteredTerritory = territory.filter(
        (item) =>
            item.areaId ===
            (userInfo.area === undefined ? filterArea : userInfo.area._id)
    );
    const newfilteredSalesPoint = salesPoint.filter(
        (item) =>
            item.territory._id ===
            (filterTerritory === "" ? userInfo?.territory?._id : filterTerritory)
    );
    const newfilteredTms = tms.filter(
        (item) =>
            item.territoryId ===
            (filterTerritory === "" ? userInfo?.territory?._id : filterTerritory)
    );
    const componentRef = React.useRef(null);
    const getActivityData = async () => {
        try {
            const response = await baseApi1.get(
                `/api/tms/report?startDate=${filterStartDate}&endDate=${filterEndDate}&region=${
                    userInfo.region === undefined ? filterRegion : userInfo.region._id
                }&area=${
                    userInfo.area === undefined ? filterArea : userInfo.area._id
                }&territory=${
                    userInfo.territory === undefined
                        ? filterTerritory
                        : userInfo.territory._id
                }&tms=${selectedTms}`
            );
            if (!response.statusText === "OK") {
                console.log("Not Getting Response");
            } else {
                if (response.data.length) {
                    const dateHeadings = Object.keys(response.data[0] ?? {})
                        .filter((field) => field.includes("/"))
                        .map((field) => ({
                            dataField: field,
                            text: field,
                            sort: true,
                        }));

                    setHeading(dateHeadings);
                }
                setAllActivity(response.data);
            }
        } catch (error) {
            console.log(error.response);
        }
    };

    const pagination = paginationFactory({
        page: 1,
        alwaysShowAllBtns: true,
        showTotal: true,
        withFirstAndLast: false,
        sizePerPageRenderer: ({
                                  options,
                                  currSizePerPage,
                                  onSizePerPageChange,
                              }) => (
            <div className="dataTables_length" id="datatable-basic_length">
                <label>
                    Show{" "}
                    {
                        <select
                            name="datatable-basic_length"
                            aria-controls="datatable-basic"
                            className="form-control form-control-sm"
                            onChange={(e) => onSizePerPageChange(e.target.value)}
                        >
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="all">All</option>
                        </select>
                    }{" "}
                    entries.
                </label>
            </div>
        ),
    });

    const handleViewData = () => {
        getActivityData();
    };
    return (
        <div>
            <div className="rna-wrapper">
                <NotificationAlert ref={notificationAlertRef}/>
            </div>
            <SimpleHeader name="Activity List" parentName="TMS Activity"/>
            <Container className="mt--6" fluid>
                <Row>
                    <div className="col">
                        <Card>
                            <CardHeader className="d-flex justify-content-between">
                                <h3 style={{color: "#DB5A3B"}}>Filters</h3>
                            </CardHeader>
                            <CardBody className="px-lg-5 py-lg-5">
                                <Form role="form">
                                    <Row className="row-example">
                                        <Col xs={4} sm={4}>
                                            <FormGroup>
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="example-date-input"
                                                    md="2"
                                                >
                                                    Start Date
                                                </label>
                                                <Input
                                                    id="example-date-input"
                                                    defaultValue={filterStartDate}
                                                    type="date"
                                                    onChange={(e) =>
                                                        setFilterStartDate(
                                                            moment(new Date(e.target.value)).format(
                                                                "yyyy-MM-DD"
                                                            )
                                                        )
                                                    }
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col xs={4} sm={4}>
                                            <FormGroup>
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="example-date-input"
                                                    md="2"
                                                >
                                                    End Date
                                                </label>

                                                <Input
                                                    id="example-date-input"
                                                    defaultValue={filterEndDate}
                                                    type="date"
                                                    onChange={(e) =>
                                                        setFilterEndDate(
                                                            moment(new Date(e.target.value)).format(
                                                                "yyyy-MM-DD"
                                                            )
                                                        )
                                                    }
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col xs={4} sm={4}>
                                            <FormGroup>
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="exampleFormControlSelect1"
                                                >
                                                    Region
                                                </label>
                                                {userInfo.region === undefined ? (
                                                    <Input
                                                        id="exampleFormControlSelect1"
                                                        onChange={(e) => setFilterRegion(e.target.value)}
                                                        type="select"
                                                    >
                                                        <option>Select</option>
                                                        {region.map((region) => (
                                                            <option value={region._id}>{region.name}</option>
                                                        ))}
                                                    </Input>
                                                ) : (
                                                    <InputGroup className="input-group-merge input-group-alternative">
                                                        <Input
                                                            type="name"
                                                            value={userInfo.region.name}
                                                            disabled
                                                        />
                                                    </InputGroup>
                                                )}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row className="row-example">
                                        <Col xs={3} sm={3}>
                                            <FormGroup>
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="exampleFormControlSelect1"
                                                >
                                                    Area
                                                </label>
                                                {userInfo.area === undefined ? (
                                                    <Input
                                                        id="exampleFormControlSelect1"
                                                        onChange={(e) => setFilterArea(e.target.value)}
                                                        type="select"
                                                    >
                                                        <option>Select</option>
                                                        {newfilteredArea.map((area) => (
                                                            <option value={area._id}>{area.name}</option>
                                                        ))}
                                                    </Input>
                                                ) : (
                                                    <InputGroup className="input-group-merge input-group-alternative">
                                                        <Input
                                                            type="name"
                                                            value={userInfo.area.name}
                                                            disabled
                                                        />
                                                    </InputGroup>
                                                )}
                                            </FormGroup>
                                        </Col>
                                        <Col xs={3} sm={3}>
                                            <FormGroup>
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="exampleFormControlSelect1"
                                                >
                                                    Territory
                                                </label>
                                                {userInfo.territory === undefined ? (
                                                    <Input
                                                        id="exampleFormControlSelect1"
                                                        onChange={(e) => setFilterTerritory(e.target.value)}
                                                        type="select"
                                                    >
                                                        <option>Select</option>
                                                        {newfilteredTerritory.map((territory) => (
                                                            <option value={territory._id}>
                                                                {territory.name}
                                                            </option>
                                                        ))}
                                                    </Input>
                                                ) : (
                                                    <InputGroup className="input-group-merge input-group-alternative">
                                                        <Input
                                                            type="name"
                                                            value={userInfo.territory.name}
                                                            disabled
                                                        />
                                                    </InputGroup>
                                                )}
                                            </FormGroup>
                                        </Col>
                                        <Col xs={3} sm={3}>
                                            <FormGroup>
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="exampleFormControlSelect1"
                                                >
                                                    Sales Point
                                                </label>
                                                <Input
                                                    id="exampleFormControlSelect1"
                                                    onChange={(e) => setFilterSalesPoint(e.target.value)}
                                                    type="select"
                                                >
                                                    <option>Select</option>
                                                    {newfilteredSalesPoint.map((territory) => (
                                                        <option value={territory._id}>
                                                            {territory.name}
                                                        </option>
                                                    ))}
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                        <Col xs={3} sm={3}>
                                            <FormGroup>
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="exampleFormControlSelect1"
                                                >
                                                    TMS
                                                </label>
                                                <Input
                                                    id="exampleFormControlSelect1"
                                                    onChange={(e) => setSelectedTms(e.target.value)}
                                                    type="select"
                                                >
                                                    <option>Select</option>
                                                    {newfilteredTms?.map((tms) => (
                                                        <option value={tms._id}>
                                                            {`${tms.name} (${tms.phone})`}
                                                        </option>
                                                    ))}
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <div className="text-right">
                                        <Button
                                            onClick={handleViewData}
                                            color="warning"
                                            type="button"
                                        >
                                            View
                                        </Button>
                                    </div>
                                </Form>
                            </CardBody>
                        </Card>
                    </div>
                </Row>
                <Row>
                    <div className="col">
                        <Card>
                            <CardHeader className="d-flex justify-content-between">
                                <h3 style={{color: "#DB5A3B"}}>TMS Activity</h3>
                            </CardHeader>
                            <div className="m-3" style={{width: "18rem"}}>
                                <Card className="card-stats bg-gradient-warning mb-4 mb-lg-0">
                                    <CardBody>
                                        <Row>
                                            <div className="col">
                                                <CardTitle className="text-uppercase text-white text-muted mb-0">
                                                    Total TMS
                                                </CardTitle>
                                                <span className="h2 font-weight-bold mb-0 text-white">
                          {allActivity.length}
                        </span>
                                            </div>
                                            <Col className="col-auto">
                                                <div
                                                    className="icon icon-shape bg-danger text-white rounded-circle shadow">
                                                    <i className="fas fa-chart-bar"/>
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </div>
                            <ToolkitProvider
                                data={allActivity}
                                keyField="name"
                                columns={[
                                    {
                                        dataField: "#",
                                        text: "SL",
                                        formatter: (cell, row, rowIndex) => {
                                            return rowIndex + 1;
                                        },
                                    },
                                    {
                                        dataField: "region",
                                        text: "Region",
                                        sort: true,
                                    },
                                    {
                                        dataField: "area",
                                        text: "Area",
                                        sort: true,
                                    },
                                    {
                                        dataField: "territory",
                                        text: "Territory",
                                        sort: true,
                                    },
                                    {
                                        dataField: "name",
                                        text: "TMS Name",
                                        sort: true,
                                    },
                                    {
                                        dataField: "phone",
                                        text: "TMS Phone",
                                        sort: true,
                                    },
                                    {
                                        dataField: "enrollId",
                                        text: "TMS EnrollID",
                                        sort: true,
                                    },
                                    {
                                        dataField: "workingDays",
                                        text: "Working Days",
                                        sort: true,
                                    },
                                    {
                                        dataField: "presentDays",
                                        text: "Present Date",
                                        sort: true,
                                    },
                                    {
                                        dataField: "presentPercent",
                                        text: "Present Percentage",
                                        sort: true,
                                        formatter: (cell) => {
                                            return cell + "%";
                                        },
                                    },
                                    ...heading,
                                ]}
                                search
                            >
                                {(props) => (
                                    <div className="py-4 table-responsive">
                                        <Container fluid>
                                            <Row>
                                                <Col xs={4} sm={4}>
                                                    <div>
                                                        <select
                                                            name="datatable-basic_length"
                                                            aria-controls="datatable-basic"
                                                            className="form-control form-control-sm w-25"
                                                            onChange={(e) => setShowPerPage(e.target.value)}
                                                        >
                                                            <option
                                                                selected={showPerPage === 10 && true}
                                                                value={10}
                                                            >
                                                                10
                                                            </option>
                                                            <option
                                                                selected={showPerPage === 25 && true}
                                                                value={25}
                                                            >
                                                                25
                                                            </option>
                                                            <option
                                                                selected={showPerPage === 50 && true}
                                                                value={50}
                                                            >
                                                                50
                                                            </option>
                                                            <option
                                                                selected={showPerPage === 100 && true}
                                                                value={100}
                                                            >
                                                                100
                                                            </option>
                                                            <option
                                                                selected={showPerPage === "all" && true}
                                                                value="all"
                                                            >
                                                                all
                                                            </option>
                                                        </select>
                                                    </div>
                                                </Col>
                                                <Col xs={4} sm={4}>
                                                    <ButtonGroup>
                                                        <Button
                                                            className="buttons-copy buttons-html5"
                                                            color="warning"
                                                            size="sm"
                                                            id="copy-tooltip"
                                                            onClick={() =>
                                                                window.open(
                                                                    `http://43.231.78.202/api/tms/report/csv?startDate=${filterStartDate}&endDate=${filterEndDate}&region=${
                                                                        userInfo.region === undefined
                                                                            ? filterRegion
                                                                            : userInfo.region._id
                                                                    }&area=${
                                                                        userInfo.area === undefined
                                                                            ? filterArea
                                                                            : userInfo.area._id
                                                                    }&territory=${
                                                                        userInfo.territory === undefined
                                                                            ? filterTerritory
                                                                            : userInfo.territory._id
                                                                    }&tms=${selectedTms}`
                                                                )
                                                            }
                                                        >
                                                            <span>CSV</span>
                                                        </Button>
                                                        <ReactToPrint
                                                            trigger={() => (
                                                                <Button
                                                                    color="warning"
                                                                    size="sm"
                                                                    className="buttons-copy buttons-html5"
                                                                    id="print-tooltip"
                                                                >
                                                                    Print
                                                                </Button>
                                                            )}
                                                            content={() => componentRef.current}
                                                        />
                                                    </ButtonGroup>
                                                    <UncontrolledTooltip
                                                        placement="top"
                                                        target="print-tooltip"
                                                    >
                                                        This will open a print page with the visible rows of
                                                        the table.
                                                    </UncontrolledTooltip>
                                                    <UncontrolledTooltip
                                                        placement="top"
                                                        target="copy-tooltip"
                                                    >
                                                        This will Download Outlet Setting As CSV.
                                                    </UncontrolledTooltip>
                                                </Col>
                                                <Col xs={4} sm={4}>
                                                    <div
                                                        id="datatable-basic_filter"
                                                        className="dataTables_filter px-4 pb-1 float-right"
                                                    >
                                                        <label>
                                                            Search:
                                                            <SearchBar
                                                                className="form-control-sm"
                                                                placeholder=""
                                                                {...props.searchProps}
                                                            />
                                                        </label>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Container>
                                        {/* {[].length > 0 ? ( */}
                                        <BootstrapTable
                                            ref={componentRef}
                                            {...props.baseProps}
                                            bootstrap4={true}
                                            pagination={pagination}
                                            bordered={false}
                                            id="react-bs-table"
                                        />
                                        {/* ) : (
                      <div className="d-flex justify-content-center">
                        <ReactLoading
                          className=""
                          type="bubbles"
                          color="#DB5A3B"
                          height={200}
                          width={100}
                        />
                      </div>
                    )} */}
                                    </div>
                                )}
                            </ToolkitProvider>
                        </Card>
                    </div>
                </Row>
            </Container>
        </div>
    );
};

export default TmsActivity;
