import React, {useEffect} from "react";
// react plugin that prints a given react component
import ReactToPrint from "react-to-print";
import classnames from "classnames";
// react component for creating dynamic tables
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {Search} from "react-bootstrap-table2-toolkit";
import moment from "moment";

// reactstrap components11
import {
    Button,
    ButtonGroup,
    Card,
    CardHeader,
    Container,
    CardBody,
    FormGroup,
    Form,
    Modal,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Row,
    Col,
    UncontrolledTooltip,
    CardTitle,
} from "reactstrap";
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";

import axios from "axios";
import NotificationAlert from "react-notification-alert";
import CurrencyFormat from "react-currency-format";
import baseApi2 from "../api/BaseApi2";
import {baseURL2} from "../api/baseURL.json";

const {SearchBar} = Search;

const ComplainUser = () => {
    const [showPerPage, setShowPerPage] = React.useState(null);
    const userInfo = JSON.parse(localStorage.getItem("user"));
    //   console.log(userInfo);
    //Add Complain
    const [newComplainTitle, setNewComplainTitle] = React.useState("");
    const [newComplainType, setNewComplainType] = React.useState("");
    const [newComplainOutlet, setNewComplainOutlet] = React.useState("");
    const [newComplainDetails, setNewComplainDetails] = React.useState("");
    //Others
    const [addComplainModal, setComplainModal] = React.useState(false);
    const [responseModal, setResponseModal] = React.useState(false);
    const [allComplainList, setAllComplainList] = React.useState([]);
    const [singleComplain, setSingleComplain] = React.useState({});
    const [pendingCom, setPendingCom] = React.useState(0);
    const [solvedCom, setSolvedCom] = React.useState(0);

    //Notification Alert
    const notificationAlertRef = React.useRef(null);
    const notify = (type, message) => {
        let options = {
            place: "bc",
            message: (
                <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
              Complain Notification
          </span>
                    <span data-notify="message">{message}</span>
                </div>
            ),
            type: type,
            icon: "ni ni-bell-55",
            autoDismiss: 7,
        };
        notificationAlertRef.current.notificationAlert(options);
    };

    const componentRef = React.useRef(null);
    // this function will copy to clipboard an entire table,
    // so you can paste it inside an excel or csv file
    const getComplainData = async () => {
        try {
            const response = await axios.get(
                `${baseURL2}:5000/api/complain/${userInfo.email}`
            );
            if (!response.statusText === "OK") {
                console.log("Not Getting Response");
            } else {
                setAllComplainList(response.data.data);
                console.log(response.data.data);
            }
        } catch (error) {
            console.log(error.response);
        }
    };
    const singleResponseView = (id) => {
        const singleData = allComplainList.find((x) => x._id === id);
        setSingleComplain(singleData);
    };

    useEffect(() => {
        getComplainData();
        const pendingComplain = allComplainList.filter(
            (x) => x.status === "pending"
        );
        const solvedComplain = allComplainList.filter((x) => x.status === "solved");
        setPendingCom(pendingComplain.length);
        setSolvedCom(solvedComplain.length);
    }, [allComplainList]);

    const pagination = paginationFactory({
        page: 1,
        alwaysShowAllBtns: true,
        showTotal: true,
        withFirstAndLast: false,
        sizePerPageRenderer: ({
                                  options,
                                  currSizePerPage,
                                  onSizePerPageChange,
                              }) => (
            <div className="dataTables_length" id="datatable-basic_length">
                <label>
                    Show{" "}
                    {
                        <select
                            name="datatable-basic_length"
                            aria-controls="datatable-basic"
                            className="form-control form-control-sm"
                            onChange={(e) => onSizePerPageChange(e.target.value)}
                        >
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="all">All</option>
                        </select>
                    }{" "}
                    entries.
                </label>
            </div>
        ),
    });

    const addNewComplain = async () => {
        const newComplain = {
            title: newComplainTitle,
            type: newComplainType,
            outlet: newComplainOutlet,
            details: newComplainDetails,
            email: userInfo.email,
            userInfo: userInfo,
            status: "pending",
            date: new Date(),
        };
        try {
            const response = await axios.post(
                `${baseURL2}:5000/api/addComplain`,
                newComplain,
                {headers: {"Content-Type": "application/json"}}
            );
            if (!response.statusText === "OK") {
                console.log("Not Getting Response");
                notify("danger", "Some Thing Went Wrong");
            } else {
                console.log(response.data);
                getComplainData();
                response.status === 200
                    ? setComplainModal(false)
                    : setComplainModal(true);
                notify("success", response.data.message);
            }
        } catch (error) {
            console.log(error.response);
        }
    };
    const submitNewComplain = () => {
        addNewComplain();
    };
    return (
        <div>
            <div className="rna-wrapper">
                <NotificationAlert ref={notificationAlertRef}/>
            </div>
            <SimpleHeader name="Complain List" parentName="Complain"/>
            <Container className="mt--6" fluid>
                <Row>
                    <div className="col">
                        <Card>
                            <CardHeader className="d-flex justify-content-between">
                                <h3 style={{color: "#DB5A3B"}}>Complain List</h3>
                                <Button
                                    onClick={() => setComplainModal(true)}
                                    style={{backgroundColor: "#DB5A3B", color: "white"}}
                                >
                                    Add New
                                </Button>
                            </CardHeader>
                            <Row>
                                <Col xs={4} sm={4}>
                                    <div className="m-3" style={{width: "15rem"}}>
                                        <Card className="card-stats bg-gradient-primary mb-4 mb-lg-0">
                                            <CardBody>
                                                <Row>
                                                    <div className="col">
                                                        <CardTitle
                                                            className="text-uppercase text-white text-muted mb-0">
                                                            Total Complain
                                                        </CardTitle>
                                                        <span className="h2 font-weight-bold mb-0 text-white">
                              <CurrencyFormat
                                  value={allComplainList?.length}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  prefix={""}
                              />
                            </span>
                                                    </div>
                                                    <Col className="col-auto">
                                                        <div
                                                            className="icon icon-shape bg-danger text-white rounded-circle shadow">
                                                            <i className="fas fa-chart-bar"/>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </div>
                                </Col>
                                <Col xs={4} sm={4}>
                                    <div className="m-3" style={{width: "15rem"}}>
                                        <Card className="card-stats bg-gradient-info mb-4 mb-lg-0">
                                            <CardBody>
                                                <Row>
                                                    <div className="col">
                                                        <CardTitle
                                                            className="text-uppercase text-white text-muted mb-0">
                                                            Pending Complain
                                                        </CardTitle>
                                                        <span className="h2 font-weight-bold mb-0 text-white">
                              <CurrencyFormat
                                  value={pendingCom}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  prefix={""}
                              />
                            </span>
                                                    </div>
                                                    <Col className="col-auto">
                                                        <div
                                                            className="icon icon-shape bg-danger text-white rounded-circle shadow">
                                                            <i className="fas fa-chart-bar"/>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </div>
                                </Col>
                                <Col xs={4} sm={4}>
                                    <div className="m-3" style={{width: "15rem"}}>
                                        <Card className="card-stats bg-gradient-success mb-4 mb-lg-0">
                                            <CardBody>
                                                <Row>
                                                    <div className="col">
                                                        <CardTitle
                                                            className="text-uppercase text-white text-muted mb-0">
                                                            Solved Complain
                                                        </CardTitle>
                                                        <span className="h2 font-weight-bold mb-0 text-white">
                              <CurrencyFormat
                                  value={solvedCom}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  prefix={""}
                              />
                            </span>
                                                    </div>
                                                    <Col className="col-auto">
                                                        <div
                                                            className="icon icon-shape bg-danger text-white rounded-circle shadow">
                                                            <i className="fas fa-chart-bar"/>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </div>
                                </Col>
                            </Row>
                            <ToolkitProvider
                                data={allComplainList}
                                keyField="name"
                                columns={[
                                    {
                                        dataField: "#",
                                        text: "SL",
                                        formatter: (cell, row, rowIndex) => {
                                            return rowIndex + 1;
                                        },
                                    },
                                    {
                                        dataField: "userInfo.region.name",
                                        text: "Region",
                                        sort: true,
                                    },
                                    {
                                        dataField: "userInfo.area.name",
                                        text: "Area",
                                        sort: true,
                                    },
                                    {
                                        dataField: "userInfo.territory.name",
                                        text: "Territory",
                                        sort: true,
                                    },
                                    {
                                        dataField: "userInfo.email",
                                        text: "Email",
                                        sort: true,
                                    },
                                    {
                                        dataField: "title",
                                        text: "Title",
                                        sort: true,
                                    },
                                    {
                                        dataField: "type",
                                        text: "Type",
                                        sort: true,
                                    },
                                    {
                                        dataField: "outlet",
                                        text: "outlet",
                                        sort: true,
                                    },
                                    {
                                        dataField: "status",
                                        text: "Status",
                                        sort: true,
                                        formatter: (cell) => {
                                            return (
                                                <span
                                                    style={{
                                                        color: cell === "pending" ? "red" : "green",
                                                    }}
                                                >
                          {cell}
                        </span>
                                            );
                                        },
                                    },
                                    {
                                        dataField: "_id",
                                        text: "Action",
                                        formatter: (cell, row) => {
                                            const handleReply = () => {
                                                singleResponseView(cell);
                                                setResponseModal(true);
                                            };

                                            const action = (
                                                <div>
                                                    <Button
                                                        style={{
                                                            display:
                                                                row.status === "solved" ? "block" : "none",
                                                        }}
                                                        onClick={handleReply}
                                                        color="warning"
                                                    >
                                                        View Replay
                                                    </Button>
                                                </div>
                                            );
                                            return action;
                                        },
                                    },
                                ]}
                                search
                            >
                                {(props) => (
                                    <div className="py-4 table-responsive">
                                        <Container fluid>
                                            <Row>
                                                <Col xs={4} sm={4}>
                                                    <div>
                                                        <select
                                                            name="datatable-basic_length"
                                                            aria-controls="datatable-basic"
                                                            className="form-control form-control-sm w-25"
                                                            onChange={(e) => setShowPerPage(e.target.value)}
                                                        >
                                                            <option
                                                                selected={showPerPage === 10 && true}
                                                                value={10}
                                                            >
                                                                10
                                                            </option>
                                                            <option
                                                                selected={showPerPage === 25 && true}
                                                                value={25}
                                                            >
                                                                25
                                                            </option>
                                                            <option
                                                                selected={showPerPage === 50 && true}
                                                                value={50}
                                                            >
                                                                50
                                                            </option>
                                                            <option
                                                                selected={showPerPage === 100 && true}
                                                                value={100}
                                                            >
                                                                100
                                                            </option>
                                                            <option
                                                                selected={showPerPage === "all" && true}
                                                                value="all"
                                                            >
                                                                all
                                                            </option>
                                                        </select>
                                                    </div>
                                                </Col>
                                                <Col xs={4} sm={4}>
                                                    <ButtonGroup>
                                                        <ReactToPrint
                                                            trigger={() => (
                                                                <Button
                                                                    color="warning"
                                                                    size="sm"
                                                                    className="buttons-copy buttons-html5"
                                                                    id="print-tooltip"
                                                                >
                                                                    Print
                                                                </Button>
                                                            )}
                                                            content={() => componentRef.current}
                                                        />
                                                    </ButtonGroup>
                                                    <UncontrolledTooltip
                                                        placement="top"
                                                        target="print-tooltip"
                                                    >
                                                        This will open a print page with the visible rows of
                                                        the table.
                                                    </UncontrolledTooltip>
                                                </Col>
                                                <Col xs={4} sm={4}>
                                                    <div
                                                        id="datatable-basic_filter"
                                                        className="dataTables_filter px-4 pb-1 float-right"
                                                    >
                                                        <label>
                                                            Search:
                                                            <SearchBar
                                                                className="form-control-sm"
                                                                placeholder=""
                                                                {...props.searchProps}
                                                            />
                                                        </label>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Container>

                                        <BootstrapTable
                                            ref={componentRef}
                                            {...props.baseProps}
                                            bootstrap4={true}
                                            pagination={pagination}
                                            bordered={false}
                                            id="react-bs-table"
                                        />
                                    </div>
                                )}
                            </ToolkitProvider>
                        </Card>
                    </div>
                </Row>
            </Container>
            {/* Add Complain */}
            <Modal
                className="modal-dialog-centered"
                size="md"
                isOpen={addComplainModal}
                toggle={() => setComplainModal(false)}
            >
                <div className="modal-body p-0">
                    <Card className="bg-secondary border-0 mb-0">
                        <CardHeader style={{color: "#DB5A3B"}} className="bg-transparent">
                            Add Complain
                        </CardHeader>
                        <CardBody className="px-lg-5 py-lg-5">
                            <Form role="form">
                                <FormGroup
                                    className={classnames("mb-3", {
                                        focused: newComplainTitle,
                                    })}
                                >
                                    <label
                                        className="form-control-label"
                                        htmlFor="exampleFormControlSelect1"
                                    >
                                        Complain Title
                                    </label>
                                    <InputGroup className="input-group-merge input-group-alternative">
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="ni ni-caps-small"/>
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            placeholder="Complain Title"
                                            type="text"
                                            onChange={(e) => setNewComplainTitle(e.target.value)}
                                        />
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="exampleFormControlSelect1"
                                    >
                                        Complain Type
                                    </label>
                                    <Input
                                        id="exampleFormControlSelect1"
                                        onChange={(e) => setNewComplainType(e.target.value)}
                                        type="select"
                                    >
                                        <option>Select</option>
                                        <option value="PCM Warning Claim">PCM Warning Claim</option>
                                        <option value="KB Request">KB Request</option>
                                        <option value="Blank Request">Blank Request</option>
                                        <option value="Outlet Code Change">
                                            Outlet Code Change
                                        </option>
                                        <option value="TMS Issue">TMS Issue</option>
                                        <option value="Others">Others</option>
                                    </Input>
                                </FormGroup>
                                <FormGroup
                                    className={classnames("mb-3", {
                                        focused: newComplainOutlet,
                                    })}
                                >
                                    <label
                                        className="form-control-label"
                                        htmlFor="exampleFormControlSelect1"
                                    >
                                        Complain Outlet Code
                                    </label>
                                    <InputGroup className="input-group-merge input-group-alternative">
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="ni ni-caps-small"/>
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            placeholder="Complain Outlet"
                                            type="text"
                                            onChange={(e) => setNewComplainOutlet(e.target.value)}
                                        />
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="exampleFormControlTextarea1"
                                    >
                                        Complain Details
                                    </label>
                                    <Input
                                        id="exampleFormControlTextarea1"
                                        rows="3"
                                        type="textarea"
                                        onChange={(e) => setNewComplainDetails(e.target.value)}
                                    />
                                </FormGroup>
                                <div className="text-center">
                                    <Button
                                        onClick={() => setComplainModal(false)}
                                        className="my-4"
                                        color="danger"
                                        type="button"
                                    >
                                        Close
                                    </Button>
                                    <Button
                                        onClick={submitNewComplain}
                                        className="my-4"
                                        color="success"
                                        type="button"
                                    >
                                        Add
                                    </Button>
                                </div>
                            </Form>
                        </CardBody>
                    </Card>
                </div>
            </Modal>
            {/* View Response */}
            <Modal
                className="modal-dialog-centered"
                size="md"
                isOpen={responseModal}
                toggle={() => setResponseModal(false)}
            >
                <div className="modal-body p-0">
                    <Card className="bg-secondary border-0 mb-0">
                        <CardHeader style={{color: "#DB5A3B"}} className="bg-transparent">
                            Complain Response
                        </CardHeader>
                        <CardBody className="px-lg-5 py-lg-5">
                            <CardTitle>
                                <span style={{color: "#DB5A3B"}}>My Complain</span> {" ("}
                                <span>{singleComplain?.title}</span>
                                {"-"}
                                <span>{singleComplain?.outlet}</span>
                                {"-"}
                                <span>
                  {moment(new Date(singleComplain?.date)).format(
                      "yyyy-MM-dd HH:mm:ss"
                  )}
                </span>
                                {")"}
                            </CardTitle>
                            <h4 className="text-muted">Complain: </h4>
                            <CardTitle>{singleComplain?.details}</CardTitle>
                            <hr/>
                            <CardTitle>
                                <span style={{color: "#DB5A3B"}}>Admin Response</span>
                                {" ("}
                                <span>
                  {moment(new Date(singleComplain?.replyDate)).format(
                      "yyyy-MM-dd HH:mm:ss"
                  )}
                </span>
                                {")"}
                            </CardTitle>
                            <h4 className="text-muted">Instraction: </h4>
                            <span className="text-muted">By {singleComplain?.replyBy}</span>
                            <CardTitle>{singleComplain?.reply}</CardTitle>
                            <div className="text-center">
                                <Button
                                    onClick={() => setResponseModal(false)}
                                    className="my-4"
                                    color="danger"
                                    type="button"
                                >
                                    Close
                                </Button>
                            </div>
                        </CardBody>
                    </Card>
                </div>
            </Modal>
        </div>
    );
};

export default ComplainUser;
