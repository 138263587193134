import React, { useEffect } from "react";
// react plugin that prints a given react component
import ReactToPrint from "react-to-print";
import classnames from "classnames";
// react component for creating dynamic tables
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

// reactstrap components11
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Modal,
  Row,
  UncontrolledTooltip,
} from "reactstrap";
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";

import axios from "axios";
import NotificationAlert from "react-notification-alert";
import CurrencyFormat from "react-currency-format";
import baseApi1 from "../../api/BaseApi1";
import {baseURL1} from "../../api/baseURL.json";

const { SearchBar } = Search;

const RegionSetting = () => {
  const [showPerPage, setShowPerPage] = React.useState(null);
  //Edit Region
  const [regionID, setRegionID] = React.useState("");
  const [regionName, setRegionName] = React.useState("");
  //Add Region
  const [newRegionID, setNewRegionID] = React.useState("");
  const [newRegionName, setNewRegionName] = React.useState("");
  //Others
  const [formModal, setformModal] = React.useState(false);
  const [addRegionModal, setRegionModal] = React.useState(false);
  const [allRegion, setAllRegion] = React.useState([]);
  const [singleRegion, setSingleRegion] = React.useState({});

  //Notification Alert
  const notificationAlertRef = React.useRef(null);
  const notify = (type, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            Region Notification
          </span>
          <span data-notify="message">{message}</span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 7,
    };
    notificationAlertRef.current.notificationAlert(options);
  };

  const componentRef = React.useRef(null);
  // this function will copy to clipboard an entire table,
  // so you can paste it inside an excel or csv file
  const getRegionData = async () => {
    try {
      const response = await baseApi1.get("/api/regions");
      if (!response.statusText === "OK") {
        console.log("Not Getting Response");
      } else {
        setAllRegion(response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getOneRegionData = async (id) => {
    try {
      const response = await baseApi1.get(
        `/api/region/one/${id}`
      );
      if (!response.statusText === "OK") {
        console.log("Not Getting Response");
      } else {
        setSingleRegion(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const deleteOneRegion = async (id) => {
    try {
      const response = await baseApi1.delete(
        `/api/regions/${id}`
      );
      if (!response.statusText === "OK") {
        console.log("Not Getting Response");
        notify("danger", response.data.message);
      } else {
        getRegionData();
        notify("success", response.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const updateOneRegionData = async (id) => {
    const editedRegion = {
      id: regionID === "" ? singleRegion?.id : regionID,
      name: regionName === "" ? singleRegion?.name : regionName,
    };
    try {
      const response = await baseApi1.put(
        `/api/regions/${id}`,
        editedRegion,
        { headers: { "Content-Type": "application/json" } }
      );
      if (!response.statusText === "OK") {
        console.log("Not Getting Response");
        notify("danger", response.data.message);
      } else {
        getRegionData();
        setformModal(false);
        notify("success", response.data.message);
      }
    } catch (error) {
      notify("danger", error.response.data.message);
    }
  };
  useEffect(() => {
    getRegionData();
  }, []);

  const pagination = paginationFactory({
    page: 1,
    alwaysShowAllBtns: true,
    showTotal: true,
    withFirstAndLast: false,
    sizePerPageRenderer: ({
      options,
      currSizePerPage,
      onSizePerPageChange,
    }) => (
      <div className="dataTables_length" id="datatable-basic_length">
        <label>
          Show{" "}
          {
            <select
              name="datatable-basic_length"
              aria-controls="datatable-basic"
              className="form-control form-control-sm"
              onChange={(e) => onSizePerPageChange(e.target.value)}
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
              <option value="all">All</option>
            </select>
          }{" "}
          entries.
        </label>
      </div>
    ),
  });
  const addNewRegion = async () => {
    const newRegion = {
      id: newRegionID,
      name: newRegionName,
    };
    try {
      const response = await baseApi1.post(
        "/api/regions",
        newRegion,
        { headers: { "Content-Type": "application/json" } }
      );
      if (!response.statusText === "OK") {
        console.log("Not Getting Response");
        notify("danger", "Some Thing Went Wrong");
      } else {
        getRegionData();
        response.status === 200 || response.status === 201
          ? setRegionModal(false)
          : setRegionModal(true);
        notify("success", response.data.message);
      }
    } catch (error) {
      notify("danger", error.response.data.message);
    }
  };
  const submitNewTms = () => {
    addNewRegion();
  };
  const handleSingleTmsDelete = (id) => {
    deleteOneRegion(id);
  };
  return (
    <div>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <SimpleHeader name="Region List" parentName="Region" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="d-flex justify-content-between">
                <h3 style={{ color: "#DB5A3B" }}>Region Setting</h3>
                <Button
                  onClick={() => setRegionModal(true)}
                  style={{ backgroundColor: "#DB5A3B", color: "white" }}
                >
                  Add New
                </Button>
              </CardHeader>
              <div className="m-3" style={{ width: "18rem" }}>
                <Card className="card-stats bg-gradient-warning mb-4 mb-lg-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle className="text-uppercase text-white text-muted mb-0">
                          Total Region
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0 text-white">
                          <CurrencyFormat
                            value={allRegion.length}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={""}
                          />
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                          <i className="fas fa-chart-bar" />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </div>
              <ToolkitProvider
                data={allRegion}
                keyField="name"
                columns={[
                  {
                    dataField: "#",
                    text: "SL",
                    formatter: (cell, row, rowIndex) => {
                      return rowIndex + 1;
                    },
                  },
                  {
                    dataField: "id",
                    text: "Region ID",
                    sort: true,
                  },
                  {
                    dataField: "name",
                    text: "Region",
                    sort: true,
                  },
                  {
                    dataField: "_id",
                    text: "Action",
                    formatter: (cell) => {
                      const handleEdit = () => {
                        getOneRegionData(cell);
                        setformModal(true);
                      };
                      return (
                        <div>
                          <Button color="info" onClick={handleEdit}>
                            Edit
                          </Button>
                          <Button
                            onClick={() => handleSingleTmsDelete(cell)}
                            color="danger"
                          >
                            Delete
                          </Button>
                        </div>
                      );
                    },
                  },
                ]}
                search
              >
                {(props) => (
                  <div className="py-4 table-responsive">
                    <Container fluid>
                      <Row>
                        <Col xs={4} sm={4}>
                          <div>
                            <select
                              name="datatable-basic_length"
                              aria-controls="datatable-basic"
                              className="form-control form-control-sm w-25"
                              onChange={(e) => setShowPerPage(e.target.value)}
                            >
                              <option
                                selected={showPerPage === 10 && true}
                                value={10}
                              >
                                10
                              </option>
                              <option
                                selected={showPerPage === 25 && true}
                                value={25}
                              >
                                25
                              </option>
                              <option
                                selected={showPerPage === 50 && true}
                                value={50}
                              >
                                50
                              </option>
                              <option
                                selected={showPerPage === 100 && true}
                                value={100}
                              >
                                100
                              </option>
                              <option
                                selected={showPerPage === "all" && true}
                                value="all"
                              >
                                all
                              </option>
                            </select>
                          </div>
                        </Col>
                        <Col xs={4} sm={4}>
                          <ButtonGroup>
                            <Button
                              className="buttons-copy buttons-html5"
                              color="warning"
                              size="sm"
                              id="copy-tooltip"
                              onClick={() =>
                                window.open(
                                  `${baseURL1}/api/regions/csv`
                                )
                              }
                            >
                              <span>CSV</span>
                            </Button>
                            <ReactToPrint
                              trigger={() => (
                                <Button
                                  color="warning"
                                  size="sm"
                                  className="buttons-copy buttons-html5"
                                  id="print-tooltip"
                                >
                                  Print
                                </Button>
                              )}
                              content={() => componentRef.current}
                            />
                          </ButtonGroup>
                          <UncontrolledTooltip
                            placement="top"
                            target="print-tooltip"
                          >
                            This will open a print page with the visible rows of
                            the table.
                          </UncontrolledTooltip>
                          <UncontrolledTooltip
                            placement="top"
                            target="copy-tooltip"
                          >
                            This will Download Communication Setting As CSV.
                          </UncontrolledTooltip>
                        </Col>
                        <Col xs={4} sm={4}>
                          <div
                            id="datatable-basic_filter"
                            className="dataTables_filter px-4 pb-1 float-right"
                          >
                            <label>
                              Search:
                              <SearchBar
                                className="form-control-sm"
                                placeholder=""
                                {...props.searchProps}
                              />
                            </label>
                          </div>
                        </Col>
                      </Row>
                    </Container>
                    <BootstrapTable
                      ref={componentRef}
                      {...props.baseProps}
                      bootstrap4={true}
                      pagination={pagination}
                      bordered={false}
                      id="react-bs-table"
                    />
                  </div>
                )}
              </ToolkitProvider>
            </Card>
          </div>
        </Row>
      </Container>
      {/* Edit Modal */}
      <Modal
        className="modal-dialog-centered"
        size="md"
        isOpen={formModal}
        toggle={() => setformModal(false)}
      >
        <div className="modal-body p-0">
          <Card className="bg-secondary border-0 mb-0">
            <CardHeader style={{ color: "#DB5A3B" }} className="bg-transparent">
              Update Region
            </CardHeader>
            <CardBody className="px-lg-5 py-lg-5">
              <Form role="form">
                <FormGroup
                  className={classnames("mb-3", {
                    focused: regionID,
                  })}
                >
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlSelect1"
                  >
                    Region ID
                  </label>
                  <InputGroup className="input-group-merge input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Region ID"
                      defaultValue={singleRegion?.id}
                      type="name"
                      onChange={(e) => setRegionID(e.target.value)}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup
                  className={classnames("mb-3", {
                    focused: regionName,
                  })}
                >
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlSelect1"
                  >
                    Region Name
                  </label>
                  <InputGroup className="input-group-merge input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Region Name"
                      defaultValue={singleRegion?.name}
                      type="name"
                      onChange={(e) => setRegionName(e.target.value)}
                    />
                  </InputGroup>
                </FormGroup>
                <div className="text-center">
                  <Button
                    onClick={() => setformModal(false)}
                    className="my-4"
                    color="danger"
                    type="button"
                  >
                    Close
                  </Button>
                  <Button
                    onClick={() => updateOneRegionData(singleRegion?._id)}
                    className="my-4"
                    color="success"
                    type="button"
                  >
                    Update
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </div>
      </Modal>
      {/* Add Region */}
      <Modal
        className="modal-dialog-centered"
        size="md"
        isOpen={addRegionModal}
        toggle={() => setRegionModal(false)}
      >
        <div className="modal-body p-0">
          <Card className="bg-secondary border-0 mb-0">
            <CardHeader style={{ color: "#DB5A3B" }} className="bg-transparent">
              Add Region
            </CardHeader>
            <CardBody className="px-lg-5 py-lg-5">
              <Form role="form">
                <FormGroup
                  className={classnames("mb-3", {
                    focused: newRegionID,
                  })}
                >
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlSelect1"
                  >
                    Region ID
                  </label>
                  <InputGroup className="input-group-merge input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Region ID"
                      type="name"
                      onChange={(e) => setNewRegionID(e.target.value)}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup
                  className={classnames("mb-3", {
                    focused: newRegionName,
                  })}
                >
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlSelect1"
                  >
                    Region Name
                  </label>
                  <InputGroup className="input-group-merge input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Region Name"
                      type="name"
                      onChange={(e) => setNewRegionName(e.target.value)}
                    />
                  </InputGroup>
                </FormGroup>

                <div className="text-center">
                  <Button
                    onClick={() => setRegionModal(false)}
                    className="my-4"
                    color="danger"
                    type="button"
                  >
                    Close
                  </Button>
                  <Button
                    onClick={submitNewTms}
                    className="my-4"
                    color="success"
                    type="button"
                  >
                    Add
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </div>
      </Modal>
    </div>
  );
};

export default RegionSetting;
