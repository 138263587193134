import React, { useContext, useEffect } from "react";
// react plugin that prints a given react component
import ReactToPrint from "react-to-print";
import classnames from "classnames";
// react component for creating dynamic tables
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { FileUploader } from "react-drag-drop-files";
import Select from "react-dropdown-select";
import ReactLoading from "react-loading";

// reactstrap components11
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  Container,
  CardBody,
  FormGroup,
  Form,
  Modal,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";

import axios from "axios";
import NotificationAlert from "react-notification-alert";
import DataContext from "context/DataContext";
import baseApi1 from "../../api/BaseApi1";
import {baseURL1} from "../../api/baseURL.json";

const fileTypes = ["JPEG", "PNG", "JPG"];
const { SearchBar } = Search;
const AddRetailer = () => {
  const { outlet } = useContext(DataContext);
  const [showPerPage, setShowPerPage] = React.useState(null);
  //Edit Retailer
  const [retailerName, setRetailerName] = React.useState("");
  const [retailerOutlet, setRetailerOutlet] = React.useState([]);
  const [retailerPhone, setRetailerPhone] = React.useState("");
  const [retailerAddress, setRetailerAddress] = React.useState("");
  const [retailerEmergencyContact, setRetailerEmergencyContact] =
    React.useState("");
  const [retailerRelationship, setRetailerRelationship] = React.useState("");
  const [retailerEmail, setRetailerEmail] = React.useState("");
  const [retailerPhoto, setRetailerPhoto] = React.useState(null);
  //Add Retailer
  const [newRetailerName, setNewRetailerName] = React.useState("");
  const [newRetailerOutlet, setNewRetailerOutlet] = React.useState({});
  const [newRetailerPhone, setNewRetailerPhone] = React.useState("");
  const [newRetailerAddress, setNewRetailerAddress] = React.useState("");
  const [newRetailerEmergencyContact, setNewRetailerEmergencyContact] =
    React.useState("");
  const [newRetailerRelationship, setNewRetailerRelationship] =
    React.useState("");
  const [newRetailerEmail, setNewRetailerEmail] = React.useState("");
  const [newRetailerPhoto, setNewRetailerPhoto] = React.useState(null);
  //Others
  const [formModal, setformModal] = React.useState(false);
  const [addRetailerModal, setRetailerModal] = React.useState(false);
  const [allRetailer, setAllRetailer] = React.useState([]);
  const [singleRetailer, setSingleRetailer] = React.useState({});
  const [outletSelect, setOutletSelect] = React.useState([]);
  const [selectedOutlet, setSelectedOutlet] = React.useState([]);
  useEffect(() => {
    const outlets = outlet.map((o) => {
      return { value: o?._id, label: o?.code };
    });
    (addRetailerModal === true || formModal === true) &&
      setOutletSelect(outlets);
    setSelectedOutlet([
      { value: singleRetailer?.outlet, label: singleRetailer?.outletCode },
    ]);
  }, [outlet, addRetailerModal, formModal, singleRetailer]);
  //Notification Alert
  const notificationAlertRef = React.useRef(null);
  const notify = (type, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            Retailer Notification
          </span>
          <span data-notify="message">{message}</span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 7,
    };
    notificationAlertRef.current.notificationAlert(options);
  };

  const componentRef = React.useRef(null);
  // this function will copy to clipboard an entire table,
  // so you can paste it inside an excel or csv file
  const getRetailerData = async () => {
    try {
      const response = await baseApi1.get("/api/retailer/all");
      if (!response.statusText === "OK") {
        console.log("Not Getting Response");
      } else {
        setAllRetailer(response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getOneRetailerData = (id) => {
    const singleRetailerFinal = allRetailer.find((x) => x._id === id);
    setSingleRetailer(singleRetailerFinal);
  };
  const deleteOneRetailer = async (id) => {
    try {
      const response = await baseApi1.delete(
        `/api/retailer/${id}`
      );
      if (!response.statusText === "OK") {
        console.log("Not Getting Response");
        notify("danger", response.data.message);
      } else {
        getRetailerData();
        notify("success", response.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const updateOneRetailerData = async (id) => {
    const editedRetailer = new FormData();
    editedRetailer.append(
      "name",
      retailerName === "" ? singleRetailer?.name : retailerName
    );
    editedRetailer.append(
      "outletCode",
      retailerOutlet.length === 0
        ? singleRetailer?.outletCode
        : retailerOutlet[0]?.label
    );
    editedRetailer.append(
      "phone",
      retailerPhone === "" ? singleRetailer?.phone : retailerPhone
    );
    editedRetailer.append(
      "address",
      retailerAddress === "" ? singleRetailer?.address : retailerAddress
    );
    editedRetailer.append(
      "emergencyContact",
      retailerEmergencyContact === ""
        ? singleRetailer?.emergencyContact
        : retailerEmergencyContact
    );
    editedRetailer.append(
      "relationship",
      retailerRelationship === ""
        ? singleRetailer?.relationship
        : retailerRelationship
    );
    editedRetailer.append(
      "email",
      retailerEmail === "" ? singleRetailer?.email : retailerEmail
    );
    editedRetailer.append("photo", retailerPhoto !== null && retailerPhoto);
    try {
      const response = await baseApi1.put(
        `/api/retailer/${id}`,
        editedRetailer,
        { headers: { "Content-Type": "application/json" } }
      );
      if (!response.statusText === "OK") {
        console.log("Not Getting Response");
        notify("danger", response.data.message);
      } else {
        getRetailerData();
        setformModal(false);
        notify("success", response.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getRetailerData();
  }, []);
  const handleAddPhoto = (file) => {
    setNewRetailerPhoto(file);
  };
  const handleEditPhoto = (file) => {
    setRetailerPhoto(file);
  };

  const pagination = paginationFactory({
    page: 1,
    alwaysShowAllBtns: true,
    showTotal: true,
    withFirstAndLast: false,
    sizePerPageRenderer: ({
      options,
      currSizePerPage,
      onSizePerPageChange,
    }) => (
      <div className="dataTables_length" id="datatable-basic_length">
        <label>
          Show{" "}
          {
            <select
              name="datatable-basic_length"
              aria-controls="datatable-basic"
              className="form-control form-control-sm"
              onChange={(e) => onSizePerPageChange(e.target.value)}
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
              <option value="all">All</option>
            </select>
          }{" "}
          entries.
        </label>
      </div>
    ),
  });
  const addNewRetailer = async () => {
    const newRetailer = new FormData();
    newRetailer.append("name", newRetailerName);
    newRetailer.append("outletCode", newRetailerOutlet?.label);
    newRetailer.append("phone", newRetailerPhone);
    newRetailer.append("address", newRetailerAddress);
    newRetailer.append("emergencyContact", newRetailerEmergencyContact);
    newRetailer.append("relationship", newRetailerRelationship);
    newRetailer.append("email", newRetailerEmail);
    newRetailer.append("photo", newRetailerPhoto);
    // const newRetailer = {
    //   name: newRetailerName,
    // };
    try {
      const response = await baseApi1.post(
        "/api/retailer",
        newRetailer,
        { headers: { "Content-Type": "application/json" } }
      );
      if (!response.statusText === "OK") {
        console.log("Not Getting Response");
        notify("danger", "Some Thing Went Wrong");
      } else {
        getRetailerData();
        response.status === 200 || response.status === 201
          ? setRetailerModal(false)
          : setRetailerModal(true);
        notify("success", response.data.message);
      }
    } catch (error) {
      console.log(error.response);
    }
  };
  const submitNewRetailer = () => {
    addNewRetailer();
  };
  const handleSingleRetailerDelete = (id) => {
    deleteOneRetailer(id);
  };
  return (
    <div>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <SimpleHeader name="Retailer List" parentName="Retailer" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="d-flex justify-content-between">
                <h3 style={{ color: "#DB5A3B" }}>Add Retailer</h3>
                <Button
                  onClick={() => setRetailerModal(true)}
                  style={{ backgroundColor: "#DB5A3B", color: "white" }}
                >
                  Add New
                </Button>
              </CardHeader>
              <ToolkitProvider
                data={allRetailer}
                keyField="phone"
                columns={[
                  {
                    dataField: "#",
                    text: "SL",
                    formatter: (cell, row, rowIndex) => {
                      return rowIndex + 1;
                    },
                  },
                  {
                    dataField: "name",
                    text: "Retailer Name",
                    sort: true,
                  },
                  {
                    dataField: "outletCode",
                    text: "Retailer Outlet",
                    sort: true,
                  },
                  {
                    dataField: "phone",
                    text: "Retailer Phone",
                    sort: true,
                  },
                  {
                    dataField: "address",
                    text: "Retailer Address",
                    sort: true,
                  },
                  {
                    dataField: "emergencyContact",
                    text: "Emergency Contact",
                    sort: true,
                  },
                  {
                    dataField: "relationship",
                    text: "Relationship",
                    sort: true,
                  },
                  {
                    dataField: "email",
                    text: "Email",
                    sort: true,
                  },
                  {
                    dataField: "_id",
                    text: "Action",
                    formatter: (cell) => {
                      const handleEdit = () => {
                        getOneRetailerData(cell);
                        setformModal(true);
                      };
                      const action = (
                        <div>
                          <Button color="info" onClick={handleEdit}>
                            Edit
                          </Button>
                          <Button
                            onClick={() => handleSingleRetailerDelete(cell)}
                            color="danger"
                          >
                            Delete
                          </Button>
                        </div>
                      );
                      return action;
                    },
                  },
                ]}
                search
              >
                {(props) => (
                  <div className="py-4 table-responsive">
                    <Container fluid>
                      <Row>
                        <Col xs={4} sm={4}>
                          <div>
                            <select
                              name="datatable-basic_length"
                              aria-controls="datatable-basic"
                              className="form-control form-control-sm w-25"
                              onChange={(e) => setShowPerPage(e.target.value)}
                            >
                              <option
                                selected={showPerPage === 10 && true}
                                value={10}
                              >
                                10
                              </option>
                              <option
                                selected={showPerPage === 25 && true}
                                value={25}
                              >
                                25
                              </option>
                              <option
                                selected={showPerPage === 50 && true}
                                value={50}
                              >
                                50
                              </option>
                              <option
                                selected={showPerPage === 100 && true}
                                value={100}
                              >
                                100
                              </option>
                              <option
                                selected={showPerPage === "all" && true}
                                value="all"
                              >
                                all
                              </option>
                            </select>
                          </div>
                        </Col>
                        <Col xs={4} sm={4}>
                          <ButtonGroup>
                            <Button
                              className="buttons-copy buttons-html5"
                              color="warning"
                              size="sm"
                              id="copy-tooltip"
                              onClick={() =>
                                window.open(
                                  `${baseURL1}/api/retailer/csv`
                                )
                              }
                            >
                              <span>CSV</span>
                            </Button>
                            <ReactToPrint
                              trigger={() => (
                                <Button
                                  color="warning"
                                  size="sm"
                                  className="buttons-copy buttons-html5"
                                  id="print-tooltip"
                                >
                                  Print
                                </Button>
                              )}
                              content={() => componentRef.current}
                            />
                          </ButtonGroup>
                          <UncontrolledTooltip
                            placement="top"
                            target="print-tooltip"
                          >
                            This will open a print page with the visible rows of
                            the table.
                          </UncontrolledTooltip>
                          <UncontrolledTooltip
                            placement="top"
                            target="copy-tooltip"
                          >
                            This will Download Retailers As CSV.
                          </UncontrolledTooltip>
                        </Col>
                        <Col xs={4} sm={4}>
                          <div
                            id="datatable-basic_filter"
                            className="dataTables_filter px-4 pb-1 float-right"
                          >
                            <label>
                              Search:
                              <SearchBar
                                className="form-control-sm"
                                placeholder=""
                                {...props.searchProps}
                              />
                            </label>
                          </div>
                        </Col>
                      </Row>
                    </Container>
                    {allRetailer.length > 0 ? (
                      <BootstrapTable
                        ref={componentRef}
                        {...props.baseProps}
                        bootstrap4={true}
                        pagination={pagination}
                        bordered={false}
                        id="react-bs-table"
                      />
                    ) : (
                      <div className="d-flex justify-content-center">
                        <ReactLoading
                          className=""
                          type="bubbles"
                          color="#DB5A3B"
                          height={200}
                          width={100}
                        />
                      </div>
                    )}
                  </div>
                )}
              </ToolkitProvider>
            </Card>
          </div>
        </Row>
      </Container>
      {/* Edit Modal */}
      <Modal
        className="modal-dialog-centered"
        size="md"
        isOpen={formModal}
        toggle={() => setformModal(false)}
      >
        <div className="modal-body p-0">
          <Card className="bg-secondary border-0 mb-0">
            <CardHeader style={{ color: "#DB5A3B" }} className="bg-transparent">
              Update Retailer
            </CardHeader>
            <CardBody className="px-lg-5 py-lg-5">
              <Form role="form">
                <FormGroup
                  className={classnames("mb-3", {
                    focused: retailerName,
                  })}
                >
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlSelect1"
                  >
                    Retailer Name
                  </label>
                  <InputGroup className="input-group-merge input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Retailer Name"
                      defaultValue={singleRetailer?.name}
                      type="text"
                      onChange={(e) => setRetailerName(e.target.value)}
                    />
                  </InputGroup>
                </FormGroup>

                <FormGroup className={classnames("mb-3")}>
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlSelect1"
                  >
                    Retailer Outlet
                  </label>
                  <Select
                    placeholder="Search and Select Outlet"
                    background="#f2f2f2"
                    clearable={true}
                    backspaceDelete={true}
                    options={outletSelect}
                    values={selectedOutlet}
                    onChange={(value) => setRetailerOutlet(value)}
                  />
                </FormGroup>

                <FormGroup
                  className={classnames("mb-3", {
                    focused: retailerPhone,
                  })}
                >
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlSelect1"
                  >
                    Retailer Phone
                  </label>
                  <InputGroup className="input-group-merge input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Retailer Phone"
                      type="text"
                      defaultValue={singleRetailer?.phone}
                      onChange={(e) => setRetailerPhone(e.target.value)}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup
                  className={classnames("mb-3", {
                    focused: retailerAddress,
                  })}
                >
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlSelect1"
                  >
                    Retailer Address
                  </label>
                  <InputGroup className="input-group-merge input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Retailer Address"
                      defaultValue={singleRetailer?.address}
                      type="text"
                      onChange={(e) => setRetailerAddress(e.target.value)}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup
                  className={classnames("mb-3", {
                    focused: retailerEmergencyContact,
                  })}
                >
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlSelect1"
                  >
                    Emergency Contact
                  </label>
                  <InputGroup className="input-group-merge input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Emergency Contact"
                      type="text"
                      defaultValue={singleRetailer?.emergencyContact}
                      onChange={(e) =>
                        setRetailerEmergencyContact(e.target.value)
                      }
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup
                  className={classnames("mb-3", {
                    focused: retailerRelationship,
                  })}
                >
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlSelect1"
                  >
                    Relationship
                  </label>
                  <InputGroup className="input-group-merge input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Relationship"
                      type="text"
                      defaultValue={singleRetailer?.relationship}
                      onChange={(e) => setRetailerRelationship(e.target.value)}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup
                  className={classnames("mb-3", {
                    focused: retailerEmail,
                  })}
                >
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlSelect1"
                  >
                    Retailer Email
                  </label>
                  <InputGroup className="input-group-merge input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Retailer Email"
                      type="text"
                      defaultValue={singleRetailer?.email}
                      onChange={(e) => setRetailerEmail(e.target.value)}
                    />
                  </InputGroup>
                  <div className="mt-3">
                    <FileUploader
                      handleChange={handleEditPhoto}
                      label="Upload Retailer Photo"
                      name="file"
                      types={fileTypes}
                    />
                    <p>
                      {retailerPhoto
                        ? `File name: ${retailerPhoto?.name}`
                        : "no photo uploaded yet"}
                    </p>
                  </div>
                </FormGroup>
                <div className="text-center">
                  <Button
                    onClick={() => setformModal(false)}
                    className="my-4"
                    color="danger"
                    type="button"
                  >
                    Close
                  </Button>
                  <Button
                    onClick={() => updateOneRetailerData(singleRetailer?._id)}
                    className="my-4"
                    color="success"
                    type="button"
                  >
                    Update
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </div>
      </Modal>
      {/* Add Retailer */}
      <Modal
        className="modal-dialog-centered"
        size="md"
        isOpen={addRetailerModal}
        toggle={() => setRetailerModal(false)}
      >
        <div className="modal-body p-0">
          <Card className="bg-secondary border-0 mb-0">
            <CardHeader style={{ color: "#DB5A3B" }} className="bg-transparent">
              Add Retailer
            </CardHeader>
            <CardBody className="px-lg-5 py-lg-5">
              <Form role="form">
                <FormGroup
                  className={classnames("mb-3", {
                    focused: newRetailerName,
                  })}
                >
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlSelect1"
                  >
                    Retailer Name
                  </label>
                  <InputGroup className="input-group-merge input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Retailer Name"
                      type="text"
                      onChange={(e) => setNewRetailerName(e.target.value)}
                    />
                  </InputGroup>
                </FormGroup>

                <FormGroup className={classnames("mb-3")}>
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlSelect1"
                  >
                    Retailer Outlet
                  </label>
                  <Select
                    placeholder="Search and Select Outlet"
                    background=" #f2f2f2"
                    clearable={true}
                    backspaceDelete={true}
                    options={outletSelect}
                    values={[]}
                    onChange={(value) => setNewRetailerOutlet(value[0])}
                  />
                </FormGroup>

                <FormGroup
                  className={classnames("mb-3", {
                    focused: newRetailerPhone,
                  })}
                >
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlSelect1"
                  >
                    Retailer Phone
                  </label>
                  <InputGroup className="input-group-merge input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Retailer Phone"
                      type="text"
                      onChange={(e) => setNewRetailerPhone(e.target.value)}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup
                  className={classnames("mb-3", {
                    focused: newRetailerAddress,
                  })}
                >
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlSelect1"
                  >
                    Retailer Address
                  </label>
                  <InputGroup className="input-group-merge input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Retailer Address"
                      type="text"
                      onChange={(e) => setNewRetailerAddress(e.target.value)}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup
                  className={classnames("mb-3", {
                    focused: newRetailerEmergencyContact,
                  })}
                >
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlSelect1"
                  >
                    Emergency Contact
                  </label>
                  <InputGroup className="input-group-merge input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Emergency Contact"
                      type="text"
                      onChange={(e) =>
                        setNewRetailerEmergencyContact(e.target.value)
                      }
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup
                  className={classnames("mb-3", {
                    focused: newRetailerRelationship,
                  })}
                >
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlSelect1"
                  >
                    Relationship
                  </label>
                  <InputGroup className="input-group-merge input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Relationship"
                      type="text"
                      onChange={(e) =>
                        setNewRetailerRelationship(e.target.value)
                      }
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup
                  className={classnames("mb-3", {
                    focused: newRetailerEmail,
                  })}
                >
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlSelect1"
                  >
                    Retailer Email
                  </label>
                  <InputGroup className="input-group-merge input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Retailer Email"
                      type="text"
                      onChange={(e) => setNewRetailerEmail(e.target.value)}
                    />
                  </InputGroup>
                  <div className="mt-3">
                    <FileUploader
                      handleChange={handleAddPhoto}
                      label="Upload Retailer Photo"
                      name="file"
                      types={fileTypes}
                    />
                    <p>
                      {newRetailerPhoto
                        ? `File name: ${newRetailerPhoto?.name}`
                        : "no photo uploaded yet"}
                    </p>
                  </div>
                </FormGroup>
                <div className="text-center">
                  <Button
                    onClick={() => setRetailerModal(false)}
                    className="my-4"
                    color="danger"
                    type="button"
                  >
                    Close
                  </Button>
                  <Button
                    onClick={submitNewRetailer}
                    className="my-4"
                    color="success"
                    type="button"
                  >
                    Add
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </div>
      </Modal>
    </div>
  );
};

export default AddRetailer;
