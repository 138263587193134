import baseUrl2 from "../api/BaseApi2";

export const getCallingMonth = async () => {
    try {
        const response = await baseUrl2.get(`/api/getCallingMonth`);
        if (response.statusText !== "OK") {
            console.log("Something Went Wrong");
        } else {
            return response.data;
        }
    } catch (e) {
        console.log(e.response);
    }
};

export const addCallingMonth = async (month) => {
    const payload = {
        month: month,
    };
    try {
        const response = await baseUrl2.post(`/api/addCallingMonth`, payload, {
            headers: {"Content-type": "application/json"}
        });
        if (response.statusText !== "OK") {
            return `"danger","Something Went Wrong","Session"`;
        } else {
            return response?.data;
        }
    } catch (e) {
        console.log(e.response);
    }
};
