// nodejs library to set properties for components
import PropTypes from "prop-types";
// reactstrap components11
import {Container, Row, Col} from "reactstrap";

function AuthHeader({title, lead}) {
    return (
        <>
            <div className="header bg-warning py-7 py-lg-8 pt-lg-9">
                <Container>
                    <div className="header-body text-center mb-7">
                        <Row className="justify-content-center">
                            <Col className="px-5" lg="6" md="8" xl="5">
                                {title ? <h1 className="text-white">{title}</h1> : null}
                                {lead ? <p className="text-lead text-white">{lead}</p> : null}
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
        </>
    );
}

AuthHeader.propTypes = {
    title: PropTypes.string,
    lead: PropTypes.string,
};

export default AuthHeader;
