import React, {useContext, useEffect} from "react";
// react plugin that prints a given react component
import ReactToPrint from "react-to-print";
import classnames from "classnames";
// react component for creating dynamic tables
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {Search} from "react-bootstrap-table2-toolkit";

// reactstrap components11
import {
    Button,
    ButtonGroup,
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Col,
    Container,
    Form,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Modal,
    Row,
    UncontrolledTooltip,
} from "reactstrap";
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";

import axios from "axios";
import NotificationAlert from "react-notification-alert";
import DataContext from "context/DataContext";
import CurrencyFormat from "react-currency-format";
import baseApi1 from "../../api/BaseApi1";
import {baseURL1} from "../../api/baseURL.json";

const {SearchBar} = Search;

const TerritorySetting = () => {
    const {region, area} = useContext(DataContext);
    const [showPerPage, setShowPerPage] = React.useState(null);
    //Edit Area
    const [editRegion, setEditRegion] = React.useState("");
    const [editArea, setEditArea] = React.useState("");
    const [territoryID, setTerritoryID] = React.useState("");
    const [territoryName, setTerritoryName] = React.useState("");
    //Add Area
    const [newRegion, setNewRegion] = React.useState("");
    const [newArea, setNewArea] = React.useState("");
    const [newTerritoryID, setNewTerritoryID] = React.useState("");
    const [newTerritoryName, setNewTerritoryName] = React.useState("");
    //Others
    const [formModal, setformModal] = React.useState(false);
    const [addTerritoryModal, setTerritoryModal] = React.useState(false);
    const [allTerritory, setAllTerritory] = React.useState([]);
    const [singleTerritory, setSingleTerritory] = React.useState({});
    //EditFilters
    const filteredArea = area.filter((item) => item.regionId === editRegion);
    //AddFilters
    const newfilteredArea = area.filter((item) => item.regionId === newRegion);
    //Notification Alert
    const notificationAlertRef = React.useRef(null);
    const notify = (type, message) => {
        let options = {
            place: "tc",
            message: (
                <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
              Territory Notification
          </span>
                    <span data-notify="message">{message}</span>
                </div>
            ),
            type: type,
            icon: "ni ni-bell-55",
            autoDismiss: 7,
        };
        notificationAlertRef.current.notificationAlert(options);
    };

    const componentRef = React.useRef(null);
    // this function will copy to clipboard an entire table,
    // so you can paste it inside an excel or csv file
    const getTerritoryData = async () => {
        try {
            const response = await baseApi1.get(
                "/api/territories/all"
            );
            if (!response.statusText === "OK") {
                console.log("Not Getting Response");
            } else {
                setAllTerritory(response.data.data);
            }
        } catch (error) {
            console.log(error);
        }
    };
    const getOneTerritoryData = async (id) => {
        try {
            const response = await baseApi1.get(
                `/api/territory/one/${id}`
            );
            if (!response.statusText === "OK") {
                console.log("Not Getting Response");
            } else {
                setEditRegion(response.data.region);
                setEditArea(response.data.area);
                setTerritoryID(response.data.id);
                setSingleTerritory(response.data);
            }
        } catch (error) {
            console.log(error);
        }
    };
    const deleteOneTerritory = async (id) => {
        try {
            const response = await baseApi1.delete(
                `/api/territories/${id}`
            );
            if (!response.statusText === "OK") {
                console.log("Not Getting Response");
                notify("danger", response.data.message);
            } else {
                getTerritoryData();
                notify("success", response.data.message);
            }
        } catch (error) {
            notify("danger", error.response.data.message);
        }
    };
    const updateOneTerritoryData = async (id) => {
        const editedTerritory = {
            region: editRegion === "" ? singleTerritory?.region : editRegion,
            area: editArea === "" ? singleTerritory?.area : editArea,
            id: territoryID === "" ? singleTerritory?.id : territoryID,
            name: territoryName === "" ? singleTerritory?.name : territoryName,
        };
        try {
            const response = await baseApi1.put(
                `/api/territories/${id}`,
                editedTerritory,
                {headers: {"Content-Type": "application/json"}}
            );
            if (!response.statusText === "OK") {
                console.log("Not Getting Response");
                notify("danger", response.data.message);
            } else {
                getTerritoryData();
                setformModal(false);
                notify("success", response.data.message);
            }
        } catch (error) {
            notify("danger", error.response.data.message);
        }
    };
    useEffect(() => {
        getTerritoryData();
    }, []);

    const pagination = paginationFactory({
        page: 1,
        alwaysShowAllBtns: true,
        showTotal: true,
        withFirstAndLast: false,
        sizePerPageRenderer: ({
                                  options,
                                  currSizePerPage,
                                  onSizePerPageChange,
                              }) => (
            <div className="dataTables_length" id="datatable-basic_length">
                <label>
                    Show{" "}
                    {
                        <select
                            name="datatable-basic_length"
                            aria-controls="datatable-basic"
                            className="form-control form-control-sm"
                            onChange={(e) => onSizePerPageChange(e.target.value)}
                        >
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="all">All</option>
                        </select>
                    }{" "}
                    entries.
                </label>
            </div>
        ),
    });
    const addNewTerritory = async () => {
        const newTerritoryData = {
            region: newRegion,
            area: newArea,
            id: newTerritoryID,
            name: newTerritoryName,
        };
        try {
            const response = await baseApi1.post(
                "/api/territories",
                newTerritoryData,
                {headers: {"Content-Type": "application/json"}}
            );
            if (!response.statusText === "OK") {
                console.log("Not Getting Response");
                notify("danger", "Some Thing Went Wrong");
            } else {
                getTerritoryData();
                response.status === 200 || response.status === 201
                    ? setTerritoryModal(false)
                    : setTerritoryModal(true);
                notify("success", response.data.message);
            }
        } catch (error) {
            notify("danger", error.response.data.message);
        }
    };
    const submitNewTms = () => {
        addNewTerritory();
    };
    const handleSingleTmsDelete = (id) => {
        deleteOneTerritory(id);
    };
    return (
        <div>
            <div className="rna-wrapper">
                <NotificationAlert ref={notificationAlertRef}/>
            </div>
            <SimpleHeader name="Territory List" parentName="Territory"/>
            <Container className="mt--6" fluid>
                <Row>
                    <div className="col">
                        <Card>
                            <CardHeader className="d-flex justify-content-between">
                                <h3 style={{color: "#DB5A3B"}}>Territory Setting</h3>
                                <Button
                                    onClick={() => setTerritoryModal(true)}
                                    style={{backgroundColor: "#DB5A3B", color: "white"}}
                                >
                                    Add New
                                </Button>
                            </CardHeader>
                            <div className="m-3" style={{width: "18rem"}}>
                                <Card className="card-stats bg-gradient-warning mb-4 mb-lg-0">
                                    <CardBody>
                                        <Row>
                                            <div className="col">
                                                <CardTitle className="text-uppercase text-white text-muted mb-0">
                                                    Total Region
                                                </CardTitle>
                                                <span className="h2 font-weight-bold mb-0 text-white">
                          <CurrencyFormat
                              value={allTerritory.length}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={""}
                          />
                        </span>
                                            </div>
                                            <Col className="col-auto">
                                                <div
                                                    className="icon icon-shape bg-danger text-white rounded-circle shadow">
                                                    <i className="fas fa-chart-bar"/>
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </div>
                            <ToolkitProvider
                                data={allTerritory}
                                keyField="name"
                                columns={[
                                    {
                                        dataField: "#",
                                        text: "SL",
                                        formatter: (cell, row, rowIndex) => {
                                            return rowIndex + 1;
                                        },
                                    },
                                    {
                                        dataField: "region",
                                        text: "Region",
                                        sort: true,
                                    },
                                    {
                                        dataField: "area",
                                        text: "Area",
                                        sort: true,
                                    },
                                    {
                                        dataField: "id",
                                        text: "Territory ID",
                                        sort: true,
                                    },
                                    {
                                        dataField: "name",
                                        text: "Territory Name",
                                        sort: true,
                                    },
                                    {
                                        dataField: "_id",
                                        text: "Action",
                                        formatter: (cell) => {
                                            const handleEdit = () => {
                                                getOneTerritoryData(cell);
                                                setformModal(true);
                                            };
                                            return (
                                                <div>
                                                    <Button color="info" onClick={handleEdit}>
                                                        Edit
                                                    </Button>
                                                    <Button
                                                        onClick={() => handleSingleTmsDelete(cell)}
                                                        color="danger"
                                                    >
                                                        Delete
                                                    </Button>
                                                </div>
                                            );
                                        },
                                    },
                                ]}
                                search
                            >
                                {(props) => (
                                    <div className="py-4 table-responsive">
                                        <Container fluid>
                                            <Row>
                                                <Col xs={4} sm={4}>
                                                    <div>
                                                        <select
                                                            name="datatable-basic_length"
                                                            aria-controls="datatable-basic"
                                                            className="form-control form-control-sm w-25"
                                                            onChange={(e) => setShowPerPage(e.target.value)}
                                                        >
                                                            <option
                                                                selected={showPerPage === 10 && true}
                                                                value={10}
                                                            >
                                                                10
                                                            </option>
                                                            <option
                                                                selected={showPerPage === 25 && true}
                                                                value={25}
                                                            >
                                                                25
                                                            </option>
                                                            <option
                                                                selected={showPerPage === 50 && true}
                                                                value={50}
                                                            >
                                                                50
                                                            </option>
                                                            <option
                                                                selected={showPerPage === 100 && true}
                                                                value={100}
                                                            >
                                                                100
                                                            </option>
                                                            <option
                                                                selected={showPerPage === "all" && true}
                                                                value="all"
                                                            >
                                                                all
                                                            </option>
                                                        </select>
                                                    </div>
                                                </Col>
                                                <Col xs={4} sm={4}>
                                                    <ButtonGroup>
                                                        <Button
                                                            className="buttons-copy buttons-html5"
                                                            color="warning"
                                                            size="sm"
                                                            id="copy-tooltip"
                                                            onClick={() =>
                                                                window.open(
                                                                    `${baseURL1}/api/territories/all/csv`
                                                                )
                                                            }
                                                        >
                                                            <span>CSV</span>
                                                        </Button>
                                                        <ReactToPrint
                                                            trigger={() => (
                                                                <Button
                                                                    color="warning"
                                                                    size="sm"
                                                                    className="buttons-copy buttons-html5"
                                                                    id="print-tooltip"
                                                                >
                                                                    Print
                                                                </Button>
                                                            )}
                                                            content={() => componentRef.current}
                                                        />
                                                    </ButtonGroup>
                                                    <UncontrolledTooltip
                                                        placement="top"
                                                        target="print-tooltip"
                                                    >
                                                        This will open a print page with the visible rows of
                                                        the table.
                                                    </UncontrolledTooltip>
                                                    <UncontrolledTooltip
                                                        placement="top"
                                                        target="copy-tooltip"
                                                    >
                                                        This will Download Territory Setting As CSV.
                                                    </UncontrolledTooltip>
                                                </Col>
                                                <Col xs={4} sm={4}>
                                                    <div
                                                        id="datatable-basic_filter"
                                                        className="dataTables_filter px-4 pb-1 float-right"
                                                    >
                                                        <label>
                                                            Search:
                                                            <SearchBar
                                                                className="form-control-sm"
                                                                placeholder=""
                                                                {...props.searchProps}
                                                            />
                                                        </label>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Container>
                                        <BootstrapTable
                                            ref={componentRef}
                                            {...props.baseProps}
                                            bootstrap4={true}
                                            pagination={pagination}
                                            bordered={false}
                                            id="react-bs-table"
                                        />
                                    </div>
                                )}
                            </ToolkitProvider>
                        </Card>
                    </div>
                </Row>
            </Container>
            {/* Edit Modal */}
            <Modal
                className="modal-dialog-centered"
                size="md"
                isOpen={formModal}
                toggle={() => setformModal(false)}
            >
                <div className="modal-body p-0">
                    <Card className="bg-secondary border-0 mb-0">
                        <CardHeader style={{color: "#DB5A3B"}} className="bg-transparent">
                            Update Territory
                        </CardHeader>
                        <CardBody className="px-lg-5 py-lg-5">
                            <Form role="form">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="exampleFormControlSelect1"
                                    >
                                        Region
                                    </label>
                                    <Input
                                        id="exampleFormControlSelect1"
                                        onChange={(e) => setEditRegion(e.target.value)}
                                        type="select"
                                    >
                                        <option>Select</option>
                                        {region.map((region) => (
                                            <option
                                                selected={
                                                    region._id === singleTerritory?.region ? true : false
                                                }
                                                value={region._id}
                                            >
                                                {region.name}
                                            </option>
                                        ))}
                                    </Input>
                                </FormGroup>
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="exampleFormControlSelect1"
                                    >
                                        Area
                                    </label>
                                    <Input
                                        id="exampleFormControlSelect1"
                                        onChange={(e) => setEditArea(e.target.value)}
                                        type="select"
                                    >
                                        <option>Select</option>
                                        {filteredArea.map((area) => (
                                            <option
                                                selected={
                                                    area._id === singleTerritory?.area ? true : false
                                                }
                                                value={area._id}
                                            >
                                                {area.name}
                                            </option>
                                        ))}
                                    </Input>
                                </FormGroup>
                                <FormGroup
                                    className={classnames("mb-3", {
                                        focused: territoryID,
                                    })}
                                >
                                    <label
                                        className="form-control-label"
                                        htmlFor="exampleFormControlSelect1"
                                    >
                                        Territory ID
                                    </label>
                                    <InputGroup className="input-group-merge input-group-alternative">
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="ni ni-email-83"/>
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            placeholder="Territory ID"
                                            defaultValue={singleTerritory?.id}
                                            type="name"
                                            onChange={(e) => setTerritoryID(e.target.value)}
                                        />
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup
                                    className={classnames("mb-3", {
                                        focused: territoryName,
                                    })}
                                >
                                    <label
                                        className="form-control-label"
                                        htmlFor="exampleFormControlSelect1"
                                    >
                                        Territory Name
                                    </label>
                                    <InputGroup className="input-group-merge input-group-alternative">
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="ni ni-email-83"/>
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            placeholder="Territory Name"
                                            defaultValue={singleTerritory?.name}
                                            type="name"
                                            onChange={(e) => setTerritoryName(e.target.value)}
                                        />
                                    </InputGroup>
                                </FormGroup>
                                <div className="text-center">
                                    <Button
                                        onClick={() => setformModal(false)}
                                        className="my-4"
                                        color="danger"
                                        type="button"
                                    >
                                        Close
                                    </Button>
                                    <Button
                                        onClick={() => updateOneTerritoryData(singleTerritory?._id)}
                                        className="my-4"
                                        color="success"
                                        type="button"
                                    >
                                        Update
                                    </Button>
                                </div>
                            </Form>
                        </CardBody>
                    </Card>
                </div>
            </Modal>
            {/* Add Territory */}
            <Modal
                className="modal-dialog-centered"
                size="md"
                isOpen={addTerritoryModal}
                toggle={() => setTerritoryModal(false)}
            >
                <div className="modal-body p-0">
                    <Card className="bg-secondary border-0 mb-0">
                        <CardHeader style={{color: "#DB5A3B"}} className="bg-transparent">
                            Add Territory
                        </CardHeader>
                        <CardBody className="px-lg-5 py-lg-5">
                            <Form role="form">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="exampleFormControlSelect1"
                                    >
                                        Region
                                    </label>
                                    <Input
                                        id="exampleFormControlSelect1"
                                        onChange={(e) => setNewRegion(e.target.value)}
                                        type="select"
                                    >
                                        <option>Select</option>
                                        {region.map((region) => (
                                            <option value={region._id}>{region.name}</option>
                                        ))}
                                    </Input>
                                </FormGroup>
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="exampleFormControlSelect1"
                                    >
                                        Area
                                    </label>
                                    <Input
                                        id="exampleFormControlSelect1"
                                        onChange={(e) => setNewArea(e.target.value)}
                                        type="select"
                                    >
                                        <option>Select</option>
                                        {newfilteredArea.map((area) => (
                                            <option value={area._id}>{area.name}</option>
                                        ))}
                                    </Input>
                                </FormGroup>
                                <FormGroup
                                    className={classnames("mb-3", {
                                        focused: newTerritoryID,
                                    })}
                                >
                                    <label
                                        className="form-control-label"
                                        htmlFor="exampleFormControlSelect1"
                                    >
                                        Territory ID
                                    </label>
                                    <InputGroup className="input-group-merge input-group-alternative">
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="ni ni-email-83"/>
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            placeholder="Territory ID"
                                            type="name"
                                            onChange={(e) => setNewTerritoryID(e.target.value)}
                                        />
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup
                                    className={classnames("mb-3", {
                                        focused: newTerritoryName,
                                    })}
                                >
                                    <label
                                        className="form-control-label"
                                        htmlFor="exampleFormControlSelect1"
                                    >
                                        Territory Name
                                    </label>
                                    <InputGroup className="input-group-merge input-group-alternative">
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="ni ni-email-83"/>
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            placeholder="Territory Name"
                                            type="name"
                                            onChange={(e) => setNewTerritoryName(e.target.value)}
                                        />
                                    </InputGroup>
                                </FormGroup>
                                <div className="text-center">
                                    <Button
                                        onClick={() => setTerritoryModal(false)}
                                        className="my-4"
                                        color="danger"
                                        type="button"
                                    >
                                        Close
                                    </Button>
                                    <Button
                                        onClick={submitNewTms}
                                        className="my-4"
                                        color="success"
                                        type="button"
                                    >
                                        Add
                                    </Button>
                                </div>
                            </Form>
                        </CardBody>
                    </Card>
                </div>
            </Modal>
        </div>
    );
};

export default TerritorySetting;
