import React, { useContext, useEffect } from "react";
// react plugin that prints a given react component
import ReactToPrint from "react-to-print";
import classnames from "classnames";
// react component for creating dynamic tables
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

// reactstrap components11
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Modal,
  Row,
  UncontrolledTooltip,
} from "reactstrap";
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import baseApi1 from "../../api/BaseApi1";

import axios from "axios";
import NotificationAlert from "react-notification-alert";
import DataContext from "context/DataContext";
import CurrencyFormat from "react-currency-format";
import {baseURL1} from "../../api/baseURL.json";

const { SearchBar } = Search;

const AreaSetting = () => {
  const { region } = useContext(DataContext);
  const [showPerPage, setShowPerPage] = React.useState(null);
  //Edit Area
  const [editRegion, setEditRegion] = React.useState("");
  const [areaID, setAreaID] = React.useState("");
  const [areaName, setAreaName] = React.useState("");
  //Add Area
  const [newRegion, setNewRegion] = React.useState("");
  const [newAreaID, setNewAreaID] = React.useState("");
  const [newAreaName, setNewAreaName] = React.useState("");
  //Others
  const [formModal, setformModal] = React.useState(false);
  const [addAreaModal, setAreaModal] = React.useState(false);
  const [allArea, setAllArea] = React.useState([]);
  const [singleArea, setSingleArea] = React.useState({});

  //Notification Alert
  const notificationAlertRef = React.useRef(null);
  const notify = (type, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            Area Notification
          </span>
          <span data-notify="message">{message}</span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 7,
    };
    notificationAlertRef.current.notificationAlert(options);
  };

  const componentRef = React.useRef(null);
  // this function will copy to clipboard an entire table,
  // so you can paste it inside an excel or csv file
  const getAreaData = async () => {
    try {
      const response = await baseApi1.get("/api/areas/all");
      if (!response.statusText === "OK") {
        console.log("Not Getting Response");
      } else {
        setAllArea(response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getOneAreaData = async (id) => {
    try {
      const response = await baseApi1.get(`/api/area/one/${id}`);
      if (!response.statusText === "OK") {
        console.log("Not Getting Response");
      } else {
        setSingleArea(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const deleteOneArea = async (id) => {
    try {
      const response = await baseApi1.delete(`/api/areas/${id}`);
      if (!response.statusText === "OK") {
        console.log("Not Getting Response");
        notify("danger", response.data.message);
      } else {
        getAreaData();
        notify("success", response.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const updateOneAreaData = async (id) => {
    const editedArea = {
      region: editRegion === "" ? singleArea?.region : editRegion,
      id: areaID === "" ? singleArea?.id : areaID,
      name: areaName === "" ? singleArea?.name : areaName,
    };
    try {
      const response = await baseApi1.put(
        `/api/areas/${id}`,
        editedArea,
        { headers: { "Content-Type": "application/json" } }
      );
      if (!response.statusText === "OK") {
        console.log("Not Getting Response");
        notify("danger", response.data.message);
      } else {
        getAreaData();
        setformModal(false);
        notify("success", response.data.message);
      }
    } catch (error) {
      notify("danger", error.response.data.message);
    }
  };
  useEffect(() => {
    getAreaData();
  }, []);

  const pagination = paginationFactory({
    page: 1,
    alwaysShowAllBtns: true,
    showTotal: true,
    withFirstAndLast: false,
    sizePerPageRenderer: ({
      options,
      currSizePerPage,
      onSizePerPageChange,
    }) => (
      <div className="dataTables_length" id="datatable-basic_length">
        <label>
          Show{" "}
          {
            <select
              name="datatable-basic_length"
              aria-controls="datatable-basic"
              className="form-control form-control-sm"
              onChange={(e) => onSizePerPageChange(e.target.value)}
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
              <option value="all">All</option>
            </select>
          }{" "}
          entries.
        </label>
      </div>
    ),
  });
  const addNewArea = async () => {
    const newAreaData = {
      region: newRegion,
      id: newAreaID,
      name: newAreaName,
    };
    try {
      const response = await baseApi1.post(
        "/api/areas",
        newAreaData,
        { headers: { "Content-Type": "application/json" } }
      );
      if (!response.statusText === "OK") {
        console.log("Not Getting Response");
        notify("danger", "Some Thing Went Wrong");
      } else {
        getAreaData();
        response.status === 200 || response.status === 201
          ? setAreaModal(false)
          : setAreaModal(true);
        notify("success", response.data.message);
      }
    } catch (error) {
      console.log(error.response.data);
      notify("danger", error.response.data.message);
    }
  };
  const submitNewTms = () => {
    addNewArea();
  };
  const handleSingleTmsDelete = (id) => {
    deleteOneArea(id);
  };
  return (
    <div>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <SimpleHeader name="Area List" parentName="Area" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="d-flex justify-content-between">
                <h3 style={{ color: "#DB5A3B" }}>Area Setting</h3>
                <Button
                  onClick={() => setAreaModal(true)}
                  style={{ backgroundColor: "#DB5A3B", color: "white" }}
                >
                  Add New
                </Button>
              </CardHeader>
              <div className="m-3" style={{ width: "18rem" }}>
                <Card className="card-stats bg-gradient-warning mb-4 mb-lg-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle className="text-uppercase text-white text-muted mb-0">
                          Total Area
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0 text-white">
                          <CurrencyFormat
                            value={allArea.length}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={""}
                          />
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                          <i className="fas fa-chart-bar" />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </div>
              <ToolkitProvider
                data={allArea}
                keyField="name"
                columns={[
                  {
                    dataField: "#",
                    text: "SL",
                    formatter: (cell, row, rowIndex) => {
                      return rowIndex + 1;
                    },
                  },
                  {
                    dataField: "region",
                    text: "Region",
                    sort: true,
                  },
                  {
                    dataField: "id",
                    text: "Area ID",
                    sort: true,
                  },
                  {
                    dataField: "name",
                    text: "Area Name",
                    sort: true,
                  },
                  {
                    dataField: "_id",
                    text: "Action",
                    formatter: (cell) => {
                      const handleEdit = () => {
                        getOneAreaData(cell);
                        setformModal(true);
                      };
                      return (
                        <div>
                          <Button color="info" onClick={handleEdit}>
                            Edit
                          </Button>
                          <Button
                            onClick={() => handleSingleTmsDelete(cell)}
                            color="danger"
                          >
                            Delete
                          </Button>
                        </div>
                      );
                    },
                  },
                ]}
                search
              >
                {(props) => (
                  <div className="py-4 table-responsive">
                    <Container fluid>
                      <Row>
                        <Col xs={4} sm={4}>
                          <div>
                            <select
                              name="datatable-basic_length"
                              aria-controls="datatable-basic"
                              className="form-control form-control-sm w-25"
                              onChange={(e) => setShowPerPage(e.target.value)}
                            >
                              <option
                                selected={showPerPage === 10 && true}
                                value={10}
                              >
                                10
                              </option>
                              <option
                                selected={showPerPage === 25 && true}
                                value={25}
                              >
                                25
                              </option>
                              <option
                                selected={showPerPage === 50 && true}
                                value={50}
                              >
                                50
                              </option>
                              <option
                                selected={showPerPage === 100 && true}
                                value={100}
                              >
                                100
                              </option>
                              <option
                                selected={showPerPage === "all" && true}
                                value="all"
                              >
                                all
                              </option>
                            </select>
                          </div>
                        </Col>
                        <Col xs={4} sm={4}>
                          <ButtonGroup>
                            <Button
                              className="buttons-copy buttons-html5"
                              color="warning"
                              size="sm"
                              id="copy-tooltip"
                              onClick={() =>
                                window.open(
                                  `${baseURL1}/api/areas/all/csv`
                                )
                              }
                            >
                              <span>CSV</span>
                            </Button>
                            <ReactToPrint
                              trigger={() => (
                                <Button
                                  color="warning"
                                  size="sm"
                                  className="buttons-copy buttons-html5"
                                  id="print-tooltip"
                                >
                                  Print
                                </Button>
                              )}
                              content={() => componentRef.current}
                            />
                          </ButtonGroup>
                          <UncontrolledTooltip
                            placement="top"
                            target="print-tooltip"
                          >
                            This will open a print page with the visible rows of
                            the table.
                          </UncontrolledTooltip>
                          <UncontrolledTooltip
                            placement="top"
                            target="copy-tooltip"
                          >
                            This will Download Communication Setting As CSV.
                          </UncontrolledTooltip>
                        </Col>
                        <Col xs={4} sm={4}>
                          <div
                            id="datatable-basic_filter"
                            className="dataTables_filter px-4 pb-1 float-right"
                          >
                            <label>
                              Search:
                              <SearchBar
                                className="form-control-sm"
                                placeholder=""
                                {...props.searchProps}
                              />
                            </label>
                          </div>
                        </Col>
                      </Row>
                    </Container>
                    <BootstrapTable
                      ref={componentRef}
                      {...props.baseProps}
                      bootstrap4={true}
                      pagination={pagination}
                      bordered={false}
                      id="react-bs-table"
                    />
                  </div>
                )}
              </ToolkitProvider>
            </Card>
          </div>
        </Row>
      </Container>
      {/* Edit Modal */}
      <Modal
        className="modal-dialog-centered"
        size="md"
        isOpen={formModal}
        toggle={() => setformModal(false)}
      >
        <div className="modal-body p-0">
          <Card className="bg-secondary border-0 mb-0">
            <CardHeader style={{ color: "#DB5A3B" }} className="bg-transparent">
              Update Area
            </CardHeader>
            <CardBody className="px-lg-5 py-lg-5">
              <Form role="form">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlSelect1"
                  >
                    Region
                  </label>
                  <Input
                    id="exampleFormControlSelect1"
                    onChange={(e) => setEditRegion(e.target.value)}
                    type="select"
                  >
                    <option>Select</option>
                    {region.map((region) => (
                      <option
                        selected={
                          region._id === singleArea?.region ? true : false
                        }
                        value={region._id}
                      >
                        {region.name}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
                <FormGroup
                  className={classnames("mb-3", {
                    focused: areaID,
                  })}
                >
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlSelect1"
                  >
                    Area ID
                  </label>
                  <InputGroup className="input-group-merge input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Area ID"
                      defaultValue={singleArea?.id}
                      type="name"
                      onChange={(e) => setAreaID(e.target.value)}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup
                  className={classnames("mb-3", {
                    focused: areaName,
                  })}
                >
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlSelect1"
                  >
                    Area Name
                  </label>
                  <InputGroup className="input-group-merge input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Area Name"
                      defaultValue={singleArea?.name}
                      type="name"
                      onChange={(e) => setAreaName(e.target.value)}
                    />
                  </InputGroup>
                </FormGroup>
                <div className="text-center">
                  <Button
                    onClick={() => setformModal(false)}
                    className="my-4"
                    color="danger"
                    type="button"
                  >
                    Close
                  </Button>
                  <Button
                    onClick={() => updateOneAreaData(singleArea?._id)}
                    className="my-4"
                    color="success"
                    type="button"
                  >
                    Update
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </div>
      </Modal>
      {/* Add Area */}
      <Modal
        className="modal-dialog-centered"
        size="md"
        isOpen={addAreaModal}
        toggle={() => setAreaModal(false)}
      >
        <div className="modal-body p-0">
          <Card className="bg-secondary border-0 mb-0">
            <CardHeader style={{ color: "#DB5A3B" }} className="bg-transparent">
              Add Area
            </CardHeader>
            <CardBody className="px-lg-5 py-lg-5">
              <Form role="form">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlSelect1"
                  >
                    Region
                  </label>
                  <Input
                    id="exampleFormControlSelect1"
                    onChange={(e) => setNewRegion(e.target.value)}
                    type="select"
                  >
                    <option>Select</option>
                    {region.map((region) => (
                      <option value={region._id}>{region.name}</option>
                    ))}
                  </Input>
                </FormGroup>
                <FormGroup
                  className={classnames("mb-3", {
                    focused: newAreaID,
                  })}
                >
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlSelect1"
                  >
                    Area ID
                  </label>
                  <InputGroup className="input-group-merge input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Area ID"
                      type="name"
                      onChange={(e) => setNewAreaID(e.target.value)}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup
                  className={classnames("mb-3", {
                    focused: newAreaName,
                  })}
                >
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlSelect1"
                  >
                    Area Name
                  </label>
                  <InputGroup className="input-group-merge input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Area Name"
                      type="name"
                      onChange={(e) => setNewAreaName(e.target.value)}
                    />
                  </InputGroup>
                </FormGroup>
                <div className="text-center">
                  <Button
                    onClick={() => setAreaModal(false)}
                    className="my-4"
                    color="danger"
                    type="button"
                  >
                    Close
                  </Button>
                  <Button
                    onClick={submitNewTms}
                    className="my-4"
                    color="success"
                    type="button"
                  >
                    Add
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </div>
      </Modal>
    </div>
  );
};

export default AreaSetting;
