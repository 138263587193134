import React from 'react';
import {Card, CardBody, Button, Container} from "reactstrap";
import AuthHeader from "../../components/Headers/AuthHeader";
import IndexNavbar from "../../components/Navbars/IndexNavbar";

const Teams = () => {
    return (
        <div>
            <IndexNavbar/>
            <AuthHeader title="Best Team For ERP Application" lead=""/>
            <Container className="mt--8 pb-5">
                <Card>
                    <CardBody>
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                            <img
                                alt="..."
                                className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                                src={require("assets/img/brand/abdul.jpg").default}
                                style={{width: "140px"}}
                            />
                        </a>
                        <div className="pt-4 text-center">
                            <h5 className="h3 title">
                                <span className="d-block mb-1">MD ABDUR RAHMAN</span>
                                <small className="h4 font-weight-light text-muted">
                                    Lead Software Developer
                                </small>
                                <br/>
                                <small className="h4 font-weight-light text-muted">
                                    rj.abdurrahman@outlook.com
                                </small>
                                <br/>
                                <small className="h4 font-weight-light text-muted">
                                    +8801757110296
                                </small>
                                <br/>
                                <small className="h4 font-weight-light text-muted">
                                    Sotopodaulia, Jhikargachha, Horidrapota - 7432, Jashore
                                </small>
                            </h5>
                            <div className="mt-3">
                                <Button
                                    className="btn-icon-only rounded-circle"
                                    color="facebook"
                                    href="#pablo"
                                    onClick={(e) => e.preventDefault()}
                                >
                                    <i className="fab fa-facebook"/>
                                </Button>
                            </div>
                        </div>
                    </CardBody>
                </Card>
                <Card>
                    <CardBody>
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                            <img
                                alt="..."
                                className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                                src={require("assets/img/brand/Uzzal.jpg").default}
                                style={{width: "140px"}}
                            />
                        </a>
                        <div className="pt-4 text-center">
                            <h5 className="h3 title">
                                <span className="d-block mb-1">Jahangir Hossain Uzzal</span>
                                <small className="h4 font-weight-light text-muted">
                                    Senior Full Stack Developer
                                </small>
                                <br/>
                                <small className="h4 font-weight-light text-muted">
                                    uzzal@fifo-tech.com
                                </small>
                                <br/>
                                <small className="h4 font-weight-light text-muted">
                                    +8801927666222
                                </small>
                                <br/>
                                <small className="h4 font-weight-light text-muted">
                                    P/O: 2142/A Uttora, Dhaka
                                </small>
                            </h5>
                            <div className="mt-3">
                                <Button
                                    className="btn-icon-only rounded-circle"
                                    color="facebook"
                                    href="https://facebook.com"
                                    onClick={(e) => e.preventDefault()}
                                >
                                    <i className="fab fa-facebook"/>
                                </Button>
                                <Button
                                    className="btn-icon-only rounded-circle"
                                    color="dribbble"
                                    href="https://github.com"
                                    onClick={(e) => e.preventDefault()}
                                >
                                    <i className="fab fa-github"/>
                                </Button>
                            </div>
                        </div>
                    </CardBody>
                </Card>
                <Card>
                    <CardBody>
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                            <img
                                alt="..."
                                className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                                src={require("assets/img/brand/13611496.jpg").default}
                                style={{width: "140px"}}
                            />
                        </a>
                        <div className="pt-4 text-center">
                            <h5 className="h3 title">
                                <span className="d-block mb-1">Rashaduzamman Rian</span>
                                <small className="h4 font-weight-light text-muted">
                                    Web Developer (MERN)
                                </small>
                                <br/>
                                <small className="h4 font-weight-light text-muted">
                                    rian@fifo-tech.com
                                </small>
                                <br/>
                                <small className="h4 font-weight-light text-muted">
                                    +8801632321051
                                </small>
                                <br/>
                                <small className="h4 font-weight-light text-muted">
                                    P/O: Shapla Housing, Dhaka-1207
                                </small>
                            </h5>
                            <div className="mt-3">
                                <Button
                                    className="btn-icon-only rounded-circle"
                                    color="facebook"
                                    onClick={(e) => window.open("https://facebook.com/rian.nbr")}
                                >
                                    <i className="fab fa-facebook"/>
                                </Button>
                                <Button
                                    className="btn-icon-only rounded-circle"
                                    color="dribbble"
                                    onClick={(e) => window.open("https://github.com/riandev")}
                                >
                                    <i className="fab fa-github"/>
                                </Button>
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </Container>

        </div>
    );
};

export default Teams;
