import React, {useContext, useEffect} from "react";

import ReactToPrint from "react-to-print";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {Search} from "react-bootstrap-table2-toolkit";
import {
    Button,
    ButtonGroup,
    Card,
    CardHeader,
    Container,
    CardBody,
    FormGroup,
    Form,
    Input,
    Row,
    Col,
    UncontrolledTooltip,
    InputGroup,
} from "reactstrap";
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";

import axios from "axios";
import NotificationAlert from "react-notification-alert";
import DataContext from "context/DataContext";
import baseApi1 from "../api/BaseApi1";
import {baseURL1} from "../api/baseURL.json";

const {SearchBar} = Search;
const ViewDatabase = () => {
    const {region, area, territory, salesPoint, tms} = useContext(DataContext);
    const [showPerPage, setShowPerPage] = React.useState(25);
    const [page, setPage] = React.useState(1);
    const [searchTerm, setSearchTerm] = React.useState("");
    const [totalSize, setTotalSize] = React.useState(10);
    const userInfo = JSON.parse(localStorage.getItem("user"));
    //Filters
    const [filterRegion, setFilterRegion] = React.useState("");
    const [filterArea, setFilterArea] = React.useState("");
    const [filterTerritory, setFilterTerritory] = React.useState("");
    const [filterSalesPoint, setFilterSalesPoint] = React.useState("");
    const [selectedTms, setSelectedTms] = React.useState("");
    const [allDatabase, setAllDatabase] = React.useState([]);
    console.log("allDatabase", allDatabase);

    //Notification Alert
    const notificationAlertRef = React.useRef(null);
    const notify = (type, message) => {
        let options = {
            place: "bc",
            message: (
                <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
              Master Database Notification
          </span>
                    <span data-notify="message">{message}</span>
                </div>
            ),
            type: type,
            icon: "ni ni-bell-55",
            autoDismiss: 7,
        };
        notificationAlertRef.current.notificationAlert(options);
    };
    //DropdownFilter
    const newfilteredArea = area.filter((item) => item.regionId === filterRegion);
    const newfilteredTerritory = territory.filter(
        (item) => item.areaId === filterArea
    );
    const newfilteredSalesPoint = salesPoint.filter(
        (item) =>
            item.territory._id ===
            (filterTerritory === "" ? userInfo?.territory?._id : filterTerritory)
    );
    const newfilteredTms = tms.filter(
        (item) =>
            item.territoryId ===
            (filterTerritory === "" ? userInfo?.territory?._id : filterTerritory)
    );

    const componentRef = React.useRef(null);
    const getViewData = async () => {
        try {
            const response = await baseApi1.get(
                `/api/database?region=${
                    userInfo.region === undefined ? filterRegion : userInfo.region._id
                }&area=${
                    userInfo.area === undefined ? filterArea : userInfo.area._id
                }&territory=${
                    userInfo.territory === undefined
                        ? filterTerritory
                        : userInfo.territory._id
                }&salesPoint=${filterSalesPoint}&tms=${selectedTms}&page=${page}&limit=${showPerPage}&search=${searchTerm}`
            );
            if (!response.statusText === "OK") {
                console.log("Not Getting Response");
            } else {
                setAllDatabase(response?.data?.data);
                setTotalSize(response?.data?.total);
                console.log("Api Load", response);
            }
        } catch (error) {
            notify("danger", error.response.message);
        }
    };

    const pagination = paginationFactory({
        page,
        totalSize,
        showTotal: true,
        sizePerPage: showPerPage,
        alwaysShowAllBtns: true,
        withFirstAndLast: true,
        onPageChange: (page) => {
            setPage(page);
        },
        onSizePerPageChange: (_, size) => {
            setShowPerPage(size);
        },
        sizePerPageRenderer: ({
                                  options,
                                  currSizePerPage,
                                  onSizePerPageChange,
                              }) => (
            <div className="dataTables_length" id="datatable-basic_length">
                <label>
                    Show{" "}
                    {
                        <select
                            value={currSizePerPage}
                            name="datatable-basic_length"
                            aria-controls="datatable-basic"
                            className="form-control form-control-sm"
                            onChange={(e) => onSizePerPageChange(e.target.value)}
                        >
                            <option value={10}>10</option>
                            <option value={25}>25</option>
                            <option value={50}>50</option>
                            <option value={100}>100</option>
                            <option value="all">all</option>
                        </select>
                    }{" "}
                    entries.
                </label>
            </div>
        ),
    });
    useEffect(() => {
        getViewData();
    }, [page, showPerPage, searchTerm]);

    const handleViewData = async () => {
        await setAllDatabase([]);
        getViewData();
    };
    return (
        <div>
            <div className="rna-wrapper">
                <NotificationAlert ref={notificationAlertRef}/>
            </div>
            <SimpleHeader name="Master Database" parentName="Database"/>
            <Container className="mt--6" fluid>
                <Row>
                    <div className="col">
                        <Card>
                            <CardHeader className="d-flex justify-content-between">
                                <h3 style={{color: "#DB5A3B"}}>Filters</h3>
                            </CardHeader>
                            <CardBody className="px-lg-5 py-lg-5">
                                <Form role="form">
                                    <Row className="row-example">
                                        <Col xs={4} sm={4}>
                                            <FormGroup>
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="exampleFormControlSelect1"
                                                >
                                                    Region
                                                </label>
                                                {userInfo.region === undefined ? (
                                                    <Input
                                                        id="exampleFormControlSelect1"
                                                        onChange={(e) => setFilterRegion(e.target.value)}
                                                        type="select"
                                                    >
                                                        <option>Select</option>
                                                        {region.map((region) => (
                                                            <option value={region._id}>{region.name}</option>
                                                        ))}
                                                    </Input>
                                                ) : (
                                                    <InputGroup className="input-group-merge input-group-alternative">
                                                        <Input
                                                            type="name"
                                                            value={userInfo.region.name}
                                                            disabled
                                                        />
                                                    </InputGroup>
                                                )}
                                            </FormGroup>
                                        </Col>
                                        <Col xs={4} sm={4}>
                                            <FormGroup>
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="exampleFormControlSelect1"
                                                >
                                                    Area
                                                </label>
                                                {userInfo.area === undefined ? (
                                                    <Input
                                                        id="exampleFormControlSelect1"
                                                        onChange={(e) => setFilterArea(e.target.value)}
                                                        type="select"
                                                    >
                                                        <option>Select</option>
                                                        {newfilteredArea.map((area) => (
                                                            <option value={area._id}>{area.name}</option>
                                                        ))}
                                                    </Input>
                                                ) : (
                                                    <InputGroup className="input-group-merge input-group-alternative">
                                                        <Input
                                                            type="name"
                                                            value={userInfo.area.name}
                                                            disabled
                                                        />
                                                    </InputGroup>
                                                )}
                                            </FormGroup>
                                        </Col>
                                        <Col xs={4} sm={4}>
                                            <FormGroup>
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="exampleFormControlSelect1"
                                                >
                                                    Territory
                                                </label>
                                                {userInfo.territory === undefined ? (
                                                    <Input
                                                        id="exampleFormControlSelect1"
                                                        onChange={(e) => setFilterTerritory(e.target.value)}
                                                        type="select"
                                                    >
                                                        <option>Select</option>
                                                        {newfilteredTerritory.map((territory) => (
                                                            <option value={territory._id}>
                                                                {territory.name}
                                                            </option>
                                                        ))}
                                                    </Input>
                                                ) : (
                                                    <InputGroup className="input-group-merge input-group-alternative">
                                                        <Input
                                                            type="name"
                                                            value={userInfo.territory.name}
                                                            disabled
                                                        />
                                                    </InputGroup>
                                                )}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row className="row-example">
                                        <Col xs={6} sm={6}>
                                            <FormGroup>
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="exampleFormControlSelect1"
                                                >
                                                    Sales Point
                                                </label>
                                                <Input
                                                    id="exampleFormControlSelect1"
                                                    onChange={(e) => setFilterSalesPoint(e.target.value)}
                                                    type="select"
                                                >
                                                    <option>Select</option>
                                                    {newfilteredSalesPoint.map((sp) => (
                                                        <option value={sp._id}>{sp.name}</option>
                                                    ))}
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                        <Col xs={6} sm={6}>
                                            <FormGroup>
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="exampleFormControlSelect1"
                                                >
                                                    TMS
                                                </label>
                                                <Input
                                                    id="exampleFormControlSelect1"
                                                    onChange={(e) => setSelectedTms(e.target.value)}
                                                    type="select"
                                                >
                                                    <option>Select</option>
                                                    {newfilteredTms?.map((tms) => (
                                                        <option value={tms._id}>
                                                            {`${tms.name} (${tms.phone})`}
                                                        </option>
                                                    ))}
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <div className="text-right">
                                        <Button
                                            onClick={handleViewData}
                                            color="warning"
                                            type="button"
                                        >
                                            View
                                        </Button>
                                    </div>
                                </Form>
                            </CardBody>
                        </Card>
                    </div>
                </Row>
                <Row>
                    <div className="col">
                        <Card>
                            <CardHeader className="d-flex justify-content-between">
                                <h3 style={{color: "#DB5A3B"}}>View Master Database</h3>
                            </CardHeader>
                            <ToolkitProvider
                                data={allDatabase}
                                keyField="Outlet Code"
                                columns={[
                                    {
                                        dataField: "#",
                                        text: "SL",
                                        formatter: (cell, row, rowIndex) => {
                                            return rowIndex + 1;
                                        },
                                    },
                                    {
                                        dataField: "Region ID",
                                        text: "Region ID",
                                        sort: true,
                                    },
                                    {
                                        dataField: "Region",
                                        text: "Region Name",
                                        sort: true,
                                    },
                                    {
                                        dataField: "Area ID",
                                        text: "Area ID",
                                        sort: true,
                                    },
                                    {
                                        dataField: "Area",
                                        text: "Area Name",
                                        sort: true,
                                    },
                                    {
                                        dataField: "Territory ID",
                                        text: "Territory ID",
                                        sort: true,
                                    },
                                    {
                                        dataField: "Territory",
                                        text: "Territory Name",
                                        sort: true,
                                    },
                                    {
                                        dataField: "Sales Point ID",
                                        text: "SalesPoint ID",
                                        sort: true,
                                    },
                                    {
                                        dataField: "Sales Point",
                                        text: "SalesPoint Name",
                                        sort: true,
                                    },
                                    {
                                        dataField: "Route ID",
                                        text: "Route ID",
                                        sort: true,
                                    },
                                    {
                                        dataField: "Route",
                                        text: "Route Name",
                                        sort: true,
                                    },
                                    {
                                        dataField: "Outlet Code",
                                        text: "Outlet Code",
                                        sort: true,
                                    },
                                    {
                                        dataField: "Outlet Name",
                                        text: "Outlet Name",
                                        sort: true,
                                    },
                                    {
                                        dataField: "PCM_Name",
                                        text: "Deployed PCM",
                                        sort: true,
                                    },
                                    {
                                        dataField: "Retailer Name",
                                        text: "Retailer Name",
                                        sort: true,
                                    },
                                    {
                                        dataField: "Retailer Phone",
                                        text: "Retailer Phone",
                                        sort: true,
                                    },
                                    {
                                        dataField: "Retailer Email",
                                        text: "Retailer Email",
                                        sort: true,
                                    },
                                    {
                                        dataField: "Retailer Address",
                                        text: "Retailer Address",
                                        sort: true,
                                    },
                                    {
                                        dataField: "Emergency Contact",
                                        text: "Retailer Emergency Contact",
                                        sort: true,
                                    },
                                    {
                                        dataField: "Relationship",
                                        text: "Relation With Retailer",
                                        sort: true,
                                    },
                                    {
                                        dataField: "TMS Name",
                                        text: "TMS Name",
                                        sort: true,
                                    },
                                    {
                                        dataField: "TMS Entroll ID",
                                        text: "TMS EnrollId",
                                        sort: true,
                                    },
                                    {
                                        dataField: "TMS Email",
                                        text: "TMS Email",
                                        sort: true,
                                    },
                                    {
                                        dataField: "TMS Phone",
                                        text: "TMS Phone",
                                        sort: true,
                                    },
                                    {
                                        dataField: "TMS Password",
                                        text: "TMS Password",
                                        sort: true,
                                    },
                                    {
                                        dataField: "active",
                                        text: "TMS Status",
                                        sort: true,
                                    },
                                    {
                                        dataField: "Volume_RMC April_2022",
                                        text: "Portfolio Jul_2022",
                                        sort: true,
                                    },
                                    {
                                        dataField: "Volume_RMC May_2022",
                                        text: "Portfolio Aug_2022",
                                        sort: true,
                                    },
                                    {
                                        dataField: "Volume_RMC June_2022",
                                        text: "Portfolio Sept_2022",
                                        sort: true,
                                    },
                                    {
                                        dataField: "Focused_Volume April_2022",
                                        text: "Focused_Volume Jul_2022",
                                        sort: true,
                                    },
                                    {
                                        dataField: "Focused_Volume May_2022",
                                        text: "Focused_Volume Aug_2022",
                                        sort: true,
                                    },
                                    {
                                        dataField: "Focused_Volume June_2022",
                                        text: "Focused_Volume Sept_2022",
                                        sort: true,
                                    },
                                    {
                                        dataField: "Brand",
                                        text: "Focused Brand",
                                        sort: true,
                                    },
                                    {
                                        dataField: "EAS April_2022",
                                        text: "EAS Jul_2022",
                                        sort: true,
                                    },
                                    {
                                        dataField: "EAS May_2022",
                                        text: "EAS Aug_2022",
                                        sort: true,
                                    },
                                    {
                                        dataField: "EAS June_2022",
                                        text: "EAS Sept_2022",
                                        sort: true,
                                    },
                                ]}
                                search
                            >
                                {(props) => (
                                    <div className="py-4 table-responsive">
                                        <Container fluid>
                                            <Row>
                                                <Col xs={4} sm={4}>
                                                    <div>
                                                        <select
                                                            name="datatable-basic_length"
                                                            aria-controls="datatable-basic"
                                                            className="form-control form-control-sm w-25"
                                                            onChange={(e) => setShowPerPage(e.target.value)}
                                                        >
                                                            <option
                                                                selected={showPerPage === 10 && true}
                                                                value={10}
                                                            >
                                                                10
                                                            </option>
                                                            <option
                                                                selected={showPerPage === 25 && true}
                                                                value={25}
                                                            >
                                                                25
                                                            </option>
                                                            <option
                                                                selected={showPerPage === 50 && true}
                                                                value={50}
                                                            >
                                                                50
                                                            </option>
                                                            <option
                                                                selected={showPerPage === 100 && true}
                                                                value={100}
                                                            >
                                                                100
                                                            </option>
                                                            <option
                                                                selected={showPerPage === "all" && true}
                                                                value="all"
                                                            >
                                                                all
                                                            </option>
                                                        </select>
                                                    </div>
                                                </Col>
                                                <Col xs={4} sm={4}>
                                                    <ButtonGroup>
                                                        <Button
                                                            className="buttons-copy buttons-html5"
                                                            color="warning"
                                                            size="sm"
                                                            id="copy-tooltip"
                                                            onClick={() =>
                                                                window.open(
                                                                    `${baseURL1}/api/database/csv?region=${
                                                                        userInfo.region === undefined
                                                                            ? filterRegion
                                                                            : userInfo.region._id
                                                                    }&area=${
                                                                        userInfo.area === undefined
                                                                            ? filterArea
                                                                            : userInfo.area._id
                                                                    }&territory=${
                                                                        userInfo.territory === undefined
                                                                            ? filterTerritory
                                                                            : userInfo.territory._id
                                                                    }&salesPoint=${filterSalesPoint}&tms=${selectedTms}`
                                                                )
                                                            }
                                                        >
                                                            <span>CSV</span>
                                                        </Button>
                                                        <ReactToPrint
                                                            trigger={() => (
                                                                <Button
                                                                    color="warning"
                                                                    size="sm"
                                                                    className="buttons-copy buttons-html5"
                                                                    id="print-tooltip"
                                                                >
                                                                    Print
                                                                </Button>
                                                            )}
                                                            content={() => componentRef.current}
                                                        />
                                                    </ButtonGroup>
                                                    <UncontrolledTooltip
                                                        placement="top"
                                                        target="print-tooltip"
                                                    >
                                                        This will open a print page with the visible rows of
                                                        the table.
                                                    </UncontrolledTooltip>
                                                    <UncontrolledTooltip
                                                        placement="top"
                                                        target="copy-tooltip"
                                                    >
                                                        This will Download Outlet Setting As CSV.
                                                    </UncontrolledTooltip>
                                                </Col>
                                                <Col xs={4} sm={4}>
                                                    <div
                                                        id="datatable-basic_filter"
                                                        className="dataTables_filter px-4 pb-1 float-right"
                                                    >
                                                        <label>
                                                            Search:
                                                            <SearchBar
                                                                className="form-control-sm"
                                                                placeholder=""
                                                                {...props.searchProps}
                                                            />
                                                        </label>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Container>
                                        <BootstrapTable
                                            remote
                                            ref={componentRef}
                                            {...props.baseProps}
                                            bootstrap4={true}
                                            pagination={pagination}
                                            bordered={false}
                                            id="react-bs-table"
                                            onTableChange={(type, {searchText}) => {
                                                setSearchTerm(searchText);
                                            }}
                                        />
                                    </div>
                                )}
                            </ToolkitProvider>
                        </Card>
                    </div>
                </Row>
            </Container>
        </div>
    );
};

export default ViewDatabase;
