// nodejs library that concatenates classes
import classnames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import { Link, useHistory } from "react-router-dom";
import Marquee from "react-fast-marquee";
// reactstrap components11
import {
    Collapse,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    ListGroupItem,
    ListGroup,
    Media,
    Navbar,
    NavItem,
    NavLink,
    Nav,
    Container,
    Row,
    Col,
} from "reactstrap";

function AdminNavbar({ theme, sidenavOpen, toggleSidenav }) {
    const history = useHistory();
    // function that on mobile devices makes the search open
    const openSearch = () => {
        document.body.classList.add("g-navbar-search-showing");
        setTimeout(function () {
            document.body.classList.remove("g-navbar-search-showing");
            document.body.classList.add("g-navbar-search-show");
        }, 150);
        setTimeout(function () {
            document.body.classList.add("g-navbar-search-shown");
        }, 300);
    };
    // function that on mobile devices makes the search close
    const closeSearch = () => {
        document.body.classList.remove("g-navbar-search-shown");
        setTimeout(function () {
            document.body.classList.remove("g-navbar-search-show");
            document.body.classList.add("g-navbar-search-hiding");
        }, 150);
        setTimeout(function () {
            document.body.classList.remove("g-navbar-search-hiding");
            document.body.classList.add("g-navbar-search-hidden");
        }, 300);
        setTimeout(function () {
            document.body.classList.remove("g-navbar-search-hidden");
        }, 500);
    };

    const logOut = () => {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        history.replace("/auth/login");
    };
    const userInfo = JSON.parse(localStorage.getItem("user"));
    return (
        <>
            <Navbar
                className={classnames(
                    "navbar-top navbar-expand border-bottom",
                    { "navbar-dark bg-warning": theme === "dark" },
                    { "navbar-light bg-warning": theme === "light" }
                )}
            >
                <Container fluid>
                    <Collapse navbar isOpen={true}>
                        <Form
                            className={classnames(
                                "navbar-search form-inline mr-sm-3",
                                { "navbar-search-light": theme === "dark" },
                                { "navbar-search-dark": theme === "light" }
                            )}
                        >
                            <FormGroup className="mb-0">
                                <InputGroup className="input-group-alternative input-group-merge">
                                    {/* <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="fas fa-search" />
                    </InputGroupText>
                  </InputGroupAddon> */}
                                    {/* <Marquee
                    style={{ backgroundColor: "orange", color: "white" }}
                    pauseOnClick={true}
                    pauseOnHover={true}
                  >
                    Hello Team, Thanks For Your Energatic Start
                  </Marquee> */}
                                </InputGroup>
                            </FormGroup>
                            <button
                                aria-label="Close"
                                className="close"
                                type="button"
                                onClick={closeSearch}
                            >
                                <span aria-hidden={true}>×</span>
                            </button>
                        </Form>

                        <Nav className="align-items-center ml-md-auto" navbar>
                            <NavItem className="d-xl-none">
                                <div
                                    className={classnames(
                                        "pr-3 sidenav-toggler",
                                        { active: sidenavOpen },
                                        { "sidenav-toggler-dark": theme === "dark" }
                                    )}
                                    onClick={toggleSidenav}
                                >
                                    <div className="sidenav-toggler-inner">
                                        <i className="sidenav-toggler-line" />
                                        <i className="sidenav-toggler-line" />
                                        <i className="sidenav-toggler-line" />
                                    </div>
                                </div>
                            </NavItem>
                            <NavItem className="d-sm-none">
                                <NavLink onClick={openSearch}>
                                    <i className="ni ni-zoom-split-in" />
                                </NavLink>
                            </NavItem>
                            <UncontrolledDropdown nav>
                                <DropdownToggle className="nav-link" color="" tag="a">
                                    <i className="ni ni-bell-55" />
                                </DropdownToggle>
                                <DropdownMenu
                                    className="dropdown-menu-xl py-0 overflow-hidden"
                                    right
                                >
                                    <div className="px-3 py-3">
                                        <h6 className="text-sm text-muted m-0">
                                            You have <strong className="text-info">no</strong>{" "}
                                            notifications.
                                        </h6>
                                    </div>

                                    <ListGroup flush>
                                        <ListGroupItem
                                            className="list-group-item-action"
                                            href="#pablo"
                                            onClick={() => {
                                                <NavItem>
                                                    <NavLink to="/admin/calender" tag={Link}></NavLink>
                                                </NavItem>;
                                            }}
                                            tag="a"
                                        >
                                            <Row className="align-items-center">
                                                <Col className="col-auto">
                                                    <img
                                                        alt="..."
                                                        className="avatar rounded-circle"
                                                        src={require("assets/img/brand/Logo.png").default}
                                                    />
                                                </Col>
                                                <div className="col ml--2">
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <div>
                                                            <h4 className="mb-0 text-sm">
                                                                Welcome Notification
                                                            </h4>
                                                        </div>
                                                        <div className="text-right text-muted">
                                                            <small>2 hrs ago</small>
                                                        </div>
                                                    </div>
                                                    <p className="text-sm mb-0">
                                                        Let's have a good and energatic start !!?
                                                    </p>
                                                </div>
                                            </Row>
                                        </ListGroupItem>
                                    </ListGroup>

                                    <DropdownItem
                                        className="text-center text-info font-weight-bold py-3"
                                        href="#pablo"
                                        onClick={(e) => e.preventDefault()}
                                    >
                                        View all
                                    </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                            <UncontrolledDropdown nav>
                                <DropdownToggle className="nav-link" color="" tag="a">
                                    <i className="ni ni-ungroup" />
                                </DropdownToggle>
                                <DropdownMenu
                                    className="dropdown-menu-lg dropdown-menu-dark bg-default"
                                    right
                                >
                                    <Row className="shortcuts px-4">
                                        <Col
                                            className="shortcut-item"
                                            href="#pablo"
                                            onClick={(e) => e.preventDefault()}
                                            xs="4"
                                            tag="a"
                                        >
                                            <span className="shortcut-media avatar rounded-circle bg-gradient-red">
                                                <i className="ni ni-calendar-grid-58" />
                                            </span>
                                            <small>Calendar</small>
                                        </Col>
                                        <Col
                                            className="shortcut-item"
                                            href="#pablo"
                                            onClick={(e) => e.preventDefault()}
                                            xs="4"
                                            tag="a"
                                        >
                                            <span className="shortcut-media avatar rounded-circle bg-gradient-green">
                                                <i className="ni ni-books" />
                                            </span>
                                            <small>Reports</small>
                                        </Col>
                                    </Row>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </Nav>
                        <Nav className="align-items-center ml-auto ml-md-0" navbar>
                            <UncontrolledDropdown nav>
                                <DropdownToggle className="nav-link pr-0" color="" tag="a">
                                    <Media className="align-items-center">
                                        <span className="avatar avatar-sm rounded-circle">
                                            <img
                                                alt="..."
                                                src={require("assets/img/brand/Logo.png").default}
                                            />
                                        </span>
                                        <Media className="ml-2 d-none d-lg-block">
                                            <span className="mb-0 text-sm font-weight-bold">
                                                {userInfo.name}
                                            </span>
                                        </Media>
                                    </Media>
                                </DropdownToggle>
                                <DropdownMenu right>
                                    <DropdownItem className="noti-title" header tag="div">
                                        <h6 className="text-overflow m-0">Welcome!</h6>
                                    </DropdownItem>
                                    <DropdownItem
                                        href="/admin/change-password"
                                        onClick={(e) => {
                                            window.location.href = '/admin/change-password'
                                            e.preventDefault()
                                        }}
                                    >
                                        <i className="ni ni-key-25" />
                                        <span>Change Password</span>
                                    </DropdownItem>
                                    <DropdownItem divider />
                                    <DropdownItem
                                        href="#pablo"
                                        onClick={(e) => e.preventDefault()}
                                    >
                                        <i className="ni ni-support-16" />
                                        <span>Support</span>
                                    </DropdownItem>
                                    <DropdownItem divider />
                                    <DropdownItem href="#pablo" onClick={logOut}>
                                        <i className="ni ni-user-run" />
                                        <span>Logout</span>
                                    </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </Nav>
                    </Collapse>
                </Container>
            </Navbar>
        </>
    );
}

AdminNavbar.defaultProps = {
    toggleSidenav: () => {
    },
    sidenavOpen: false,
    theme: "dark",
};
AdminNavbar.propTypes = {
    toggleSidenav: PropTypes.func,
    sidenavOpen: PropTypes.bool,
    theme: PropTypes.oneOf(["dark", "light"]),
};

export default AdminNavbar;
