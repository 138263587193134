import React, { useCallback, useContext, useLayoutEffect } from 'react'
import _ from "lodash";
import NotificationAlert from "react-notification-alert";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import { Button, ButtonGroup, Card, CardBody, CardHeader, CardTitle, Col, Container, Form, FormGroup, Input, InputGroup, Row, UncontrolledTooltip } from 'reactstrap';
import baseApi1 from "../../api/BaseApi1";
import DataContext from 'context/DataContext';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { baseURL1 } from "../../api/baseURL.json";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import CurrencyFormat from 'react-currency-format';
const { SearchBar } = Search;

export default function SalesIncentiveReport() {
    const notificationAlertRef = React.useRef(null);
    const userInfo = JSON.parse(localStorage.getItem("user"));
    const { region, area, territory, salesPoint, tms } = useContext(DataContext);
    const [filterRegion, setFilterRegion] = React.useState("");
    const [filterArea, setFilterArea] = React.useState("");
    const [filterTerritory, setFilterTerritory] = React.useState("");
    const [filterSalesPoint, setFilterSalesPoint] = React.useState("");
    const [selectedTms, setSelectedTms] = React.useState("");
    const newfilteredArea = area.filter(
        (item) =>
            item.region ===
            (userInfo.region === undefined ? filterRegion : userInfo.region.name)
    );
    const newfilteredTerritory = territory.filter(
        (item) =>
            item.area ===
            (userInfo.area === undefined ? filterArea : userInfo.area.name)
    );
    const newfilteredSalesPoint = salesPoint?.filter(
        (item) =>
            item?.territory?.name ===
            (filterTerritory === "" ? userInfo?.territory?.name : filterTerritory)
    );
    const newfilteredTms = tms.filter(
        (x) =>
            x.territory ===
            (filterTerritory === "" ? userInfo?.territory?.name : filterTerritory)
    );
    const [showPerPage, setShowPerPage] = React.useState(10);
    const [searchTerm, setSearchTerm] = React.useState("");
    const [page, setPage] = React.useState(1);
    const [totalSize, setTotalSize] = React.useState(10);

    const [data, setData] = React.useState([]);
    const [summary, setSummary] = React.useState([]);
    const [distributions, setDistributions] = React.useState(0);

    const notify = (type, message) => {
        let options = {
            place: "bc",
            message: (
                <div className="alert-text">
                    <span className="alert-title" data-notify="title">
                        {" "}
                        !Incentive
                    </span>
                    <span data-notify="message">{message}</span>
                </div>
            ),
            type: type,
            icon: "ni ni-bell-55",
            autoDismiss: 7,
        };
        notificationAlertRef.current.notificationAlert(options);
    };

    const handleViewData = useCallback(async () => {
        const response = await baseApi1.get(
            `/report/sales-incentive?region=${userInfo.region === undefined ? filterRegion : userInfo.region.name
            }&area=${userInfo.area === undefined ? filterArea : userInfo.area.name
            }&territory=${userInfo.territory === undefined
                ? filterTerritory
                : userInfo.territory.name
            }&salesPoint=${filterSalesPoint}&tms=${selectedTms}&search=${searchTerm}&page=${page}&limit=${showPerPage}`
        );
        if (!response.statusText === "OK") {
            notify("danger", response.data.message);
        } else {
            setData(response.data.data);
            setSummary(response.data.summary);
            setTotalSize(parseInt(response?.data?.total));
            setDistributions(response?.data?.distributionCount || 0);
        }
    }, [baseApi1, userInfo, filterRegion, filterArea, filterTerritory, filterSalesPoint, selectedTms, page])


    const pagination = paginationFactory({
        page,
        totalSize,
        showTotal: true,
        sizePerPage: showPerPage,
        alwaysShowAllBtns: true,
        withFirstAndLast: true,
        onPageChange: (page) => {
            setPage(page);
        },
        onSizePerPageChange: (_, size) => {
            setShowPerPage(size);
        },
        sizePerPageRenderer: ({
            options,
            currSizePerPage,
            onSizePerPageChange,
        }) => (
            <div className="dataTables_length" id="datatable-basic_length">
                <label>
                    Show{" "}
                    {
                        <select
                            value={currSizePerPage}
                            name="datatable-basic_length"
                            aria-controls="datatable-basic"
                            className="form-control form-control-sm"
                            onChange={(e) => onSizePerPageChange(e.target.value)}
                        >
                            <option value={10}>10</option>
                            <option value={25}>25</option>
                            <option value={50}>50</option>
                            <option value={100}>100</option>
                            <option value="all">all</option>
                        </select>
                    }{" "}
                    entries.
                </label>
            </div>
        ),
    });

    useLayoutEffect(() => {
        handleViewData();
    }, [page, showPerPage, searchTerm]);

    return (
        <div>
            <div className="rna-wrapper">
                <NotificationAlert ref={notificationAlertRef} />
            </div>
            <SimpleHeader name="Incentive Status" parentName="Incentive" />
            <Container className="mt--6" fluid>
                <Row>
                    <div className="col">
                        <Card>
                            <CardHeader className="d-flex justify-content-between">
                                <h3 style={{ color: "#DB5A3B" }}>Sales Incentive Status</h3>
                            </CardHeader>
                            {Boolean(summary.length) && <div className="mx-3 mt-3" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", flexWrap: "wrap" }}>
                                {[
                                    { title: "TOTAL", total: _.sumBy(summary, 'count') },
                                    { title: "PASSED", total: _.find(summary, { _id: 'Eligible' })?.count || 0},
                                    { title: "FAILED", total: _.sumBy(_.filter(summary, x => x._id !== 'Eligible'), 'count') },
                                    { title: "PASSED RATIO", total: Math.round((_.find(summary, { _id: 'Eligible' })?.count / _.sumBy(summary, 'count')) * 100) || 0 },
                                    { title: "DISTRIBUTED", total: distributions },
                                ].map(x => <Card className="card-stats bg-gradient-warning mb-4 mb-lg-0" style={{ width: "18rem" }}>
                                    <CardBody>
                                        <Row>
                                            <div className="col">
                                                <CardTitle className="text-uppercase text-white text-muted mb-0">
                                                    {x.title}
                                                </CardTitle>
                                                <span className="h2 font-weight-bold mb-0 text-white">
                                                    {x.title.includes('RATIO') ? <h1 className="text-white">{x.total}%</h1> : <CurrencyFormat
                                                        value={x.total}
                                                        displayType={"text"}
                                                        thousandSeparator={true}
                                                        prefix={""}
                                                    />}
                                                </span>
                                            </div>
                                            <Col className="col-auto">
                                                <div
                                                    className="icon icon-shape bg-danger text-white rounded-circle shadow">
                                                    <i className="fas fa-chart-bar" />
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>)}
                            </div>}
                            <div className="m-3" style={{ width: "calc(100% - 40px)" }}>
                                <Form role="form" onSubmit={(e) => e.preventDefault()}>
                                    <Row className="row-example">
                                        <Col xs={2} sm={2}>
                                            <FormGroup>
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="exampleFormControlSelect1"
                                                >
                                                    Region
                                                </label>
                                                {userInfo.region === undefined ? (
                                                    <Input
                                                        onChange={(e) => setFilterRegion(e.target.value)}
                                                        type="select"
                                                    >
                                                        <option>Select</option>
                                                        {region.map((region) => (
                                                            <option value={region.name}>{region.name}</option>
                                                        ))}
                                                    </Input>
                                                ) : (
                                                    <InputGroup className="input-group-merge input-group-alternative">
                                                        <Input
                                                            type="name"
                                                            value={userInfo.region.name}
                                                            disabled
                                                        />
                                                    </InputGroup>
                                                )}
                                            </FormGroup>
                                        </Col>
                                        <Col xs={2} sm={2}>
                                            <FormGroup>
                                                <label
                                                    className="form-control-label"
                                                >
                                                    Area
                                                </label>
                                                {userInfo.area === undefined ? (
                                                    <Input
                                                        onChange={(e) => setFilterArea(e.target.value)}
                                                        type="select"
                                                    >
                                                        <option>Select</option>
                                                        {newfilteredArea.map((area) => (
                                                            <option value={area.name}>{area.name}</option>
                                                        ))}
                                                    </Input>
                                                ) : (
                                                    <InputGroup className="input-group-merge input-group-alternative">
                                                        <Input
                                                            type="name"
                                                            value={userInfo.area.name}
                                                            disabled
                                                        />
                                                    </InputGroup>
                                                )}
                                            </FormGroup>
                                        </Col>
                                        <Col xs={2} sm={2}>
                                            <FormGroup>
                                                <label
                                                    className="form-control-label"
                                                >
                                                    Territory
                                                </label>
                                                {userInfo.territory === undefined ? (
                                                    <Input
                                                        onChange={(e) => setFilterTerritory(e.target.value)}
                                                        type="select"
                                                    >
                                                        <option>Select</option>
                                                        {newfilteredTerritory.map((territory) => (
                                                            <option value={territory.name}>
                                                                {territory.name}
                                                            </option>
                                                        ))}
                                                    </Input>
                                                ) : (
                                                    <InputGroup className="input-group-merge input-group-alternative">
                                                        <Input
                                                            type="name"
                                                            value={userInfo.territory.name}
                                                            disabled
                                                        />
                                                    </InputGroup>
                                                )}
                                            </FormGroup>
                                        </Col>
                                        <Col xs={3} sm={3}>
                                            <FormGroup>
                                                <label
                                                    className="form-control-label"
                                                >
                                                    Sales Point
                                                </label>
                                                <Input
                                                    onChange={(e) => setFilterSalesPoint(e.target.value)}
                                                    type="select"
                                                >
                                                    <option>Select</option>
                                                    {newfilteredSalesPoint.map((sp) => (
                                                        <option value={sp.name}>{sp.name}</option>
                                                    ))}
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                        <Col xs={3} sm={3}>
                                            <FormGroup>
                                                <label
                                                    className="form-control-label"
                                                >
                                                    TMS
                                                </label>
                                                <Input
                                                    onChange={(e) => setSelectedTms(e.target.value)}
                                                    type="select"
                                                >
                                                    <option>Select</option>
                                                    {newfilteredTms?.map((tms) => (
                                                        <option value={tms._id}>
                                                            {`${tms.name} (${tms.phone})`}
                                                        </option>
                                                    ))}
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <div className="text-right">
                                        <Button
                                            onClick={(e) => {
                                                e.preventDefault();
                                                handleViewData();
                                            }}
                                            color="warning"
                                            type="button"
                                        >
                                            View
                                        </Button>
                                    </div>
                                </Form>
                            </div>
                        </Card>
                    </div>
                </Row>
                <ToolkitProvider
                    data={data}
                    keyField="name"
                    columns={[
                        {
                            dataField: "#",
                            text: "SL",
                            formatter: (cell, row, rowIndex) => {
                                return rowIndex + 1;
                            },
                        },
                        ...['Region', 'Area', 'Territory', 'SalesPoint', 'OutletCode'].map(x => ({
                            dataField: x,
                            text: x,
                            sort: true,
                        })),
                        ...(_.difference(
                            _.keys(data[0]),
                            ['Region', 'Area', 'Territory', 'SalesPoint', 'OutletCode'])
                        ).map(x => ({
                            dataField: x,
                            text: x,
                            sort: true,
                        }))
                    ]}
                    search
                >
                    {(props) => (
                        <div className="py-4 table-responsive">
                            <Container fluid>
                                <Row>
                                    <Col xs={4} sm={4}>
                                        <div>
                                            <select
                                                name="datatable-basic_length"
                                                aria-controls="datatable-basic"
                                                className="form-control form-control-sm w-25"
                                                onChange={(e) => setShowPerPage(e.target.value)}
                                            >
                                                <option
                                                    selected={showPerPage === 10 && true}
                                                    value={10}
                                                >
                                                    10
                                                </option>
                                                <option
                                                    selected={showPerPage === 25 && true}
                                                    value={25}
                                                >
                                                    25
                                                </option>
                                                <option
                                                    selected={showPerPage === 50 && true}
                                                    value={50}
                                                >
                                                    50
                                                </option>
                                                <option
                                                    selected={showPerPage === 100 && true}
                                                    value={100}
                                                >
                                                    100
                                                </option>
                                                <option
                                                    selected={showPerPage === "all" && true}
                                                    value="all"
                                                >
                                                    all
                                                </option>
                                            </select>
                                        </div>
                                    </Col>
                                    <Col xs={4} sm={4}>
                                        <ButtonGroup>
                                            <Button
                                                className="buttons-copy buttons-html5"
                                                color="warning"
                                                size="sm"
                                                id="copy-tooltip"
                                                onClick={() =>
                                                    window.open(
                                                        `${baseURL1}/csv/sales-incentive?region=${userInfo.region === undefined ? filterRegion : userInfo.region.name
                                                        }&area=${userInfo.area === undefined ? filterArea : userInfo.area.name
                                                        }&territory=${userInfo.territory === undefined
                                                            ? filterTerritory
                                                            : userInfo.territory.name
                                                        }&salesPoint=${filterSalesPoint}&tms=${selectedTms}`
                                                    )
                                                }
                                            >
                                                <span>CSV</span>
                                            </Button>
                                        </ButtonGroup>
                                        <UncontrolledTooltip
                                            placement="top"
                                            target="copy-tooltip"
                                        >
                                            This will Download Communication Setting As CSV.
                                        </UncontrolledTooltip>
                                    </Col>
                                    <Col xs={4} sm={4}>
                                        <div
                                            id="datatable-basic_filter"
                                            className="dataTables_filter px-4 pb-1 float-right"
                                        >
                                            <label>
                                                Search:
                                                <SearchBar
                                                    className="form-control-sm"
                                                    placeholder=""
                                                    {...props.searchProps}
                                                />
                                            </label>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                            <BootstrapTable
                                remote
                                {...props.baseProps}
                                bootstrap4={true}
                                pagination={pagination}
                                bordered={false}
                                hover={true}
                                id="react-bs-table"
                                onTableChange={(type, { searchText }) => {
                                    setSearchTerm(searchText);
                                }}
                            />
                        </div>
                    )}
                </ToolkitProvider>
            </Container>
        </div>
    )
}
