import React from 'react'
import NotificationAlert from "react-notification-alert";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import { Button, Card, CardHeader, Container, Form, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Row } from 'reactstrap';
import classNames from 'classnames';
import baseApi1 from "../../api/BaseApi1";
import { useFormik } from 'formik';

export default function ChangePassword() {

    const notificationAlertRef = React.useRef(null);
    const notify = (type, message) => {
        let options = {
            place: "bc",
            message: (
                <div className="alert-text">
                    <span className="alert-title" data-notify="title">
                        {" "}
                        !Incentive
                    </span>
                    <span data-notify="message">{message}</span>
                </div>
            ),
            type: type,
            icon: "ni ni-bell-55",
            autoDismiss: 7,
        };
        notificationAlertRef.current.notificationAlert(options);
    };

    const {
        values,
        handleChange,
        handleSubmit,
        handleBlur,
        touched,
        errors,
        resetForm
    } = useFormik({
        initialValues: {
            password: '',
            newPassword: '',
            confirmPassword: ''
        },
        validate: (values) => {
            const errors = {};

            if (!values.password) {
                errors.password = 'Current password is required';
            } else if (values.password.length < 6) {
                errors.password = 'Password must be at least 6 characters long';
            }

            if (!values.newPassword) {
                errors.newPassword = 'New password is required';
            } else if (values.newPassword.length < 6) {
                errors.newPassword = 'New password must be at least 6 characters long';
            }

            if (!values.confirmPassword) {
                errors.confirmPassword = 'Please confirm your new password';
            } else if (values.newPassword !== values.confirmPassword) {
                errors.confirmPassword = 'Passwords do not match';
            }

            return errors;
        },
        onSubmit: async (values) => {
            try {
                const response = await baseApi1.post(
                    "/api/user/change-password",
                    values,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            "Authorization": "Bearer " + window.localStorage.token
                        }
                    }
                );
                if (!response.statusText === "OK") {
                    console.log("Not Getting Response");
                    notify("danger", "Some Thing Went Wrong");
                } else {
                    notify("success", response.data.message);
                    resetForm();
                }
            } catch (error) {
                notify("danger", error.response.data.message);
            }
        },
    })

    return (
        <div>
            <div className="rna-wrapper">
                <NotificationAlert ref={notificationAlertRef} />
            </div>
            <SimpleHeader name="Change Password" parentName="User" />
            <Container className="mt--6" fluid>
                <Row>
                    <div className="col">
                        <Card>
                            <CardHeader className="d-flex justify-content-between">
                                <h3 style={{ color: "#DB5A3B" }}>Change Password</h3>
                            </CardHeader>
                            <div className="m-3" style={{ width: "32rem" }}>
                                <Form onSubmit={handleSubmit} role="form">
                                    <FormGroup
                                        className={classNames("mb-3")}
                                    >
                                        <label
                                            className="form-control-label"
                                            htmlFor="exampleFormControlSelect1"
                                        >
                                            Current Password
                                        </label>
                                        <InputGroup className="input-group-merge input-group-alternative mb-2">
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    <i className="ni ni-key-25" />
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input
                                                placeholder="Current Password"
                                                type="password"
                                                id="password"
                                                name="password"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.password}
                                            />
                                        </InputGroup>
                                        {touched.password && errors.password && <div className="text-red my-2">{errors.password}</div>}
                                        <label
                                            className="form-control-label"
                                            htmlFor="exampleFormControlSelect1"
                                        >
                                            New Password
                                        </label>
                                        <InputGroup className="input-group-merge input-group-alternative mb-2">
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    <i className="ni ni-key-25" />
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input
                                                placeholder="New Password"
                                                type="password"
                                                id="newPassword"
                                                name="newPassword"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.newPassword}
                                            />
                                        </InputGroup>
                                        {touched.newPassword && errors.newPassword && <div className="text-red my-2">{errors.newPassword}</div>}
                                        <label
                                            className="form-control-label"
                                            htmlFor="exampleFormControlSelect1"
                                        >
                                            Confirm Password
                                        </label>
                                        <InputGroup className="input-group-merge input-group-alternative">
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    <i className="ni ni-key-25" />
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input
                                                placeholder="Confirm Password"
                                                type="password"
                                                id="confirmPassword"
                                                name="confirmPassword"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.confirmPassword}
                                            />
                                        </InputGroup>
                                        {touched.confirmPassword && errors.confirmPassword && <div className="text-red my-2">{errors.confirmPassword}</div>}
                                    </FormGroup>
                                    <div className="text-right mb-4">
                                        <Button
                                            color="success"
                                            type="submit"
                                        >
                                            Submit
                                        </Button>
                                    </div>
                                </Form>
                            </div>
                        </Card>
                    </div>
                </Row>
            </Container>
        </div>
    )
}
