import React, { useContext, useEffect } from "react";
// react component for creating dynamic tables
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import ReactLoading from "react-loading";

// reactstrap components11
import {
  Button,
  Card,
  CardHeader,
  Container,
  CardBody,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";

import axios from "axios";
import NotificationAlert from "react-notification-alert";
import moment from "moment";
import baseApi1 from "../../api/BaseApi1";
import {baseURL1} from "../../api/baseURL.json";

const { SearchBar } = Search;
const TmsApproval = () => {
  const [showPerPage, setShowPerPage] = React.useState(null);
  const { jwt_token } = JSON.parse(localStorage.getItem("user"));
  //Pending List
  const [pendingList, setPendingList] = React.useState([]);

  //Notification Alert
  const notificationAlertRef = React.useRef(null);
  const notify = (type, message) => {
    let options = {
      place: "bc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            Retailer Number Change Notification!
          </span>
          <span data-notify="message">{message}</span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 7,
    };
    notificationAlertRef.current.notificationAlert(options);
  };
  const componentRef = React.useRef(null);
  //Load Pending List
  const getPendingChangeRequests = async () => {
    try {
      const response = await baseApi1.get(
        "/api/retailer/change-tms/all"
      );
      if (!response.statusText === "OK") {
        notify("danger", response.data.message);
      } else {
        setPendingList(response.data.data);
      }
    } catch (error) {
      notify("danger", error.response.data.message);
    }
  };
  //Approve All
  const approveAll = async () => {
    try {
      const response = await baseApi1.post(
        "/api/retailer/change-tms/approve-all",
        {},
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (!response.statusText === "OK") {
        notify("danger", response.data.message);
      } else {
        getPendingChangeRequests();
        notify("success", response.data);
      }
    } catch (error) {
      notify("danger", error.response.data.message);
    }
  };
  //Approve One
  const approveOne = async (id) => {
    const approveID = JSON.stringify({ _id: id });
    try {
      const response = await baseApi1.post(
        "/api/retailer/change-tms/approve",
        approveID,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (!response.statusText === "OK") {
        notify("danger", response.data.message);
      } else {
        getPendingChangeRequests();
        notify("success", response.data.message);
      }
    } catch (error) {
      notify("danger", error.response.data.message);
    }
  };
  //Delete One
  const deleteOne = async (id) => {
    const deleteID = JSON.stringify({ _id: id });
    try {
      const response = await baseApi1.delete(
        `/api/retailer/change-tms/${id}`,
        { deleteID },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (!response.statusText === "OK") {
        notify("danger", response.data.message);
      } else {
        getPendingChangeRequests();
        notify("success", response.data.data);
      }
    } catch (error) {
      notify("danger", error.response.data.message);
    }
  };

  useEffect(() => {
    getPendingChangeRequests();
  }, []);
  const handleApproveAll = () => {
    approveAll();
  };
  const pagination = paginationFactory({
    page: 1,
    alwaysShowAllBtns: true,
    showTotal: true,
    withFirstAndLast: false,
    sizePerPageRenderer: ({
      options,
      currSizePerPage,
      onSizePerPageChange,
    }) => (
      <div className="dataTables_length" id="datatable-basic_length">
        <label>
          Show{" "}
          {
            <select
              name="datatable-basic_length"
              aria-controls="datatable-basic"
              className="form-control form-control-sm"
              onChange={(e) => onSizePerPageChange(e.target.value)}
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
              <option value="all">All</option>
            </select>
          }{" "}
          entries.
        </label>
      </div>
    ),
  });
  return (
    <div>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <SimpleHeader name="TMS Approval List" parentName="TMS Approval" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="d-flex justify-content-between">
                <h3 style={{ color: "#DB5A3B" }}>TMS Approval List</h3>
              </CardHeader>
              <ToolkitProvider
                data={pendingList}
                keyField="retailer?.outletCode"
                columns={[
                  {
                    dataField: "#",
                    text: "SL",
                    formatter: (cell, row, rowIndex) => {
                      return rowIndex + 1;
                    },
                  },
                  {
                    dataField: "region",
                    text: "Region",
                    sort: true,
                    formatter: (cell) => {
                      return cell?.name;
                    },
                  },
                  {
                    dataField: "area",
                    text: "Area",
                    sort: true,
                    formatter: (cell) => {
                      return cell?.name;
                    },
                  },
                  {
                    dataField: "territory",
                    text: "Territory",
                    sort: true,
                    formatter: (cell) => {
                      return cell?.name;
                    },
                  },
                  {
                    dataField: "outlet",
                    text: "Outlet Name",
                    sort: true,
                    formatter: (cell) => {
                      return cell?.name;
                    },
                  },
                  {
                    dataField: "retailer",
                    text: "Outlet Code",
                    sort: true,
                    formatter: (cell) => {
                      return cell?.outletCode;
                    },
                  },
                  {
                    dataField: "retailer",
                    text: "Retailer Phone",
                    sort: true,
                    formatter: (cell) => {
                      return cell?.phone;
                    },
                  },
                  {
                    dataField: "tms",
                    text: "Requested TMS",
                    sort: true,
                    formatter: (cell) => {
                      return cell?.name;
                    },
                  },
                  {
                    dataField: "_id",
                    text: "Action",
                    formatter: (cell) => {
                      const handleApprove = () => {
                        approveOne(cell);
                      };
                      const handleDelete = () => {
                        deleteOne(cell);
                      };
                      const action = (
                        <div>
                          <Button color="success" onClick={handleApprove}>
                            Approve
                          </Button>
                          <Button onClick={handleDelete} color="danger">
                            Delete
                          </Button>
                        </div>
                      );
                      return action;
                    },
                  },
                ]}
                search
              >
                {(props) => (
                  <div className="py-4 table-responsive">
                    <Container fluid>
                      <Row>
                        <Col xs={4} sm={4}>
                          <div>
                            <select
                              name="datatable-basic_length"
                              aria-controls="datatable-basic"
                              className="form-control form-control-sm w-25"
                              onChange={(e) => setShowPerPage(e.target.value)}
                            >
                              <option
                                selected={showPerPage === 10 && true}
                                value={10}
                              >
                                10
                              </option>
                              <option
                                selected={showPerPage === 25 && true}
                                value={25}
                              >
                                25
                              </option>
                              <option
                                selected={showPerPage === 50 && true}
                                value={50}
                              >
                                50
                              </option>
                              <option
                                selected={showPerPage === 100 && true}
                                value={100}
                              >
                                100
                              </option>
                              <option
                                selected={showPerPage === "all" && true}
                                value="all"
                              >
                                all
                              </option>
                            </select>
                          </div>
                        </Col>
                        <Col xs={4} sm={4}>
                          <Button
                            color="success"
                            size="sm"
                            onClick={handleApproveAll}
                          >
                            Approve All
                          </Button>
                        </Col>
                        <Col xs={4} sm={4}>
                          <div
                            id="datatable-basic_filter"
                            className="dataTables_filter px-4 pb-1 float-right"
                          >
                            <label>
                              Search:
                              <SearchBar
                                className="form-control-sm"
                                placeholder=""
                                {...props.searchProps}
                              />
                            </label>
                          </div>
                        </Col>
                      </Row>
                    </Container>
                    <BootstrapTable
                      ref={componentRef}
                      {...props.baseProps}
                      bootstrap4={true}
                      pagination={pagination}
                      bordered={false}
                      id="react-bs-table"
                    />
                    {/* {pendingList.length > 0 ? (
                      <BootstrapTable
                        ref={componentRef}
                        {...props.baseProps}
                        bootstrap4={true}
                        pagination={pagination}
                        bordered={false}
                        id="react-bs-table"
                      />
                    ) : (
                      <div className="d-flex justify-content-center">
                        <ReactLoading
                          className=""
                          type="bubbles"
                          color="#DB5A3B"
                          height={200}
                          width={100}
                        />
                      </div>
                    )} */}
                  </div>
                )}
              </ToolkitProvider>
            </Card>
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default TmsApproval;
