import React, { useContext, useEffect } from "react";
// react plugin that prints a given react component
import ReactToPrint from "react-to-print";
// react component for creating dynamic tables
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

// reactstrap components11
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  Container,
  CardBody,
  FormGroup,
  Form,
  Modal,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";

import axios from "axios";
import NotificationAlert from "react-notification-alert";
import { FileUploader } from "react-drag-drop-files";
import ReactLoading from "react-loading";
import baseApi1 from "../../api/BaseApi1";
import {baseURL1} from "../../api/baseURL.json";

const fileTypes = ["MP4"];

const { SearchBar } = Search;
const BrandCommunication = () => {
  const [showPerPage, setShowPerPage] = React.useState(null);
  //Edit Communication
  const [selectCommunication, setSelectCommunication] = React.useState("");
  const [brand, setBrand] = React.useState("");
  const [communicationType, setCommunicationType] = React.useState("");
  const [campaign, setCampaign] = React.useState("");
  const [callCard, setCallCard] = React.useState("");
  const [editFile, setEditFile] = React.useState(null);
  //Add Communication
  const [newSelectCommunication, setNewSelectCommunication] =
    React.useState("");
  const [newBrand, setNewBrand] = React.useState("");
  const [newCommunicationType, setNewCommunicationType] = React.useState("");
  const [newCampaign, setNewCampaign] = React.useState("");
  const [newCallCard, setNewCallCard] = React.useState("");
  const [newFile, setNewFile] = React.useState(null);
  //Others
  const [formModal, setformModal] = React.useState(false);
  const [addCommunicationModal, setCommunicationModal] = React.useState(false);
  const [allCommunication, setAllCommunication] = React.useState([]);
  const [allSelectCommunication, setAllSelectCommunication] = React.useState(
    []
  );
  const [allCommunicationType, setAllCommunicationType] = React.useState([]);
  const [allBrand, setAllBrand] = React.useState([]);
  const [allCampaign, setAllCampaign] = React.useState([]);
  const [singleCommunication, setSingleCommunication] = React.useState({});

  //Notification Alert
  const notificationAlertRef = React.useRef(null);
  const notify = (type, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            Communication Notification
          </span>
          <span data-notify="message">{message}</span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 7,
    };
    notificationAlertRef.current.notificationAlert(options);
  };

  const componentRef = React.useRef(null);
  // this function will copy to clipboard an entire table,
  // so you can paste it inside an excel or csv file
  const getCommunicationData = async () => {
    try {
      const response = await baseApi1.get(
        "/api/brandcommunication/all"
      );
      if (!response.statusText === "OK") {
        console.log("Not Getting Response");
      } else {
        setAllCommunication(response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getOneCommunicationData = (id) => {
    const singleCommunicationFinal = allCommunication.find((x) => x._id === id);
    console.log(singleCommunicationFinal);
    setSingleCommunication(singleCommunicationFinal);
  };
  const deleteOneCommunication = async (id) => {
    try {
      const response = await baseApi1.delete(
        `/api/brandcommunication/${id}`
      );
      if (!response.statusText === "OK") {
        console.log("Not Getting Response");
        notify("danger", response.data.message);
      } else {
        getCommunicationData();
        notify("success", response.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const updateOneCommunicationData = async (id) => {
    const editedCommunication = new FormData();
    editedCommunication.append(
      "communication",
      selectCommunication === ""
        ? singleCommunication?.communicationId
        : selectCommunication
    );
    editedCommunication.append(
      "communicationType",
      communicationType === ""
        ? singleCommunication?.communicationTypeId
        : communicationType
    );
    editedCommunication.append(
      "brand",
      brand === "" ? singleCommunication.brand : brand
    );
    editedCommunication.append(
      "file",
      editFile === null ? singleCommunication.file : editFile
    );
    editedCommunication.append(
      "callCard",
      callCard === "" ? singleCommunication.callCard : callCard
    );
    editedCommunication.append(
      "campaign",
      campaign === "" ? singleCommunication.campaignId : campaign
    );
    try {
      const response = await baseApi1.put(
        `/api/brandcommunication/${id}`,
        editedCommunication,
        { headers: { "Content-Type": "application/json" } }
      );
      if (!response.statusText === "OK") {
        console.log("Not Getting Response");
        notify("danger", response.data.message);
      } else {
        getCommunicationData();
        setformModal(false);
        notify("success", response.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getSelectCommunication = async () => {
    try {
      const response = await baseApi1.get(
        "/api/communication/all"
      );
      if (!response.statusText === "OK") {
        console.log("Not Getting Response");
      } else {
        setAllSelectCommunication(response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getAllBrand = async () => {
    try {
      const response = await baseApi1.get("/api/brand/all");
      if (!response.statusText === "OK") {
        console.log("Not Getting Response");
      } else {
        setAllBrand(response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getAllCommunicationType = async () => {
    try {
      const response = await baseApi1.get(
        "/api/communicationtype/all"
      );
      if (!response.statusText === "OK") {
        console.log("Not Getting Response");
      } else {
        setAllCommunicationType(response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getAllCampaign = async () => {
    try {
      const response = await baseApi1.get("/api/campaign/all");
      if (!response.statusText === "OK") {
        console.log("Not Getting Response");
      } else {
        setAllCampaign(response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getCommunicationData();
    getSelectCommunication();
    getAllBrand();
    getAllCommunicationType();
    getAllCampaign();
  }, []);

  const pagination = paginationFactory({
    page: 1,
    alwaysShowAllBtns: true,
    showTotal: true,
    withFirstAndLast: false,
    sizePerPageRenderer: ({
      options,
      currSizePerPage,
      onSizePerPageChange,
    }) => (
      <div className="dataTables_length" id="datatable-basic_length">
        <label>
          Show{" "}
          {
            <select
              name="datatable-basic_length"
              aria-controls="datatable-basic"
              className="form-control form-control-sm"
              onChange={(e) => onSizePerPageChange(e.target.value)}
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
              <option value="all">All</option>
            </select>
          }{" "}
          entries.
        </label>
      </div>
    ),
  });
  const addNewCommunication = async () => {
    const newCommunicationData = new FormData();
    newCommunicationData.append("communication", newSelectCommunication);
    newCommunicationData.append("communicationType", newCommunicationType);
    newCommunicationData.append("brand", newBrand);
    newCommunicationData.append("file", newFile);
    newCommunicationData.append("callCard", newCallCard);
    newCommunicationData.append("campaign", newCampaign);
    try {
      const response = await baseApi1.post(
        "/api/brandcommunication",
        newCommunicationData,
        { headers: { "Content-Type": "application/json" } }
      );
      if (!response.statusText === "OK") {
        console.log("Not Getting Response");
        notify("danger", "Some Thing Went Wrong");
      } else {
        getCommunicationData();
        response.status === 200 || response.status === 201
          ? setCommunicationModal(false)
          : setCommunicationModal(true);
        notify("success", response.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const submitNewCommunication = () => {
    addNewCommunication();
  };
  const handleSingleCommunicationDelete = (id) => {
    deleteOneCommunication(id);
  };

  const handleAddFile = (file) => {
    setNewFile(file);
  };
  const handleEditFile = (file) => {
    setEditFile(file);
  };

  return (
    <div>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <SimpleHeader name="Communication List" parentName="Communication" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="d-flex justify-content-between">
                <h3 style={{ color: "#DB5A3B" }}>Brand Communication</h3>
                <Button
                  onClick={() => setCommunicationModal(true)}
                  style={{ backgroundColor: "#DB5A3B", color: "white" }}
                >
                  Add New
                </Button>
              </CardHeader>
              <ToolkitProvider
                data={allCommunication}
                keyField="communication"
                columns={[
                  {
                    dataField: "#",
                    text: "SL",
                    formatter: (cell, row, rowIndex) => {
                      return rowIndex + 1;
                    },
                  },
                  {
                    dataField: "communication",
                    text: "Communication",
                    sort: true,
                  },
                  {
                    dataField: "communicationType",
                    text: "Communication Type",
                    sort: true,
                  },
                  {
                    dataField: "brand",
                    text: "Brand",
                    sort: true,
                  },
                  {
                    dataField: "file",
                    text: "File Name",
                    sort: true,
                  },
                  {
                    dataField: "callCard",
                    text: "Call Card",
                    sort: true,
                    formatter: (cell) => {
                      return cell === true ? "Yes" : "No";
                    },
                  },
                  {
                    dataField: "campaign",
                    text: "Campaign",
                    sort: true,
                  },
                  {
                    dataField: "date",
                    text: "Added Date",
                    sort: true,
                    formatter: (cell) => {
                      return new Date(cell).toDateString();
                    },
                  },
                  {
                    dataField: "_id",
                    text: "Action",
                    formatter: (cell) => {
                      const handleEdit = () => {
                        getOneCommunicationData(cell);
                        setformModal(true);
                      };
                      const action = (
                        <div>
                          <Button color="info" onClick={handleEdit}>
                            Edit
                          </Button>
                          <Button
                            onClick={() =>
                              handleSingleCommunicationDelete(cell)
                            }
                            color="danger"
                          >
                            Delete
                          </Button>
                        </div>
                      );
                      return action;
                    },
                  },
                ]}
                search
              >
                {(props) => (
                  <div className="py-4 table-responsive">
                    <Container fluid>
                      <Row>
                        <Col xs={4} sm={4}>
                          <div>
                            <select
                              name="datatable-basic_length"
                              aria-controls="datatable-basic"
                              className="form-control form-control-sm w-25"
                              onChange={(e) => setShowPerPage(e.target.value)}
                            >
                              <option
                                selected={showPerPage === 10 && true}
                                value={10}
                              >
                                10
                              </option>
                              <option
                                selected={showPerPage === 25 && true}
                                value={25}
                              >
                                25
                              </option>
                              <option
                                selected={showPerPage === 50 && true}
                                value={50}
                              >
                                50
                              </option>
                              <option
                                selected={showPerPage === 100 && true}
                                value={100}
                              >
                                100
                              </option>
                              <option
                                selected={showPerPage === "all" && true}
                                value="all"
                              >
                                all
                              </option>
                            </select>
                          </div>
                        </Col>
                        <Col xs={4} sm={4}>
                          <ButtonGroup>
                            <Button
                              className="buttons-copy buttons-html5"
                              color="warning"
                              size="sm"
                              id="copy-tooltip"
                              onClick={() =>
                                window.open(
                                  `${baseURL1}/api/brandcommunication/csv`
                                )
                              }
                            >
                              <span>CSV</span>
                            </Button>
                            <ReactToPrint
                              trigger={() => (
                                <Button
                                  color="warning"
                                  size="sm"
                                  className="buttons-copy buttons-html5"
                                  id="print-tooltip"
                                >
                                  Print
                                </Button>
                              )}
                              content={() => componentRef.current}
                            />
                          </ButtonGroup>
                          <UncontrolledTooltip
                            placement="top"
                            target="print-tooltip"
                          >
                            This will open a print page with the visible rows of
                            the table.
                          </UncontrolledTooltip>
                          <UncontrolledTooltip
                            placement="top"
                            target="copy-tooltip"
                          >
                            This will Download Brand Communication As CSV.
                          </UncontrolledTooltip>
                        </Col>
                        <Col xs={4} sm={4}>
                          <div
                            id="datatable-basic_filter"
                            className="dataTables_filter px-4 pb-1 float-right"
                          >
                            <label>
                              Search:
                              <SearchBar
                                className="form-control-sm"
                                placeholder=""
                                {...props.searchProps}
                              />
                            </label>
                          </div>
                        </Col>
                      </Row>
                    </Container>
                    {allCommunication.length > 0 ? (
                      <BootstrapTable
                        ref={componentRef}
                        {...props.baseProps}
                        bootstrap4={true}
                        pagination={pagination}
                        bordered={false}
                        id="react-bs-table"
                      />
                    ) : (
                      <div className="d-flex justify-content-center">
                        <ReactLoading
                          className=""
                          type="bubbles"
                          color="#DB5A3B"
                          height={200}
                          width={100}
                        />
                      </div>
                    )}
                  </div>
                )}
              </ToolkitProvider>
            </Card>
          </div>
        </Row>
      </Container>
      {/* Edit Modal */}
      <Modal
        className="modal-dialog-centered"
        size="md"
        isOpen={formModal}
        toggle={() => setformModal(false)}
      >
        <div className="modal-body p-0">
          <Card className="bg-secondary border-0 mb-0">
            <CardHeader style={{ color: "#DB5A3B" }} className="bg-transparent">
              Update Brand Communication
            </CardHeader>
            <CardBody className="px-lg-5 py-lg-5">
              <Form role="form">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlSelect1"
                  >
                    Select Communication
                  </label>
                  <Input
                    id="exampleFormControlSelect1"
                    onChange={(e) => setSelectCommunication(e.target.value)}
                    type="select"
                  >
                    <option>Select</option>
                    {allSelectCommunication.map((c) => (
                      <option
                        selected={
                          c._id === singleCommunication?.communicationId
                            ? true
                            : false
                        }
                        value={c._id}
                      >
                        {c.name}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlSelect1"
                  >
                    Select Brand
                  </label>
                  <Input
                    id="exampleFormControlSelect1"
                    onChange={(e) => setBrand(e.target.value)}
                    type="select"
                  >
                    <option>Select</option>
                    {allBrand.map((b) => (
                      <option
                        selected={
                          b._id === singleCommunication?.brandId ? true : false
                        }
                        value={b._id}
                      >
                        {b.name}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlSelect1"
                  >
                    Communication Type
                  </label>
                  <Input
                    id="exampleFormControlSelect1"
                    onChange={(e) => setCommunicationType(e.target.value)}
                    type="select"
                  >
                    <option>Select</option>
                    {allCommunicationType.map((ct) => (
                      <option
                        selected={
                          ct._id === singleCommunication?.communicationTypeId
                        }
                        value={ct._id}
                      >
                        {ct.name}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlSelect1"
                  >
                    Campaign
                  </label>
                  <Input
                    id="exampleFormControlSelect1"
                    onChange={(e) => setCampaign(e.target.value)}
                    type="select"
                  >
                    <option>Select</option>
                    {allCampaign.map((cp) => (
                      <option
                        selected={cp._id === singleCommunication?.campaignId}
                        value={cp._id}
                      >
                        {cp.name}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlSelect1"
                  >
                    Call Card
                  </label>
                  <Input
                    id="exampleFormControlSelect1"
                    onChange={(e) => setCallCard(e.target.value)}
                    type="select"
                  >
                    <option>Select</option>
                    <option
                      selected={singleCommunication?.callCard === true && true}
                      value="yes"
                    >
                      Yes
                    </option>
                    <option
                      selected={singleCommunication?.callCard === false && true}
                      value="no"
                    >
                      No
                    </option>
                  </Input>
                </FormGroup>
                <div className="mt-3">
                  <FileUploader
                    handleChange={handleEditFile}
                    label="Upload Communication Video"
                    name="file"
                    types={fileTypes}
                  />
                  <p>
                    {editFile
                      ? `File name: ${editFile?.name}`
                      : "no files uploaded yet"}
                  </p>
                </div>
                <div className="text-center">
                  <Button
                    onClick={() => setformModal(false)}
                    className="my-4"
                    color="danger"
                    type="button"
                  >
                    Close
                  </Button>
                  <Button
                    onClick={() =>
                      updateOneCommunicationData(singleCommunication?._id)
                    }
                    className="my-4"
                    color="success"
                    type="button"
                  >
                    Update
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </div>
      </Modal>
      {/* Add Brand Communication */}
      <Modal
        className="modal-dialog-centered"
        size="md"
        isOpen={addCommunicationModal}
        toggle={() => setCommunicationModal(false)}
      >
        <div className="modal-body p-0">
          <Card className="bg-secondary border-0 mb-0">
            <CardHeader style={{ color: "#DB5A3B" }} className="bg-transparent">
              Add Brand Communication
            </CardHeader>
            <CardBody className="px-lg-5 py-lg-5">
              <Form role="form">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlSelect1"
                  >
                    Select Communication
                  </label>
                  <Input
                    id="exampleFormControlSelect1"
                    onChange={(e) => setNewSelectCommunication(e.target.value)}
                    type="select"
                  >
                    <option>Select</option>
                    {allSelectCommunication.map((c) => (
                      <option value={c._id}>{c.name}</option>
                    ))}
                  </Input>
                </FormGroup>
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlSelect1"
                  >
                    Select Brand
                  </label>
                  <Input
                    id="exampleFormControlSelect1"
                    onChange={(e) => setNewBrand(e.target.value)}
                    type="select"
                  >
                    <option>Select</option>
                    {allBrand.map((b) => (
                      <option value={b._id}>{b.name}</option>
                    ))}
                  </Input>
                </FormGroup>
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlSelect1"
                  >
                    Communication Type
                  </label>
                  <Input
                    id="exampleFormControlSelect1"
                    onChange={(e) => setNewCommunicationType(e.target.value)}
                    type="select"
                  >
                    <option>Select</option>
                    {allCommunicationType.map((ct) => (
                      <option value={ct._id}>{ct.name}</option>
                    ))}
                  </Input>
                </FormGroup>
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlSelect1"
                  >
                    Campaign
                  </label>
                  <Input
                    id="exampleFormControlSelect1"
                    onChange={(e) => setNewCampaign(e.target.value)}
                    type="select"
                  >
                    <option>Select</option>
                    {allCampaign.map((cp) => (
                      <option value={cp._id}>{cp.name}</option>
                    ))}
                  </Input>
                </FormGroup>
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlSelect1"
                  >
                    Call Card
                  </label>
                  <Input
                    id="exampleFormControlSelect1"
                    onChange={(e) => setNewCallCard(e.target.value)}
                    type="select"
                  >
                    <option>Select</option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </Input>
                </FormGroup>
                <div className="mt-3">
                  <FileUploader
                    handleChange={handleAddFile}
                    label="Upload Communication Video"
                    name="file"
                    types={fileTypes}
                  />
                  <p>
                    {newFile
                      ? `File name: ${newFile?.name}`
                      : "no files uploaded yet"}
                  </p>
                </div>
                <div className="text-center">
                  <Button
                    onClick={() => setCommunicationModal(false)}
                    className="my-4"
                    color="danger"
                    type="button"
                  >
                    Close
                  </Button>
                  <Button
                    onClick={submitNewCommunication}
                    className="my-4"
                    color="success"
                    type="button"
                  >
                    Add
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </div>
      </Modal>
    </div>
  );
};

export default BrandCommunication;
