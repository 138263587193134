import React, { useContext, useEffect } from "react";
// react plugin that prints a given react component
import ReactToPrint from "react-to-print";
import classnames from "classnames";
// react component for creating dynamic tables
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import ReactLoading from "react-loading";
import CurrencyFormat from "react-currency-format";
// reactstrap components11
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  Container,
  CardBody,
  FormGroup,
  Form,
  Modal,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  Col,
  UncontrolledTooltip,
  CardTitle,
} from "reactstrap";
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import baseApi1 from "../api/BaseApi1";
import {baseURL1} from "../api/baseURL.json";

import axios from "axios";
import NotificationAlert from "react-notification-alert";
import DataContext from "context/DataContext";

const { SearchBar } = Search;
const RouteSetting = () => {
  const { region, area, territory, salesPoint } = useContext(DataContext);
  const [showPerPage, setShowPerPage] = React.useState(null);
  //Edit Route
  const [editRegion, setEditRegion] = React.useState("");
  const [editArea, setEditArea] = React.useState("");
  const [editTerritory, setEditTerritory] = React.useState("");
  const [editSalesPoint, setEditSalesPoint] = React.useState("");
  const [routeId, setRouteId] = React.useState("");
  const [routeName, setRouteName] = React.useState("");
  //Add Route
  const [newRegion, setNewRegion] = React.useState("");
  const [newArea, setNewArea] = React.useState("");
  const [newTerritory, setNewTerritory] = React.useState("");
  const [newSalesPoint, setNewSalesPoint] = React.useState("");
  const [newRouteId, setNewRouteId] = React.useState("");
  const [newRouteName, setNewRouteName] = React.useState("");
  //Others
  const [formModal, setformModal] = React.useState(false);
  const [addRouteModal, setRouteModal] = React.useState(false);
  const [allRoute, setAllRoute] = React.useState([]);
  const [singleRoute, setSingleRoute] = React.useState({});
  //EditFilters
  const filteredArea = area.filter((item) => item.regionId === editRegion);
  const filteredTerritory = territory.filter(
    (item) => item.areaId === editArea
  );
  const filteredSalesPoint = salesPoint.filter(
    (item) => item.territory._id === editTerritory
  );
  //AddFilters
  const newfilteredArea = area.filter((item) => item.regionId === newRegion);
  const newfilteredTerritory = territory.filter(
    (item) => item.areaId === newArea
  );
  const newfilteredSalesPoint = salesPoint.filter(
    (item) => item.territory._id === newTerritory
  );
  //Notification Alert
  const notificationAlertRef = React.useRef(null);
  const notify = (type, message) => {
    let options = {
      place: "bc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            Route Notification
          </span>
          <span data-notify="message">{message}</span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 7,
    };
    notificationAlertRef.current.notificationAlert(options);
  };

  const componentRef = React.useRef(null);
  // this function will copy to clipboard an entire table,
  // so you can paste it inside an excel or csv file
  const getRouteData = async () => {
    try {
      const response = await baseApi1.get("/api/routes/all");
      if (!response.statusText === "OK") {
        console.log("Not Getting Response");
      } else {
        setAllRoute(response.data.data);
      }
    } catch (error) {
      console.log(error.response);
    }
  };
  const getOneRouteData = async (id) => {
    const singleRouteFinal = allRoute.find((x) => x._id === id);
    setEditRegion(singleRouteFinal?.region?._id);
    setEditArea(singleRouteFinal?.area?._id);
    setEditTerritory(singleRouteFinal?.territory?._id);
    setEditSalesPoint(singleRouteFinal?.salesPoint?._id);
    setSingleRoute(singleRouteFinal);
  };
  const deleteOneRoute = async (id) => {
    try {
      const response = await baseApi1.delete(`/api/route/${id}`);
      if (!response.statusText === "OK") {
        console.log("Not Getting Response");
        notify("danger", response.data.message);
      } else {
        getRouteData();
        notify("success", response.data.message);
      }
    } catch (error) {
      console.log(error.response);
    }
  };
  const updateOneRouteData = async (id) => {
    const editedRoute = {
      region: editRegion === "" ? singleRoute?.region : editRegion,
      area: editArea === "" ? singleRoute?.area : editArea,
      territory: editTerritory === "" ? singleRoute?.area : editTerritory,
      salesPoint:
        editSalesPoint === "" ? singleRoute?.salesPoint : editSalesPoint,
      id: routeId === "" ? singleRoute?.id : routeId,
      name: routeName === "" ? singleRoute?.name : routeName,
    };
    try {
      const response = await baseApi1.put(
        `/api/route/${id}`,
        editedRoute,
        { headers: { "Content-Type": "application/json" } }
      );
      if (!response.statusText === "OK") {
        console.log("Not Getting Response");
        notify("danger", response.data.message);
      } else {
        getRouteData();
        setformModal(false);
        notify("success", response.data.message);
      }
    } catch (error) {
      console.log(error.response.data.message);
      notify("danger", error.response.data.message);
    }
  };
  useEffect(() => {
    getRouteData();
  }, []);

  const pagination = paginationFactory({
    page: 1,
    alwaysShowAllBtns: true,
    showTotal: true,
    withFirstAndLast: false,
    sizePerPageRenderer: ({
      options,
      currSizePerPage,
      onSizePerPageChange,
    }) => (
      <div className="dataTables_length" id="datatable-basic_length">
        <label>
          Show{" "}
          {
            <select
              name="datatable-basic_length"
              aria-controls="datatable-basic"
              className="form-control form-control-sm"
              onChange={(e) => onSizePerPageChange(e.target.value)}
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
              <option value="all">All</option>
            </select>
          }{" "}
          entries.
        </label>
      </div>
    ),
  });
  const addNewRoute = async () => {
    const newRouteData = {
      region: newRegion,
      area: newArea,
      territory: newTerritory,
      salesPoint: newSalesPoint,
      id: newRouteId,
      name: newRouteName,
    };
    try {
      const response = await baseApi1.post(
        "/api/route",
        newRouteData,
        { headers: { "Content-Type": "application/json" } }
      );
      if (!response.statusText === "OK") {
        console.log("Not Getting Response");
        notify("danger", "Some Thing Went Wrong");
      } else {
        getRouteData();
        response.status === 200 || response.status === 201
          ? setRouteModal(false)
          : setRouteModal(true);
        notify("success", response.data.message);
      }
    } catch (error) {
      console.log(error.response);
      notify("danger", error.response.data.message);
    }
  };
  const submitNewRoute = () => {
    addNewRoute();
  };
  const handleSingleRouteDelete = (id) => {
    deleteOneRoute(id);
  };
  return (
    <div>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <SimpleHeader name="Routes List" parentName="Route" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="d-flex justify-content-between">
                <h3 style={{ color: "#DB5A3B" }}>Route Setting</h3>
                <Button
                  onClick={() => setRouteModal(true)}
                  style={{ backgroundColor: "#DB5A3B", color: "white" }}
                >
                  Add New
                </Button>
              </CardHeader>
              <div className="m-3" style={{ width: "18rem" }}>
                <Card className="card-stats bg-gradient-warning mb-4 mb-lg-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle className="text-uppercase text-white text-muted mb-0">
                          Total Routes
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0 text-white">
                          <CurrencyFormat
                            value={allRoute.length}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={""}
                          />
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                          <i className="fas fa-chart-bar" />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </div>
              <ToolkitProvider
                data={allRoute}
                keyField="id"
                columns={[
                  {
                    dataField: "#",
                    text: "SL",
                    formatter: (cell, row, rowIndex) => {
                      return rowIndex + 1;
                    },
                  },
                  {
                    dataField: "region",
                    text: "Region",
                    sort: true,
                    formatter: (cell) => {
                      return cell?.name;
                    },
                  },
                  {
                    dataField: "area",
                    text: "Area",
                    sort: true,
                    formatter: (cell) => {
                      return cell?.name;
                    },
                  },
                  {
                    dataField: "territory",
                    text: "Territory",
                    sort: true,
                    formatter: (cell) => {
                      return cell?.name;
                    },
                  },
                  {
                    dataField: "salesPoint",
                    text: "Sales Point",
                    sort: true,
                    formatter: (cell) => {
                      return cell?.name;
                    },
                  },

                  {
                    dataField: "id",
                    text: "Route ID",
                    sort: true,
                  },
                  {
                    dataField: "name",
                    text: "Route Name",
                    sort: true,
                  },
                  {
                    dataField: "_id",
                    text: "Action",
                    formatter: (cell) => {
                      const handleEdit = () => {
                        getOneRouteData(cell);
                        setformModal(true);
                      };
                      const action = (
                        <div>
                          <Button color="info" onClick={handleEdit}>
                            Edit
                          </Button>
                          <Button
                            onClick={() => handleSingleRouteDelete(cell)}
                            color="danger"
                          >
                            Delete
                          </Button>
                        </div>
                      );
                      return action;
                    },
                  },
                ]}
                search
              >
                {(props) => (
                  <div className="py-4 table-responsive">
                    <Container fluid>
                      <Row>
                        <Col xs={4} sm={4}>
                          <div>
                            <select
                              name="datatable-basic_length"
                              aria-controls="datatable-basic"
                              className="form-control form-control-sm w-25"
                              onChange={(e) => setShowPerPage(e.target.value)}
                            >
                              <option
                                selected={showPerPage === 10 && true}
                                value={10}
                              >
                                10
                              </option>
                              <option
                                selected={showPerPage === 25 && true}
                                value={25}
                              >
                                25
                              </option>
                              <option
                                selected={showPerPage === 50 && true}
                                value={50}
                              >
                                50
                              </option>
                              <option
                                selected={showPerPage === 100 && true}
                                value={100}
                              >
                                100
                              </option>
                              <option
                                selected={showPerPage === "all" && true}
                                value="all"
                              >
                                all
                              </option>
                            </select>
                          </div>
                        </Col>
                        <Col xs={4} sm={4}>
                          <ButtonGroup>
                            <Button
                              className="buttons-copy buttons-html5"
                              color="warning"
                              size="sm"
                              id="copy-tooltip"
                              onClick={() =>
                                window.open(
                                  `${baseURL1}/api/outlets/all/csv`
                                )
                              }
                            >
                              <span>CSV</span>
                            </Button>
                            <ReactToPrint
                              trigger={() => (
                                <Button
                                  color="warning"
                                  size="sm"
                                  className="buttons-copy buttons-html5"
                                  id="print-tooltip"
                                >
                                  Print
                                </Button>
                              )}
                              content={() => componentRef.current}
                            />
                          </ButtonGroup>
                          <UncontrolledTooltip
                            placement="top"
                            target="print-tooltip"
                          >
                            This will open a print page with the visible rows of
                            the table.
                          </UncontrolledTooltip>
                          <UncontrolledTooltip
                            placement="top"
                            target="copy-tooltip"
                          >
                            This will Download Route Setting As CSV.
                          </UncontrolledTooltip>
                        </Col>
                        <Col xs={4} sm={4}>
                          <div
                            id="datatable-basic_filter"
                            className="dataTables_filter px-4 pb-1 float-right"
                          >
                            <label>
                              Search:
                              <SearchBar
                                className="form-control-sm"
                                placeholder=""
                                {...props.searchProps}
                              />
                            </label>
                          </div>
                        </Col>
                      </Row>
                    </Container>
                    {allRoute.length > 0 ? (
                      <BootstrapTable
                        ref={componentRef}
                        {...props.baseProps}
                        bootstrap4={true}
                        pagination={pagination}
                        bordered={false}
                        id="react-bs-table"
                      />
                    ) : (
                      <div className="d-flex justify-content-center">
                        <ReactLoading
                          className=""
                          type="bubbles"
                          color="#DB5A3B"
                          height={200}
                          width={100}
                        />
                      </div>
                    )}
                  </div>
                )}
              </ToolkitProvider>
            </Card>
          </div>
        </Row>
      </Container>
      {/* Edit Modal */}
      <Modal
        className="modal-dialog-centered"
        size="md"
        isOpen={formModal}
        toggle={() => setformModal(false)}
      >
        <div className="modal-body p-0">
          <Card className="bg-secondary border-0 mb-0">
            <CardHeader style={{ color: "#DB5A3B" }} className="bg-transparent">
              Update Route
            </CardHeader>
            <CardBody className="px-lg-5 py-lg-5">
              <Form role="form">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlSelect1"
                  >
                    Region
                  </label>
                  <Input
                    id="exampleFormControlSelect1"
                    onChange={(e) => setEditRegion(e.target.value)}
                    type="select"
                  >
                    <option>Select</option>
                    {region.map((region) => (
                      <option
                        selected={
                          region._id === singleRoute?.region?._id ? true : false
                        }
                        value={region._id}
                      >
                        {region.name}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlSelect1"
                  >
                    Area
                  </label>
                  <Input
                    id="exampleFormControlSelect1"
                    onChange={(e) => setEditArea(e.target.value)}
                    type="select"
                  >
                    <option>Select</option>
                    {filteredArea.map((area) => (
                      <option
                        selected={
                          area._id === singleRoute?.area?._id ? true : false
                        }
                        value={area._id}
                      >
                        {area.name}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlSelect1"
                  >
                    Territory
                  </label>
                  <Input
                    id="exampleFormControlSelect1"
                    onChange={(e) => setEditTerritory(e.target.value)}
                    type="select"
                  >
                    <option>Select</option>
                    {filteredTerritory.map((territory) => (
                      <option
                        selected={
                          territory._id === singleRoute?.territory?._id
                            ? true
                            : false
                        }
                        value={territory._id}
                      >
                        {territory.name}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlSelect1"
                  >
                    Sales Point
                  </label>
                  <Input
                    id="exampleFormControlSelect1"
                    onChange={(e) => setEditSalesPoint(e.target.value)}
                    type="select"
                  >
                    <option>Select</option>
                    {filteredSalesPoint.map((sp) => (
                      <option
                        selected={
                          sp._id === singleRoute?.salesPoint?._id ? true : false
                        }
                        value={sp._id}
                      >
                        {sp.name}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
                <FormGroup
                  className={classnames("mb-3", {
                    focused: routeId,
                  })}
                >
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlSelect1"
                  >
                    Route ID
                  </label>
                  <InputGroup className="input-group-merge input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Route ID"
                      defaultValue={singleRoute?.id}
                      type="text"
                      onChange={(e) => setRouteId(e.target.value)}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup
                  className={classnames("mb-3", {
                    focused: routeName,
                  })}
                >
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlSelect1"
                  >
                    Route Name
                  </label>
                  <InputGroup className="input-group-merge input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Route Name"
                      defaultValue={singleRoute?.name}
                      type="text"
                      onChange={(e) => setRouteName(e.target.value)}
                    />
                  </InputGroup>
                </FormGroup>
                <div className="text-center">
                  <Button
                    onClick={() => setformModal(false)}
                    className="my-4"
                    color="danger"
                    type="button"
                  >
                    Close
                  </Button>
                  <Button
                    onClick={() => updateOneRouteData(singleRoute?._id)}
                    className="my-4"
                    color="success"
                    type="button"
                  >
                    Update
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </div>
      </Modal>
      {/* Add Outlet */}
      <Modal
        className="modal-dialog-centered"
        size="md"
        isOpen={addRouteModal}
        toggle={() => setRouteModal(false)}
      >
        <div className="modal-body p-0">
          <Card className="bg-secondary border-0 mb-0">
            <CardHeader style={{ color: "#DB5A3B" }} className="bg-transparent">
              Add Route
            </CardHeader>
            <CardBody className="px-lg-5 py-lg-5">
              <Form role="form">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlSelect1"
                  >
                    Region
                  </label>
                  <Input
                    id="exampleFormControlSelect1"
                    onChange={(e) => setNewRegion(e.target.value)}
                    type="select"
                  >
                    <option>Select</option>
                    {region.map((region) => (
                      <option value={region._id}>{region.name}</option>
                    ))}
                  </Input>
                </FormGroup>
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlSelect1"
                  >
                    Area
                  </label>
                  <Input
                    id="exampleFormControlSelect1"
                    onChange={(e) => setNewArea(e.target.value)}
                    type="select"
                  >
                    <option>Select</option>
                    {newfilteredArea.map((area) => (
                      <option value={area._id}>{area.name}</option>
                    ))}
                  </Input>
                </FormGroup>
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlSelect1"
                  >
                    Territory
                  </label>
                  <Input
                    id="exampleFormControlSelect1"
                    onChange={(e) => setNewTerritory(e.target.value)}
                    type="select"
                  >
                    <option>Select</option>
                    {newfilteredTerritory.map((territory) => (
                      <option value={territory._id}>{territory.name}</option>
                    ))}
                  </Input>
                </FormGroup>
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlSelect1"
                  >
                    Sales Point
                  </label>
                  <Input
                    id="exampleFormControlSelect1"
                    onChange={(e) => setNewSalesPoint(e.target.value)}
                    type="select"
                  >
                    <option>Select</option>
                    {newfilteredSalesPoint.map((sp) => (
                      <option value={sp._id}>{sp.name}</option>
                    ))}
                  </Input>
                </FormGroup>
                <FormGroup
                  className={classnames("mb-3", {
                    focused: routeId,
                  })}
                >
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlSelect1"
                  >
                    Route ID
                  </label>
                  <InputGroup className="input-group-merge input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Route ID"
                      type="text"
                      onChange={(e) => setNewRouteId(e.target.value)}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup
                  className={classnames("mb-3", {
                    focused: routeName,
                  })}
                >
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlSelect1"
                  >
                    Route Name
                  </label>
                  <InputGroup className="input-group-merge input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Route Name"
                      type="text"
                      onChange={(e) => setNewRouteName(e.target.value)}
                    />
                  </InputGroup>
                </FormGroup>
                <div className="text-center">
                  <Button
                    onClick={() => setRouteModal(false)}
                    className="my-4"
                    color="danger"
                    type="button"
                  >
                    Close
                  </Button>
                  <Button
                    onClick={submitNewRoute}
                    className="my-4"
                    color="success"
                    type="button"
                  >
                    Add
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </div>
      </Modal>
    </div>
  );
};

export default RouteSetting;
