import React, {useContext, useEffect} from "react";

// reactstrap components11
import {Button, Card, CardHeader, Container, CardBody, Row} from "reactstrap";
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";

import axios from "axios";
import NotificationAlert from "react-notification-alert";
import {FileUploader} from "react-drag-drop-files";
import ReactLoading from "react-loading";
import baseApi1 from "../../api/BaseApi1";

const fileTypes = ["CSV", "XLS", "XLXS"];

const ImportData = () => {
    const [newFile, setNewFile] = React.useState(null);
    //Notification Alert
    const notificationAlertRef = React.useRef(null);
    const notify = (type, message) => {
        let options = {
            place: "bc",
            message: (
                <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
              Import Notification
          </span>
                    <span data-notify="message">{message}</span>
                </div>
            ),
            type: type,
            icon: "ni ni-bell-55",
            autoDismiss: 7,
        };
        notificationAlertRef.current.notificationAlert(options);
    };
    const uploadFile = async () => {
        try {
            const response = await axios.post();
        } catch (error) {
            console.log(error.response);
        }
    };

    useEffect(() => {
        // getRegionData();
    }, []);
    const handleAddFile = (file) => {
        setNewFile(file);
    };

    return (
        <div>
            <div className="rna-wrapper">
                <NotificationAlert ref={notificationAlertRef}/>
            </div>
            <SimpleHeader name="Import Master Data" parentName="Import Data"/>
            <Container className="mt--6" fluid>
                <Row>
                    <div className="col">
                        <Card>
                            <CardHeader className="d-flex justify-content-between">
                                <h3 style={{color: "#DB5A3B"}}>Import Database</h3>
                            </CardHeader>
                            <CardBody className="m-4">
                                <div>
                                    <FileUploader
                                        handleChange={handleAddFile}
                                        label="Upload Master Database"
                                        name="file"
                                        types={fileTypes}
                                    />
                                    <p>
                                        {newFile
                                            ? `File name: ${newFile?.name}`
                                            : "no files uploaded yet"}
                                    </p>
                                </div>
                                <Button className="my-4" color="success" type="button">
                                    Upload
                                </Button>
                            </CardBody>
                        </Card>
                    </div>
                </Row>
            </Container>
        </div>
    );
};

export default ImportData;
