import React, {useState} from "react";
import {GoogleMap, InfoWindow, Marker} from "@react-google-maps/api";

const markers = [
    {
        id: 1,
        name: "Software Technology Park, Dhaka",
        position: {lat: 23.754161299999996, lng: 90.39335184232797}
    },
    {
        id: 2,
        name: "Mirpur 1, Dhaka",
        position: {lat: 23.794011966812686, lng: 90.35660479185415}
    },
    {
        id: 3,
        name: "South Monipur, Dhaka",
        position: {lat: 23.79416903719008, lng: 90.36896441092539}
    },
    {
        id: 4,
        name: "Software Technology Park, Dhaka",
        position: {lat: 23.754161299999996, lng: 90.39335184232797}
    }
];

function Map() {
    const google = window.google;
    const [activeMarker, setActiveMarker] = useState(null);

    const handleActiveMarker = (marker) => {
        if (marker === activeMarker) {
            return;
        }
        setActiveMarker(marker);
    };

    const handleOnLoad = (map) => {
        const bounds = new google.maps.LatLngBounds();
        markers.forEach(({position}) => bounds.extend(position));
        map.fitBounds(bounds);
    };

    return (
        <GoogleMap
            onLoad={handleOnLoad}
            onClick={() => setActiveMarker(null)}
            mapContainerStyle={{width: "700px", height: "500px",marginTop:'15px',marginLeft:"50px"}}
        >
            {markers.map(({id, name, position}) => (
                <Marker
                    key={id}
                    position={position}
                    onClick={() => handleActiveMarker(id)}
                >
                    {activeMarker === id ? (
                        <InfoWindow onCloseClick={() => setActiveMarker(null)}>
                            <div>{name}</div>
                        </InfoWindow>
                    ) : null}
                </Marker>
            ))}
        </GoogleMap>
    );
}

export default Map;
