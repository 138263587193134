import React from 'react';
import SimpleHeader from "../../components/Headers/SimpleHeader";
import {Button, Card, CardBody, Col, Container, FormGroup, Input, Label} from "reactstrap";
import {baseURL2} from "../../api/baseURL.json";

const LeadGenerate = () => {
    let currentMonth = new Date().getFullYear() + "-" + new Date().getMonth();
    const [month, setMonth] = React.useState(0);
    console.log(month);
    return (
        <div>
            <SimpleHeader name="Lead" parentName="Call"/>
            <Container className="mt--6" fluid>
                <Card>
                    <CardBody>
                        <div>
                            <FormGroup className="row">
                                <Label
                                    className="form-control-label"
                                    htmlFor="example-month-input"
                                    md="2"
                                >
                                    Month
                                </Label>
                                <Col md="10">
                                    <Input
                                        defaultValue={currentMonth}
                                        onChange={(e) => setMonth((e.target.value.split("-")[1]) - 1)}
                                        id="example-month-input"
                                        type="month"
                                    />
                                </Col>
                            </FormGroup>
                            <div className="d-flex justify-content-center">
                                <Button onClick={() => window.open(`${baseURL2}:5000/api/generateLead?month=${month}`)}
                                        color="warning" outline>
                                    Generate
                                </Button>
                                <Button
                                    onClick={() => window.open(`${baseURL2}:5000/api/reGenerateLead?month=${month}`)}
                                    className="ml-3" color="primary" outline>
                                    Re-Generate
                                </Button>
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </Container>
        </div>
    );
};

export default LeadGenerate;
