import Login from "views/pages/examples/Login.js";
import TmsControl from "views/pages/TmsControl";
import Campaign from "views/pages/Campaign";
import DetailsReport from "views/pages/DetailsReport";
import CommunicationSetting from "views/pages/CommunicationSetting";
import CommunicationType from "views/pages/CommunicationType";
import RegionSetting from "views/pages/RegionSetting";
import AreaSetting from "views/pages/AreaSetting";
import TerritorySetting from "views/pages/TerritorySetting";
import OutletSetting from "views/pages/OutletSetting";
import BrandSetting from "views/pages/BrandSetting";
import RoleSetting from "views/pages/RoleSetting";
import ImportData from "views/pages/ImportData";
import BrandCommunication from "views/pages/BrandCommunication";
import AddRetailer from "views/pages/AddRetailer";
import AddTarget from "views/AddTarget";
import TmsApproval from "views/pages/TmsApproval";
import ViewStatus from "views/pages/ViewStatus";
import AddGuideline from "views/pages/AddGuideline";
import TopUp from "views/pages/TopUp";
import ChangeData from "views/pages/ChangeData";
import AdvocacyEntry from "views/pages/AdvocacyEntry";
import Dashboard from "views/Dashboard";
import ViewDatabase from "views/ViewDatabase";
import CalendarView from "views/pages/Calendar";
import TmsActivity from "views/TmsActivity";
import SalesData from "views/SalesData";
import ImportSales from "views/ImportSales";
import ComplainAdmin from "views/ComplainAdmin";
import ComplainUser from "views/ComplainUser";
import SalesPointSetting from "views/SalesPointSetting";
import RouteSetting from "views/RouteSetting";
import ChangeHistory from "./views/pages/ChangeHistory";
import ApproveHistory from "./views/pages/ApproveHistory";
import BrandApproval from "./views/pages/BrandApproval";
import IncentiveSetting from "./views/pages/IncentiveSetting";
import SelectIncentive from "./views/pages/SelectIncentive";
import DistributionIncentive from "./views/pages/DistributionIncentive";
import PhotoFrame from "./views/pages/PhotoFrame";
import AddPhotoFrame from "./views/pages/AddPhotoFrame";
import ViewTmsMap from "./views/pages/ViewTmsMap";
import TmsDistribution from "./views/pages/tmsDistribution/TmsDistribution";
import PendingDistribution from "./views/pendingDistributions/PendingDistribution";
import LeadGenerate from "./views/Calls/LeadGenerate";
import CallingMonth from "./views/Calls/CallingMonth";
import ChangePassword from "views/pages/ChangePassword";
import CompletedDistributions from "views/pages/CompletedDistributions";
import EasIncentiveReport from "views/pages/EasIncentiveReport";
import SalesIncentiveReport from "views/pages/SalesIncentiveReport";
import IncentiveSelections from "views/pages/IncentiveSelections";
import ProposedTargets from "views/pages/ProposedTargets";

const routes = [
    {
        path: "/dashboard",
        name: "Dashboard",
        icon: "ni ni-shop text-primary",
        component: Dashboard,
        layout: "/admin",
        invisible: ["tms"]
    },
    {
        collapse: true,
        name: "TMS Control Panel",
        icon: "ni ni-single-02 text-primary",
        state: "tmsCollapse",
        invisible: ["National Manager", "Regional Manager", "Area Manager", "tms"],
        views: [
            {
                path: "/tms",
                name: "View TMS",
                miniName: "V",
                component: TmsControl,
                layout: "/admin",
                invisible: [
                    "National Manager",
                    "Territory Manager",
                    "Regional Manager",
                    "Area Manager",
                ],
            },
            {
                path: "/tmsActivity",
                name: "TMS Activity",
                miniName: "A",
                component: TmsActivity,
                layout: "/admin",
                invisible: ["National Manager", "Regional Manager", "Area Manager", "tms"],
            },
            {
                path: "/tmsMapActivity",
                name: "TMS Map Activity",
                miniName: "M",
                component: ViewTmsMap,
                layout: "/admin",
                invisible: ["National Manager", "Regional Manager", "Area Manager", "tms"],
            },
            {
                path: "/approveTMS",
                name: "Approve TMS",
                miniName: "A",
                component: TmsApproval,
                layout: "/admin",
                invisible: [
                    "National Manager",
                    "Territory Manager",
                    "Regional Manager",
                    "Area Manager",
                ],
            },
        ],
    },
    {
        path: "/campaign",
        name: "Campaign",
        icon: "ni ni-app text-green",
        component: Campaign,
        layout: "/admin",
        invisible: [
            "National Manager",
            "Territory Manager",
            "Regional Manager",
            "Area Manager", "tms"
        ],
    },
    {
        path: "/detailsReport",
        name: "Details Report",
        icon: "ni ni-cloud-download-95 text-blue",
        component: DetailsReport,
        layout: "/admin",
        invisible: ["tms"],
    },
    {
        path: "/brandCommunication",
        name: "Brand Communication",
        icon: "ni ni-button-play text-purple",
        component: BrandCommunication,
        layout: "/admin",
        invisible: [
            "National Manager",
            "Territory Manager",
            "Regional Manager",
            "Area Manager", "tms"
        ],
    },
    {
        collapse: true,
        name: "Retailer",
        icon: "ni ni-circle-08 text-primary",
        state: "retailersCollapse",
        invisible: [
            "National Manager",
            "Territory Manager",
            "Regional Manager",
            "Area Manager", "tms"
        ],
        views: [
            {
                path: "/addRetailer",
                name: "Add Retailer",
                miniName: "+",
                component: AddRetailer,
                layout: "/admin",
                invisible: [
                    "National Manager",
                    "Territory Manager",
                    "Regional Manager",
                    "Area Manager", "tms"
                ],
            },
        ],
    },
    {
        collapse: true,
        name: "Targets",
        icon: "ni ni-box-2 text-purple",
        state: "targetsCollapse",
        invisible: ["National Manager", "Regional Manager", "Area Manager", "tms"],
        views: [
            {
                path: "/proposedTargets",
                name: "Proposed Targets",
                miniName: "S",
                component: ProposedTargets,
                layout: "/admin",
                invisible: ["National Manager", "Regional Manager", "Area Manager", "tms"],
            },
            {
                path: "/addTarget",
                name: "Targets",
                miniName: "S",
                component: AddTarget,
                layout: "/admin",
                invisible: ["National Manager", "Regional Manager", "Area Manager", "Territory Manager"],
            },
        ],
    },
    {
        path: "/viewStatus",
        name: "View Status",
        icon: "ni ni-chart-bar-32 text-blue",
        component: ViewStatus,
        layout: "/admin",
        invisible: ["technicalSupport", "deliveryPartner", "tms"],
    },
    {
        path: "/addGuideline",
        name: "Add Guideline",
        icon: "ni ni-collection text-orange",
        component: AddGuideline,
        layout: "/admin",
        invisible: [
            "National Manager",
            "Territory Manager",
            "Regional Manager",
            "Area Manager", "tms"
        ],
    },
    {
        path: "/viewDatabase",
        name: "Database",
        icon: "ni ni-umbrella-13 text-red",
        component: ViewDatabase,
        layout: "/admin",
        invisible: ["National Manager", "Regional Manager", "Area Manager", "tms"],
    },
    {
        path: "/changeData",
        name: "My Retailer",
        icon: "ni ni-money-coins text-purple",
        component: ChangeData,
        layout: "/admin",
        invisible: [
            "National Manager",
            "Regional Manager",
            "Area Manager",
            "Superadmin", "tms"
        ],
    },
    {
        path: "/changeHistory",
        name: "Change Status",
        icon: "ni ni-money-coins text-purple",
        component: ChangeHistory,
        layout: "/admin",
        invisible: [
            "National Manager",
            "Regional Manager",
            "Area Manager",
            "Superadmin", "tms"
        ],
    },
    {
        path: "/approveHistory",
        name: "Approve History",
        icon: "ni ni-money-coins text-purple",
        component: ApproveHistory,
        layout: "/admin",
        invisible: [
            "National Manager",
            "Territory Manager",
            "Regional Manager",
            "Area Manager", "tms"
        ],
    },
    {
        path: "/brandApproval",
        name: "Approve History",
        icon: "ni ni-money-coins text-purple",
        component: BrandApproval,
        layout: "/admin",
        invisible: [
            "National Manager",
            "Territory Manager",
            "Regional Manager",
            "Superadmin", "tms"
        ],
    },
    {
        path: "/advocacyEntry",
        name: "EAS Advocacy Entry",
        icon: "ni ni-collection text-blue",
        component: AdvocacyEntry,
        layout: "/admin",
        invisible: [
            "National Manager",
            "Regional Manager",
            "Area Manager",
            "Superadmin", "tms"
        ],
    },
    {
        path: "/topup",
        name: "Recharge Panel",
        icon: "ni ni-bag-17 text-purple",
        component: TopUp,
        layout: "/admin",
        api: true,
        invisible: [
            "National Manager",
            "Territory Manager",
            "Regional Manager",
            "Area Manager", "tms"
        ],
    },
    {
        collapse: true,
        name: "Incentive",
        icon: "ni ni-support-16 text-green",
        state: "incentiveCollapse",
        invisible: ["National Manager", "Regional Manager", "Area Manager", "Territory Manager", "tms"],
        views: [
            {
                path: "/incentiveSetting",
                name: "Incentive Setting",
                miniName: "S",
                component: IncentiveSetting,
                layout: "/admin",
            },
            {
                path: "/selectIncentive",
                name: "Select Incentive",
                miniName: "S",
                component: SelectIncentive,
                layout: "/admin",
            },
            {
                path: "/distributionIncentive",
                name: "Distribution Incentive",
                miniName: "D",
                component: DistributionIncentive,
                layout: "/admin",
            }
        ],
    },
    {
        path: "/incentive-selections",
        name: "Incentive Selections",
        icon: "ni ni-support-16 text-green",
        component: IncentiveSelections,
        layout: "/admin",
        invisible: [],
    },
    {
        collapse: true,
        name: "Incentive Distribution",
        icon: "ni ni-support-16 text-green",
        state: "tmsDistributionCollapse",
        invisible: ["National Manager", "Regional Manager", "Area Manager", "Territory Manager"],
        views: [
            {
                path: "/tmswebdistribution",
                name: "Incentive Distribution",
                miniName: "S",
                component: TmsDistribution,
                layout: "/admin",
            },
        ],
    },
    {
        collapse: true,
        name: "Incentive Reprots",
        icon: "ni ni-support-16 text-green",
        state: "incentiveStatus",
        invisible: ["technicalSupport", "deliveryPartner", "tms"],
        views: [
            {
                path: "/distributions",
                name: "Completed Distributions",
                miniName: "S",
                component: CompletedDistributions,
                layout: "/admin",
            },
            {
                path: "/eas-incentive",
                name: "Advocacy Incentive Report",
                miniName: "S",
                component: EasIncentiveReport,
                layout: "/admin",
            },
            {
                path: "/sales-incentive",
                name: "Sales Incentive Report",
                miniName: "S",
                component: SalesIncentiveReport,
                layout: "/admin",
            },
        ],
    },
    {
        collapse: true,
        name: "Pending Distribution",
        icon: "ni ni-support-16 text-green",
        state: "pendingDistributionCollapse",
        invisible: ["National Manager", "Regional Manager", "Area Manager", "Superadmin", "tms"],
        views: [
            {
                path: "/incentive-sections",
                name: "Incentive Selections",
                miniName: "S",
                component: IncentiveSelections,
                layout: "/admin",
            },
            {
                path: "/pendingdistribution",
                name: "Pending Distribution",
                miniName: "S",
                component: PendingDistribution,
                layout: "/admin",
            },
        ],
    },
    {
        collapse: true,
        name: "Photo Frame",
        icon: "ni ni-delivery-fast text-purple",
        state: "photoFrameCollapse",
        invisible: ["National Manager", "Regional Manager", "Area Manager", "tms"],
        views: [
            {
                path: "/photoFrame",
                name: "List Frame",
                miniName: "F",
                component: PhotoFrame,
                layout: "/admin",
                invisible: ["National Manager", "Regional Manager", "Area Manager"],
            },
            {
                path: "/addPhotoFrame",
                name: "Add Frame",
                miniName: "A",
                component: AddPhotoFrame,
                layout: "/admin",
                invisible: ["National Manager", "Regional Manager", "Area Manager"],
            },
        ],
    },
    {
        collapse: true,
        name: "Sales Status",
        icon: "ni ni-delivery-fast text-yellow",
        state: "salesCollapse",
        invisible: ["National Manager", "Regional Manager", "Area Manager", "tms"],
        views: [
            {
                path: "/viewSales",
                name: "View Sales",
                miniName: "V",
                component: SalesData,
                layout: "/admin",
                invisible: ["National Manager", "Regional Manager", "Area Manager"],
            },
            {
                path: "/importSales",
                name: "Import Sales",
                miniName: "I",
                component: ImportSales,
                layout: "/admin",
                invisible: [
                    "National Manager",
                    "Territory Manager",
                    "Regional Manager",
                    "Area Manager",
                ],
            },
        ],
    },
    {
        collapse: true,
        name: "Call Status",
        icon: "ni ni-chat-round text-primary",
        state: "callCollapse",
        invisible: ["National Manager", "Regional Manager", "Area Manager", "tms"],
        views: [
            {
                path: "/leadGen",
                name: "Lead Generate",
                miniName: "L",
                component: LeadGenerate,
                layout: "/admin",
            },
            {
                path: "/activeMonth",
                name: "Calling Month",
                miniName: "C",
                component: CallingMonth,
                layout: "/admin",
            }
        ],
    },
    {
        collapse: true,
        name: "Settings",
        icon: "ni ni-ui-04 text-primary",
        state: "settingsCollapse",
        invisible: [
            "National Manager",
            "Territory Manager",
            "Regional Manager",
            "Area Manager", "tms"
        ],
        views: [
            {
                path: "/regionSetting",
                name: "Region Setting",
                miniName: "R",
                component: RegionSetting,
                layout: "/admin",
            },
            {
                path: "/areaSetting",
                name: "Area Setting",
                miniName: "A",
                component: AreaSetting,
                layout: "/admin",
            },
            {
                path: "/territorySetting",
                name: "Territory Setting",
                miniName: "T",
                component: TerritorySetting,
                layout: "/admin",
            },
            {
                path: "/salesPointSetting",
                name: "Sales Point Setting",
                miniName: "T",
                component: SalesPointSetting,
                layout: "/admin",
            },
            {
                path: "/routeSetting",
                name: "Route Setting",
                miniName: "R",
                component: RouteSetting,
                layout: "/admin",
            },
            {
                path: "/outletSetting",
                name: "Outlet Setting",
                miniName: "O",
                component: OutletSetting,
                layout: "/admin",
            },
            {
                path: "/brandSetting",
                name: "Brand Setting",
                miniName: "B",
                component: BrandSetting,
                layout: "/admin",
            },
            {
                path: "/communicationSetting",
                name: "Communication Setting",
                miniName: "S",
                component: CommunicationSetting,
                layout: "/admin",
            },
            {
                path: "/communicationType",
                name: "Communication Type",
                miniName: "T",
                component: CommunicationType,
                layout: "/admin",
            },
            {
                path: "/roleSetting",
                name: "Role Setting",
                miniName: "R",
                component: RoleSetting,
                layout: "/admin",
            },
            {
                path: "/importData",
                name: "Import Data",
                miniName: "I",
                component: ImportData,
                layout: "/admin",
            },
        ],
    },
    {
        path: "/complainAdmin",
        name: "Complain Admin",
        icon: "ni ni-chat-round text-red",
        component: ComplainAdmin,
        layout: "/admin",
        api: true,
        invisible: [
            "National Manager",
            "Territory Manager",
            "Regional Manager",
            "Area Manager",
            "Superadmin", "tms"
        ],
    },

    {
        path: "/complainUser",
        name: "Complain Center",
        icon: "ni ni-bag-17 text-purple",
        component: ComplainUser,
        layout: "/admin",
        api: true,
        invisible: [
            "National Manager",
            "Territory Manager",
            "Regional Manager",
            "Area Manager",
            "Superadmin", "tms"
        ],
    },
    {
        path: "/login",
        name: "Login",
        component: Login,
        layout: "/auth",
        invisible: [
            "National Manager",
            "Territory Manager",
            "Regional Manager",
            "Area Manager",
            "Superadmin", "tms"
        ],
    },
    {
        path: "/calender",
        name: "Calender",
        component: CalendarView,
        layout: "/admin",
        invisible: [
            "National Manager",
            "Territory Manager",
            "Regional Manager",
            "Area Manager",
            "Superadmin", "tms"
        ],
    },
    {
        path: "/change-password",
        name: "Change Password",
        component: ChangePassword,
        layout: "/admin",
        invisible: [
            "National Manager",
            "Territory Manager",
            "Regional Manager",
            "Area Manager",
            "Superadmin"
        ],
    },
];

export default routes;
