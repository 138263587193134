import React from "react";
import ReactDOM from "react-dom";
// react library for routing
import { BrowserRouter, Switch, Redirect, Route } from "react-router-dom";

// plugins styles from node_modules
import "react-notification-alert/dist/animate.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import "@fullcalendar/common/main.min.css";
import "@fullcalendar/daygrid/main.min.css";
import "sweetalert2/dist/sweetalert2.min.css";
import "select2/dist/css/select2.min.css";
import "quill/dist/quill.core.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
// plugins styles downloaded
import "assets/vendor/nucleo/css/nucleo.css";
// core styles
import "assets/scss/argon-dashboard-pro-react.scss?v1.2.0";

import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";
import { DataProvider } from "context/DataContext";
import PrivateRoute from "components/PrivateRoute/PrivateRoute";
import AuthRoutes from "components/PrivateRoute/AuthRoutes";
import Teams from "./views/pages/Teams";

ReactDOM.render(
  <DataProvider>
    <BrowserRouter>
      <Switch>
          <Route exact path="/view/teams">
              <Teams/>
          </Route>
        <PrivateRoute path="/admin" component={AdminLayout} />
        <AuthRoutes path="/auth" component={AuthLayout} />
        {/* <PrivateRoute path="/auth/login" component={Login} /> */}
        {/* <Route path="/" render={(props) => <IndexView {...props} />} /> */}
        <Redirect from="/" to="/admin/dashboard" />
      </Switch>
    </BrowserRouter>
  </DataProvider>,
  document.getElementById("root")
);
