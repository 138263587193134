import React, { useEffect } from "react";
// react component for creating dynamic tables
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

// reactstrap components11
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Row,
} from "reactstrap";
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";

import axios from "axios";
import NotificationAlert from "react-notification-alert";
import moment from "moment";
import baseApi1 from "../../api/BaseApi1";

const { SearchBar } = Search;
const AdvocacyEntry = () => {
  const [showPerPage, setShowPerPage] = React.useState(null);
  const { jwt_token } = JSON.parse(localStorage.getItem("user"));
  //Manual Entry
  const [retailerNumber, setRetailerNumber] = React.useState("");
  const [consumerNumber, setConsumerNumber] = React.useState("");
  //Pending List
  const [pendingList, setPendingList] = React.useState([]);
  const [approveAllStatus, setApproveAllStatus] = React.useState(false);

  //Notification Alert
  const notificationAlertRef = React.useRef(null);
  const notify = (type, message) => {
    let options = {
      place: "bc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            Advocacy Notification
          </span>
          <span data-notify="message">{message}</span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 7,
    };
    notificationAlertRef.current.notificationAlert(options);
  };
  const componentRef = React.useRef(null);
  //Load Pending List
  const getPendingAdvocacyData = async () => {
    try {
      const response = await baseApi1.get("/api/my-advocacy", {
        headers: {
          Authorization: `Bearer ${jwt_token}`,
        },
      });
      if (!response.statusText === "OK") {
        notify("danger", response.data.message);
      } else {
        setPendingList(response.data.data);
      }
    } catch (error) {
      notify("danger", error.response.data.message);
    }
  };
  //Approve All
  const approveAll = async () => {
    setApproveAllStatus(true);
    try {
      const response = await baseApi1.post(
        "/api/my-advocacy/approve-all",
        {},
        {
          headers: {
            Authorization: `Bearer ${jwt_token}`,
          },
        }
      );
      if (!response.statusText === "OK") {
        notify("danger", response.data.message);
      } else {
        getPendingAdvocacyData();
        notify("success", response.data.message);
        setApproveAllStatus(false);
      }
    } catch (error) {
      notify("danger", error.response.data.message);
    }
  };
  //Approve One
  const approveOne = async (id) => {
    setApproveAllStatus(true);
    const approveID = JSON.stringify({ _id: id });
    try {
      const response = await baseApi1.post(
        "/api/my-advocacy/approve",
        approveID,
        {
          headers: {
            Authorization: `Bearer ${jwt_token}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (!response.statusText === "OK") {
        notify("danger", response.data.message);
      } else {
        getPendingAdvocacyData();
        notify("success", response.data.message);
        setApproveAllStatus(false);
      }
    } catch (error) {
      notify("danger", error.response.data.message);
    }
  };
  //Delete One
  const deleteOne = async (id) => {
    const deleteID = JSON.stringify({ _id: id });
    try {
      const response = await baseApi1.delete(
        `/api/my-advocacy/${id}`,
        deleteID,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (!response.statusText === "OK") {
        notify("danger", response.data.message);
      } else {
        getPendingAdvocacyData();
        notify("danger", response.data.data);
      }
    } catch (error) {
      notify("danger", error.response.data.message);
    }
  };
  // Manual Entry
  const doAdvocacy = async () => {
    const advocacyData = JSON.stringify({
      from: retailerNumber,
      content: `B ${consumerNumber}`,
    });
    try {
      const response = await baseApi1.post(
        "/sms/check",
        advocacyData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (!response.statusText === "OK") {
        notify("danger", response.data.message);
      } else {
        setRetailerNumber("");
        setConsumerNumber("");
        notify("success", response.data.message);
      }
    } catch (error) {
      notify("danger", error.response.data.message);
      if (error.response.data.message) {
        setRetailerNumber("");
        setConsumerNumber("");
      }
    }
  };

  const handleDoAdvocacy = () => {
    doAdvocacy();
  };

  useEffect(() => {
    getPendingAdvocacyData();
  }, []);
  const handleApproveAll = () => {
    approveAll();
  };
  const pagination = paginationFactory({
    page: 1,
    alwaysShowAllBtns: true,
    showTotal: true,
    withFirstAndLast: false,
    sizePerPageRenderer: ({
      options,
      currSizePerPage,
      onSizePerPageChange,
    }) => (
      <div className="dataTables_length" id="datatable-basic_length">
        <label>
          Show{" "}
          {
            <select
              name="datatable-basic_length"
              aria-controls="datatable-basic"
              className="form-control form-control-sm"
              onChange={(e) => onSizePerPageChange(e.target.value)}
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
              <option value="all">All</option>
            </select>
          }{" "}
          entries.
        </label>
      </div>
    ),
  });

  return (
    <div>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <SimpleHeader name="EAS Advocacy List" parentName="EAS Advocacy" />
      <Container className="mt--6" fluid>
        <Card>
          <CardHeader>
            <h3 style={{ color: "#DB5A3B" }} className="mb-0">
              EAS Advocacy (Manual Entry)
            </h3>
          </CardHeader>
          <CardBody>
            <Form>
              <FormGroup>
                <label
                  className="form-control-label"
                  htmlFor="exampleFormControlInput1"
                >
                  Retailer Number
                </label>
                <Input
                  id="exampleFormControlInput1"
                  placeholder="Retailer Number"
                  value={retailerNumber}
                  onChange={(e) => setRetailerNumber(e.target.value)}
                  type="text"
                />
              </FormGroup>
              <FormGroup>
                <label
                  className="form-control-label"
                  htmlFor="exampleFormControlInput1"
                >
                  Consumer Number
                </label>
                <Input
                  id="exampleFormControlInput1"
                  value={consumerNumber}
                  placeholder="Consumer Number"
                  onChange={(e) => setConsumerNumber(e.target.value)}
                  type="text"
                />
              </FormGroup>
              <div className="text-right">
                <Button
                  onClick={handleDoAdvocacy}
                  color="warning"
                  type="button"
                >
                  Submit
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="d-flex justify-content-between">
                <h3 style={{ color: "#DB5A3B" }}>
                  EAS Advocacy (Approval List)
                </h3>
              </CardHeader>
              <ToolkitProvider
                data={pendingList}
                keyField="outletCode"
                columns={[
                  {
                    dataField: "#",
                    text: "SL",
                    formatter: (cell, row, rowIndex) => {
                      return rowIndex + 1;
                    },
                  },
                  {
                    dataField: "region",
                    text: "Region",
                    sort: true,
                    formatter: (cell) => {
                      return cell?.name;
                    },
                  },
                  {
                    dataField: "area",
                    text: "Area",
                    sort: true,
                    formatter: (cell) => {
                      return cell?.name;
                    },
                  },
                  {
                    dataField: "territory",
                    text: "Territory",
                    sort: true,
                    formatter: (cell) => {
                      return cell?.name;
                    },
                  },
                  {
                    dataField: "outletCode",
                    text: "Outlet Code",
                    sort: true,
                  },
                  {
                    dataField: "retailerPhone",
                    text: "Retailer Phone",
                    sort: true,
                  },
                  {
                    dataField: "phone",
                    text: "Consumer Phone",
                    sort: true,
                  },
                  {
                    dataField: "date",
                    text: "Request Time",
                    sort: true,
                    formatter: (cell) => {
                      return moment(new Date(cell)).format(
                        "MMMM Do YYYY, h:mm:ss a"
                      );
                    },
                  },
                  {
                    dataField: "_id",
                    text: "Action",
                    formatter: (cell) => {
                      const handleApprove = () => {
                        approveOne(cell);
                      };
                      const handleDelete = () => {
                        deleteOne(cell);
                      };
                      return (
                        <div>
                          <Button
                            disabled={approveAllStatus}
                            color="success"
                            onClick={handleApprove}
                          >
                            Approve
                          </Button>
                          <Button onClick={handleDelete} color="danger">
                            Delete
                          </Button>
                        </div>
                      );
                    },
                  },
                ]}
                search
              >
                {(props) => (
                  <div className="py-4 table-responsive">
                    <Container fluid>
                      <Row>
                        <Col xs={4} sm={4}>
                          <div>
                            <select
                              name="datatable-basic_length"
                              aria-controls="datatable-basic"
                              className="form-control form-control-sm w-25"
                              onChange={(e) => setShowPerPage(e.target.value)}
                            >
                              <option
                                selected={showPerPage === 10 && true}
                                value={10}
                              >
                                10
                              </option>
                              <option
                                selected={showPerPage === 25 && true}
                                value={25}
                              >
                                25
                              </option>
                              <option
                                selected={showPerPage === 50 && true}
                                value={50}
                              >
                                50
                              </option>
                              <option
                                selected={showPerPage === 100 && true}
                                value={100}
                              >
                                100
                              </option>
                              <option
                                selected={showPerPage === "all" && true}
                                value="all"
                              >
                                all
                              </option>
                            </select>
                          </div>
                        </Col>
                        <Col xs={4} sm={4}>
                          <Button
                            color="success"
                            disabled={approveAllStatus}
                            size="sm"
                            onClick={handleApproveAll}
                          >
                            Approve All
                          </Button>
                        </Col>
                        <Col xs={4} sm={4}>
                          <div
                            id="datatable-basic_filter"
                            className="dataTables_filter px-4 pb-1 float-right"
                          >
                            <label>
                              Search:
                              <SearchBar
                                className="form-control-sm"
                                placeholder=""
                                {...props.searchProps}
                              />
                            </label>
                          </div>
                        </Col>
                      </Row>
                    </Container>
                    <BootstrapTable
                      ref={componentRef}
                      {...props.baseProps}
                      bootstrap4={true}
                      pagination={pagination}
                      bordered={false}
                      id="react-bs-table"
                    />
                  </div>
                )}
              </ToolkitProvider>
            </Card>
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default AdvocacyEntry;
