import React, {useEffect, useLayoutEffect} from "react";
// react plugin that prints a given react component
import ReactToPrint from "react-to-print";
import classnames from "classnames";
// react component for creating dynamic tables
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {Search} from "react-bootstrap-table2-toolkit";

// reactstrap components11
import {
    Button,
    ButtonGroup,
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Col,
    Container,
    Form,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Modal,
    Row,
    UncontrolledTooltip,
} from "reactstrap";
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";

import axios from "axios";
import NotificationAlert from "react-notification-alert";
import CurrencyFormat from "react-currency-format";
import {useFileUpload} from "use-file-upload";
import baseApi1 from "../../api/BaseApi1";
import baseApi2 from "../../api/BaseApi2";
import {baseURL2, baseURL1} from "../../api/baseURL.json";

const {SearchBar} = Search;

const IncentiveSetting = () => {
    const [showPerPage, setShowPerPage] = React.useState(10);
    const [page, setPage] = React.useState(1);
    const [searchTerm, setSearchTerm] = React.useState("");
    const [totalSize, setTotalSize] = React.useState(10);
    //Edit Incentive
    const [incentiveName, setIncentiveName] = React.useState("");
    const [incentiveType, setIncentiveType] = React.useState("");
    const [incentiveDescription, setIncentiveDescription] = React.useState("");
    const [incentivePhoto, setIncentivePhoto] = useFileUpload();
    //Add Incentive
    const [newIncentiveName, setNewIncentiveName] = React.useState("");
    const [newIncentiveType, setNewIncentiveType] = React.useState("");
    const [newIncentiveDescription, setNewIncentiveDescription] =
        React.useState("");
    const [newIncentivePhoto, setNewIncentivePhoto] = useFileUpload();
    //Others
    const defaultSrc = "https://i.ibb.co/sv6vLrj/paper.png";
    const [formModal, setFormModal] = React.useState(false);
    const [addIncentiveModal, setAddIncentiveModal] = React.useState(false);
    const [allIncentive, setAllIncentive] = React.useState([]);
    const [singleIncentive, setSingleIncentive] = React.useState({});

    const [statusData, setStatusData] = React.useState({});
    const [editStatus, setEditStatus] = React.useState("");

    //Notification Alert
    const notificationAlertRef = React.useRef(null);
    const notify = (type, message) => {
        let options = {
            place: "bc",
            message: (
                <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
              !Incentive
          </span>
                    <span data-notify="message">{message}</span>
                </div>
            ),
            type: type,
            icon: "ni ni-bell-55",
            autoDismiss: 7,
        };
        notificationAlertRef.current.notificationAlert(options);
    };

    const componentRef = React.useRef(null);

    const getIncentives = async () => {
        try {
            const response = await axios.get(
                `${baseURL2}:5000/api/getIncentive?limit=${showPerPage}&page=${page}&search=${searchTerm}`
            );
            if (response.statusText !== "OK") {
                notify("danger", "Something went Wrong");
            } else {
                setAllIncentive(response?.data?.data);
                setTotalSize(parseInt(response?.data?.total));
            }
        } catch (e) {
            console.log(e.response);
        }
    };

    const getOneIncentive = async (id) => {
        try {
            const response = await axios.get(
                `${baseURL2}:5000/api/getOneIncentive/${id}`
            );
            if (response.statusText !== "OK") {
                notify("danger", "Something went Wrong");
            } else {
                setSingleIncentive(response?.data?.data);
            }
        } catch (e) {
            console.log(e.response);
        }
    };

    useLayoutEffect(() => {
        getIncentives();
    }, [page, showPerPage, searchTerm]);

    const submitNewIncentive = async () => {
        console.log("test");
        const newIncentive = new FormData();
        newIncentive.append("name", newIncentiveName);
        newIncentive.append("description", newIncentiveDescription);
        newIncentive.append("type", newIncentiveType);
        newIncentive.append("photo", newIncentivePhoto?.file);
        try {
            const response = await axios.post(
                `${baseURL2}:5000/api/addIncentive`,
                newIncentive,
                {
                    headers: {"Content-Type": "application/json"},
                }
            );
            if (response.statusText !== "OK") {
                notify("danger", "Something Went Wrong");
            } else {
                notify("success", response?.data?.message);
                setAddIncentiveModal(false);
                getIncentives();
            }
        } catch (e) {
            console.log(e.response);
        }
    };

    const pagination = paginationFactory({
        page,
        totalSize,
        showTotal: true,
        sizePerPage: showPerPage,
        alwaysShowAllBtns: true,
        withFirstAndLast: true,
        onPageChange: (page) => {
            setPage(page);
        },
        onSizePerPageChange: (_, size) => {
            setShowPerPage(size);
        },
        sizePerPageRenderer: ({
                                  options,
                                  currSizePerPage,
                                  onSizePerPageChange,
                              }) => (
            <div className="dataTables_length" id="datatable-basic_length">
                <label>
                    Show{" "}
                    {
                        <select
                            value={currSizePerPage}
                            name="datatable-basic_length"
                            aria-controls="datatable-basic"
                            className="form-control form-control-sm"
                            onChange={(e) => onSizePerPageChange(e.target.value)}
                        >
                            <option value={10}>10</option>
                            <option value={25}>25</option>
                            <option value={50}>50</option>
                            <option value={100}>100</option>
                            <option value="all">all</option>
                        </select>
                    }{" "}
                    entries.
                </label>
            </div>
        ),
    });

    const updateOneIncentive = async (id) => {
        const updateIncentive = new FormData();
        updateIncentive.append(
            "name",
            incentiveName === "" ? singleIncentive?.name : incentiveName
        );
        updateIncentive.append(
            "description",
            incentiveDescription === ""
                ? singleIncentive?.description
                : incentiveDescription
        );
        updateIncentive.append(
            "type",
            incentiveType === "" ? singleIncentive?.type : incentiveType
        );
        updateIncentive.append("oldPhoto", singleIncentive?.photo);
        updateIncentive.append(
            "photo",
            incentivePhoto?.file !== undefined && incentivePhoto?.file
        );
        try {
            const response = await axios.put(
                `${baseURL2}:5000/api/updateOneIncentive/${id}`,
                updateIncentive,
                {headers: {"Content-Type": "application/json"}}
            );
            if (response.statusText !== "OK") {
                notify("danger", "Something Went Wrong");
            } else {
                notify("success", response.data.message);
                setFormModal(false);
                getIncentives();
            }
        } catch (e) {
            console.log(e.response);
        }
    };

    const deleteIncentiove = async (id) => {
        try {
            const response = await axios.delete(
                `${baseURL2}:5000/api/deleteOneIncentive/${id}`
            );
            if (response.statusText !== "OK") {
                notify("danger", "Something Went Wrong");
            } else {
                notify("success", response?.data?.message);
                getIncentives();
            }
        } catch (e) {
            console.log(e.response);
        }
    };

    const getEditStatus = async () => {
        try {
            const response = await axios.get(
                `${baseURL2}:5000/api/getEditStatus`
            );
            if (response.statusText !== "OK") {
                notify("danger", "Something Went Wrong");
            } else {
                setStatusData(response?.data);
            }
        } catch (e) {
            console.log(e.response);
        }
    };

    React.useEffect(() => {
        getEditStatus();
    }, []);

    const submitEditStatus = async () => {
        const payload = {
            status: editStatus,
        };
        try {
            const response = await axios.post(
                `${baseURL2}:5000/api/addEditStatus`,
                payload,
                {
                    headers: {"Content-Type": "application/json"},
                }
            );
            if (response.statusText !== "OK") {
                notify("danger", "Something Went Wrong");
            } else {
                notify("success", response?.data?.message);
                getEditStatus();
            }
        } catch (e) {
            console.log(e.response);
        }
    };

    function imageFormatter(cell) {
        return (
            <img
                style={{width: 50, height: 50}}
                className="rounded"
                src={`${baseURL1}/media/incentives/${cell}`}
                alt="icon"
            />
        );
    }

    return (
        <div>
            <div className="rna-wrapper">
                <NotificationAlert ref={notificationAlertRef}/>
            </div>
            <SimpleHeader name="Incentive List" parentName="Incentive"/>
            <Container className="mt--6" fluid>
                <Row>
                    <div className="col">
                        <Card>
                            <CardHeader className="d-flex justify-content-between">
                                <h3 style={{color: "#DB5A3B"}}>Incentive Setting</h3>
                                <Button
                                    onClick={() => setAddIncentiveModal(true)}
                                    style={{backgroundColor: "#DB5A3B", color: "white"}}
                                >
                                    Add New
                                </Button>
                            </CardHeader>
                            <div className="m-3" style={{width: "18rem"}}>
                                <Card className="card-stats bg-gradient-warning mb-4 mb-lg-0">
                                    <CardBody>
                                        <Row>
                                            <div className="col">
                                                <CardTitle className="text-uppercase text-white text-muted mb-0">
                                                    Total Incentive
                                                </CardTitle>
                                                <span className="h2 font-weight-bold mb-0 text-white">
                          <CurrencyFormat
                              value={totalSize}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={""}
                          />
                        </span>
                                            </div>
                                            <Col className="col-auto">
                                                <div
                                                    className="icon icon-shape bg-danger text-white rounded-circle shadow">
                                                    <i className="fas fa-chart-bar"/>
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </div>
                            <ToolkitProvider
                                data={allIncentive}
                                keyField="name"
                                columns={[
                                    {
                                        dataField: "photo",
                                        text: "Photo",
                                        formatter: imageFormatter,
                                    },
                                    {
                                        dataField: "#",
                                        text: "SL",
                                        formatter: (cell, row, rowIndex) => {
                                            return rowIndex + 1;
                                        },
                                    },
                                    {
                                        dataField: "name",
                                        text: "Name",
                                        sort: true,
                                    },
                                    {
                                        dataField: "description",
                                        text: "Description",
                                        sort: true,
                                    },
                                    {
                                        dataField: "type",
                                        text: "Type",
                                        sort: true,
                                    },
                                    {
                                        dataField: "_id",
                                        text: "Action",
                                        formatter: (cell) => {
                                            const handleEdit = () => {
                                                setFormModal(true);
                                                getOneIncentive(cell);
                                            };
                                            const handleDelete = () => {
                                                deleteIncentiove(cell);
                                            };
                                            return (
                                                <div>
                                                    <Button color="info" onClick={handleEdit}>
                                                        Edit
                                                    </Button>
                                                    <Button color="danger" onClick={handleDelete}>
                                                        Delete
                                                    </Button>
                                                </div>
                                            );
                                        },
                                    },
                                ]}
                                search
                            >
                                {(props) => (
                                    <div className="py-4 table-responsive">
                                        <Container fluid>
                                            <Row>
                                                <Col xs={4} sm={4}>
                                                    <div>
                                                        <select
                                                            name="datatable-basic_length"
                                                            aria-controls="datatable-basic"
                                                            className="form-control form-control-sm w-25"
                                                            onChange={(e) => setShowPerPage(e.target.value)}
                                                        >
                                                            <option
                                                                selected={showPerPage === 10 && true}
                                                                value={10}
                                                            >
                                                                10
                                                            </option>
                                                            <option
                                                                selected={showPerPage === 25 && true}
                                                                value={25}
                                                            >
                                                                25
                                                            </option>
                                                            <option
                                                                selected={showPerPage === 50 && true}
                                                                value={50}
                                                            >
                                                                50
                                                            </option>
                                                            <option
                                                                selected={showPerPage === 100 && true}
                                                                value={100}
                                                            >
                                                                100
                                                            </option>
                                                            <option
                                                                selected={showPerPage === "all" && true}
                                                                value="all"
                                                            >
                                                                all
                                                            </option>
                                                        </select>
                                                    </div>
                                                </Col>
                                                <Col xs={4} sm={4}>
                                                    <ButtonGroup>
                                                        <Button
                                                            className="buttons-copy buttons-html5"
                                                            color="warning"
                                                            size="sm"
                                                            id="copy-tooltip"
                                                            onClick={() =>
                                                                window.open(
                                                                    `${baseURL2}:5000/api/getIncentiveCsv`
                                                                )
                                                            }
                                                        >
                                                            <span>CSV</span>
                                                        </Button>
                                                        <ReactToPrint
                                                            trigger={() => (
                                                                <Button
                                                                    color="warning"
                                                                    size="sm"
                                                                    className="buttons-copy buttons-html5"
                                                                    id="print-tooltip"
                                                                >
                                                                    Print
                                                                </Button>
                                                            )}
                                                            content={() => componentRef.current}
                                                        />
                                                    </ButtonGroup>
                                                    <UncontrolledTooltip
                                                        placement="top"
                                                        target="print-tooltip"
                                                    >
                                                        This will open a print page with the visible rows of
                                                        the table.
                                                    </UncontrolledTooltip>
                                                    <UncontrolledTooltip
                                                        placement="top"
                                                        target="copy-tooltip"
                                                    >
                                                        This will Download Communication Setting As CSV.
                                                    </UncontrolledTooltip>
                                                </Col>
                                                <Col xs={4} sm={4}>
                                                    <div
                                                        id="datatable-basic_filter"
                                                        className="dataTables_filter px-4 pb-1 float-right"
                                                    >
                                                        <label>
                                                            Search:
                                                            <SearchBar
                                                                className="form-control-sm"
                                                                placeholder=""
                                                                {...props.searchProps}
                                                            />
                                                        </label>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Container>
                                        <BootstrapTable
                                            remote
                                            ref={componentRef}
                                            {...props.baseProps}
                                            bootstrap4={true}
                                            pagination={pagination}
                                            bordered={false}
                                            hover={true}
                                            id="react-bs-table"
                                            onTableChange={(type, {searchText}) => {
                                                setSearchTerm(searchText);
                                            }}
                                        />
                                    </div>
                                )}
                            </ToolkitProvider>
                        </Card>
                    </div>
                </Row>
            </Container>
            <Container className="mt-3" fluid>
                <Row>
                    <div className="col">
                        <Card>
                            <CardHeader className="d-flex justify-content-between">
                                <h3 style={{color: "#DB5A3B"}}>Edit Status</h3>
                            </CardHeader>
                            <Container>
                                <FormGroup>
                                    <label
                                        className="form-control-label mt-3 mb-3"
                                        htmlFor="exampleFormControlSelect1"
                                    >
                                        Edit Status
                                    </label>
                                    <Row>
                                        <Col xs={8} sm={8} md={8} lg={8}>
                                            <Input
                                                id="exampleFormControlSelect1"
                                                onChange={(e) => setEditStatus(e.target.value)}
                                                type="select"
                                            >
                                                <option>Select</option>
                                                <option
                                                    selected={statusData?.status === "active"}
                                                    value="active"
                                                >
                                                    Active
                                                </option>
                                                <option
                                                    selected={statusData?.status === "inactive"}
                                                    value="inactive"
                                                >
                                                    Inactive
                                                </option>
                                            </Input>
                                        </Col>
                                        <Col xs={4} sm={4} md={4} lg={4}>
                                            <Button
                                                onClick={() => submitEditStatus()}
                                                color="success"
                                            >
                                                Submit
                                            </Button>
                                        </Col>
                                    </Row>
                                </FormGroup>
                            </Container>
                        </Card>
                    </div>
                </Row>
            </Container>
            {/* Edit Modal */}
            <Modal
                className="modal-dialog-centered"
                size="md"
                isOpen={formModal}
                toggle={() => setFormModal(false)}
            >
                <div className="modal-body p-0">
                    <Card className="bg-secondary border-0 mb-0">
                        <CardHeader style={{color: "#DB5A3B"}} className="bg-transparent">
                            Update Incentive
                        </CardHeader>
                        <CardBody className="px-lg-5 py-lg-5">
                            <Form role="form">
                                <FormGroup
                                    className={classnames("mb-3", {
                                        focused: incentiveName,
                                    })}
                                >
                                    <label
                                        className="form-control-label"
                                        htmlFor="exampleFormControlSelect1"
                                    >
                                        Incentive Name
                                    </label>
                                    <InputGroup className="input-group-merge input-group-alternative">
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="ni ni-caps-small"/>
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            placeholder="Region ID"
                                            defaultValue={singleIncentive?.name}
                                            type="name"
                                            onChange={(e) => setIncentiveName(e.target.value)}
                                        />
                                    </InputGroup>
                                </FormGroup>

                                <FormGroup
                                    className={classnames("mb-3", {
                                        focused: incentiveDescription,
                                    })}
                                >
                                    <label
                                        className="form-control-label"
                                        htmlFor="exampleFormControlSelect1"
                                    >
                                        Incentive Description
                                    </label>
                                    <InputGroup className="input-group-merge input-group-alternative">
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="ni ni-caps-small"/>
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            placeholder="Incentive Description"
                                            defaultValue={singleIncentive?.description}
                                            type="name"
                                            onChange={(e) => setIncentiveDescription(e.target.value)}
                                        />
                                    </InputGroup>
                                </FormGroup>

                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="exampleFormControlSelect1"
                                    >
                                        Incentive Type
                                    </label>
                                    <Input
                                        id="exampleFormControlSelect1"
                                        onChange={(e) => setIncentiveType(e.target.value)}
                                        type="select"
                                    >
                                        <option>Select</option>
                                        <option
                                            selected={singleIncentive?.type === "type1"}
                                            value="type1"
                                        >
                                            Type 1
                                        </option>
                                        <option
                                            selected={singleIncentive?.type === "type2"}
                                            value="type2"
                                        >
                                            Type 2
                                        </option>
                                    </Input>
                                </FormGroup>
                                <label
                                    className="form-control-label"
                                    htmlFor="exampleFormControlSelect1"
                                >
                                    Incentive Photo
                                </label>
                                <div className="text-center">
                                    <img
                                        src={
                                            incentivePhoto?.source ||
                                            `${baseURL2}:5000/incentiveMedia/${singleIncentive?.photo}`
                                        }
                                        alt="preview"
                                        className="fluid w-25 rounded mb-3"
                                    />
                                    <br/>
                                    <Button
                                        color="primary"
                                        outline
                                        type="button"
                                        onClick={() => setIncentivePhoto({accept: "image/*"})}
                                    >
                                        Upload Incentive Photo
                                    </Button>
                                </div>
                                <div className="text-center">
                                    <Button
                                        onClick={() => setFormModal(false)}
                                        className="my-4"
                                        color="danger"
                                        type="button"
                                    >
                                        Close
                                    </Button>
                                    <Button
                                        onClick={() => updateOneIncentive(singleIncentive?._id)}
                                        className="my-4"
                                        color="success"
                                        type="button"
                                    >
                                        Update
                                    </Button>
                                </div>
                            </Form>
                        </CardBody>
                    </Card>
                </div>
            </Modal>
            {/* Add Incentive Type */}
            <Modal
                className="modal-dialog-centered"
                size="md"
                isOpen={addIncentiveModal}
                toggle={() => setAddIncentiveModal(false)}
            >
                <div className="modal-body p-0">
                    <Card className="bg-secondary border-0 mb-0">
                        <CardHeader style={{color: "#DB5A3B"}} className="bg-transparent">
                            Add Incentive Type
                        </CardHeader>
                        <CardBody className="px-lg-5 py-lg-5">
                            <Form role="form">
                                <FormGroup
                                    className={classnames("mb-3", {
                                        focused: newIncentiveName,
                                    })}
                                >
                                    <label
                                        className="form-control-label"
                                        htmlFor="exampleFormControlSelect1"
                                    >
                                        Incentive Name
                                    </label>
                                    <InputGroup className="input-group-merge input-group-alternative">
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="ni ni-caps-small"/>
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            placeholder="Incentive Name"
                                            type="name"
                                            onChange={(e) => setNewIncentiveName(e.target.value)}
                                        />
                                    </InputGroup>
                                </FormGroup>

                                <FormGroup
                                    className={classnames("mb-3", {
                                        focused: newIncentiveDescription,
                                    })}
                                >
                                    <label
                                        className="form-control-label"
                                        htmlFor="exampleFormControlSelect1"
                                    >
                                        Incentive Description
                                    </label>
                                    <InputGroup className="input-group-merge input-group-alternative">
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="ni ni-caps-small"/>
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            placeholder="Incentive Description"
                                            type="name"
                                            onChange={(e) =>
                                                setNewIncentiveDescription(e.target.value)
                                            }
                                        />
                                    </InputGroup>
                                </FormGroup>

                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="exampleFormControlSelect1"
                                    >
                                        Incentive Type
                                    </label>
                                    <Input
                                        id="exampleFormControlSelect1"
                                        onChange={(e) => setNewIncentiveType(e.target.value)}
                                        type="select"
                                    >
                                        <option>Select</option>
                                        <option value="type1">Type 1</option>
                                        <option value="type2">Type 2</option>
                                    </Input>
                                </FormGroup>

                                <label
                                    className="form-control-label"
                                    htmlFor="exampleFormControlSelect1"
                                >
                                    Incentive Photo
                                </label>
                                <div className="text-center">
                                    <img
                                        src={newIncentivePhoto?.source || defaultSrc}
                                        alt="preview"
                                        className="fluid w-25 rounded mb-3"
                                    />
                                    <br/>
                                    <Button
                                        color="primary"
                                        outline
                                        type="button"
                                        onClick={() => setNewIncentivePhoto({accept: "image/*"})}
                                    >
                                        Upload Incentive Photo
                                    </Button>
                                </div>
                                <div className="text-right">
                                    <Button
                                        onClick={() => setAddIncentiveModal(false)}
                                        className="my-4"
                                        color="danger"
                                        type="button"
                                    >
                                        Close
                                    </Button>
                                    <Button
                                        onClick={() => submitNewIncentive()}
                                        className="my-4"
                                        color="success"
                                        type="button"
                                    >
                                        Add
                                    </Button>
                                </div>
                            </Form>
                        </CardBody>
                    </Card>
                </div>
            </Modal>
        </div>
    );
};

export default IncentiveSetting;
