import React, {useContext, useEffect} from "react";
// react plugin that prints a given react component
import ReactToPrint from "react-to-print";
// react component for creating dynamic tables
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {Search} from "react-bootstrap-table2-toolkit";
import ReactLoading from "react-loading";
// reactstrap components11
import {
    Button,
    ButtonGroup,
    Card,
    CardHeader,
    Container,
    CardBody,
    FormGroup,
    Form,
    Input,
    Row,
    Col,
    UncontrolledTooltip,
    CardTitle,
    InputGroup,
} from "reactstrap";
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";

import axios from "axios";
import NotificationAlert from "react-notification-alert";
import DataContext from "context/DataContext";
import moment from "moment";
import baseApi1 from "../../api/BaseApi1";
import {baseURL1} from "../../api/baseURL.json";

const {SearchBar} = Search;

const ViewStatus = () => {
    const {region, area, territory, salesPoint, tms} = useContext(DataContext);
    const [showPerPage, setShowPerPage] = React.useState(25);
    const [page, setPage] = React.useState(1);
    const [searchTerm, setSearchTerm] = React.useState("");
    const [totalSize, setTotalSize] = React.useState(10);
    const userInfo = JSON.parse(localStorage.getItem("user"));
    //Filters
    const [filterStartDate, setFilterStartDate] = React.useState("");
    const [filterEndDate, setFilterEndDate] = React.useState("");
    const [targetType, setTargetType] = React.useState("");
    const [filterRegion, setFilterRegion] = React.useState("");
    const [filterArea, setFilterArea] = React.useState("");
    const [filterTerritory, setFilterTerritory] = React.useState("");
    const [filterSalesPoint, setFilterSalesPoint] = React.useState("");
    const [selectedTms, setSelectedTms] = React.useState("");
    const [allView, setAllView] = React.useState([]);
    //Charts
    const [summary, setSummary] = React.useState([]);
    //Notification Alert
    const notificationAlertRef = React.useRef(null);
    const notify = (type, message) => {
        let options = {
            place: "bc",
            message: (
                <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
              View Status Notification
          </span>
                    <span data-notify="message">{message}</span>
                </div>
            ),
            type: type,
            icon: "ni ni-bell-55",
            autoDismiss: 7,
        };
        notificationAlertRef.current.notificationAlert(options);
    };
    //DropdownFilter
    const newfilteredArea = area.filter((item) => item.regionId === (filterRegion === "" ? userInfo?.region?._id : filterRegion));
    const newfilteredTerritory = territory.filter(
        (item) => item.areaId === filterArea
    );
    const newfilteredSalesPoint = salesPoint.filter(
        (item) =>
            item.territory._id ===
            (filterTerritory === "" ? userInfo?.territory?._id : filterTerritory)
    );
    const newfilteredTms = tms.filter(
        (item) =>
            item.territoryId ===
            (filterTerritory === "" ? userInfo?.territory?._id : filterTerritory)
    );

    const componentRef = React.useRef(null);
    const getViewData = async () => {
        try {
            const response = await baseApi1.get(
                `/api/status/all?startDate=${filterStartDate}&endDate=${filterEndDate}&targetType=${targetType}&region=${
                    userInfo.region === undefined ? filterRegion : userInfo.region._id
                }&area=${
                    userInfo.area === undefined ? filterArea : userInfo.area._id
                }&territory=${
                    userInfo.territory === undefined
                        ? filterTerritory
                        : userInfo.territory._id
                }&salesPoint=${filterSalesPoint}&tms=${selectedTms}&page=${page}&limit=${showPerPage}&search=${searchTerm}`
            );
            if (!response.statusText === "OK") {
                console.log("Not Getting Response");
            } else {
                setAllView(response?.data?.data);
                setTotalSize(response.data.total);
            }
        } catch (error) {
            notify("danger", error.response.message);
        }
    };

    const pagination = paginationFactory({
        page,
        totalSize,
        showTotal: true,
        sizePerPage: showPerPage,
        alwaysShowAllBtns: true,
        withFirstAndLast: true,
        onPageChange: (page) => {
            setPage(page);
        },
        onSizePerPageChange: (_, size) => {
            setShowPerPage(size);
        },
        sizePerPageRenderer: ({
                                  options,
                                  currSizePerPage,
                                  onSizePerPageChange,
                              }) => (
            <div className="dataTables_length" id="datatable-basic_length">
                <label>
                    Show{" "}
                    {
                        <select
                            value={currSizePerPage}
                            name="datatable-basic_length"
                            aria-controls="datatable-basic"
                            className="form-control form-control-sm"
                            onChange={(e) => onSizePerPageChange(e.target.value)}
                        >
                            <option value={10}>10</option>
                            <option value={25}>25</option>
                            <option value={50}>50</option>
                            <option value={100}>100</option>
                            <option value="all">all</option>
                        </select>
                    }{" "}
                    entries.
                </label>
            </div>
        ),
    });
    const getSummaryData = async () => {
        try {
            const response = await baseApi1.get(
                `/api/status/summary?startDate=${filterStartDate}&endDate=${filterEndDate}&targetType=${targetType}&region=${
                    userInfo.region === undefined ? filterRegion : userInfo.region._id
                }&area=${
                    userInfo.area === undefined ? filterArea : userInfo.area._id
                }&territory=${
                    userInfo.territory === undefined
                        ? filterTerritory
                        : userInfo.territory._id
                }&salesPoint=${filterSalesPoint}&tms=${selectedTms}&page=${page}&limit=${showPerPage}&search=${searchTerm}`
            );
            if (!response.statusText === "OK") {
                console.log("Not Getting Response");
            } else {
                setSummary(response.data);
            }
        } catch (error) {
            notify("danger", error.response.message);
        }
    };
    useEffect(() => {
        if (filterStartDate !== "" && filterEndDate !== "") {
            getViewData();
        }
    }, [page, showPerPage, searchTerm]);

    const handleViewData = () => {
        getViewData();
        getSummaryData();
    };

    return (
        <div>
            <div className="rna-wrapper">
                <NotificationAlert ref={notificationAlertRef}/>
            </div>
            <SimpleHeader name="View Status" parentName="Status"/>
            <Container className="mt--6" fluid>
                <Row>
                    <div className="col">
                        <Card>
                            <CardHeader className="d-flex justify-content-between">
                                <h3 style={{color: "#DB5A3B"}}>Filters</h3>
                            </CardHeader>
                            <Row className="mt-3">
                                <Col xs={4} sm={4}>
                                    <div className="ml-2" style={{width: "18rem"}}>
                                        <Card className="card-stats bg-gradient-primary mb-4 mb-lg-0">
                                            <CardBody>
                                                <Row>
                                                    <div className="col">
                                                        <CardTitle
                                                            className="text-uppercase text-white text-muted mb-0">
                                                            EAS Status
                                                        </CardTitle>
                                                        <CardTitle className="text-white text-muted mb-0">
                                                            Achivement vs Target
                                                        </CardTitle>
                                                        <span className="h2 font-weight-bold mb-0 text-white">
                              {`${
                                  summary[0]?.achieved.toLocaleString() ===
                                  undefined
                                      ? 0
                                      : summary[0]?.achieved.toLocaleString()
                              }/${
                                  summary[0]?.target.toLocaleString() ===
                                  undefined
                                      ? 0
                                      : summary[0]?.target.toLocaleString()
                              }`}
                            </span>
                                                    </div>
                                                    <Col className="col-auto">
                                                        <div
                                                            className="icon icon-shape bg-danger text-white rounded-circle shadow">
                              <span>
                                {isNaN(
                                    Math.round(
                                        (summary[0]?.achieved /
                                            summary[0]?.target) *
                                        100
                                    )
                                )
                                    ? 0 + "%"
                                    : Math.round(
                                    (summary[0]?.achieved /
                                        summary[0]?.target) *
                                    100
                                ) + "%"}
                              </span>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </div>
                                </Col>
                                <Col xs={4} sm={4}>
                                    <div className="" style={{width: "18rem"}}>
                                        <Card className="card-stats bg-gradient-info mb-4 mb-lg-0">
                                            <CardBody>
                                                <Row>
                                                    <div className="col">
                                                        <CardTitle
                                                            className="text-uppercase text-white text-muted mb-0">
                                                            Portfolio Status
                                                        </CardTitle>
                                                        <CardTitle className="text-white text-muted mb-0">
                                                            Achivement vs Target
                                                        </CardTitle>
                                                        <span className="h2 font-weight-bold mb-0 text-white">
                              {`${
                                  summary[1]?.achieved.toLocaleString() ===
                                  undefined
                                      ? 0
                                      : summary[1]?.achieved.toLocaleString()
                              }/${
                                  summary[1]?.target
                                      .toFixed(0)
                                      .toLocaleString() === undefined
                                      ? 0
                                      : summary[1]?.target
                                          .toFixed(0)
                                          .toLocaleString()
                              }`}
                            </span>
                                                    </div>
                                                    <Col className="col-auto">
                                                        <div
                                                            className="icon icon-shape bg-danger text-white rounded-circle shadow">
                              <span>
                                {isNaN(
                                    Math.round(
                                        (summary[1]?.achieved /
                                            summary[1]?.target) *
                                        100
                                    )
                                )
                                    ? 0 + "%"
                                    : Math.round(
                                    (summary[1]?.achieved /
                                        summary[1]?.target) *
                                    100
                                ) + "%"}
                              </span>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </div>
                                </Col>
                                <Col xs={4} sm={4}>
                                    <div className="" style={{width: "18rem"}}>
                                        <Card className="card-stats bg-gradient-success mb-4 mb-lg-0 ">
                                            <CardBody>
                                                <Row>
                                                    <div className="col">
                                                        <CardTitle
                                                            className="text-uppercase text-white text-muted mb-0">
                                                            Focus Status
                                                        </CardTitle>
                                                        <CardTitle className="text-white text-muted mb-0">
                                                            Achivement vs Target
                                                        </CardTitle>
                                                        <span className="h2 font-weight-bold mb-0 text-white">
                              {`${
                                  summary[2]?.achieved.toLocaleString() ===
                                  undefined
                                      ? 0
                                      : summary[2]?.achieved.toLocaleString()
                              }/${
                                  summary[2]?.target.toLocaleString() ===
                                  undefined
                                      ? 0
                                      : summary[2]?.target.toLocaleString()
                              }`}
                            </span>
                                                    </div>
                                                    <Col className="col-auto">
                                                        <div
                                                            className="icon icon-shape bg-danger text-white rounded-circle shadow">
                              <span>
                                {isNaN(
                                    Math.round(
                                        (summary[2]?.achieved /
                                            summary[2]?.target) *
                                        100
                                    )
                                )
                                    ? 0 + "%"
                                    : Math.round(
                                    (summary[2]?.achieved /
                                        summary[2]?.target) *
                                    100
                                ) + "%"}
                              </span>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </div>
                                </Col>
                            </Row>
                            <CardBody className="px-lg-5 py-lg-5">
                                <Form role="form">
                                    <Row className="row-example">
                                        <Col xs={3} sm={3}>
                                            <FormGroup>
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="example-date-input"
                                                    md="2"
                                                >
                                                    Start Date
                                                </label>
                                                <Input
                                                    id="example-date-input"
                                                    defaultValue={filterStartDate}
                                                    type="date"
                                                    onChange={(e) =>
                                                        setFilterStartDate(
                                                            moment(new Date(e.target.value)).format(
                                                                "yyyy-MM-DD"
                                                            )
                                                        )
                                                    }
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col xs={3} sm={3}>
                                            <FormGroup>
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="example-date-input"
                                                    md="2"
                                                >
                                                    End Date
                                                </label>

                                                <Input
                                                    id="example-date-input"
                                                    defaultValue={filterEndDate}
                                                    type="date"
                                                    onChange={(e) =>
                                                        setFilterEndDate(
                                                            moment(new Date(e.target.value)).format(
                                                                "yyyy-MM-DD"
                                                            )
                                                        )
                                                    }
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col xs={3} sm={3}>
                                            <FormGroup>
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="example-date-input"
                                                    md="2"
                                                >
                                                    Target Type
                                                </label>

                                                <Input
                                                    id="exampleFormControlSelect1"
                                                    onChange={(e) => setTargetType(e.target.value)}
                                                    type="select"
                                                >
                                                    <option>Select</option>
                                                    <option value="eas">EAS</option>
                                                    <option value="portfolio">Portfolio</option>
                                                    <option value="focus">Focus Volume</option>
                                                    <option value="all">All</option>
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                        <Col xs={3} sm={3}>
                                            <FormGroup>
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="exampleFormControlSelect1"
                                                >
                                                    Region
                                                </label>
                                                {userInfo.region === undefined ? (
                                                    <Input
                                                        id="exampleFormControlSelect1"
                                                        onChange={(e) => setFilterRegion(e.target.value)}
                                                        type="select"
                                                    >
                                                        <option>Select</option>
                                                        {region.map((region) => (
                                                            <option value={region._id}>{region.name}</option>
                                                        ))}
                                                    </Input>
                                                ) : (
                                                    <InputGroup className="input-group-merge input-group-alternative">
                                                        <Input
                                                            type="name"
                                                            value={userInfo.region.name}
                                                            disabled
                                                        />
                                                    </InputGroup>
                                                )}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row className="row-example">
                                        <Col xs={3} sm={3}>
                                            <FormGroup>
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="exampleFormControlSelect1"
                                                >
                                                    Area
                                                </label>
                                                {userInfo.area === undefined ? (
                                                    <Input
                                                        id="exampleFormControlSelect1"
                                                        onChange={(e) => setFilterArea(e.target.value)}
                                                        type="select"
                                                    >
                                                        <option>Select</option>
                                                        {newfilteredArea.map((area) => (
                                                            <option value={area._id}>{area.name}</option>
                                                        ))}
                                                    </Input>
                                                ) : (
                                                    <InputGroup className="input-group-merge input-group-alternative">
                                                        <Input
                                                            type="name"
                                                            value={userInfo.area.name}
                                                            disabled
                                                        />
                                                    </InputGroup>
                                                )}
                                            </FormGroup>
                                        </Col>
                                        <Col xs={3} sm={3}>
                                            <FormGroup>
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="exampleFormControlSelect1"
                                                >
                                                    Territory
                                                </label>
                                                {userInfo.territory === undefined ? (
                                                    <Input
                                                        id="exampleFormControlSelect1"
                                                        onChange={(e) => setFilterTerritory(e.target.value)}
                                                        type="select"
                                                    >
                                                        <option>Select</option>
                                                        {newfilteredTerritory.map((territory) => (
                                                            <option value={territory._id}>
                                                                {territory.name}
                                                            </option>
                                                        ))}
                                                    </Input>
                                                ) : (
                                                    <InputGroup className="input-group-merge input-group-alternative">
                                                        <Input
                                                            type="name"
                                                            value={userInfo.territory.name}
                                                            disabled
                                                        />
                                                    </InputGroup>
                                                )}
                                            </FormGroup>
                                        </Col>
                                        <Col xs={3} sm={3}>
                                            <FormGroup>
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="exampleFormControlSelect1"
                                                >
                                                    Sales Point
                                                </label>
                                                <Input
                                                    id="exampleFormControlSelect1"
                                                    onChange={(e) => setFilterSalesPoint(e.target.value)}
                                                    type="select"
                                                >
                                                    <option>Select</option>
                                                    {newfilteredSalesPoint.map((sp) => (
                                                        <option value={sp._id}>{sp.name}</option>
                                                    ))}
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                        <Col xs={3} sm={3}>
                                            <FormGroup>
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="exampleFormControlSelect1"
                                                >
                                                    TMS
                                                </label>
                                                <Input
                                                    id="exampleFormControlSelect1"
                                                    onChange={(e) => setSelectedTms(e.target.value)}
                                                    type="select"
                                                >
                                                    <option>Select</option>
                                                    {newfilteredTms?.map((tms) => (
                                                        <option value={tms._id}>
                                                            {`${tms.name} (${tms.phone})`}
                                                        </option>
                                                    ))}
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <div className="text-right">
                                        <Button
                                            onClick={handleViewData}
                                            color="warning"
                                            type="button"
                                        >
                                            View
                                        </Button>
                                    </div>
                                </Form>
                            </CardBody>
                        </Card>
                    </div>
                </Row>
                <Row>
                    <div className="col">
                        <Card>
                            <CardHeader className="d-flex justify-content-between">
                                <h3 style={{color: "#DB5A3B"}}>View Outlet Status</h3>
                            </CardHeader>
                            <ToolkitProvider
                                data={allView}
                                keyField="outlet"
                                columns={[
                                    {
                                        dataField: "#",
                                        text: "SL",
                                        formatter: (cell, row, rowIndex) => {
                                            return rowIndex + 1;
                                        },
                                    },
                                    {
                                        dataField: "region",
                                        text: "Region",
                                        sort: true,
                                    },
                                    {
                                        dataField: "area",
                                        text: "Area",
                                        sort: true,
                                    },
                                    {
                                        dataField: "territory",
                                        text: "Territory",
                                        sort: true,
                                    },
                                    {
                                        dataField: "salesPoint",
                                        text: "Sales Point",
                                        sort: true,
                                    },
                                    {
                                        dataField: "route",
                                        text: "Route",
                                        sort: true,
                                    },
                                    {
                                        dataField: "outlet",
                                        text: "Outlet Code",
                                        sort: true,
                                    },
                                    {
                                        dataField: "targetType",
                                        text: "Target Type",
                                        sort: true,
                                    },
                                    {
                                        dataField: "brand",
                                        text: "Focus Brand",
                                        sort: true,
                                    },
                                    {
                                        dataField: "month",
                                        text: "Target Month",
                                        sort: true,
                                        formatter: (cell, row) => {
                                            return row.month + 1 + "/" + row.year;
                                        },
                                    },
                                    {
                                        dataField: "target",
                                        text: "Target",
                                        sort: true,
                                    },
                                    {
                                        dataField: "achieved",
                                        text: "Achieved",
                                        sort: true,
                                    },
                                    {
                                        dataField: "percent",
                                        text: "Percentage",
                                        sort: true,
                                        formatter: (cell) => {
                                            return isNaN(Math.round(cell))
                                                ? 0 + "%"
                                                : Math.round(cell) + "%";
                                        },
                                    },
                                    {
                                        dataField: "remarks",
                                        text: "Remakrs",
                                        sort: true,
                                    },
                                ]}
                                search
                            >
                                {(props) => (
                                    <div className="py-4 table-responsive">
                                        <Container fluid>
                                            <Row>
                                                <Col xs={4} sm={4}>
                                                    <div>
                                                        <select
                                                            name="datatable-basic_length"
                                                            aria-controls="datatable-basic"
                                                            className="form-control form-control-sm w-25"
                                                            onChange={(e) => setShowPerPage(e.target.value)}
                                                        >
                                                            <option
                                                                selected={showPerPage === 10 && true}
                                                                value={10}
                                                            >
                                                                10
                                                            </option>
                                                            <option
                                                                selected={showPerPage === 25 && true}
                                                                value={25}
                                                            >
                                                                25
                                                            </option>
                                                            <option
                                                                selected={showPerPage === 50 && true}
                                                                value={50}
                                                            >
                                                                50
                                                            </option>
                                                            <option
                                                                selected={showPerPage === 100 && true}
                                                                value={100}
                                                            >
                                                                100
                                                            </option>
                                                            <option
                                                                selected={showPerPage === "all" && true}
                                                                value="all"
                                                            >
                                                                all
                                                            </option>
                                                        </select>
                                                    </div>
                                                </Col>
                                                <Col xs={4} sm={4}>
                                                    <ButtonGroup>
                                                        <Button
                                                            className="buttons-copy buttons-html5"
                                                            color="warning"
                                                            size="sm"
                                                            id="copy-tooltip"
                                                            onClick={() =>
                                                                window.open(
                                                                    `${baseURL1}/api/status/csv?startDate=${filterStartDate}&endDate=${filterEndDate}&targetType=${targetType}&region=${
                                                                        userInfo.region === undefined
                                                                            ? filterRegion
                                                                            : userInfo.region._id
                                                                    }&area=${
                                                                        userInfo.area === undefined
                                                                            ? filterArea
                                                                            : userInfo.area._id
                                                                    }&territory=${
                                                                        userInfo.territory === undefined
                                                                            ? filterTerritory
                                                                            : userInfo.territory._id
                                                                    }&salesPoint=${filterSalesPoint}&tms=${selectedTms}`
                                                                )
                                                            }
                                                        >
                                                            <span>CSV</span>
                                                        </Button>
                                                        <ReactToPrint
                                                            trigger={() => (
                                                                <Button
                                                                    color="warning"
                                                                    size="sm"
                                                                    className="buttons-copy buttons-html5"
                                                                    id="print-tooltip"
                                                                >
                                                                    Print
                                                                </Button>
                                                            )}
                                                            content={() => componentRef.current}
                                                        />
                                                    </ButtonGroup>
                                                    <UncontrolledTooltip
                                                        placement="top"
                                                        target="print-tooltip"
                                                    >
                                                        This will open a print page with the visible rows of
                                                        the table.
                                                    </UncontrolledTooltip>
                                                    <UncontrolledTooltip
                                                        placement="top"
                                                        target="copy-tooltip"
                                                    >
                                                        This will Download Outlet Setting As CSV.
                                                    </UncontrolledTooltip>
                                                </Col>
                                                <Col xs={4} sm={4}>
                                                    <div
                                                        id="datatable-basic_filter"
                                                        className="dataTables_filter px-4 pb-1 float-right"
                                                    >
                                                        <label>
                                                            Search:
                                                            <SearchBar
                                                                className="form-control-sm"
                                                                placeholder=""
                                                                {...props.searchProps}
                                                            />
                                                        </label>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Container>
                                        <BootstrapTable
                                            remote
                                            ref={componentRef}
                                            {...props.baseProps}
                                            bootstrap4={true}
                                            pagination={pagination}
                                            bordered={false}
                                            id="react-bs-table"
                                            onTableChange={(type, {searchText}) => {
                                                setSearchTerm(searchText);
                                            }}
                                        />
                                    </div>
                                )}
                            </ToolkitProvider>
                        </Card>
                    </div>
                </Row>
            </Container>
        </div>
    );
};

export default ViewStatus;
